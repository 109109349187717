<template>
  <div v-if="post">
    <div class="d-flex align-center">
      <v-icon size="36" :color="getSocialMediaTypeColor(post.socialProfile.type)">
        {{ getSocialMediaTypeIcon(post.socialProfile.type) }}
      </v-icon>
      <v-card-title>{{ getSocialMediaTypeIndividualTitle(post.socialProfile.type) }}</v-card-title>
    </div>
    <v-card>
      <v-list-item>
        <v-list-item-avatar>
          <img v-if="post.socialProfile.avatar" :src="post.socialProfile.avatar"/>
          <img v-else src="https://s.pinimg.com/images/user/default_60.png"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ post.socialProfile.username }}
          </v-list-item-title>
          <v-list-item-action-text>
            Just now
          </v-list-item-action-text>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon size="22">mdi-dots-horizontal</v-icon>
        </v-list-item-action>
      </v-list-item>
      <div class="px-4">
        <p v-html="postDescription"></p>
      </div>
      <div v-if="post.media.length > 0" class="d-flex flex-column align-center">
        <v-carousel v-model="carousal" hide-delimiters v-if="post.media.length > 0"
                    :show-arrows="post.media.length > 1" height="100%">
          <v-carousel-item v-for="(item,i) in post.media" :key="i">
            <img v-if="item.type.includes('image')" :src="item.url" width="100%"/>
            <video v-else :src="item.url" :poster="item.thumbnail.url" width="100%" controls/>
          </v-carousel-item>
        </v-carousel>
      </div>
      <v-row class="px-4 py-4" justify="center" dense>
        <v-col cols="4" class="d-flex justify-center">
          <v-icon size="18">mdi-thumb-up-outline</v-icon>
          <span class="ml-2 font-weight-bold text-caption">
            Like
          </span>
        </v-col>
        <v-col cols="4" class="d-flex justify-center">
          <v-icon size="18">mdi-comment-outline</v-icon>
          <span class="ml-2 font-weight-bold text-caption">
            Comment
          </span>
        </v-col>
        <v-col cols="4" class="d-flex justify-center">
          <v-icon size="18">mdi-share-outline</v-icon>
          <span class="ml-2 font-weight-bold text-caption">
            Share
          </span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {getSocialMediaTypeIndividualTitle, getSocialMediaTypeIcon, getSocialMediaTypeColor} from '@/utils/local'

export default {
  name: "FacebookView",
  props: {
    post: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      carousal: null
    }
  },
  computed: {
    postDescription() {
      return this.post.description ? this.post.description.replaceAll('\n', '<br/>') : null
    }
  },
  methods: {
    getSocialMediaTypeIndividualTitle,
    getSocialMediaTypeIcon,
    getSocialMediaTypeColor
  }
}
</script>

<style scoped>

</style>