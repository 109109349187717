<template>
    <div>
        <v-card v-for="(item, key) in items" :key="key"
                class="my-4 px-4 py-2"
                :class="item.id === selectedItem.id ? 'select-item' : ''"
                elevation="0"
                :color="item.id !== selectedItem.id ? '#e5e7eb' : '#2177B0'"
                @click="onSelection(item)"
        >
            <v-row>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1" class="d-flex justify-center align-center">
                    <v-icon
                        :color="item.id !== selectedItem.id ? 'gray' : 'white'"
                    >mdi-check-circle
                    </v-icon>
                </v-col>
                <v-col cols="9" class="mt-4">
                    <h5 :style="item.id !== selectedItem.id ? 'color:gray' : 'color:white'" >{{ item.title }}</h5>
                    <p style="font-size: 12px" :style="item.id !== selectedItem.id ? 'color:gray' : 'color:white'" class="text-secondary">{{ item.sub_title }}</p>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'RadioListWithIcon',
    props: {
        items: {
            type: Array,
            required: true,
        },
        selectedItem: {
            type: Object,
            default: () => {}
        },
        onSelection: {
            type: Function,
            required: true,
        }
    },
}
</script>

<style scoped>
.select-category {
    cursor: not-allowed;
    background: #2177B0;
    color: white;
}
</style>
