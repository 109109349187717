<template>
  <v-layout fill-height>
    <v-app-bar app dense elevation="0">
      <div
          v-if="title !== 'Publisher' && title !== 'Get Started' && title !== 'Plan Canceled' && title !== 'Payment Required'"
          class="mr-2 d-flex align-center justify-center pointer" @click="$router.back()">
        <v-icon>mdi-chevron-left</v-icon>
        <p v-if="!$vuetify.breakpoint.xs" class="ma-0 pointer">Back</p>
      </div>
      <v-card-title class="text-body-1 text-sm-h6">{{ title }}</v-card-title>
      <v-spacer/>
      <div v-if="!$vuetify.breakpoint.smAndDown">

        <v-btn v-if="title === 'Publisher'"
               class="mr-2" color="primary"
               elevation="0" @click="$store.commit('setOpenGenerateIdeaModal', true)">
          <v-icon class="mr-2" small>mdi-lightbulb-on-outline</v-icon>
          Ideas
        </v-btn>
        <v-btn
                id="addSocialPlatform"
            v-if="title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required' && title !== 'Customize Lead Page' && title !== 'Leads'"
            class="mr-2 darken-1" color="primary"
            elevation="0" @click="$store.commit('setOpenSocialConnector', true)">
          <v-icon class="mr-2" small>mdi-bullhorn</v-icon>
          Add Social Platform
        </v-btn>
        <v-btn
                id="copyLeadUrl"
            v-if="title === 'Leads' && title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            class="mr-2" color="primary" elevation="0"
            outlined @click="copyURL">
          <v-icon class="mr-2" small>mdi-content-copy</v-icon>
          Copy Lead URL
        </v-btn>
        <v-btn
                id="changeLeadUrl"
            v-if="title === 'Leads' && title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            class="mr-2" color="primary" elevation="0"
            outlined @click="openModal = true">
          <v-icon class="mr-2" small>mdi-pencil</v-icon>
          Change Lead URL
        </v-btn>
        <v-btn
                id="customizeLeadPage"
            v-if="title === 'Leads' && title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            class="mr-2" color="primary" elevation="0"
            outlined @click="$router.push('customize/lead-page')">
          <v-icon class="mr-2" small>mdi-palette</v-icon>
          Customize Lead Page
        </v-btn>
        <v-btn
                id="reloadBtn"
            v-if="title === 'Social Platforms' && title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            class="mr-2" color="primary" elevation="0" outlined @click="reloadSocialPlatforms">
          <v-icon v-if="!reloading" class="mr-2" small>mdi-reload</v-icon>
          {{ !reloading ? 'Reload' : 'Reloading' }}
          <span v-if="reloading">
                  <v-progress-circular class="ml-2" indeterminate size="20" width="2"/>
                </span>
        </v-btn>

        <button id="editProfileButton" class="d-none" type="button" @click="openEditForm"></button>

        <v-btn id="reportAnIssue" class="mr-2" color="primary" elevation="0" outlined @click="openReportIssueModal()">
          <v-icon class="mr-2" small>mdi-bug</v-icon>
          Report an Issue
        </v-btn>
        <v-btn id="signOut" v-if="title === 'Get Started' || title === 'Plan Canceled' || title === 'Payment Required'" color="primary"
               elevation="0" outlined @click="signOut">
          <v-icon class="mr-2" small>mdi-logout</v-icon>
          Sign Out
        </v-btn>
        <ProfilePopup
            v-if="title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            @openPromoter="openPromoter"/>
      </div>
      <v-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="rightDrawer = !rightDrawer">mdi-menu-open</v-icon>
    </v-app-bar>
    <v-main>
      <v-banner
          v-if="disconnectedPlatforms && disconnectedPlatforms.length > 0"
          :single-line="$vuetify.breakpoint.mdAndUp"
          class="mx-auto"
          outlined
          rounded
          sticky
          style="background: rgb(254 167 167)"
      >
        <v-icon slot="icon" color="error darken-3">
          mdi-alert-circle
        </v-icon>
        <span v-if="disconnectedPlatforms.length < 2">
          Your
          {{ getSocialMediaTypeIndividualTitle(disconnectedPlatforms[0].type).toLowerCase() }}
          account {{ disconnectedPlatforms[0].username }} has some issues.</span>
        <span v-else>Some of your social platforms have issues.</span>
        <v-btn
                id="reconnect"
            v-if="title !== 'Social Platforms'"
            :to="'/social-platforms'"
            class="ml-3"
            color="black"
            outlined
        >
          Reconnect
        </v-btn>
      </v-banner>
      <v-banner
          v-if="showPaymentFailed"
          :single-line="$vuetify.breakpoint.mdAndUp"
          class="mx-auto"
          outlined
          rounded
          sticky
          style="background: rgb(254 167 167)"
      >
        <v-icon slot="icon" color="error darken-3">
          mdi-alert-circle
        </v-icon>
        <span>Payment Failed. We will try again but in case payment doesn't go through within 24 hours, you will need to provide a new payment method.</span>
      </v-banner>
      <v-banner
          v-if="isCanceledAtPeriodEnd"
          :single-line="$vuetify.breakpoint.mdAndUp"
          class="mx-auto"
          outlined
          rounded
          sticky
          style="background: #ffb65e"
      >
        <v-icon slot="icon" color="warning darken-3">
          mdi-alert-circle
        </v-icon>
        <span>Your subscription has been canceled. You can use postredi till {{ canceledAtPeriodEnd }}. All scheduled posts will be moved to draft after period ends.</span>
        <span class="primary--text mx-2 pointer text-decoration-underline" @click="revertCancellation">Reactivate</span>
      </v-banner>
      <router-view/>
    </v-main>
    <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndDown"
        v-model="rightDrawer"
        app
        right
    >
      <div class="pa-4 d-flex justify-center align-center flex-column" style="width: 100%;">
        <ProfileDrawer v-if="title !== 'Get Started'" class="mb-2" @openPromoter="openPromoter"/>

        <v-btn
                id="ideas"
            v-if="title !== 'Account Settings' && title !== 'Leads' && title !== 'Customize Lead Page' && title !== 'Get Started'"
            class="mb-2 darken-1" color="primary"
            elevation="0" width="100%" @click="$store.commit('setOpenGenerateIdeaModal', true)">
          <v-icon class="mr-2" small>mdi-lightbulb-on-outline</v-icon>
          Ideas
        </v-btn>
        <v-btn
                id="addSocialPlatform"
            v-if="title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required' && title !== 'Customize Lead Page' && title !== 'Leads'"
            class="mb-2" color="primary"
            elevation="0" width="100%" @click="$store.commit('setOpenSocialConnector', true)">
          <v-icon class="mr-2" small>mdi-bullhorn</v-icon>
          Add Social Platform
        </v-btn>
        <v-btn id="copyLeadUrl" v-if="title === 'Leads' && title !== 'Get Started'" class="mb-2" color="primary" elevation="0"
               outlined width="100%" @click="copyURL">
          <v-icon class="mr-2" small>mdi-content-copy</v-icon>
          Copy Lead URL
        </v-btn>

        <v-btn if="changeLeadUrl" v-if="title === 'Leads' && title !== 'Get Started'" class="mb-2" color="primary"
               elevation="0"
               outlined width="100%" @click="openModal = true">
          <v-icon class="mr-2" small>mdi-pencil</v-icon>
          Change Lead URL
        </v-btn>


        <v-btn
            id="customizeLeadPage" v-if="title === 'Leads' && title !== 'Get Started'  && title !== 'Plan Canceled' && title !== 'Payment Required'"
            class="mb-2" color="primary" elevation="0"
            outlined width="100%" @click="$router.push('customize/lead-page')">
          <v-icon class="mr-2" small>mdi-palette</v-icon>
          Customize Lead Page
        </v-btn>
        <v-btn id="reload" v-if="title === 'Social Platforms' && title !== 'Get Started'" class="mb-2" color="primary" elevation="0"
               outlined
               width="100%" @click="reloadSocialPlatforms">
          <v-icon v-if="!reloading" class="mr-2" small>mdi-reload</v-icon>
          {{ !reloading ? 'Reload' : 'Reloading' }}
          <span v-if="reloading">
                  <v-progress-circular class="ml-2" indeterminate size="20" width="2"/>
                </span>
        </v-btn>
        <v-btn id="editProfile" class="mb-2" color="primary"
               elevation="0" width="100%" @click="openEditForm">
          <v-icon class="mr-2" small>mdi-account</v-icon>
          Edit Profile
        </v-btn>
        <v-btn id="changePassword" class="mb-2" color="primary"
               elevation="0" outlined width="100%" @click="openSecurityForm">
          <v-icon class="mr-2" small>mdi-lock</v-icon>
          Change Password
        </v-btn>
        <v-btn id="reportAnIssue" class="mb-2" color="primary"
               elevation="0" outlined width="100%" @click="openReportIssueModal()">
          <v-icon class="mr-2" small>mdi-bug</v-icon>
          Report an Issue
        </v-btn>
        <v-btn id="signOut"  class="mb-2" elevation="0"
               outlined width="100%" @click="signOut">
          <v-icon class="mr-2" small>mdi-logout</v-icon>
          Sign Out
        </v-btn>
      </div>
    </v-navigation-drawer>
    <SocialConnectionDialog v-model="socialConnectionDialog"/>
    <ProfileForm v-model="profileForm" :user="user"/>
    <SecurityForm v-model="securityForm"/>
    <ReportIssueForm v-model="reportIssueForm"/>
    <LoadingDialog v-model="loading" :message="loadingMessage"/>
    <ErrorDialog v-model="error" :error="errorVal"/>
    <BecomePromoter v-model="promoterDialog" :accepted="acceptedPromoter" @becomePromoter="becomePromoter"/>

    <v-dialog :value="openModal" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" persistent
              @input="openModal = false">
      <v-card class="pa-4" style="position: relative">
        <div style="position:absolute; top: 0; right: 0">
          <v-avatar class="pointer" @click="closeLeadForm">
            <v-icon>mdi-close</v-icon>
          </v-avatar>
        </div>
        <div>
          <div>
            <h3 class="text-md-h4 text-h5 mb-4 primary--text font-weight-medium">Change Lead URL</h3>
          </div>

          <v-form ref="leadUrlForm">
            <v-text-field v-model="leadUrl" :disabled="loading" :rules="[required(), this.checkURL()]" outlined
                          placeholder="Lead URL"></v-text-field>


            <v-btn id="submitBtn" :disabled="loading" class="mb-6" color="primary" elevation="0" width="100%" x-large
                   @click.prevent="changeUrl">
              <span>{{ loading ? 'Submitting' : 'Submit' }}</span>
              <v-progress-circular v-if="loading" class="ml-3" indeterminate size="18" width="2"/>
            </v-btn>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-tour v-if="title === 'Publisher'" :steps="steps" tourKey="startUpTour"/>
  </v-layout>
</template>

<script>
import SocialConnectionDialog from "@/components/social-platforms/SocialConnectionDialog";
import ProfilePopup from "@/components/ProfilePopup";
import {getSocialMediaTypeIndividualTitle, getUserDB, webAppUrl} from "@/utils/local";
import ProfileDrawer from "@/components/ProfileDrawer";
import ProfileForm from "@/components/auth/ProfileEdit";
import SecurityForm from "@/components/auth/SecurityEdit";
import ReportIssueForm from "@/components/auth/ReportIssueForm";
import VTour from "@/components/VTour";
import dayjs from "dayjs";
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import {required} from "@/utils/validators";
import axios from "axios";
import BecomePromoter from "@/components/BecomePromoter";

export default {
  name: 'BaseLayout',
  components: {
    BecomePromoter,
    ErrorDialog,
    LoadingDialog,
    SecurityForm,
    ProfileForm,
    ProfileDrawer,
    ProfilePopup,
    SocialConnectionDialog,
    ReportIssueForm,
    VTour,
  },
  data() {
    return {
      reloading: false,
      rightDrawer: false,
      profileForm: false,
      securityForm: false,
      reportIssueForm: false,
      loading: false,
      loadingMessage: 'Loading...',
      error: false,
      promoterDialog: false,
      acceptedPromoter: false,

      leadUrl: '',
      openModal: false,
      errorVal: {},

      steps: [
        {
          'image': require('../assets/images/video_1.gif'),
          'title': 'Add Social Platforms',
          'description': 'Tap on the Add Social Platform Button on the top right. A dialog will appear and you can easily connect your facebook, instagram, linkedin and twitter account.',
        },
        {
          'image': require('../../src/assets/images/video_2.gif'),
          'title': 'Create & Schedule Post',
          'description': 'Tap on "New Post" button on the top left, select your social accounts, write caption, attach media and done. Now you can Schedule for later or Post Now.' +
              'No worries if you run out of post ideas, we got your back, click on "generate post ideas" button, select the desiered content and done.',
        },
        {
          'image': require('../../src/assets/images/video_3.gif'),
          'title': 'What To Post?',
          'description': 'Never run out of ideas – our Social media management packages gives you highly engaging real estate investing posts you can “curate” or model.',
        }],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    socialConnectionDialog() {
      return this.$store.state.openSocialConnector
    },
    showPaymentFailed() {
      return this.$store.state.user.subscription && this.$store.state.user.customer.default_payment_method && (this.$store.state.user.subscription.status === 'incomplete' || this.$store.state.user.subscription.status === 'past_due') && !this.$route.path.includes('get-started')
    },
    title() {
      switch (this.$route.path) {
        case '/':
          return 'Publisher'
        case '/customize/lead-page':
          return 'Customize Lead Page'
        case '/leads':
          return 'Leads'
        case '/social-platforms':
          return 'Social Platforms'
        case '/get-started':
          return 'Get Started'
        case '/plan-canceled':
          return 'Plan Canceled'
        case '/payment-required':
          return 'Payment Required'
        case '/account':
          return 'Account Settings'
        case '/add-payment':
          return 'Add Payment'
        case '/promoter':
          return 'Promoter'
        default:
          return 'Publisher'
      }
    },
    disconnectedPlatforms() {
      return this.$store.state.socialPlatforms.filter((o) => o.isReAuthRequired)
    },
    isCanceledAtPeriodEnd() {
      return this.$store.state.user.subscription && this.$store.state.user.subscription.cancel_at_period_end && this.$store.state.user.subscription.status !== 'canceled'
    },
    canceledAtPeriodEnd() {
      if (this.isCanceledAtPeriodEnd) {
        return this.formatDate(this.$store.state.user.subscription.cancel_at)
      }
      return null
    },
  },

  async mounted() {
    const user = (await axios.get('/auth/profile')).data;
    this.leadUrl = user.organizationSlug;
  },

  methods: {
    required,
    getUserDB,
    getSocialMediaTypeIndividualTitle,
    closeWelcomeDialog() {
      this.$store.commit("setShowWelcomeModal", false);
      this.isUserFromSignUp = false;
    },
    checkURL(message = "In Special Characters Only - is allowed") {
      return (v) => /^[a-zA-Z0-9-]*$/i.test(v) || message
    },
    async changeUrl() {
      if (this.$refs.leadUrlForm.validate()) {
        try {
          this.loading = true;
          await axios.patch('/persons/' + this.user._id, {
            organizationSlug: this.leadUrl
          });

          this.$store.state.user.organizationSlug = this.leadUrl;

          this.loading = false;
          this.closeLeadForm()
        } catch (e) {
          console.log(e);
          if (e.status === 406) {
            this.errorVal = {
              title: "Error",
              description: "This Lead URL is not available. Please try another one."
            };
          } else {
            this.errorVal = {
              title: "Error",
              description: e?.response?.data?.message || "Some error occured"
            };
          }
          this.error = true;
          this.loading = false;
        }
      }
    },
    closeLeadForm() {
      this.$refs.leadUrlForm.resetValidation()
      this.leadUrl = this.$store.state.user.organizationSlug;
      this.openModal = false
    },
    async reloadSocialPlatforms() {
      try {
        this.reloading = true
        await this.$store.dispatch('getSocialPlatforms')
        this.rightDrawer = false
        this.reloading = false
      } catch (e) {
        window.console.log(e)
        this.reloading = false
      }
    },
    copyURL() {
      const url = webAppUrl + '/' + this.$store.state.user.organizationSlug
      navigator.clipboard.writeText(url)
      this.$toast.success('URL Copied', {
        position: 'bottom-right'
      })
    },
    openEditForm() {
      this.profileMenu = false
      this.profileForm = true
      this.rightDrawer = false
    },
    openSecurityForm() {
      this.profileMenu = false
      this.securityForm = true
      this.rightDrawer = false
    },
    openReportIssueModal() {
      this.profileMenu = false
      this.reportIssueForm = true
      this.rightDrawer = false
    },
    async signOut() {
      try {
        window.localStorage.clear();
        await this.$router.push("/sign-in").catch(() => {
        });
      } catch (err) {
        console.log(err)
      }
    },
    formatDate(date, format = "MM/DD/YYYY") {
      const d = new Date(0);
      d.setUTCSeconds(date);
      return dayjs(d).format(format);
    },
    async revertCancellation() {
      console.log('revert')
      if (confirm("Are you sure?")) {
        this.loading = true;
        this.loadingMessage = "Reverting cancellation...";
        try {
          await this.$axios.patch("stripe/person/" + this.$store.state.user._id + '/reactivate-subscription');
          const user = await this.getUserDB()
          this.$store.commit('setUser', user)
          this.loading = false;
        } catch (e) {
          console.log(e);
          this.errorVal = {
            title: "Error",
            description: e?.response?.data?.message || "Some error occured"
          };
          this.error = true;
          this.loading = false;
        }
      }
    },
    openPromoter() {
      this.promoterDialog = true
      this.acceptedPromoter = false
    },
    async becomePromoter() {
      console.log('become a promoter')
      try {
        this.loading = true;
        await axios.post('/persons/become-a-promoter');
        await this.getUserDB()
        this.acceptedPromoter = true
        this.loading = false;
      } catch (e) {
        console.log(e);
        if (e.status === 406) {
          this.errorVal = {
            title: "Error",
            description: "This Lead URL is not available. Please try another one."
          };
        } else {
          this.errorVal = {
            title: "Error",
            description: e?.response?.data?.message || "Some error occured"
          };
        }
        this.error = true;
        this.loading = false;
      }
    },
  }
}
</script>
