<template>
  <div class="py-2">
    <v-container>
      <v-dialog v-model="couponDialog" width="50%">
        <div class="coupon-card">
          <v-icon
              large
              style="position: absolute; right: 30px; top: 20px"
              @click="couponDialog = false"
          >mdi-close
          </v-icon
          >
          <img class="logo" src="../assets/logo-large.png"/>
          <h3>Get <strong>$4</strong> off on all the packages for lifetime</h3>
          <di class="coupon-row">
            <span id="cpnCode">POSTREDIPH</span>
            <span id="cpnBtn" @click="copyCode">Copy Code</span>
          </di>
          <h4 v-show="codeCopied" style="color: green; margin-top: -10px">
            Code Copied!
          </h4>
          <p>Only for Philippines</p>
        </div>
      </v-dialog>

      <div v-if="plans && plans.length > 0 && !selectedPlan">
        <h1 class="text-h4 font-weight-bold text-center">
          Choose the best plan for you
        </h1>
        <p class="text-center" style="color: #6B7280">Save <span style="color: #10B981">$1680</span> of money by
          switching to a yearly plan.</p>

        <!--Switch-->
        <div class="d-flex justify-center align-center mb-2">
          <p class="ma-0 mr-4">Monthly</p>
          <v-switch
              v-model="isYearly"
              :disabled="!!selectedCoupon"
              class="ma-0"
              color="primary"
              dense
              hide-details
              inset
          ></v-switch>
          <p class="ma-0">Yearly</p>
        </div>

        <v-row justify="center">
          <!--Professional Monthly Plan-->
          <v-col class="px-6" cols="12" md="4">
            <v-card
                class="px-6 py-4"
                color="#39B0FF"
                dark
                elevation="0"
                height="100%"
                rounded="xl"
            >
              <h2 class="font-weight-bold py-2">Professional</h2>

              <div v-if="isYearly" class="d-flex justify-center align-baseline">
                <p class="text-h6 mb-0 text-decoration-line-through">
                  Normally ${{ proPlanMonthlyPrice }}
                </p>
              </div>

              <p :class="[isYearly ? 'mt-0' : 'mt-2']" class="py-2 mb-2 d-flex align-baseline">
                <!-- monthly -->
                <span v-if="!isYearly" class="text-h2 font-weight-bold">
                  ${{ proPlanMonthlyPrice }}
                </span>
                <!-- yearly -->
                <span v-else class="text-h2 font-weight-bold">${{ proPlanYearlyPrice }}</span>

                <span class="ml-2" :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-h4'"> / month</span>
              </p>

              <p v-if="isYearly" class="mb-2">billed annually
                <b>(Save ${{ getYearlySavingPrice(proPlanMonthlyPrice, proPlanYearlyPrice) }} per Year)</b>
              </p>

              <p class="mb-2">The perfect way to get started and get used to our tools</p>

              <v-divider class="my-2"></v-divider>

              <div class="mb-2">
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon" style="">
                  <p>4 Social media platforms</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon">
                  <p>Post Idea Generator</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon">
                  <p>Access to leads</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/wrong.svg')" alt="wrong icon" class="detail-icon">
                  <p style="color: #9CD8FF">Marketing assistant for posting</p>
                </div>
              </div>

              <v-btn
                  :dark="false"
                  class="mb-2"
                  depressed
                  color="white"
                  style="width: 100%; padding: 20px 0; color: black"
                  @click="choosePlan(proPlan)"
              >
                <b>Get Started</b>
              </v-btn>

            </v-card>
          </v-col>

          <!--Done for you yearly Plan-->
          <v-col class="px-6" cols="12" md="4">
            <v-card
                class="px-6 py-4"
                color="white"
                height="100%"
                rounded="xl"
                elevation="2"
            >
              <h2 class="font-weight-bold py-2">Investor</h2>

              <div v-if="isYearly" class="d-flex justify-center align-baseline">
                <p class="text-h6 mb-0 text-decoration-line-through"
                >Normally ${{ dfyPlanMonthlyPrice }}
                </p>
              </div>

              <p :class="[isYearly ? 'mt-0' : 'mt-2']" class="py-2 mb-2 d-flex align-baseline">
                <!-- monthly -->
                <span v-if="!isYearly" class="text-h2 font-weight-bold">${{ dfyPlanMonthlyPrice }}</span>
                <!-- yearly -->
                <span v-else class="text-h2 font-weight-bold">${{ dfyPlanYearlyPrice }}</span>
                <span class="ml-2" :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-h4'"> / month</span>
              </p>

              <p v-if="isYearly" class="mb-2">billed annually <b>(Save
                ${{ getYearlySavingPrice(dfyPlanMonthlyPrice, dfyPlanYearlyPrice) }} per Year)</b></p>

              <p class="mb-2">Everything is done for you. Best for super busy investors</p>

              <v-divider class="my-2"></v-divider>

              <div class="mb-2">
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/blue-right.svg')" alt="right icon" class="detail-icon" style="">
                  <p style="color: #6B7280">4 Social media platforms</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/blue-right.svg')" alt="right icon" class="detail-icon">
                  <p style="color: #6B7280">Post Idea Generator</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/blue-right.svg')" alt="right icon" class="detail-icon">
                  <p style="color: #6B7280">Access to leads</p>
                </div>
                <div class="d-flex">
                  <img :src="require('../assets/images/icons/blue-right.svg')" alt="right icon" class="detail-icon">
                  <p style="color: #6B7280">Marketing assistant for posting</p>
                </div>
              </div>

              <v-btn
                  class="mb-2"
                  color="#39B0FF"
                  dark
                  depressed
                  style="width: 100%; padding: 20px 0"
                  @click="choosePlan(dfyPlan)"
              >
                Get Started
              </v-btn>

            </v-card>
          </v-col>

        </v-row>
      </div>
      <div v-else-if="selectedPlan">
        <h1 class="text-md-h2 text-h4 mb-3 font-weight-bold text-center">
          Provide credit card details for payment.
        </h1>
        <p class="text-center">
          We will not be storing or sharing your card details.
        </p>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-card
                :color="getColor(selectedPlan)"
                class="pa-6 d-flex flex-column justify-start text-center border"
                :dark="selectedPlan && selectedPlan.name === 'Professional'"
                :elevation="selectedPlan && selectedPlan.name !== 'Professional' ? 2 : 0"
                height="100%"
                rounded="xl"
            >
              <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 22px' : ''">
                Selected Package
              </h2>

              <h2 class="text-h5 font-weight-bold py-2 mb-4">{{ selectedPlan.name }}</h2>

              <div v-if="isYearly && selectedPlan.name === 'Professional'" class="d-flex justify-center align-baseline">
                <p class="text-h6 mb-0 text-decoration-line-through">
                  Normally ${{ proPlanMonthlyPrice }}
                </p>
              </div>

              <div v-if="isYearly && selectedPlan.name === 'Done for You - Full Service' " class="d-flex justify-center align-baseline">
                <p class="text-h6 mb-0 text-decoration-line-through">
                  Normally ${{ dfyPlanMonthlyPrice }}
                </p>
              </div>

              <p :class="[isYearly ? 'mt-0' : 'mt-2']" class="py-2 mb-2">

                <!-- coupon applied -->
                <span v-if="selectedCoupon">
                    <!-- monthly -->
                    <span v-if="!isYearly" class="text-h2 font-weight-bold">
                      ${{ getDiscount() }}
                    </span>
                  <!-- yearly -->
                    <span v-else class="text-h2 font-weight-bold">
                      ${{ getDiscount() / 12 }}
                    </span>
                </span>

                <span v-else>
                  <!-- monthly -->
                  <span v-if="!isYearly" class="text-h2 font-weight-bold">
                    ${{ selectedPlan.prices.find((o) => o.id.toString().includes("monthly")).unit_amount / 100 }}
                  </span>
                  <!-- yearly -->
                  <span v-else class="text-h2 font-weight-bold">${{ selectedPlan.prices.find((o) => o.id.toString().includes("yearly")).unit_amount / 100 / 12 }}</span>
              </span>

              </p>


              <!--              <p class="mb-0 d-flex justify-center">-->
              <!--                <span class="text-h3 font-weight-medium">$</span>-->
              <!--                <span v-if="selectedCoupon" class="text-h1 font-weight-bold">{{-->
              <!--                    isYearly ? getDiscount() / 12 : getDiscount()-->
              <!--                  }}</span>-->

              <!--                <span v-else class="text-h1 font-weight-bold">{{-->
              <!--                    isYearly-->
              <!--                        ? selectedPlan.prices.find((o) =>-->
              <!--                        o.id.toString().includes("yearly")-->
              <!--                    ).unit_amount / 100 / 12-->
              <!--                        : selectedPlan.prices.find((o) => o.id.toString().includes("monthly")).unit_amount / 100-->
              <!--                  }}-->
              <!--                </span>-->

              <!--                <span-->
              <!--                    v-if="selectedCoupon"-->
              <!--                    class="align-self-end text-h5 text-decoration-line-through"-->
              <!--                >-->
              <!--                  ${{-->
              <!--                    isYearly-->
              <!--                        ? selectedPlan.prices.find((o) =>-->
              <!--                        o.id.toString().includes("yearly")-->
              <!--                    ).unit_amount / 100 / 12-->
              <!--                        : selectedPlan.prices.find((o) => o.id.toString().includes("monthly")).unit_amount / 100-->
              <!--                  }}-->
              <!--                </span>-->

              <!--                <span-->
              <!--                    v-if="isYearly"-->
              <!--                    :class="`align-self-end ${-->
              <!--                    selectedCoupon ? 'text-caption ml-2' : 'text-h5'-->
              <!--                  } text-decoration-line-through`"-->
              <!--                >-->
              <!--                  ${{-->
              <!--                    selectedPlan.prices.find((o) =>-->
              <!--                        o.id.toString().includes("monthly")-->
              <!--                    ).unit_amount / 100-->
              <!--                  }}-->
              <!--                </span>-->
              <!--              </p>-->

              <div class="mb-4">
                <p class="mb-4">per Month</p>
                <p class="mb-0">{{ isYearly ? "billed yearly" : "billed monthly" }}</p>

                <p v-if="selectedCoupon && !isYearly" class="mb-0">
                  <b>{{
                      selectedCoupon && selectedCoupon.coupon.duration === "once"
                          ? "Discount applied on first month"
                          : "Discount applied for lifetime"
                    }}
                  </b>
                </p>


                <p
                    v-if="isYearly"
                    :class="`mb-0 ${
                    selectedCoupon ? 'text-decoration-line-through' : ''
                  }`"
                >
                  {{
                    isYearly
                        ? "$" +
                        selectedPlan.prices.find((o) =>
                            o.id.toString().includes("yearly")
                        ).unit_amount /
                        100 +
                        "/yr"
                        : "-"
                  }}
                </p>
                <p v-if="selectedCoupon && isYearly" class="mb-0">
                  {{ selectedCoupon ? "$" + getDiscount() + "/yr" : "-" }}
                </p>
                <p v-if="selectedCoupon && isYearly" class="mb-0">
                  {{
                    selectedCoupon && selectedCoupon.coupon.duration === "once"
                        ? "Discount applied on first month"
                        : "Discount applied for lifetime"
                  }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                  v-if="!selectedCoupon"
                  :color="getColorBtn(selectedPlan)"
                  :dark="selectedPlan && selectedPlan.name !== 'Professional'"
                  :disabled="loader"
                  class="align-self-center"
                  elevation="0"
                  width="fit-content"
                  @click="selectedPlan = null"
              >
                <span :style="{color: selectedPlan && selectedPlan.name === 'Professional' ? 'black' : 'white'}">Change Plan</span>
              </v-btn>

            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <div v-if="!isYearly">
              <h2
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size: 22px' : ''"
                  class="mb-2"
              >
                Discounts & Coupons
              </h2>
              <v-text-field
                  v-model="proCoupon"
                  :clearable="!!selectedCoupon && !disableCoupon"
                  :readonly="!!selectedCoupon || disableCoupon"
                  dense
                  hide-details
                  label="Coupon Code"
                  outlined
                  @click:clear="clearCoupon"
              >
                <template v-slot:append>
                  <v-btn
                      v-if="!selectedCoupon"
                      color="#39B0FF"
                      class="mb-2"
                      dark
                      small
                      @click="validateCoupon({ coupon: proCoupon, type: 'pro' })"
                  >
                    Apply
                  </v-btn>
                </template>
              </v-text-field>
              <p class="text-caption ma-0 text-left mb-2" style="color: red">
                {{ proCouponError }}
              </p>
            </div>

            <v-form ref="form">
              <h2
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size: 22px' : ''"
                  class="mb-2"
              >
                Card Details
              </h2>
              <p>New Payment Method</p>
              <v-skeleton-loader v-if="loader" type="heading"/>
              <div id="cardWrapper" class="ma-4"></div>
              <p class="red--text">{{ stripeValidationError }}</p>
              <p class="red--text">{{ error }}</p>
            </v-form>
          </v-col>
        </v-row>
        <div class="d-flex justify-end" style="gap: 15px">
          <v-btn
              :disabled="loader || stripeValidationError || !isCardComplete"
              color="primary"
              elevation="0"
              @click="checkout"
          >
            Submit
          </v-btn>
        </div>
      </div>
    </v-container>
    <LoadingDialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import {currentTimezone, getUserDB, webAppUrl} from "@/utils/local";
import LoadingDialog from "@/components/LoadingDialog";

export default {
  name: "get-started",
  components: {LoadingDialog},
  data() {
    return {
      codeCopied: false,
      couponDialog: false,
      selectedPlan: null,
      error: null,
      stripeValidationError: null,
      stripeLoading: false,
      isCardComplete: false,
      plans: null,
      proPlan: null,
      proCoupon: null,
      proCouponError: null,
      dfyPlan: null,
      dfyCoupon: null,
      dfyCouponError: null,
      isYearly: false,
      loading: false,
      dialog: false,
      check: false,
      radio: 0,
      card: null,
      packages: [],
      address: {},
      loader: false,
      setupIntent: null,
      selectedCoupon: null,
      disableCoupon: null,
      countryName: null,
    };
  },
  computed: {
    proPlanMonthlyPrice() {
      return this.proPlan.prices.find((o) =>
          o.id.toString().includes("monthly")
      ).unit_amount / 100;
    },
    proPlanYearlyPrice() {
      return this.proPlan.prices.find((o) =>
          o.id.toString().includes("yearly")
      ).unit_amount / 100 / 12;
    },
    dfyPlanMonthlyPrice() {
      return this.dfyPlan.prices.find((o) =>
          o.id.toString().includes("monthly")
      ).unit_amount / 100;
    },
    dfyPlanYearlyPrice() {
      return this.dfyPlan.prices.find((o) =>
              o.id.toString().includes("yearly")
          ).unit_amount /
          100 / 12;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getCountryName();
    await this.getPlans();
    await this.checkPromo();

    this.$store.commit("setTimezone", this.currentTimezone());
    localStorage.setItem("Timezone", currentTimezone());

    if (!this.$store.state.user.customer) {
      await this.createCustomer();
    }
    this.loading = false;

    if (this.countryName === "PH") {
      this.couponDialog = true;
    }
  },
  methods: {
    getUserDB,
    currentTimezone,
    getYearlySavingPrice(monthly_price, yearly_discount) {
      const monthly_price_per_year = monthly_price * 12;
      const yearly_price = yearly_discount * 12;
      return monthly_price_per_year - yearly_price;
    },
    copyCode() {
      navigator.clipboard.writeText("POSTREDIPH");
      this.codeCopied = true;
    },
    async getCountryName() {
      let country;
      let ip;

      await fetch("https://api.ipify.org?format=json")
          .then((response) => response.json())
          .then((response) => {
            ip = response.ip;
          })
          .catch(()=>{
            ip = '165.227.79.204';
            this.loading = false;
          });

      await this.$axios.get("/getTimeZone/" + ip).then((response) => {
        country = response.data.country;
      });
      console.log(country);
      this.countryName = country;
    },
    getColor(plan) {
      switch (plan.name) {
        case "Professional":
          return "#39B0FF";
        case "Done for You - Full Service":
          return "#FFFFFF";
        default:
          return "#39B0FF";
      }
    },
    getColorBtn(plan) {
      switch (plan.name) {
        case "Professional":
          return "#FFFFFF";
        case "Done for You - Full Service":
          return "#39B0FF";
        default:
          return "#FFFFFF";
      }
    },
    async choosePlan(plan = null) {
      if (plan) {
        console.log('plan', plan);
        this.loader = true;
        if (this.card) {
          this.card.unmount();
          this.card = null;
          this.elements = null;
        }
        this.selectedPlan = null;
        this.selectedPlan = plan;
        console.log('selectedPlan', this.selectedPlan);
        this.stripeLoading = true;
        const setupIntent = (await this.$axios.get("/stripe/setup-intent"))
            .data;
        if (!this.card) {
          const card = document.createElement("div");
          card.id = "card";
          try {
            const elements = this.$stripe.elements({
              clientSecret: setupIntent.client_secret,
            });
            this.elements = elements;
            this.card = elements.create("payment", {
              terms: {
                card: "auto",
              },
            });
            this.card.mount(card);
            this.card.on("change", (e) => this.setValidationError(e));
            this.card.on("ready", () => {
              this.stripeLoading = false;
              this.loader = false;
            });
          } catch (e) {
            console.log(e);
            console.log(e.response);
          }
          const cardWrapper = document.getElementById("cardWrapper");

          if (cardWrapper) {
            cardWrapper.appendChild(card);
          }
        }
      }
    },
    async checkout() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          if (!this.card) {
            this.error = "Card is missing";
            this.loading = false;
            return;
          }
          const elements = this.elements;
          if (!this.setupIntent) {
            this.setupIntent = await this.$stripe.confirmSetup({
              elements,
              confirmParams: {
                return_url: webAppUrl,
              },
              redirect: "if_required",
            });
            if (this.setupIntent && this.setupIntent.error) {
              this.error =
                  this.setupIntent.error.message || "Some error occurred";
              this.loading = false;
              this.setupIntent = null;
              return;
            }
            console.log(this.setupIntent, "Setup intent");
            console.log("After confirm setup");
          }
          const customerData = {
            email: this.$store.state.user.username,
            payment_method: this.setupIntent.setupIntent.payment_method,
          };
          if (this.$store.state.user.customer.default_payment_method) {
            customerData.old_payment_method =
                this.$store.state.user.customer.default_payment_method;
          }
          await this.$axios.post("/stripe/update-customer", customerData);
          console.log("after update cus");
          let price;
          if (!this.isYearly) {
            price = this.selectedPlan.prices.find((o) =>
                o.id.toString().includes("monthly")
            );
          } else {
            price = this.selectedPlan.prices.find((o) =>
                o.id.toString().includes("yearly")
            );
          }
          console.log(price, "price");
          console.log(this.$store.state.user.customer, "customer");
          const subscriptionData = {
            customer: this.$store.state.user.customer.id,
            price: price.id,
            promotion_code: this.selectedCoupon
                ? this.selectedCoupon.code
                : null,
          };
          await this.$axios.post(
              `/stripe/create-subscription`,
              subscriptionData
          );
          const user = await this.getUserDB();
          this.$store.commit("setUser", user);
          await this.$router.push('/welcome')
          this.loading = false;
        } catch (e) {
          console.log(e, "Main Catch");
          if (!e.toString().toLowerCase().includes("redirected")) {
            this.error =
                e?.response?.data?.message ||
                e?.data?.message ||
                "Some error occurred";
          }
        }
        this.loading = false;
      }
    },
    async getPlans() {
      try {
        this.loading = true;
        const plans = (await this.$axios.get("/stripe/products")).data.data;
        for (let i = 0; i < plans.length; i++) {
          plans[i].prices = (
              await this.$axios.get("stripe/price/" + plans[i].id)
          ).data.data;
        }
        console.log(plans, "plans");
        this.proPlan = plans.find((o) => o.name === "Professional");
        this.dfyPlan = plans.find((o) => o.name === "Done for You - Full Service");
        console.log(this.proPlan, "pro plan");
        console.log(this.dfyPlan, "dfy plan")
        this.plans = plans.reverse();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : null;
      this.isCardComplete = event.complete;
      console.log(event, "EVENT");
    },
    getDiscount() {
      let discountPrice = 0;
      let plan;
      if (this.selectedCoupon && this.selectedCoupon.coupon) {
        if (this.proCoupon) {
          plan = this.proPlan;
        } else {
          plan = this.dfyPlan;
        }
        let price;
        if (!this.isYearly) {
          price =
              plan.prices.find((o) => o.id.toString().includes("monthly"))
                  .unit_amount / 100;
        } else {
          price =
              plan.prices.find((o) => o.id.toString().includes("yearly"))
                  .unit_amount / 100;
        }
        console.log(price, "PRICE");

        if (this.selectedCoupon.coupon.amount_off) {
          discountPrice = price - this.selectedCoupon.coupon.amount_off / 100;
        } else {
          const amount = (this.selectedCoupon.coupon.percent_off / 100) * price;
          console.log(amount, "Amount");
          discountPrice = price - amount;
        }

        return parseFloat(discountPrice).toFixed(2);
      }
    },
    clearCoupon() {
      this.dfyCouponError = null;
      this.dfyCoupon = null;
      this.proCouponError = null;
      this.proCoupon = null;
      this.selectedCoupon = null;
    },
    async validateCoupon(coupon) {
      try {
        this.dfyCouponError = "";
        this.proCouponError = "";
        if (coupon && coupon.coupon && coupon.coupon !== "") {
          this.selectedCoupon = (
              await this.$axios.get(
                  "/stripe/coupons/" + coupon.coupon + "/validate"
              )
          ).data;
        } else {
          if (coupon.type === "pro")
            this.proCouponError = "Coupon Code is required";
          else this.dfyCouponError = "Coupon Code is required";
        }
      } catch (e) {
        console.log(e);
        if (coupon.type === "pro")
          this.proCouponError = "Coupon is invalid. Try a different one.";
        else this.dfyCouponError = "Coupon is invalid. Try a different one.";
      }
    },
    checkPromo() {
      if (this.$store.state.user.promo) {
        if (
            this.$store.state.user.promoPlan &&
            this.$store.state.user.promoPlan === "dfy"
        ) {
          this.dfyCoupon = this.$store.state.user.promo;
          this.validateCoupon({coupon: this.dfyCoupon, type: "dfy"});
          this.disableCoupon = true;
        } else {
          this.proCoupon = this.$store.state.user.promo;
          this.validateCoupon({coupon: this.proCoupon, type: "pro"});
          this.disableCoupon = true;
        }
      }
    },
    async createCustomer() {
      console.log("createCustomer");
      const user = (
          await this.$axios.post("/persons/create-customer", {
            person: this.$store.state.user._id,
          })
      ).data;
      this.$store.commit("setUser", user);
    },
  },
};
</script>

<style scoped>
.detail-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.border {
  border: 1px solid red;
}

/* coupen card */
.coupon-card {
  background: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.coupon-card h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.coupon-card p {
  font-size: 15px;
}

.coupon-row {
  display: flex;
  align-items: center;
  margin: 25px auto;
  width: fit-content;
}

#cpnCode {
  border: 1px dashed #2177b0;
  padding: 10px 20px;
  border-right: 0;
}

#cpnBtn {
  border: 1px solid #fff;
  background: #2177b0;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
}

/* end coupen card */

.plan-table > div > div > table {
  table-layout: fixed;
  width: 20%;
}

.package-detail {
  list-style: none; /* Remove default bullets */
}

.package-detail li:before {
  content: "\25E6";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  color: #8974b9;
}

.package-detail.free li:before {
  color: #8974b9;
}

.package-detail.pro li:before {
  color: #4cb992;
}

.package-detail.basic li:before {
  color: #3499e1;
}

.package-detail.full li:before {
  color: #eeb67e;
}
</style>

<style lang="sass" scoped>
.plan-details
  display: flex

  & > div
    margin-right: 50px

.plan-main
  display: grid
  grid-template-columns: 1fr 2fr

.section-header
  color: rgba(0, 0, 0, .6)

.section-content
  font-size: 23px
  font-weight: bold
</style>
