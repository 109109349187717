<template>
  <div>
    <v-card elevation="0" class="mx-auto mt-5" max-width="90vw">
      <div>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="3">
              <div class="d-flex flex-column justify-center align-center">
                <image-upload
                    :hasError="showImageError"
                    :image_obj="old_image"
                    class="mx-auto span-2"
                    @uploadedImage="getUploadedImage"
                />
                <label class="my-3"> Background Image </label>
              </div>
            </v-col>

            <v-col cols="12" md="9">
              <v-text-field
                  v-model="title"
                  class="mb-4 mt-2"
                  label="Title"
                  hide-details="auto"
                  :rules="[required()]"
                  outlined
                  dense/>
              <v-text-field
                  v-model="subtitle"
                  class="mb-4"
                  label="Subtitle"
                  hide-details="auto"
                  :rules="[required()]"
                  outlined
                  dense/>

              <div class="d-flex flex-wrap justify-center justify-md-start">
                <v-btn
                    class="px-10 mr-2 mb-2"
                    color="primary"
                    @click="saveTemplate">
                  Save
                </v-btn>
                <v-btn
                    class="px-2 mr-2 mb-2"
                    color="primary"
                    outlined
                    @click="preview">
                  View
                </v-btn>
                <v-btn
                    class="px-2 mr-2 mb-2"
                    color="primary"
                    outlined
                    @click="openEditProfile">
                  Change Organization Details
                </v-btn>

              </div>

            </v-col>

            <!--            <v-col cols="12" md="6">-->
            <!--              <v-color-picker-->
            <!--                  v-model="themeColor"-->
            <!--                  dot-size="26"-->
            <!--                  show-swatches-->
            <!--                  mode="rgba"-->
            <!--                  swatches-max-height="150"/>-->
            <!--            </v-col>-->

            <v-col cols="12">
              <v-card-title class="px-0">
                Select Template
              </v-card-title>
              <div class="d-flex flex-wrap justify-center justify-md-start">
                <div class="ma-2 d-flex flex-column" v-for="(template) in templates" :key="template.id">
                  <div class="d-flex imageContainer" style="position:relative;">
                    <img :src="template.image" alt="Template" class="rounded rounded-b-0"
                         style="object-fit: contain; max-width: 80vw; max-height: 250px">
                    <div class="overlay" title="Preview" @click="previewInNewTab(template.id)">
                      <v-icon color="white" size="35">
                        mdi-eye
                      </v-icon>
                    </div>
                  </div>
                  <div @click="selectTemplate(template.id)" class="d-flex align-center justify-center py-2 rounded-b"
                       style="background-color: #80808070; cursor:pointer;">
                    <v-btn fab small icon>
                      <v-icon v-if="template.selected" color="#2177b0">
                        mdi-check-circle
                      </v-icon>
                      <v-icon v-else>
                        mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>

      </div>
    </v-card>

    <loading-dialog v-model="loading" :message="loadingMessage"/>
    <error-dialog v-model="error" :error="errorValue"/>

  </div>
</template>

<script>

import ImageUpload from "@/components/ImageUpload";
import {required} from "@/utils/validators";
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import {storage} from "@/plugins/firebase";
import {templates} from "@/utils/local";

export default {
  name: "LeadPage",

  components: {ErrorDialog, LoadingDialog, ImageUpload},

  computed: {
    old_image() {
      return {
        url: this.displayImage,
      }
    },
  },

  data: () => ({
    showImageError: false,

    image: null,
    displayImage: null,

    title: "",
    subtitle: "",
    backgroundImage: null,
    themeColor: "",
    templates: [],

    loading: false,
    loadingMessage: '',
    error: false,
    errorValue: {},
  }),

  async mounted() {
    this.templates = templates.map(template => {
      return {
        ...template,
        selected: false,
      }
    });
    await this.loadData();
  },

  methods: {
    required,

    openEditProfile() {
      console.log("openEditProfile");
      document.getElementById('editProfileButton').click()
    },

    preview() {
      const selectedTemplate = this.templates.find(template => template.selected);
      if (selectedTemplate) {
        this.previewInNewTab(selectedTemplate.id);
      }
    },

    async loadData() {
      this.loadingMessage = 'Loading...';
      this.loading = true
      this.error = false
      const data = (await this.$axios.get('/persons/' + this.$store.state.user._id)).data;
      const template = data?.selected_template;
      if (template) {
        this.title = template?.form_title;
        this.subtitle = template?.form_subtitle;
        this.displayImage = template?.background_image;
        this.themeColor = template?.theme_color;
        if (template.template_id) {
          this.selectTemplate(template.template_id);
        }
      }
      this.loading = false
    },

    getUploadedImage(_image_obj) {
      if (_image_obj) {
        this.image = _image_obj.file;
        this.showImageError = false;
      } else {
        this.displayImage = undefined;
        this.showImageError = true;
      }
    },

    async saveTemplate() {
      if (this.$refs.form.validate()) {
        try {
          if (!this.displayImage && !this.image) {
            this.showImageError = true;
            return false
          }

          this.loadingMessage = 'Saving Customization...'
          this.loading = true;

          if (this.image) {
            const fileName = this.$store.state.user._id;

            // Upload File
            let reference = storage.ref("customization/lead/" + fileName);
            await reference.put(this.image);

            // Get download url from firebase storage and add to newMedia object
            this.displayImage = await storage.ref("customization/lead/").child(fileName).getDownloadURL();
          }

          const selected_template = {
            template_id: this.templates.find(t => t.selected).id,
            form_title: this.title,
            form_subtitle: this.subtitle,
            background_image: this.displayImage || "",
            theme_color: this.themeColor,
          };

          await this.$axios.patch('/persons/' + this.$store.state.user._id, {selected_template})

          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.error = true;
          this.errorValue = {
            title: `Error while Saving Customization`,
            description: e?.data?.error ?? 'Some error occurred'
          };
        }
      }
    },

    selectTemplate(templateId) {
      this.templates.forEach(template => {
        template.selected = template.id === templateId;
      })
    },
    previewInNewTab(templateId) {
      window.open("/templates/" + templateId, '_blank');
    }
  }
}
</script>

<style scoped>

.overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
}

.imageContainer:hover .overlay {
  opacity: 1;
}

.imageContainer img {
  max-height: 300px;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  color: #3f51b5;
}
</style>

<style>
.v-color-picker {
  max-width: 100% !important;
}

.v-color-picker__canvas {
  margin: 0 auto !important;
}
</style>