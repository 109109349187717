<template>
  <div class="pa-6">
    <div v-if="!conflictedPlatform">
      <h2 class="mb-3">Which facebook accounts do you want to add?</h2>
      <p class="text-body-2">Choose the Facebook Pages, groups, and profiles that you would like to add to Postredi.</p>
      <div v-if="!loading && errors.length <= 0">
        <div>
          <v-text-field @input="searchData" @change="searchData" v-model="search" outlined dense
                        label="Search by name or keyword"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Search"/>
        </div>

        <div class="mb-3">
          <h3 class="mb-2">Facebook Pages</h3>
          <p class="text-body-2">You need to be a Page Admin or Editor for any Pages you want to add to Postredi.</p>
          <div class="my-2" v-if="pages.length > 0">
            <div v-for="(page,i) in pages" :key="i">
              <v-list-item>
                <v-list-item-avatar>
                  <img :src="page.picture.data.url"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ page.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="page.tasks.includes('MANAGE') || page.tasks.includes('CREATE_CONTENT')">
                  <v-btn @click="connectFacebook(page,i,SocialMediaType.facebookPage)" color="primary" rounded
                         elevation="0"
                         :disabled="pageLoading[i] || page.connected">
                    <span>{{ pageLoading[i] ? 'Connecting' : page.connected ? 'Connected' : 'Connect' }}</span>
                    <v-progress-circular indeterminate width="2" size="18" v-if="pageLoading[i]" class="ml-2"/>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <p>No Pages Found</p>
          </div>
        </div>

        <v-divider class="my-3"/>

        <div class="mb-3">
          <h3 class="mb-2">Facebook Groups</h3>
          <p class="text-body-2">You need to be a group Admin for any groups you want to add to Postredi. You also need
            Postredi added as an app
            in your group’s settings on Facebook. <a href="#">Learn more here</a></p>
          <div class="my-2" v-if="groups.length > 0">
            <div v-for="(group,i) in groups" :key="i" class="my-2">
              <v-list-item :style="{ opacity: group.administrator ? 1 : 0.5 }">
                <v-list-item-avatar>
                  <img :src="group.picture.data.url"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ group.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="group.administrator">
                  <v-btn @click="connectFacebook(group,i,SocialMediaType.facebookGroup)" color="primary" rounded
                         elevation="0"
                         :disabled="groupLoading[i] || group.connected">
                    <span>{{ groupLoading[i] ? 'Connecting' : group.connected ? 'Connected' : 'Connect' }}</span>
                    <v-progress-circular indeterminate width="2" size="18" v-if="groupLoading[i]" class="ml-2"/>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="!group.administrator" class="py-0" dense>
                <v-list-item-avatar color="warning lighten-3" size="30">
                  <v-icon small color="warning">mdi-alert</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>You must be a Group Admin to add a Group to Postredi</v-list-item-title>
                  <v-list-item-subtitle class="primary--text text-decoration-none">
                    <a class="text-decoration-none"
                       target="_blank"
                       :href="`https://www.facebook.com/groups/${group.id}/members/`">Ensure
                      you have adequate permissions</a></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <p>No Groups Found</p>
          </div>
        </div>

        <div class="d-flex justify-end mt-3">
          <v-btn @click="completeFacebookConnection" color="primary" elevation="0">Done</v-btn>
        </div>
      </div>
      <div v-else-if="loading" class="d-flex justify-center align-center">
        <v-progress-circular indeterminate/>
      </div>
      <div v-else-if="errors && errors.length > 0" class="d-flex justify-center align-center flex-column">
        <ul>
          <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
        </ul>
        <div class="d-flex justify-end mt-3">
          <v-btn @click="completeFacebookConnection" color="primary" elevation="0">Done</v-btn>
        </div>

      </div>
    </div>
    <div v-else>
      <h3 class="mb-3">Ownership Conflict</h3>
      <p>This social network is currently owned by another user.</p>
      <p>Do you wish to take ownership of this social network?</p>

      <div class="d-flex justify-end" style="gap:15px">
        <v-btn :disabled="ownershipLoading" elevation="0" large @click="conflictedPlatform = null">Cancel</v-btn>
        <v-btn :disabled="ownershipLoading" elevation="0" color="primary" large @click="takeOwnership">
          <span>{{ ownershipLoading ? 'Taking Ownership' : 'Take Ownership' }}</span>
          <v-progress-circular indeterminate width="2" size="18" v-if="ownershipLoading" class="ml-2"/>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {fbRedirectURL} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Facebook",
  data() {
    return {
      socialPlatformService: new SocialPlatformService(),
      search: null,
      groups: [],
      pages: [],
      data: null,
      loading: false,
      pageLoading: [],
      groupLoading: [],
      SocialMediaType,
      loggedUser: null,
      loggedUserPlatforms: [],
      conflictedPlatform: null,
      ownershipLoading: false,
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    async resolveData() {
      try {
        this.loading = true
        this.errors = []
        this.loggedUser = this.$store.state.user
        this.loggedUserPlatforms = await this.socialPlatformService.fetchByUser(this.loggedUser._id)
        await this.resolveCode()
        this.pages = this.data.pages
        this.pages.forEach((page) => {
          const connectedPage = this.loggedUserPlatforms.find((o) => o.externalId === page.id)
          if (connectedPage)
            page.connected = true
        })
        this.pages = [...this.pages]
        this.pageLoading = this.data.pages.map(() => false)
        this.groups = this.data.groups.sort(function (x, y) {
          return (x.administrator === y.administrator) ? 0 : x.administrator ? -1 : 1;
        });
        this.groups.forEach((group) => {
          const connectedGroup = this.loggedUserPlatforms.find((o) => o.externalId === group.id)
          // Also check if app installed
          if (connectedGroup)
            group.connected = true
        })
        this.groups = [...this.groups]
        this.groupLoading = this.data.groups.map(() => false)

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.errors.push(e?.response?.data || e?.response?.data?.message || 'Error while fetching facebook accounts')
      }
    },
    searchData() {
      if (this.search === '' || this.search === null) {
        this.pages = this.data.pages
        this.pageLoading = this.data.pages.map(() => false)
        this.groups = this.data.groups.sort(function (x, y) {
          return (x.administrator === y.administrator) ? 0 : x.administrator ? -1 : 1;
        });
        this.groupLoading = this.data.groups.map(() => false)
      } else {
        this.pages = this.data.pages.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase()))
        this.pageLoading = this.data.pages.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase())).map(() => false)
        this.groups = this.data.groups.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase())).sort(function (x, y) {
          return (x.administrator === y.administrator) ? 0 : x.administrator ? -1 : 1;
        });
        this.groupLoading = this.data.groups.filter((o) => o.name.toLowerCase().includes(this.search.toLowerCase())).map(() => false)
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.error || data.error_code) {
          console.log('Error in Code')
          this.error = true;
          this.errors.push(data.error_description || data.error_message || 'Error while fetching facebook accounts')
          this.loading = false;
          return;
        }
        if (data.code && data.state === this.user._id) {
          const response = await this.$axios.post("/social-platforms/connect/meta", {
            code: data.code,
            redirect_url: fbRedirectURL,
            type: SocialMediaType.facebook
          });
          console.log(response)
          console.log(response.data)
          if (response) {
            this.data = response.data
          }
        } else {
          await this.$router.push("/").catch(() => {});
        }
      } catch (e) {
        console.log('Error in Code API')
        console.log(e)
        console.log(e.response)
        this.errors.push(e?.response?.data || e?.response?.data?.message || 'Error while fetching facebook accounts')
      }
      this.loading = false;
    },
    async connectFacebook(data, index, type) {
      console.log(data)
      const postData = {
        avatar: data.picture.data.url,
        type: type,
        username: data.name,
        isReAuthRequired: false,
        externalId: data.id,
        extendedInfo: null,
        user: this.loggedUser._id,
      }

      console.log(postData)
      if (type === SocialMediaType.facebookPage) {
        this.pageLoading[index] = true
        postData.access_token = data.access_token
        this.pageLoading = [...this.pageLoading]
      } else if (type === SocialMediaType.facebookGroup) {
        this.groupLoading[index] = true
        postData.access_token = this.data.user_access_token
        this.groupLoading = [...this.groupLoading]
      }

      try {
        await this.socialPlatformService.create(postData)
        await this.$store.dispatch('getSocialPlatforms')
        data.connected = true
      } catch (e) {
        console.log('error')
        console.log(e)
        console.log(e.data.statusCode)
        if (e.data.statusCode === 409) {
          this.conflictedPlatform = postData
          this.conflictedPlatform._id = e.data._id
        }
        console.log(e)
      }
      if (type === SocialMediaType.facebookPage) {
        this.pageLoading[index] = false
        this.pages = [...this.pages]
        this.pageLoading = [...this.pageLoading]
      } else if (type === SocialMediaType.facebookGroup) {
        this.groupLoading[index] = false
        this.groups = [...this.groups]
        this.groupLoading = [...this.groupLoading]
      }
    },
    async takeOwnership() {
      try {
        this.ownershipLoading = true
        await this.socialPlatformService.update(this.conflictedPlatform)
        let platform
        if (this.conflictedPlatform.type === SocialMediaType.facebookPage) {
          platform = this.pages.find((o) => o.id === this.conflictedPlatform.externalId)
          if (platform) {
            platform.connected = true
            this.pages = [...this.pages]
          }
        } else if (this.conflictedPlatform.type === SocialMediaType.facebookGroup) {
          platform = this.groups.find((o) => o.id === this.conflictedPlatform.externalId)
          if (platform) {
            platform.connected = true
            this.groups = [...this.groups]
          }
        }
        this.conflictedPlatform = null
        this.ownershipLoading = false
        await this.$store.dispatch('getSocialPlatforms')
      } catch (e) {
        this.ownershipLoading = false
        console.log(e)
      }
    },
    completeFacebookConnection() {
      window.close()
    }
  }
}
</script>

<style scoped>

</style>