import axios from 'axios';

export class PostsService {
    async fetchAll() {
        return (await axios.get('/post')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/post/' + id)).data;
    }

    async fetchByUser(id) {
        return (await axios.get('/post/person/' + id)).data;
    }

    async create(post) {
        return (await axios.post('/post/create', post)).data;
    }

    async schedulePost(post) {
        return (await axios.post('/post/schedule', post)).data;
    }

    async postNow(post) {
        return (await axios.post('/post/now', post)).data;
    }

    async delete(post) {
        return (await axios.delete('/post/' + post._id)).data;
    }

    async update(post) {
        return (await axios.patch('/post/' + post._id, post)).data;
    }
}