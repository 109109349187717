<template>
  <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '90%'"
            persistent>
    <v-card class="pa-4">
      <h3 class="mb-3">Select the media you want to add</h3>
      <p>
        Facebook, Twitter, and LinkedIn posts can't include different media types. Select the media type you want to use
        or choose an Instagram account.
      </p>
      <div class="mb-3">
        <div v-if="localMedia.length > 0">
          <v-radio-group v-model="selectedMedia">
            <div class="my-3 pa-2" v-for="(mediaItem, i) in localMedia" :key="i">
              <v-row v-if="mediaItem.items">
                <v-col cols="1" class="d-flex justify-center align-center">
                  <v-radio :value="i"></v-radio>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center" v-if="mediaItem.items.length > 0">
                  <div style="width: 100px; height: 50px" class="red">
                    <img :src="mediaItem.items[0].url"
                         v-if="mediaItem.items[0] && !mediaItem.items[0].type.includes('video')  && mediaItem.items[0].url"
                         width="100%"
                         height="100%"/>
                    <img :src="mediaItem.items[0].thumbnail.url"
                         v-else-if="mediaItem.items[0] && mediaItem.items[0].thumbnail" width="100%"
                         height="100%"/>
                  </div>
                </v-col>
                <v-col cols="9" v-if="mediaItem.items.length > 1">
                  <p class="ma-0 font-weight-bold">{{ mediaItem.items.length }} {{ mediaItem.type }}</p>
                  <!--                  <p class="ma-0 caption">{{ humanFileSize(mediaItem.size, true, 0) }}</p>-->
                </v-col>
                <v-col cols="9" v-else-if="mediaItem.items.length === 1">
                  <p class="ma-0 font-weight-bold">{{ mediaItem.items[0].name }}</p>
                  <p class="ma-0 caption">{{ mediaItem.items[0].type }}</p>
                  <p class="ma-0 caption">{{ humanFileSize(mediaItem.items[0].size, true, 0) }}</p>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
        </div>
      </div>
      <div class="d-flex justify-end">
        <v-btn outlined elevation="0" color="primary" class="mr-3" @click="$emit('selectMedia', localMedia[0])">Cancel
        </v-btn>
        <v-btn color="primary" elevation="0" @click="$emit('selectMedia', localMedia[selectedMedia])">Select</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {
  getSocialMediaTypeColor, getSocialMediaTypeIcon, getSocialMediaTypeCount, humanFileSize
} from "@/utils/local";

export default {
  name: "MediaSelector",

  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    media: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    media() {
      this.localMedia = []
      this.selectedMedia = 0
      const images = {
        type: 'images',
        items: []
      }
      images.items = this.media.filter((o) => {
        return o.type.includes('image') && !o.type.includes('gif')
      })
      if (images.items.length > 0)
        this.localMedia.push(images)
      const gifs = {
        type: 'gifs',
        items: []
      }
      gifs.items = this.media.filter((o) => {
        return o.type.includes('gif')
      })
      if (gifs.items.length > 0)
        this.localMedia.push(gifs)

      const videos = {
        type: 'videos',
        items: []
      }
      videos.items = this.media.filter((o) => {
        return o.type.includes('video')
      })
      if (videos.items.length > 0)
        this.localMedia.push(videos)

      this.localMedia = [...this.localMedia]
    }
  },
  data() {
    return {
      localMedia: [],
      selectedMedia: 0
    }
  },

  methods: {
    getSocialMediaTypeColor,
    getSocialMediaTypeIcon,
    getSocialMediaTypeCount,
    humanFileSize
  }
}
</script>

<style scoped>
.mediaPrickerWrapper {
  border: 1px dashed #2177b0;
  border-radius: 8px;
}

.file-display {
  border: 1px solid #2177b0;
}

.contact-upload-dialog p, .contact-upload-dialog a {
  font-size: 14px;
}
</style>