<template>
  <div class="pa-6">
    <p v-if="errors.length <= 0">Reconnecting...</p>
    <p v-if="errors.length <= 0">Don't close this window.</p>
    <ul>
      <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
    </ul>
  </div>
</template>

<script>

import {fbRedirectURLReconnect} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Facebook",
  data() {
    return {
      socialPlatformService: new SocialPlatformService(),
      data: null,
      loading: false,
      SocialMediaType,
      loggedUser: null,
      loggedUserPlatforms: [],
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  methods: {
    async resolveData() {
      try {
        this.loading = true
        this.errors = []
        this.loggedUser = this.$store.state.user
        const params = this.$route.query
        const user = params.state.split('~')[0]
        if (user === this.loggedUser._id) {
          await this.resolveCode()
          console.log(this.data)
          const platform = params.state.split('~')[1]
          const socialPlatform = await this.socialPlatformService.fetchOne(platform)
          console.log(socialPlatform, 'Social Platform')
          let token, oldPlatform
          if (socialPlatform.type === SocialMediaType.facebookPage) {
            oldPlatform = this.data.pages.find((o) => o.id === socialPlatform.externalId)
            token = oldPlatform.access_token
          } else if (socialPlatform.type === SocialMediaType.facebookGroup) {
            oldPlatform = this.data.groups.find((o) => o.id === socialPlatform.externalId)
            token = this.data.user_access_token
          }
          socialPlatform.access_token = token
          socialPlatform.isReAuthRequired = false
          socialPlatform.username = oldPlatform.name
          socialPlatform.avatar = oldPlatform.picture.data.url
          await this.socialPlatformService.update(socialPlatform)
        }
        this.completeFacebookConnection()
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.errors.push(e?.response?.data || e?.response?.data?.message || e?.data?.message || 'Error while fetching facebook accounts')
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.error || data.error_code) {
          console.log('Error in Code')
          this.error = true;
          this.errors.push(data.error_description || data.error_message || 'Error while fetching facebook accounts')
          this.loading = false;
          return;
        }
        if (data.code) {
          console.log(data.code)
          const response = await this.$axios.post("/social-platforms/connect/meta", {
            code: data.code,
            redirect_url: fbRedirectURLReconnect,
            type: SocialMediaType.facebook
          });
          console.log(response)
          console.log(response.data)
          if (response) {
            this.data = response.data
          }
        } else {
          await this.$router.push("/").catch(() => {});
        }
      } catch (e) {
        console.log('Error in Code API')
        console.log(e)
        console.log(e.response)
        this.errors.push(e?.response?.data || e?.response?.data?.message || e?.data?.message || 'Error while fetching facebook accounts')
      }
      this.loading = false;
    },
    completeFacebookConnection() {
      window.close()
    }
  }
}
</script>

<style scoped>

</style>