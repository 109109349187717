import axios from 'axios';

export class LeadsService {

    async fetchOne(id) {
        return (await axios.get('/posts-user/' + id)).data;
    }

    async fetchByUser(id) {
        return (await axios.get('/leads/person/' + id)).data;
    }

    async create(lead) {
        return (await axios.post('/leads', lead)).data;
    }

}