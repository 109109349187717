<template>
  <div class="pa-6">
    <div v-if="errors && errors.length > 0" class="d-flex justify-center align-center flex-column">
      <ul>
        <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
      </ul>
      <div class="d-flex justify-end mt-3">
        <v-btn @click="closeWindow" color="primary" elevation="0">Done</v-btn>
      </div>

    </div>
    <p v-if="!conflictedPlatform && errors.length <= 0">Loading...</p>
    <div v-else-if="conflictedPlatform">
      <h3 class="mb-3">Ownership Conflict</h3>
      <p>This social network is currently owned by another user.</p>
      <p>Do you wish to take ownership of this social network?</p>

      <div class="d-flex justify-end" style="gap:15px">
        <v-btn :disabled="ownershipLoading" elevation="0" large @click="closeWindow">Cancel</v-btn>
        <v-btn :disabled="ownershipLoading" elevation="0" color="primary" large @click="takeOwnership">
          <span>{{ ownershipLoading ? 'Taking Ownership' : 'Take Ownership' }}</span>
          <v-progress-circular indeterminate width="2" size="18" v-if="ownershipLoading" class="ml-2"/>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Twitter",
  data() {
    return {
      SocialMediaType,
      loggedUser: null,
      conflictedPlatform: null,
      ownershipLoading: false,
      socialPlatformService: new SocialPlatformService(),
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  methods: {
    async resolveData() {
      try {
        this.loggedUser = this.$store.state.user
        const res = await this.resolveCode()
        if (res)
          window.close()
      } catch (e) {
        window.close()
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.oauth_token && data.oauth_verifier) {
          await this.$axios.post("/social-platforms/connect/lt", {
            oauth_token: data.oauth_token,
            oauth_verifier: data.oauth_verifier,
            type: SocialMediaType.twitter,
            user: this.loggedUser._id,
            update: false
          });
          return true
        }
      } catch (e) {
        if (e.data.statusCode === 406)
          this.errors.push(e.data.message || 'Some error occured')
        else if (e.data.statusCode === 409) {
          this.conflictedPlatform = e.data._id
        }
        return false
      }
    },
    closeWindow() {
      window.close()
    },
    async takeOwnership() {
      try {
        this.ownershipLoading = true
        const data = {
          _id: this.conflictedPlatform,
          user: this.$store.state.user._id
        }
        await this.socialPlatformService.update(data)
        this.conflictedPlatform = null
        this.ownershipLoading = false
        await this.$store.dispatch('getSocialPlatforms')
        window.close()
      } catch (e) {
        this.ownershipLoading = false
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>

</style>