<template>
  <div
      class="d-flex"
      style="max-height: calc(100vh - 50px); height: calc(100vh - 50px)"
  >
    <div
        class="main-planner"
        :style="
        isPostOpen ? 'width: 70%; height:100%' : 'width: 100%;height:100%'
      "
    >
      <v-toolbar :prominent="false" height="70px" elevation="0">
        <div
            @click="$emit('openPostForm', null)"
            class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
            style="
            width: auto;
            border: 1px solid #2177b0;
            min-height: 40px;
            background: #2177b0;
            color: white;
          "
        >
          <p class="ma-0 font-weight-medium pointer">
            New Post
            <v-icon class="ml-2" color="white">mdi-pencil</v-icon>
          </p>
        </div>
        <v-menu
            v-model="socialMediaPicker"
            :close-on-content-click="false"
            :nudge-bottom="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
                @click:append="socialMediaPicker = true"
                class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
                style="
                width: auto;
                min-width: 10%;
                border: 1px solid #2177b0;
                min-height: 40px;
                background: #2177b0;
                color: white;
              "
                v-bind="attrs"
                v-on="on"
            >
              <v-avatar
                  v-if="selectedSocialMediaProfiles.length > 0"
                  size="26"
                  class="mr-3"
                  color="#001E30"
              >
                <p class="ma-0">{{ selectedSocialMediaProfiles.length }}</p>
              </v-avatar>
              <p class="ma-0 font-weight-medium pointer">Social accounts</p>
              <v-icon color="white">mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-card class="elevation-0" rounded="0" width="460">
            <div class="pa-2">
              <v-text-field
                  v-model="socialMediaSearch"
                  label="Search by account name"
                  outlined
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  hide-details
              ></v-text-field>
            </div>
            <div
                v-if="socialMediaProfiles && socialMediaProfiles.length > 0"
                style="max-height: 450px; overflow-y: scroll"
            >
              <v-list>
                <v-list-item-group multiple>
                  <v-list-item
                      v-for="(
                      socialMediaProfile, i
                    ) in localSocialProfiles.filter(
                      (o) => o.type !== SocialMediaType.facebook
                    )"
                      :key="i"
                      @click="updateSocialMediaFilter(socialMediaProfile)"
                  >
                    <template v-slot:default>
                      <v-list-item-avatar size="32" class="mr-3">
                        <v-avatar size="32">
                          <img
                              v-if="socialMediaProfile.avatar"
                              :src="socialMediaProfile.avatar"
                          />
                          <img
                              v-else
                              src="https://s.pinimg.com/images/user/default_60.png"
                          />
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content class="d-flex">
                        <v-list-item-title>
                          <v-icon
                              small
                              :color="
                              getSocialMediaTypeColor(socialMediaProfile.type)
                            "
                          >
                            {{
                              getSocialMediaTypeIcon(socialMediaProfile.type)
                            }}
                          </v-icon>
                          {{ socialMediaProfile.username }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon
                            color="primary"
                            v-if="
                            selectedSocialMediaProfiles.find(
                              (o) => o === socialMediaProfile._id
                            )
                          "
                        >
                          mdi-checkbox-marked
                        </v-icon>
                        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <div v-else class="pa-2">
              <v-list-item dense>
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium">
                      No Social Profiles connected yet.
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </div>

            <v-list-item dense @click="openSocialMediaConnector">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title
                      style="color: #2177b0"
                      class="font-weight-medium"
                  >
                    <v-icon color="#2177b0" small>mdi-plus-circle</v-icon>
                    Add social accounts
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <div class="d-flex pa-2" style="gap: 10px">
              <v-btn
                  class="flex-grow-1"
                  elevation="0"
                  color="grey lighten-2"
                  @click="socialMediaPicker = false"
              >Close
              </v-btn>
              <v-btn
                  class="flex-grow-1"
                  elevation="0"
                  color="#2177b0"
                  dark
                  @click="applyFilter"
              >Apply
              </v-btn
              >
            </div>
          </v-card>
        </v-menu>
        <v-menu
            v-model="statusPicker"
            :close-on-content-click="false"
            :nudge-bottom="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
                @click:append="statusPicker = true"
                class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
                style="
                width: auto;
                min-width: 10%;
                border: 1px solid #2177b0;
                min-height: 40px;
                background: #2177b0;
                color: white;
              "
                v-bind="attrs"
                v-on="on"
            >
              <v-avatar
                  v-if="selectedStatuses.length > 0"
                  size="26"
                  class="mr-3"
                  color="#001E30"
              >
                <p class="ma-0">{{ selectedStatuses.length }}</p>
              </v-avatar>
              <p class="ma-0 font-weight-medium pointer">Post Status</p>
              <v-icon color="white">mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-card class="elevation-0" rounded="0" width="460">
            <v-list>
              <v-list-item-group multiple>
                <v-list-item
                    v-for="(status, i) in statuses"
                    :key="i"
                    @click="updateStatusFilter(status)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ status.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <div class="d-flex pa-2" style="gap: 10px">
              <v-btn
                  class="flex-grow-1"
                  elevation="0"
                  color="grey lighten-2"
                  @click="statusPicker = false"
              >Close
              </v-btn>
              <v-btn
                  class="flex-grow-1"
                  elevation="0"
                  color="#2177b0"
                  dark
                  @click="applyFilter"
              >Apply
              </v-btn
              >
            </div>
          </v-card>
        </v-menu>
        <v-divider vertical class="mr-4"/>
        <!--        <v-btn-toggle v-model="calendarType" class="mr-4 rounded" color="#2177b0" dense>-->
        <!--          <v-btn elevation="0">-->
        <!--            <v-icon>mdi-view-column-outline</v-icon>-->
        <!--          </v-btn>-->

        <!--          &lt;!&ndash;          <v-btn elevation="0" @click="type = 'month'">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <v-icon>mdi-calendar</v-icon>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </v-btn>&ndash;&gt;-->
        <!--        </v-btn-toggle>-->
        <!--        <v-divider vertical class="mr-4"/>-->
        <br/>
        <v-btn
            fab
            small
            elevation="0"
            color="#2177b0"
            class="mr-4"
            @click="prev"
        >
          <v-icon color="white"> mdi-chevron-left</v-icon>
        </v-btn>
        <div
            @click="setToday"
            class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
            style="
            width: auto;
            border: 1px solid #2177b0;
            min-height: 40px;
            background: #2177b0;
            color: white;
          "
        >
          <p class="ma-0 font-weight-medium pointer">Today</p>
        </div>
        <v-btn
            fab
            small
            elevation="0"
            color="#2177b0"
            class="mr-4"
            @click="next"
        >
          <v-icon color="white"> mdi-chevron-right</v-icon>
        </v-btn>
        <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-bottom="15"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
                @click:append="datePicker = true"
                class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
                style="
                width: auto;
                min-width: 10%;
                border: 1px solid #2177b0;
                min-height: 40px;
                background: #2177b0;
                color: white;
              "
                v-bind="attrs"
                v-on="on"
            >
              <p class="ma-0 font-weight-medium pointer">
                {{ formatDatePicker }}
              </p>
              <v-icon color="white">mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-date-picker v-model="date" @input="datePicked"></v-date-picker>
        </v-menu>
        <div
            @click="reload"
            class="mr-4 rounded px-4 d-flex justify-space-between align-center pointer"
            style="
            width: auto;
            border: 1px solid #2177b0;
            min-height: 40px;
            background: #2177b0;
            color: white;
          "
        >
          <p class="ma-0 font-weight-medium pointer">
            <span v-if="!reloading"
            >Reload
              <v-icon class="ml-2" color="white">mdi-reload</v-icon>
            </span>
            <span v-else
            >Reloading
              <v-progress-circular
                  width="2"
                  size="22"
                  class="ml-2"
                  color="white"
                  indeterminate
              />
            </span>
          </p>
        </div>
      </v-toolbar>
      <div style="max-height: calc(100% - 70px); overflow: auto">
        <div class="view-week-table-head">
          <div class="view-week-table-time">
            <div class="view-week-table-time-label">GMT {{ getGMTDiff }}</div>
          </div>
          <div
              v-for="(weekDay, i) in weekDays"
              :key="i"
              :class="`view-week-table-head-day ${
              isActive(weekDay.fullDate) ? 'active' : ''
            }`"
          >
            <div class="view-week-table-head-day-detail">
              <div
                  :class="`view-week-table-head-day-detail-day ${
                  isActive(weekDay.fullDate) ? 'active' : ''
                }`"
              >
                {{ weekDay.day.substring(0, 3) }}
              </div>
              <div
                  :class="`view-week-table-head-day-detail-count ${
                  weekDay.totalPosts > 0 ? 'has-count' : ''
                }`"
              >
                {{ weekDay.totalPosts }}
              </div>
            </div>
            <div class="view-week-table-head-day-date active">
              {{ weekDay.date }}
            </div>
          </div>
        </div>
        <div class="main-body-scroller">
          <div class="main-body">
            <div v-for="(time, i) in times" :key="i" class="main-hour">
              <div class="main-hour-time">
                <div class="main-hour-time-label">{{ time.timeText }}</div>
              </div>
              <div
                  v-for="(day, j) in time.days"
                  :key="j"
                  :class="`main-hour-day ${
                  isActive(day.fullDate) ? 'active' : ''}`"
              >
                <v-menu
                    v-model="newPostMenu[i][j]"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    :nudge-left="10"
                    offset-y
                    open-on-click
                    v-if="checkMenuStatus(day, i)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-divider
                        v-if="currentDivider(day, i)"
                        :style="`border-style: dashed; margin-top: ${
                        0.7 * new Date().getMinutes()
                      }px;border-color: black`"
                    ></v-divider>
                    <div
                        v-bind="attrs"
                        @click.self="on.click"
                        style="height: 100%"
                    >
                      <div>
                        <h2 class="main-hour-day-heading">
                          {{ day.totalPosts }} posts,
                          {{ formatDate(day.fullDate, "dddd, DD MMMM") }} at
                          {{ time.timeText.replace(" ", "") }}
                        </h2>
                        <div
                            v-for="(post, k) in day.posts"
                            :key="k"
                            class="mb-6"
                        >
                          <div>
                            <PostCalendarCard
                                :post="post"
                                :social-media-profiles="socialMediaProfiles"
                                @togglePost="togglePost(post)"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                          v-if="attrs['aria-expanded'] === 'true'"
                          style="
                          background: #fde2d3;
                          border: 4px solid #f37021;
                          color: #3b6b9a;
                        "
                          class="pa-1 font-weight-bold"
                      >
                        <v-icon small color="#3b6b9a">mdi-plus-circle</v-icon>
                        {{
                          time.timeText.replace(
                              " ",
                              isActive(day.fullDate) && currentDivider(day, i)
                                  ? ":" + new Date().getMinutes()
                                  : ":00 "
                          )
                        }}
                      </div>
                    </div>
                  </template>
                  <v-card class="elevation-0" rounded="0">
                    <v-list dense>
                      <v-list-item>
                        <template v-slot:default>
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">
                              Create
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <v-list-item
                          @click="$emit('openPostForm', { time, day })"
                      >
                        <template v-slot:default>
                          <v-list-item-avatar>
                            <v-icon color="black"
                            >mdi-square-edit-outline
                            </v-icon
                            >
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                              Create New Post
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <!--                      <v-list-item>-->
                      <!--                        <template v-slot:default>-->
                      <!--                          <v-list-item-avatar>-->
                      <!--                            <v-icon color="black">mdi-pinterest</v-icon>-->
                      <!--                          </v-list-item-avatar>-->
                      <!--                          <v-list-item-content>-->
                      <!--                            <v-list-item-title class="font-weight-medium">-->
                      <!--                              Pin-->
                      <!--                            </v-list-item-title>-->
                      <!--                          </v-list-item-content>-->
                      <!--                        </template>-->
                      <!--                      </v-list-item>-->
                      <!--                      <v-list-item>-->
                      <!--                        <template v-slot:default>-->
                      <!--                          <v-list-item-avatar>-->
                      <!--                            <v-icon color="black">mdi-instagram</v-icon>-->
                      <!--                          </v-list-item-avatar>-->
                      <!--                          <v-list-item-content>-->
                      <!--                            <v-list-item-title class="font-weight-medium">-->
                      <!--                              Instagram Story-->
                      <!--                            </v-list-item-title>-->
                      <!--                          </v-list-item-content>-->
                      <!--                        </template>-->
                      <!--                      </v-list-item>-->
                    </v-list>
                  </v-card>
                </v-menu>
                <div v-else>
                  <v-divider
                      v-if="currentDivider(day, i)"
                      :style="`border-style: dashed; margin-top: ${
                      0.7 * new Date().getMinutes()
                    }px;border-color: black`"
                  ></v-divider>
                  <div>
                    <h2 class="main-hour-day-heading">
                      {{ day.totalPosts }} posts,
                      {{ formatDate(day.fullDate, "dddd, DD MMMM") }} at
                      {{ time.timeText.replace(" ", "") }}
                    </h2>
                    <div v-for="(post, k) in day.posts" :key="k">
                      <div>
                        <PostCalendarCard
                            :social-media-profiles="socialMediaProfiles"
                            :post="post"
                            @togglePost="togglePost(post)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="grey lighten-3 post-drawer"
        :style="
        isPostOpen
          ? 'width: 25%;overflow-x:hidden;overflow-y:auto'
          : 'width: 0%'
      "
    >
      <!--      <div v-if="detailPost" style="width: 100%;">-->
      <!--        <v-toolbar elevation="0" :color="detailPost.status !== PostStatus.failed ? 'blue lighten-4' : 'red lighten-4'"-->
      <!--                   dense>-->
      <!--          <v-avatar color="white" size="32">-->
      <!--            <v-icon size="20" :color="getPostStatusColor(detailPost.status)">{{-->
      <!--                getPostStatusIcon(detailPost.status)-->
      <!--              }}-->
      <!--            </v-icon>-->
      <!--          </v-avatar>-->
      <!--          <v-card-title>{{ getPostStatus(detailPost.status) }}</v-card-title>-->
      <!--          <v-spacer/>-->
      <!--          <v-avatar color="white" size="32" @click="togglePost(null, false)">-->
      <!--            <v-icon size="20">mdi-close</v-icon>-->
      <!--          </v-avatar>-->
      <!--        </v-toolbar>-->
      <!--        <div v-if="detailPost.status !== PostStatus.failed" class="blue lighten-1 px-4 py-2">-->
      <!--          <p class="ma-0">{{ getPostStatus(detailPost.status) }} by {{ detailPost.user.name }}</p>-->
      <!--        </div>-->
      <!--        <div v-else class="red lighten-3 px-4 py-2">-->
      <!--          <p class="ma-0">Post Failed due to {{ detailPost.failed_reason.title }}</p>-->
      <!--        </div>-->
      <!--        <div class="px-4 py-2 d-flex justify-end post-drawer" :style=" isPostOpen ? 'width: 100%' : 'width: 0%' ">-->
      <!--          <v-btn v-if="detailPost.status !== PostStatus.posted" elevation="0" color="primary" class="mr-2">-->
      <!--            Edit-->
      <!--          </v-btn>-->
      <!--          <v-btn v-if="detailPost.status !== PostStatus.posted && detailPost.status !== PostStatus.failed" elevation="0"-->
      <!--                 color="primary" class="mr-2">-->
      <!--            Delete-->
      <!--          </v-btn>-->
      <!--          <v-btn elevation="0" color="primary" class="mr-2">-->
      <!--            Duplicate-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--              v-if="detailPost.status !== PostStatus.posted && detailPost.status !== PostStatus.failed && detailPost.status !== PostStatus.draft"-->
      <!--              elevation="0" color="primary">-->
      <!--            Mark as draft-->
      <!--          </v-btn>-->
      <!--        </div>-->
      <!--        <div class="px-4 py-2">-->
      <!--          <FacebookView :post="detailPost"/>-->
      <!--        </div>-->
      <!--        <div class="px-4 py-2">-->
      <!--          <p class="ma-0 mb-4 text-h6">Details</p>-->

      <!--          <h4>Scheduled time</h4>-->
      <!--          <p> {{ formatDate(detailPost.scheduled_date.fullDate, 'dddd, MMMM D [at] hh:mma') }}</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <PostDetail
          :detail-post="detailPost"
          :is-post-open="isPostOpen"
          @togglePost="closeDetail"
          @reloadData="reload"
          @openPostEditForm="(e) => $emit('openPostEditForm', e)"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import {
  compareDates,
  formatDate,
  formatTimeIndex,
  getDay,
  getPostStatus,
  getPostStatusColor,
  getPostStatusIcon,
  getSocialMediaTypeColor,
  getSocialMediaTypeIcon,
  getSocialMediaTypeIndividualTitle,
} from "@/utils/local";
import {PostStatus, SocialMediaType} from "@/utils/enum";

import PostCalendarCard from "@/components/posts/PostCalendarCard";
import {PostsService} from "@/services/posts-service";
import PostDetail from "@/components/posts/PostDetail";

export default {
  name: "CalendarViewWeek",
  components: {PostDetail, PostCalendarCard},
  props: {
    socialMediaProfiles: {
      type: Array,
      default: () => [],
    },
    getWeekCheck: {
      type: Boolean,
      default: false,
    },
    closePost: Boolean,
  },
  data() {
    return {
      calendarType: 0,
      postType: 1,
      weekDays: [],
      times: [],
      // date: moment().format("YYYY-MM-DD"),
      date: null,
      postsService: new PostsService(),
      datePicker: false,
      statusPicker: false,
      socialMediaPicker: false,
      newPostMenu: [],
      PostStatus,
      statuses: [
        {
          text: "Pending",
          value: PostStatus.pending,
        },
        {
          text: "Drafts",
          value: PostStatus.draft,
        },
        {
          text: "Scheduled",
          value: PostStatus.scheduled,
        },
        {
          text: "Published",
          value: PostStatus.posted,
        },
        {
          text: "Failed",
          value: PostStatus.failed,
        },
        {
          text: "Disconnected",
          value: PostStatus.disconnected,
        },
      ],
      selectedStatuses: [],
      selectedSocialMediaProfiles: [],
      SocialMediaType,
      socialMediaSearch: null,
      isPostOpen: false,
      detailPost: null,
      reloading: false,
    };
  },
    computed: {
        formatDatePicker() {
            let val = "";
            if (this.date) {
                val = moment(this.date).format("MMM [start] - [end], YYYY");
                const start = moment(this.date).startOf("week").date();
                const end = moment(this.date).endOf("week").date();
                // const end = start + 6;

                val = val.replace("start", start);
                val = val.replace("end", end);
            }
            return val.toString();
        },
        posts() {
            if (
                this.selectedSocialMediaProfiles.length > 0 &&
                this.selectedStatuses.length > 0
            ) {
                return this.allPosts.filter(
                    (o) =>
                        this.selectedStatuses.includes(o.status) &&
                        this.selectedSocialMediaProfiles.includes(o.socialProfile._id)
                );
            } else if (this.selectedSocialMediaProfiles.length > 0) {
                return this.allPosts.filter((o) =>
                    this.selectedSocialMediaProfiles.includes(o.socialProfile._id)
                );
            } else if (this.selectedStatuses.length > 0) {
                return this.allPosts.filter((o) =>
                    this.selectedStatuses.includes(o.status)
                );
            } else {
                return this.allPosts;
            }
        },
        allPosts() {
            return this.$store.state.posts;
        },
        localSocialProfiles() {
            if (this.socialMediaSearch) {
                return this.socialMediaProfiles
                    .filter((o) =>
                        o.username
                            .toLowerCase()
                            .includes(this.socialMediaSearch.toLowerCase())
                    )
                    .sort(function (a, b) {
                        return (
                            a.username.localeCompare(b.username) &&
                            a.type.localeCompare(b.type)
                        );
                    });
            } else {
                return this.socialMediaProfiles;
            }
        },
        getTimezone() {
            const timezone = this.$store.state.timezone;
            return timezone.split(" ")[1];
        },
        getGMTDiff() {
            var timezone = this.$store.state.timezone;
            var timezoneName = timezone.split(" ")[1];
            return moment.tz(timezoneName).format("Z");
        },
    },

  async mounted() {

    this.getWeek();

    this.date = moment().tz(this.getTimezone).format("YYYY-MM-DD");
  },
  watch: {
    getWeekCheck() {
      this.getWeek();
    },
    closePost(newVal) {
      if (newVal) {
        this.isPostOpen = !newVal;
        this.detailPost = null;
      }
    },
  },
  methods: {
    formatDate,
    getDay,
    formatTimeIndex,
    compareDates,
    getSocialMediaTypeColor,
    getSocialMediaTypeIcon,
    getSocialMediaTypeIndividualTitle,
    getPostStatusIcon,
    getPostStatusColor,
    getPostStatus,
    getWeek(date) {
      let current;
      if (date) {
        current = moment(date).tz(this.getTimezone).startOf("week");
      } else {
        current = moment().tz(this.getTimezone).startOf("week");
      }
      let week = [];
      let i = 1;
      while (i) {
        const obj = {
          date: current.date(),
          fullDate: current.toDate(),
          day: this.getDay(current.day()),
          totalPosts: 0,
        };
        current = current.add(1, "day");
        week.push(obj);
        i++;
        if (i > 7) i = null;
      }

      this.weekDays = week;
      this.getDayTimes();
    },
    getDayTimes() {
      let start = 24;
      const times = [];
      for (let i = 0; i < start; i++) {
        this.newPostMenu[i] = [];
        const obj = {
          index: i,
          timeText: this.formatTimeIndex(i),
          days: [],
        };
        for (let j = 0; j < this.weekDays.length; j++) {
          this.newPostMenu[i][j] = false;
          const posts = this.posts.filter((o) => {
            const endDate = moment(o.scheduled_date.date).tz(this.getTimezone)
            // const endDate = new Date(o.scheduled_date.date)
            // new Date(momentDate.format());
            if (
                this.compareDates(this.weekDays[j].fullDate, o.scheduled_date.date) &&
                endDate.hours() >= i &&
                endDate.hours() < i + 1
            ) {
              return o;
            }
          });
          this.weekDays[j].totalPosts += posts.length;
          obj.days.push({...this.weekDays[j], posts});
        }
        times.push(obj);
      }
      this.times = times;
      return times;
    },

    // getGMTDiff() {
    //   var timezone = this.getTimezone;
    //   var timezoneName = timezone.split(" ")[1];
    //   this.gmtDiff = moment.tz(timezoneName).format("Z");
    // },
    updateStatusFilter(status) {
      const element = this.selectedStatuses.find((o) => o === status.value);
      if (element) {
        const index = this.selectedStatuses.indexOf(element);
        this.selectedStatuses.splice(index, 1);
      } else {
        this.selectedStatuses.push(status.value);
      }
    },
    updateSocialMediaFilter(socialMediaProfile) {
      const element = this.selectedSocialMediaProfiles.find(
          (o) => o === socialMediaProfile._id
      );
      if (element) {
        const index = this.selectedSocialMediaProfiles.indexOf(element);
        this.selectedSocialMediaProfiles.splice(index, 1);
      } else {
        this.selectedSocialMediaProfiles.push(socialMediaProfile._id);
      }
    },
    openSocialMediaConnector() {
      this.$store.commit("setOpenSocialConnector", true);
      this.statusPicker = false;
      this.socialMediaPicker = false;
    },
    applyFilter() {
      this.date = moment(this.weekDays.shift().fullDate).tz(this.getTimezone).format("YYYY-MM-DD");
      this.getWeek(this.weekDays.shift().fullDate);

      this.statusPicker = false;
      this.socialMediaPicker = false;
    },
    isActive(date) {
      return this.compareDates(date, new Date());
    },
    checkMenuStatus(day, i) {
      const currentDate = moment();
      const currentHour = currentDate.hour();
      const date = moment(day.fullDate);
      if (date.isBefore(currentDate, "day")) {
        return false;
      } else if (date.isSame(currentDate, "day")) {
        if (i < currentHour) return false;
      }

      return true;
    },
    currentDivider(day, i) {
      const currentDate = moment().tz(this.getTimezone);
      const currentHour = currentDate.hour();
      const date = moment(day.fullDate).tz(this.getTimezone);
      if (date.isSame(currentDate, "day")) {
        if (i === currentHour) return true;
      }

      return false;
    },
    setToday() {
      this.date = moment().tz(this.getTimezone).format("YYYY-MM-DD");
      this.getWeek();
    },
    prev() {
      const last = this.weekDays.shift();
      const startDate = moment(last.fullDate).tz(this.getTimezone).subtract(1, "day").toDate();
      this.date = moment(startDate).format("YYYY-MM-DD");
      this.getWeek(startDate);
    },
    next() {
      const last = this.weekDays.pop();
      const startDate = moment(last.fullDate).tz(this.getTimezone).add(1, "day").toDate();
      this.date = moment(startDate).format("YYYY-MM-DD");
      this.getWeek(startDate);
    },
    datePicked() {
      this.getWeek(this.date);
      this.datePicker = false;
    },
    togglePost(post, flag = true) {
      this.isPostOpen = flag;
      const delayInMilliseconds = 1000; //1 second
      setTimeout(function () {
      }, delayInMilliseconds);
      this.detailPost = post;
    },
    closeDetail(event) {
      this.togglePost(event.post, event.flag);
    },
    async reload() {
      this.reloading = true;
      this.detailPost = null;
      this.isPostOpen = false;
      await this.$store.dispatch("getSocialPlatforms");
      await this.$store.dispatch("getPosts");
      this.getWeek();
      this.reloading = false;
    },
  },
};
</script>

<style>
/*Post Drawer*/

.post-drawer {
  transition: all 0.5s ease;
}

/*Week View*/

.main-planner {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 700px;
  height: 100%;
  transition: all 1s ease;
}

/*Head*/

.view-week-table-head {
  display: flex;
  flex: 0 0 auto;
  background-color: rgb(244, 241, 239);
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  border-top: 1px solid rgb(211, 210, 211);
  border-bottom: 1px solid rgb(211, 210, 211);
  box-sizing: border-box;
  padding-right: 6px;
}

.view-week-table-time {
  padding: 4px 0;
  width: 48px;
  border-right: 1px solid rgb(211, 210, 211);
  box-sizing: border-box;
}

.view-week-table-time-label {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgb(36, 31, 33);
}

.view-week-table-head-day {
  flex: 1 1 auto;
  position: relative;
  width: 0;
  border-right: 1px solid rgb(211, 210, 211);
  padding: 16px;
  font-size: 14px;
  background-color: inherit;
}

.view-week-table-head-day.active {
  background-color: rgb(227, 221, 216);
}

.view-week-table-head-day-detail {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.view-week-table-head-day-detail-day {
  display: inline-block;
  position: inherit;
  bottom: 4px;
  color: rgb(20, 48, 89);
  font-size: 14px;
  font-weight: 400;
}

.view-week-table-head-day-detail-day.active {
  font-weight: 700;
}

.view-week-table-head-day-detail-count {
  color: rgb(20, 48, 89);
  font-size: 12px;
  border: 1px solid rgb(20, 48, 89);
  border-radius: 2px;
  padding: 0 5px;
}

.view-week-table-head-day-detail-count.has-count {
  color: rgb(67, 89, 122);
  background-color: rgb(252, 252, 251);
}

.view-week-table-head-day-date {
  color: rgb(20, 48, 89);
  font-size: 54px;
  font-weight: 400;
  line-height: 68px;
}

.view-week-table-head-day-date.active {
  font-weight: 700;
}

/*Body*/

.main-body-scroller {
  flex: 1 1 0;
  overflow-y: scroll;
  position: relative;
  background-color: rgb(244, 241, 239);
}

.main-body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-hour {
  display: flex;
  flex: 1 1 auto;
  background-color: rgb(244, 241, 239);
  border-bottom: 1px solid rgb(211, 210, 211);
  min-height: 42px;
}

.main-hour-time {
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 241, 239);
  width: 48px;
  box-sizing: border-box;
}

.main-hour-time-label {
  height: 100%;
  text-transform: uppercase;
  border-right: 1px solid rgb(211, 210, 211);
  color: rgb(36, 31, 33);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 0;
  text-align: center;
}

.main-hour-day {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  width: 0;
  min-height: 42px;
  border-right: 1px solid rgb(211, 209, 208);
  user-select: none;
  background-color: inherit;
}

.main-hour-day.active {
  background-color: rgb(227, 221, 216);
}

.main-hour-day:hover {
  background-color: rgb(253, 226, 211);
}

.main-hour-day-heading {
  font-size: 0;
}

/*Post Card*/

.post-card {
  max-width: 220px;
  margin: 8px 16px 0 8px;
}

.post-card.posted {
  opacity: 0.5;
}

.post-card-main > div {
  display: block;
  margin-bottom: 4px;
}

.post-card-main-content {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  background-color: rgb(252, 252, 251);
  transition: transform 0.3s ease 0s;
  cursor: pointer;
}

.post-card-main-content:hover {
  outline: rgb(243, 112, 33) solid 3px;
  transform: translate3d(-4px, -4px, 0px);
  box-shadow: rgb(250 198 166) 4px 4px 0 3px;
}

.post-card-main-content-div {
  position: relative;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial;
  font-size: 16px;
  overflow: hidden;
  cursor: pointer;
  /*max-width: 220px;*/
  max-width: 100%;
  border-left: 4px solid rgb(84, 61, 128);
}

.post-card-main-content-div-start {
  padding: 8px;
}

.post-card-main-content-div-start-account {
  display: flex;
  flex-direction: row;
  max-height: 20px;
  font-weight: 700;
  margin-bottom: 4px;
  overflow: hidden;
  align-items: center;
}

.post-card-main-content-div-start-account-icon {
  margin-right: 4px;
}

.post-card-main-content-div-start-account-name {
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 18px;
  color: rgb(36, 31, 33);
}

.post-card-main-content-div-summary {
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  max-height: none;
}

.post-card-main-content-div-summary-text {
  position: absolute;
  top: 0px;
}

.post-card-main-content-div-summary-text-spacer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 3em;
  background-image: linear-gradient(
      rgba(252, 252, 251, 0) 0%,
      rgb(252, 252, 251) 84.99%
  );
}

.post-card-main-content-div-summary-text-spacer-gap {
  padding-bottom: 56.25%;
  margin-bottom: 25px;
}

.post-card-main-content-div-summary-text-image {
  white-space: nowrap;
}

.post-card-main-content-div-start-meta {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: rgb(80, 76, 77);
  margin-top: 8px;
}

.post-card-main-content-div-start-meta svg,
.post-card-main-content-div-start-meta-div i {
  margin-right: 5px !important;
}

.post-card-main-content-div-start-meta-div {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.post-card-main-content-div-images-main {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.post-card-main-content-div-images {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.post-card-main-content-div-images-grid {
  position: relative;
  display: grid;
  gap: 2px;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.post-card-main-content-div-image {
  position: relative;
  width: 100%;
  height: 100%;
  outline: 0px;
}

.post-card-main-content-div-image.row-span {
  grid-row-start: span 2;
}

.post-card-main-content-div-image.col-span {
  grid-column-start: span 2;
}

.post-card-main-content-div-image-source {
  background-color: rgb(239, 239, 239) !important;
  background-size: cover !important;
  background-position: center center !important;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.post-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.post-image-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(252, 252, 251);
  font-size: 14px;
  font-weight: 700;
}
</style>
