import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router";
import Toast from "vue-toastification";
import { StripePlugin } from '@vue-stripe/vue-stripe';
import store from "@/plugins/vuex";
import "vue-toastification/dist/index.css";
import './assets/style.sass'
import axios from "axios";
import {apiURL, STRIPE_PUBLIC_KEY, STRIPE_ACCOUNT_ID} from "@/utils/local";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.prototype.$axios = axios
axios.defaults.baseURL = apiURL

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.common["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        console.log(error)
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('auth_token')
                    localStorage.removeItem('auth_user')

                    break;
            }
            return Promise.reject(error.response);
        }
    }
);

Vue.config.productionTip = false
const stripeOptions = {
    pk: STRIPE_PUBLIC_KEY,
    stripeAccount: STRIPE_ACCOUNT_ID,
};

Vue.use(StripePlugin, stripeOptions);

Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
