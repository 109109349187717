export const SocialMediaType = Object.freeze({
    facebook: 'FACEBOOK',
    facebookPage: 'FACEBOOKPAGE',
    facebookGroup: 'FACEBOOKGROUP',
    twitter: 'TWITTER',
    instagram: 'INSTAGRAM',
    instagramBusiness: 'INSTAGRAMBUSINESS',
    linkedIn: 'LINKEDIN',
    youtubeChannel: 'YOUTUBECHANNEL',
    pinterest: "PINTEREST",
});

export const PostStatus = Object.freeze({
    scheduled: 'SCHEDULED',
    posted: 'POSTED',
    failed: 'FAILED',
    draft: 'DRAFT',
    pending: 'PENDING',
    disconnected: 'DISCONNECTED'
});

export const SocialMediaColor = Object.freeze({

    facebook: '#1878f3',
    twitter: '#1ca1f2',
    instagram: '#d93176',
    linkedIn: '#0b66c3',
    youtubeChannel: '#fe0000',
    pinterest: "#e30828",
});
