<template>
  <div class="payment-manager">
    <div v-if="mode === 'new'">
      <p class="sign-in__message-below"><router-link to="/get-started">Add payment method.</router-link>
      </p>
    </div>
    <div v-else>
      <UpdateCard/>
    </div>
  </div>
</template>

<script>
import UpdateCard from "@/components/payment/UpdateCard";

export default {
  name: "PaymentManager",
  components: {UpdateCard},
  props: {
    mode: {
      type: String,
      default: "new"
    }
  },
};
</script>

<style scoped>
</style>