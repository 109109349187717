<template>
  <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '700px' : '90%'"
            persistent>
    <v-card class="pa-8" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar v-if="!loading" @click="$emit('value', false)" class="pointer">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <v-form ref="postCreate">
        <div>
          <h3 class="text-h6 mb-4">START FOR FREE</h3>
          <h3 class="text-md-h3 text-h5 mb-4 primary--text font-weight-medium">Create new account</h3>
        </div>
        <v-form ref="signUpForm">
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown" class="mb-2">
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>First Name</label>
              </div>
              <v-text-field :disabled="loading" v-model="signUpData.first_name"
                            :rules="[required(), name('First name is not valid')]" outlined
                            placeholder="First Name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>Last Name</label>
              </div>
              <v-text-field :disabled="loading" v-model="signUpData.last_name" outlined
                            placeholder="Last Name"
                            :rules="[signUpData.last_name ? name('Last name is not valid') : true]"></v-text-field>
            </v-col>
          </v-row>

          <div class="mb-2">
            <div class="mb-3">
              <label>Email</label>
            </div>
            <v-text-field :disabled="loading" v-model="signUpData.username" :rules="[required(), email()]" outlined
                          placeholder="Enter your email"
                          type="email"></v-text-field>
          </div>

          <div class="mb-2">
            <div class="mb-3">
              <label>Phone</label>
            </div>

            <VuePhoneNumberInput
                v-model="phoneNumber"
                :error="phoneError && showPhoneError"
                error-color="#FF0000"
                color="#2177b0"
                valid-color="#2177b0"
                @update="onPhoneUpdate"
                size="lg"
                required
            />
            <small v-if="phoneError && showPhoneError" class="error--text px-2"> Please provide valid phone
              number.</small>
          </div>

          <div class="mt-8 mb-2">
            <div class="mb-3">
              <label>Organization</label>
            </div>
            <v-text-field
                :disabled="loading"
                v-model="signUpData.organization"
                outlined
                placeholder="Enter your organization name"
            ></v-text-field>
          </div>

          <v-row :no-gutters="$vuetify.breakpoint.smAndDown" class="mb-2">
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>Password</label>
              </div>
              <v-text-field :disabled="loading" v-model="signUpData.password"
                            :rules="[required(), minChar(), oneAlphabet(), oneNumber(), maxChar]" outlined
                            placeholder="Password"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>Confirm Password</label>
              </div>
              <v-text-field :disabled="loading"
                            v-model="signUpData.confirmPassword"
                            :rules="[required(), (v) => (v && v === signUpData.password) || 'Password could not be confirmed']"
                            outlined
                            placeholder="Confirm Password"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword">
              </v-text-field>
            </v-col>
          </v-row>
          <ul class="mb-3">
            <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
          </ul>
          <v-btn @click="signUp" :disabled="loading" color="primary" elevation="0" width="100%" x-large
                 class="mb-6">
            <span>{{ loading ? 'Creating Account' : 'Create Account' }}</span>
            <v-progress-circular size="18" indeterminate v-if="loading" width="2" class="ml-3"/>
          </v-btn>

          <p class="text-center mx-auto" :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 70%' : 'max-width: 90%'">By
            clicking Create Account, I agree to
            <a target="_blank" href="https://postredi.com/privacy-%26-terms" class="text-decoration-none"> Postredi's
              Terms</a>
            including the payment terms, and Privacy Policy
          </p>
        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, email, minChar, maxChar, oneNumber, oneAlphabet, name, charLimit} from "@/utils/validators";
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: "SignUpForm",
  model: {
    prop: 'flag',
    event: 'value'
  },

  components: {
    VuePhoneNumberInput
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    promo: {
      type: String,
      default: null
    },
    plan: {
      type: String,
      default: null
    },
    referredBy: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      signUpData: {
        first_name: null,
        last_name: null,
        username: null,
        password: null,
        confirmPassword: null,
        organization: null,
        phone: null
      },
      showPassword: false,
      errors: [],
      phoneNumber: null,
      phoneError: false,
      showPhoneError: false
    }
  },
  methods: {
    required,
    email,
    minChar, maxChar, oneNumber, oneAlphabet, name, charLimit,
    onPhoneUpdate(event) {
      console.log(event)
      if (event && event.isValid) {
        this.signUpData.phone = event.formatInternational
        this.phoneNumber = event.formatNational
        this.phoneError = false
        this.showPhoneError = false
      } else if (event.phoneNumber && !event.isValid) {
        this.signUpData.phone = null
        this.phoneError = true
        this.showPhoneError = true
      }
    },
    async signUp() {
      if (!this.signUpData.phone) {
        this.showPhoneError = true
        this.phoneError = true
      }
      if (this.$refs.signUpForm.validate()) {
        this.errors = []
        if (!this.signUpData.phone) {
          this.showPhoneError = true
          this.phoneError = true
          this.errors.push('Phone could not be used. Please use valid one')
          return
        }

        try {
          this.loading = true
          const data = {
            username: this.signUpData.username.toLowerCase(),
            name: this.signUpData.first_name + (this.signUpData.last_name ? ' ' + this.signUpData.last_name : ''),
            phone: this.signUpData.phone,
            password: this.signUpData.password,
            organizationName: this.signUpData.organization,
            role: 0
          }
          if (this.promo) {
            data.promo = this.promo
          }
          if (this.plan) {
            data.promoPlan = this.plan
          }
          if (this.referredBy) {
            data.referredBy = this.referredBy
          }
          await this.$axios.post('/auth/sign-up', data)
          const getToken = await this.$axios.post('/auth/sign-in', {
            username: data.username.toLowerCase(),
            password: data.password
          })
          console.log(getToken, 'get token')
          if (getToken) {
            const token = getToken.data;
            const user = (await this.$axios.get('/auth/profile', {
              headers: {authorization: 'bearer ' + token.access_token}
            })).data;
            this.$store.commit('setToken', token.access_token)
            this.$store.commit('setUser', user)
            // console.log('loggedIn user: ', user);
            localStorage.setItem('auth_token', token.access_token)
            localStorage.setItem('auth_user', JSON.stringify(user))
            await this.$router.push('/').catch(() => {});
          } else {
            localStorage.clear()
            this.errors.push('Sign Up in failed. Check your credentials or try again later.')
            this.loading = false;
          }
        } catch (e) {
          console.log(e, 'sign up error')
          if (!e.toString().toLowerCase().includes('redirected')) {
            this.errors.push(e?.response?.data?.message || e?.data?.message || 'Some error occured')
          }
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped></style>