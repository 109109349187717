<template>
  <v-container class="my-5">
    <!--Selected Card info-->
    <v-row justify="center">
      <v-col cols="12" lg="4" md="6" xl="3">
        <v-card v-if="selectedPlan" class="pa-3 d-flex flex-column text-center" color="#151E52" dark elevation="0"
                height="100%">
          <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 22px' : ''">Selected Package</h2>
          <h1 class="text-h5 font-weight-bold mb-4">{{ selectedPlan.name }}</h1>
          <p class="mb-0 d-flex justify-center">
            <span class="text-h3 font-weight-medium">$</span>
            <span class="text-h1 font-weight-bold">
              {{ isYearly ? selectedPlanYearlyPrice : selectedPlanMonthlyPrice }}
            </span>
            <!--            <span class="align-self-end text-h5 text-decoration-line-through" v-if="isYearly">${{-->
            <!--                selectedPlan.price.amount / 100-->
            <!--              }}</span>-->
          </p>
          <div v-if="selectedPlan.name !== 'Free'" class="mb-4">
            <p class="mb-0">per month</p>
            <p class="mb-0">{{ isYearly ? 'billed yearly' : 'billed monthly' }}</p>
            <p :style="!isYearly ? 'color:transparent' : '' " class="mb-0">{{
                isYearly ? '$' + (selectedPlan.price.amount / 100) + '/yr' : '-'
              }}</p>
          </div>
          <span v-if="$store.state.user.subscription && $store.state.user.subscription.current_period_end"
                class="font-weight-regular text-body-1">
            Next billing date: <br> {{ formatDate($store.state.user.subscription.current_period_end) }}
          </span>
          <span v-if="this.getCustomerInvoicesDetailsUpcoming" class="font-weight-bold text-body-1">
            Next billing price <br> $ {{ this.getCustomerInvoicesDetailsUpcoming.amount_due / 100 }}
          </span>

          <!--          <h2 :style="$vuetify.breakpoint.smAndDown ? 'font-size: 22px' : ''">Selected Package</h2>-->
          <!--          <h1 class="text-h5 font-weight-bold mb-4">{{ selectedPlan.name }}</h1>-->
          <!--          <p class="mb-0 d-flex justify-center">$-->
          <!--if discount applied-->
          <!--            <span class="text-h1 font-weight-bold" v-if="userSubscription.discount && userSubscription.discount.coupon">-->
          <!--              {{ isYearly ? getDiscount() / 12 : getDiscount() }}-->
          <!--            </span>-->

          <!--if no discount applied-->
          <!--            <span v-else>-->
          <!--                &lt;!&ndash;main price&ndash;&gt;-->
          <!--                <span class="text-h1 font-weight-bold" v-if="isYearly">-->
          <!--                   {{ selectedPlan.price.amount !== getCustomerInvoicesDetailsUpcoming.amount_due-->
          <!--                    ? (getCustomerInvoicesDetailsUpcoming.amount_due / 100)-->
          <!--                    : (selectedPlan.price.amount / 100) / 12 }}-->
          <!--                </span>-->
          <!--                <span class="text-h1 font-weight-bold" v-else>-->
          <!--                  &lt;!&ndash;if plan price !== upcoming invoice price&ndash;&gt;-->
          <!--                  {{ selectedPlan.price.amount !== getCustomerInvoicesDetailsUpcoming.amount_due-->
          <!--                    ? (getCustomerInvoicesDetailsUpcoming.amount_due / 100)-->
          <!--                    : (selectedPlan.price.amount / 100) }}-->
          <!--                </span>-->

          <!--                &lt;!&ndash;sub price&ndash;&gt;-->
          <!--                <span :class="`align-self-end text-h5 text-decoration-line-through`" v-if="isYearly">-->
          <!--                  ${{ proPlan.prices.find((o) => o.id.toString().includes('monthly')).unit_amount / 100 }}-->
          <!--                </span>-->
          <!--                <span :class="`align-self-end text-h5 text-decoration-line-through`" v-else>-->
          <!--                  $ {{ (selectedPlan.price.amount / 100) }}-->
          <!--                </span>-->
          <!--          </span>-->

        </v-card>
        <v-skeleton-loader v-else type="image"/>
      </v-col>


      <v-col cols="12" lg="4" md="6" xl="3">
        <div v-if="selectedPlan">
          <h2 style="color:#151E52">{{ selectedPlan.name }} Includes</h2>
          <p class="mb-2" style="color:#151E52">All these great features:</p>
          <hr class="mb-4" style="border-color:#151E52"/>
          <div class="mb-3">
            <div v-if="selectedPlan.name === 'Professional'">
              <ul class="package-detail pro">
                <li>4 Social media platforms</li>
                <li>Post Idea Generator</li>
                <li>Access to leads</li>
              </ul>
            </div>
            <div v-else>
              <ul class="package-detail full">
                <li>4 Social media platforms</li>
                <li>Post Idea Generator</li>
                <li>Access to leads</li>
                <li>Marketing assistant for posting.</li>
              </ul>
            </div>
          </div>
          <div class="d-flex align-center">
            <p v-if="!isCanceledAtPeriodEnd" class="blue--text pointer ma-0" @click="changePlan">Change
              Plan</p>
            <v-divider v-if="!isCanceledAtPeriodEnd" class="mx-2" vertical/>
            <p v-if="!isCanceledAtPeriodEnd" class="blue--text pointer ma-0" @click="cancelPlan">Cancel
              Plan</p>
            <v-divider v-if="!isCanceledAtPeriodEnd" class="mx-2" vertical/>
            <p class="ma-0">
              <a class="blue--text text-decoration-none"
                 href="https://postredi.com/terms-privacy" target="_blank">Terms of
                service</a>
            </p>
          </div>


          <!--Coupon information-->
          <hr class="my-2"/>
          <h3 class="mb-3">Discount</h3>
          <div v-if="getCustomerInvoicesDetailsUpcoming && getCustomerInvoicesDetailsUpcoming.discount">
            <p v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.percent_off">
              Applied Discount:
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'once'">
                {{ getCustomerInvoicesDetailsUpcoming.discount.coupon.percent_off }}% off on next payment.
              </span>
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'forever'">
                {{ getCustomerInvoicesDetailsUpcoming.discount.coupon.percent_off }}% off for lifetime.
              </span>
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'repeating'">
                {{ getCustomerInvoicesDetailsUpcoming.discount.coupon.percent_off }}% off for
                {{ getCustomerInvoicesDetailsUpcoming.discount.coupon.duration_in_months }} months.
              </span>
            </p>
            <p v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.amount_off">
              Applied Discount:
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'once'">{{
                  getCustomerInvoicesDetailsUpcoming.discount.coupon.amount_off / 100
                }}$ off on next payment.</span>
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'forever'">{{
                  getCustomerInvoicesDetailsUpcoming.discount.coupon.amount_off / 100
                }}$ off for lifetime.</span>
              <span v-if="getCustomerInvoicesDetailsUpcoming.discount.coupon.duration === 'repeating'">{{
                  getCustomerInvoicesDetailsUpcoming.discount.coupon.amount_off / 100
                }}$ off for {{ getCustomerInvoicesDetailsUpcoming.discount.coupon.duration_in_months }} months.</span>
            </p>
          </div>
          <div v-else>
            <p>
              No discount applied
            </p>
          </div>
          <div>
            <!--            <div style="width: 50%" class="mx-auto">-->
            <!--              <v-text-field v-if="!isYearly" v-model="proCoupon" :readonly="!!selectedCoupon || disableCoupon" label="Coupon Code"-->
            <!--                            dense-->
            <!--                            outlined hide-details :clearable="!!selectedCoupon && !disableCoupon" @click:clear="clearCoupon">-->
            <!--                <template v-slot:append>-->
            <!--                  <v-btn v-if="!selectedCoupon" @click="validateCoupon({coupon: proCoupon, type: 'pro'})" small-->
            <!--                         class="mb-2" color="#3c9474">-->
            <!--                    Apply-->
            <!--                  </v-btn>-->
            <!--                </template>-->
            <!--              </v-text-field>-->
            <!--              <p v-if="!isYearly" class="text-caption ma-0 text-left mb-2">{{ proCouponError }}</p>-->
            <!--              <v-btn width="100%" elevation="0" color="#3c9474" @click="choosePlan(proPlan)">Start Posting</v-btn>-->
            <!--            </div>-->

            <p v-if="promoError" class="red--text">{{ promoError }}</p>
            <v-form ref="promoForm" class="d-flex align-center">
              <v-text-field v-model="promoCode" :rules="[required()]" dense label="Coupon Code" outlined/>
              <v-btn :disabled="loadingPromo" class="ml-3 mb-6" color="primary" elevation="0" @click="applyPromo">
                {{ loadingPromo ? "Applying..." : "Apply" }}
              </v-btn>
            </v-form>
          </div>
        </div>
        <div v-else>
          <v-skeleton-loader class="mb-2" type="heading"/>
          <v-skeleton-loader type="text"/>
          <v-skeleton-loader type="divider"/>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6" xl="3">
        <h2 :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 22px'" class="mb-3">Payment Method</h2>
        <PaymentManager :mode="hasPaymentMethod ? 'update-card' : 'new'" class="mb-3"/>
      </v-col>
    </v-row>

    <!--Invoice History-->
    <v-row v-if="getCustomerInvoicesDetailsHistory && getCustomerInvoicesDetailsHistory.length > 0">
      <v-col cols="12">
        <h2>Billing History</h2>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Subscription Plan
              </th>
              <th class="text-left">
                Amount
              </th>
              <th class="text-left">
                Status
              </th>
              <th class="text-left">
                Invoice Date
              </th>
              <th class="text-left">
                Applied Coupon
              </th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="invoice in getCustomerInvoicesDetailsHistory"
                :key="invoice.name"
            >
              <td>{{ capitalizeFirstLetter(invoice.subscription.plan.replace('-', ' ')) }}</td>
              <td>$ {{ (invoice.amount_paid / 100).toFixed(2) }}</td>
              <td> {{ capitalizeFirstLetter(invoice.status) }}</td>
              <td> {{ getDateFromTimestamp(invoice.period_end) }}</td>
              <td>
                <span v-if="invoice.discount && invoice.discount.coupon">
                  {{ invoice.discount.coupon.name }}
                </span>
                <span v-else>N/A</span>
              </td>
              <td><a v-if="invoice.invoice_pdf" :href="invoice.invoice_pdf">Preview</a><span v-else>--</span></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <!--Dialog/Models-->
    <v-dialog v-model="packagesDialog" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '90%'">
      <div style="position: relative">
        <v-icon style="position:absolute;top: 20px;right: 20px;z-index: 1" @click="packagesDialog = false">mdi-close
        </v-icon>
        <v-card>
          <v-card-title>
            <h3>Change Your Plan</h3>
          </v-card-title>
          <!--V-Switch-->
          <div class="d-flex justify-center align-center mb-2">
            <p class="ma-0 mr-4">Monthly</p>
            <v-switch
                v-model="isYearlyPlan"
                class="ma-0"
                color="primary"
                dense
                hide-details
                inset
                @change="getProration"
            ></v-switch>
            <p class="ma-0">Yearly</p>
          </div>

          <div class="pa-4 pt-2">
            <p class="ma-0">Select a new plan from the remaining ones</p>
            <p>If you downgrade, your remaining credit will be used for future invoices.<br> Contact <a
                href="https://postredi.com/#contact-us" target="_blank">Support</a> in case of any queries.</p>

            <!--Card Radio-->
            <v-radio-group v-model="radio" @change="getProration">
              <v-row class="mb-4 text-center">
                <!--Professional-->
                <v-col v-if="proPlan" cols="12" lg="6" md="6">
                  <v-card :color="checkDisabled(0) ? 'grey' : '#39B0FF'" class="pa-3 d-flex flex-column"
                          dark
                          elevation="0" height="100%"
                          style="position:relative;">
                    <div style="position:absolute; top: 10px; right: 0">
                      <v-radio :disabled="checkDisabled(0)" :value="0"/>
                    </div>
                    <h1 class="text-md-h5 text-body-1 font-weight-bold">Professional</h1>
                    <p class="mb-4">Recommended</p>
                    <p class="mb-0 d-flex justify-center">
                      <span class="text-md-h3 text-h5 font-weight-medium">$</span>
                      <span class="text-md-h2 text-h3 font-weight-bold">{{
                          isYearlyPlan ?
                              proPlan.prices.find((o) => o.id.toString().includes('yearly')).unit_amount / 100 / 12 :
                              proPlan.prices.find((o) => o.id.toString().includes('monthly')).unit_amount / 100
                        }}</span>
                      <span v-if="isYearlyPlan" class="align-self-end text-h5 text-decoration-line-through">${{
                          proPlan.prices.find((o) => o.id.toString().includes('monthly')).unit_amount / 100
                        }}</span>
                    </p>
                    <div class="mb-4">
                      <p class="mb-0">per month</p>
                      <p class="mb-0">{{ isYearlyPlan ? 'billed yearly' : 'billed monthly' }}</p>
                      <p :style="!isYearlyPlan ? 'color:transparent' : '' " class="mb-0">{{
                          isYearlyPlan ? '$' + (proPlan.prices.find((o) => o.id.toString().includes('yearly')).unit_amount / 100) + '/yr' : '-'
                        }}</p>
                    </div>
                    <v-divider class="my-2"></v-divider>

                    <div class="mb-2">
                      <div class="d-flex">
                        <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon" style="">
                        <p>4 Social media platforms</p>
                      </div>
                      <div class="d-flex">
                        <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon">
                        <p>Post Idea Generator</p>
                      </div>
                      <div class="d-flex">
                        <img :src="require('../assets/images/icons/right.svg')" alt="right icon" class="detail-icon">
                        <p>Access to leads</p>
                      </div>
                    </div>
                  </v-card>
                </v-col>

                <!--Done for you-->
                <v-col v-if="dfyPlan" cols="12" lg="6" md="6">
                  <v-card :color="checkDisabled(1) ? 'grey' : '#FFFFFF'" class="pa-3 d-flex flex-column"
                          :dark="checkDisabled(1)"
                          elevation="2" height="100%"
                          style="position:relative;">
                    <div style="position:absolute; top: 10px; right: 0">
                      <v-radio :disabled="checkDisabled(1)" :value="1"/>
                    </div>
                    <h1 class="text-md-h5 text-body-1 font-weight-bold">Done for you</h1>
                    <p class="mb-4">Best for super busy investors.</p>
                    <p class="mb-0 d-flex justify-center"><span
                        class="text-md-h3 text-h5 font-weight-medium">$</span><span
                        class="text-md-h2 text-h3 font-weight-bold">{{
                        isYearlyPlan ?
                            dfyPlan.prices.find((o) => o.id.toString().includes('yearly')).unit_amount / 100 / 12 :
                            dfyPlan.prices.find((o) => o.id.toString().includes('monthly')).unit_amount / 100
                      }}</span>
                      <span v-if="isYearlyPlan" class="align-self-end text-h5 text-decoration-line-through">${{
                          dfyPlan.prices.find((o) => o.id.toString().includes('monthly')).unit_amount / 100
                        }}</span>
                    </p>
                    <div class="mb-4">
                      <p class="mb-0">per month</p>
                      <p class="mb-0">{{ isYearlyPlan ? 'billed yearly' : 'billed monthly' }}</p>
                      <p :style="!isYearlyPlan ? 'color:transparent' : '' " class="mb-0">{{
                          isYearlyPlan ? '$' + (dfyPlan.prices.find((o) => o.id.toString().includes('yearly')).unit_amount / 100) + '/yr' : '-'
                        }}</p>
                    </div>

                    <v-divider class="my-2"></v-divider>

                    <div class="">
                      <div class="d-flex">
                        <img :src="
                        checkDisabled(1)
                        ? require('../assets/images/icons/right.svg')
                        : require('../assets/images/icons/blue-right.svg')"
                             alt="right icon" class="detail-icon"
                        >
                        <p :style="!checkDisabled(1) ? 'color:#6B7280' : 'color:#FFFFFF'">4 Social media platforms</p>
                      </div>
                      <div class="d-flex">
                        <img :src="
                        checkDisabled(1)
                        ? require('../assets/images/icons/right.svg')
                        : require('../assets/images/icons/blue-right.svg')"
                             alt="right icon" class="detail-icon"
                        >
                        <p :style="!checkDisabled(1) ? 'color:#6B7280' : 'color:#FFFFFF'">Post Idea Generator</p>
                      </div>
                      <div class="d-flex">
                        <img :src="
                        checkDisabled(1)
                        ? require('../assets/images/icons/right.svg')
                        : require('../assets/images/icons/blue-right.svg')"
                             alt="right icon" class="detail-icon"
                        >
                        <p :style="!checkDisabled(1) ? 'color:#6B7280' : 'color:#FFFFFF'">Access to leads</p>
                      </div>
                      <div class="d-flex">
                        <img :src="
                        checkDisabled(1)
                        ? require('../assets/images/icons/right.svg')
                        : require('../assets/images/icons/blue-right.svg')"
                             alt="right icon" class="detail-icon"
                        >
                        <p :style="!checkDisabled(1) ? 'color:#6B7280' : 'color:#FFFFFF'">Marketing assistant for posting</p>
                      </div>
                    </div>

                  </v-card>
                </v-col>
              </v-row>
            </v-radio-group>

            <p v-if="(!prorationCalculated && !packagesLoading) || checkDisabled(radio)">
              Select a new plan to see Proration
            </p>
            <div v-if="prorationCalculated && !packagesLoading && !checkDisabled(radio)">
              <h1>Proration</h1>
              <p style="margin-top: 10px">
                You will have to pay
                <v-skeleton-loader
                    v-if="prorationLoading"
                    style="display: inline-block; margin-top: 6px" type="text"
                    width="50px"
                />
                <span v-else>${{ proration.amount_due / 100 }}</span>
                for plan changing on next invoice.
              </p>
            </div>

            <div style="display: flex; justify-content: flex-end">
              <v-btn :disabled="!allowUpdate || checkDisabled(radio)" color="primary" elevation="0"
                     @click="submitChangePlan">
                Submit
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="cancelDialog" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '90%'">
      <div style="position: relative">
        <v-icon style="position:absolute;top: 20px;right: 20px;z-index: 1" @click="closeCancelDialog">mdi-close
        </v-icon>
        <v-card class="py-8 px-6">
          <v-card-title class="d-flex justify-center">
            <h3>Confirm cancellation of your plan</h3>
          </v-card-title>
          <p class="text-center">Sad to see you go</p>
          <div>
            <v-radio-group v-model="cancelReason" dense>
              <v-radio :value="0" label="It's too costly."></v-radio>
              <v-radio :value="1" label="I found another product that fulfills my needs."></v-radio>
              <v-radio :value="2" label="I don't use it enough."></v-radio>
              <v-radio :value="3" label="Some other reason."></v-radio>
            </v-radio-group>
            <v-text-field v-if="cancelReason === 3" v-model="cancelReasonOther" dense label="Reason"
                          outlined></v-text-field>
          </div>
          <div class="d-flex justify-center flex-column mx-auto" style="width: 50%">
            <v-btn class="mb-2" color="primary" small text @click="cancelSubscription">Cancel my subscription
            </v-btn>
            <v-btn class="mb-2" elevation="0" @click="closeCancelDialog">I'll decide later</v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
    <LoadingDialog v-model="packagesLoading" message="Loading..."/>
    <LoadingDialog v-model="loadingDialog" :message="loadingMessage"/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </v-container>
</template>

<script>
import {required} from "@/utils/validators";
import PaymentManager from "@/components/payment/PaymentManager";
import dayjs from "dayjs";
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import {getUserDB, capitalizeFirstLetter} from "@/utils/local";

export default {
  name: "Account",
  components: {ErrorDialog, LoadingDialog, PaymentManager},
  data() {
    return {
      selectedPlan: null,
      isYearly: false,
      packages: [],
      packagesLoading: false,
      prorationLoading: false,
      packagesDialog: false,
      allowUpdate: false,
      proration: {},
      plan: {},
      radio: null,
      proPlan: null,
      dfyPlan: null,
      isYearlyPlan: false,
      prorationCalculated: false,
      loadingDialog: false,
      loadingMessage: 'Loading...',
      error: false,
      errorVal: {},
      cancelDialog: false,
      cancelReason: 0,
      cancelReasonOther: "",


      promoCode: "",
      loadingPromo: false,
      promoError: null,

      loadingInvoiceDetails: false,
    };
  },
  computed: {
    hasPaymentMethod() {
      return !!this.$store.state.user?.customer?.default_payment_method;
    },
    isActive() {
      return (this.$store.state.user.subscription && this.$store.state.user.subscription.status === "active");
    },
    person() {
      return this.$store.state.user;
    },
    userSubscription() {
      return this.$store.state.user.subscription
    },
    isCanceled() {
      return (this.$store.state.user.subscription && this.$store.state.user.subscription.status === "canceled");
    },
    isCanceledAtPeriodEnd() {
      return this.$store.state.user.subscription && this.$store.state.user.subscription.cancel_at_period_end
    },
    getCustomerInvoicesDetailsHistory() {
      return this.$store.state.invoicesDetails.history;
    },
    getCustomerInvoicesDetailsUpcoming() {
      return this.$store.state.invoicesDetails.upcoming;
    },
    selectedPlanMonthlyPrice() {
      return this.selectedPlan.price.amount / 100;
    },
    selectedPlanYearlyPrice() {
      return this.selectedPlan.price.amount / 100 / 12;
    },
  },
  mounted() {
    this.getProduct()

    // getting account history of logged-in user/customer
    this.getCustomerInvoiceDetails();
  },
  methods: {
    required,
    getUserDB,
    capitalizeFirstLetter,
    async applyPromo() {
      if (this.$refs.promoForm.validate()) {
        if (this.isActive) {
          try {
            this.loadingPromo = true;
            this.promoError = null;
            await this.$axios.patch("stripe/apply-promo-code/" + this.promoCode + "/person/" + this.person._id);

            const user = (await this.$axios.get('/auth/profile')).data;
            this.$store.commit('setUser', user)
            localStorage.setItem('auth_user', JSON.stringify(user))

            this.$refs.promoForm.reset();
            this.loadingPromo = false;
            this.$toast.success('Coupon applied')
            this.$router.go(this.$router.currentRoute)
          } catch (e) {
            console.log(e);
            this.loadingPromo = false;
            this.promoError = "No Promo Code Found";
          }
        } else {
          // const content = {
          //   component: ToastWithAction,
          //   props: {
          //     title: "Kindly subscribe to a plan first.",
          //     action: () => this.$router.push("/settings/account?step=2"),
          //     actionText: "Choose Plan"
          //   }
          // };
          this.$toast.warning("Kindly subscribe to a plan first.");
        }
      }
    },
    async getCustomerInvoiceDetails() {
      try {
        this.loadingInvoiceDetails = true;
        this.customerInvoicesDetails = await this.$axios.get('stripe/customer-invoices/' + this.$store.state.user.customer.id);
        this.$store.commit('setInvoicesDetails', this.customerInvoicesDetails.data)
        this.loadingInvoiceDetails = false;

      } catch (e) {
        console.log(e)
      }
    },
    // getDiscount() {
    //   let discountPrice = 0
    //   let price = (this.selectedPlan.price.amount) / 100;
    //   console.log(price, 'PRICE')
    //
    //   if (this.userSubscription.discount.coupon.amount_off) {
    //     discountPrice = price - (this.userSubscription.discount.coupon.amount_off / 100)
    //   } else {
    //     const amount = (this.userSubscription.discount.coupon.percent_off / 100) * price;
    //     console.log(amount, 'Amount')
    //     discountPrice = price - amount
    //   }
    //   return parseFloat(discountPrice).toFixed(2)
    // },
    async changePlan() {
      if (this.hasPaymentMethod) {
        if (this.selectedPlan && this.selectedPlan.name) {
          switch (this.selectedPlan.name) {
            case 'Professional':
              this.radio = 0
              break
            case 'Done for You - Full Service':
              this.radio = 1
              break
          }
          this.isYearlyPlan = this.selectedPlan.price.id.includes('yearly')
        }
        this.packagesLoading = true;
        await this.getPlans();
        this.packagesLoading = false;
        this.packagesDialog = true;
      } else {
        this.$toast.warning('Add payment method before changing plan.')
      }
    },
    async getPlans() {
      try {
        this.loading = true;
        const plans = (await this.$axios.get("/stripe/products")).data.data;
        for (let i = 0; i < plans.length; i++) {
          plans[i].prices = (await this.$axios.get("stripe/price/" + plans[i].id)).data.data;
        }
        this.proPlan = plans.find((o) => o.name === 'Professional');
        this.dfyPlan = plans.find((o) => o.name === "Done for You - Full Service");
        this.plans = plans.reverse();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getProduct() {
      try {
        this.selectedPlan = (await this.$axios.get('stripe/product/' + this.$store.state.user.subscription.plan.product)).data
        this.selectedPlan.price = this.$store.state.user.subscription.plan
        this.isYearly = this.$store.state.user.subscription.plan.id.includes('yearly')
        console.log('plan', this.selectedPlan);
      } catch (e) {
        console.log(e)
      }
    },
    formatDateInTable(date) {
      return dayjs(date).format('D MMM YYYY');
    },
    getDateFromTimestamp(_timestamp) {
      let date = new Date(_timestamp * 1000);
      return this.formatDateInTable(date)
    },
    formatDate(date, format = "D MMM YYYY") {
      const d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(date);
      return dayjs(d).format(format);
    },
    getColor() {
      if (this.selectedPlan && this.selectedPlan.name) {
        switch (this.selectedPlan.name) {
          case "Professional":
            return "#39B0FF";
          case "Done for You - Full Service":
            return "#FFFFFF";
          default:
            return "#39B0FF";
        }
      }
    },
    async getProration() {
      try {
        this.prorationCalculated = true;
        this.allowUpdate = false;
        let price
        switch (this.radio) {
          case 0:
            if (this.isYearlyPlan) {
              price = this.proPlan.prices.find((o) => o.id.toString().includes('yearly'))
            } else {
              price = this.proPlan.prices.find((o) => o.id.toString().includes('monthly'))
            }
            break;
          case 1:
            if (this.isYearlyPlan) {
              price = this.dfyPlan.prices.find((o) => o.id.toString().includes('yearly'))
            } else {
              price = this.dfyPlan.prices.find((o) => o.id.toString().includes('monthly'))
            }
            break;
        }
        const person = this.$store.state.user._id;
        this.prorationLoading = true;
        if (price) {
          console.log('price id: ', price.id)
          this.proration = (await this.$axios.get(`/stripe/preview-proration/${price.id}/person/${person}`)).data;
        } else {
          this.proration = {
            amount_due: 0
          };
        }
        this.prorationLoading = false;
        this.allowUpdate = true;
      } catch (e) {
        console.log(e);
      }
    },
    checkDisabled(val) {
      if (this.proPlan && this.dfyPlan) {
        let price
        switch (val) {
          case 0:
            if (this.isYearlyPlan) {
              price = this.proPlan.prices.find((o) => o.id.toString().includes('yearly'))
            } else {
              price = this.proPlan.prices.find((o) => o.id.toString().includes('monthly'))
            }
            break;
          case 1:
            if (this.isYearlyPlan) {
              price = this.dfyPlan.prices.find((o) => o.id.toString().includes('yearly'))
            } else {
              price = this.dfyPlan.prices.find((o) => o.id.toString().includes('monthly'))
            }
            break;
        }
        return price && price.id === this.selectedPlan.price.id
      } else {
        return true
      }
    },
    async submitChangePlan() {
      if (confirm("Warning! \n Are you sure you want to change your plan?")) {
        try {
          this.loadingDialog = true
          this.loadingMessage = "Changing your plan...";
          console.log('plan')
          let price
          switch (this.radio) {
            case 0:
              if (this.isYearlyPlan) {
                price = this.proPlan.prices.find((o) => o.id.toString().includes('yearly'))
              } else {
                price = this.proPlan.prices.find((o) => o.id.toString().includes('monthly'))
              }
              break;
            case 1:
              if (this.isYearlyPlan) {
                price = this.dfyPlan.prices.find((o) => o.id.toString().includes('yearly'))
              } else {
                price = this.dfyPlan.prices.find((o) => o.id.toString().includes('monthly'))
              }
              break;
          }
          const person = this.$store.state.user._id;
          const data = {
            price: price.id,
            person: person,
          }
          await this.$axios.post(`/stripe/change-subscription`, data);
          console.log('change')
          const user = await this.getUserDB()
          console.log('user :', user)
          this.$store.commit('setUser', user)
          await this.$store.dispatch('getPlan', {productID: this.$store.state.user.subscription.plan.product})
          await this.getProduct()
          this.loadingDialog = false
          this.packagesDialog = false
          this.$router.go(this.$router.currentRoute)
        } catch (e) {
          this.loadingDialog = false
          this.error = true
          this.errorVal = {
            title: 'Error changing subscription',
            description: e?.response?.data?.message || e?.data?.message || 'Some error occurred'
          }
          console.log(e)
        }
      }
    },
    cancelPlan() {
      this.cancelDialog = true;
    },
    closeCancelDialog() {
      this.cancelReason = 0;
      this.cancelReasonOther = "";
      this.cancelDialog = false;
    },
    async cancelSubscription() {
      if (confirm("Warning! \nAre you sure?")) {
        this.loadingDialog = true;
        this.loadingMessage = "Cancelling Plan...";
        let reason;
        switch (this.cancelReason) {
          case 0:
            reason = "It's too costly.";
            break;
          case 1:
            reason = "I found another product that fulfills my needs.";
            break;
          case 2:
            reason = "I don't use it enough.";
            break;
          case 3:
            if (this.cancelReasonOther)
              reason = this.cancelReasonOther;
            else
              reason = "Some other reason.";
            break;
        }
        try {
          await this.$axios.delete("stripe/person/" + this.$store.state.user._id + "/cancel-subscription?reason=" + reason);
          const user = await this.getUserDB()
          this.$store.commit('setUser', user)
          this.closeCancelDialog();
          this.loadingDialog = false;
          this.$router.go(this.$router.currentRoute)
        } catch (e) {
          console.log(e);
          this.errorVal = {
            title: "Error",
            description: e?.response?.data?.message || "Some error occurred"
          };
          this.error = true;
          this.loadingDialog = false;
        }
      }
    },
  }
}

</script>

<style scoped>

.detail-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.border {
  border: 1px solid red;
}

.package-detail {
  list-style: none; /* Remove default bullets */
}

.package-detail li:before {
  content: "\25E6";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  color: #8974b9;
}

.package-detail.free li:before {
  color: #8974b9;
}

.package-detail.pro li:before {
  color: #4cb992;
}

.package-detail.basic li:before {
  color: #3499e1;
}

.package-detail.full li:before {
  color: #eeb67e;
}
</style>