<template>
  <section :style="{ backgroundImage: 'url(' + backgroundImage + ')' }" class="wrapper">
    <div v-if="!isSubmitted" class="card">
      <div class="left">
        <div class="left__img pa-1">
          <img :src="logo" alt="thank-you"
               height="100%" style="object-fit: contain" width="100%"/>
        </div>
        <div class="left__paragraph">
          {{ subtitle }}
        </div>

        <section class="company">
          <h2 class="company__heading">{{ organizationName }}</h2>
          <h3 class="company__subheading">{{ organizationEmail }}</h3>
          <p v-if="organizationAddress" class="company__address">{{ organizationAddress }}</p>
          <span class="company__phone">{{ organizationPhone }}</span>
        </section>

      </div>
      <div class="right">
        <form action="#" class="right__form">
          <h1 class="right__heading">{{ title }}</h1>

          <div class="form__group">
            <div class="form__item">
              <label class="form__label" for="message">Message</label>
              <textarea id="message" v-model="message" :disabled="loading" class="form__textarea">
              </textarea>
              <span v-if="errors.message" class="error-text" v-html="errors.message"/>
            </div>
          </div>

          <div class="form__group">
            <div class="form__item">
              <label class="form__label" for="first_name">First Name</label>
              <input id="first_name" v-model="firstName" :disabled="loading" class="form__input" type="text">
              <span v-if="errors.firstName" class="error-text" v-html="errors.firstName"/>
            </div>

            <div class="form__item">
              <label class="form__label" for="last_name">Last Name</label>
              <input id="last_name" v-model="lastName" :disabled="loading" class="form__input" type="text">
              <span v-if="errors.lastName" class="error-text" v-html="errors.lastName"/>
            </div>
          </div>

          <div class="form__group">
            <div class="form__item">
              <label class="form__label" for="email">Email</label>
              <input id="email" v-model="email" :disabled="loading" class="form__input" type="text">
              <span v-if="errors.email" class="error-text" v-html="errors.email"/>
            </div>

            <div class="form__item">
              <label class="form__label" for="phone">Phone</label>
              <input id="Phone" v-model="phone" :disabled="loading" class="form__input" type="number">
              <span v-if="errors.phone" class="error-text" v-html="errors.phone"/>
            </div>
          </div>

          <div class="form__group">
            <div class="form__item">
              <label class="form__label" for="meeting_date">Meeting Date</label>
              <input id="meeting_date" v-model="date" :disabled="loading" class="form__input" type="date">
              <span v-if="errors.date" class="error-text" v-html="errors.date"/>
            </div>
          </div>

          <div class="form__footer">
            <button id="submit" class="form__submit" type="button" @click="submit">
              {{ loading ? 'Submitting' : 'Submit' }}
              <v-progress-circular v-if="loading" class="ml-3" indeterminate size="18" width="2"/>
            </button>
          </div>

        </form>
      </div>
    </div>

    <div v-else class="pa-16 d-flex flex-column align-center rounded"
         style="background-color: white;max-width: 700px; max-height: 500px;">
      <h1 class="mb-6 text-sm-h3 text-center">Thank You!</h1>
      <p class="text-center">Thanks for getting in touch with us. We have received your message and will contact you
        shortly.</p>
      <img alt="thank-you" height="30%" src="@/assets/thankyou.svg" width="40%"/>
    </div>

  </section>
</template>

<script>
import * as Logo from "@/assets/logo-large.png";
import {LeadsService} from "@/services/leads-service";
import moment from "moment";
import {
  templateDefaultBackgroundImage,
  templateDefaultOrganizationAddress,
  templateDefaultOrganizationEmail,
  templateDefaultOrganizationName,
  templateDefaultOrganizationPhone,
  templateDefaultSubtitle,
  templateDefaultTitle
} from "@/utils/local";

export default {
  name: "lead3",

  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    personId: {
      type: String,
      default: null
    },
  },

  data: () => ({
    title: "",
    subtitle: "",
    backgroundImage: null,
    themeColor: "",
    templateId: null,

    organizationName: null,
    organizationLogo: null,
    organizationPhone: null,
    organizationEmail: null,
    organizationAddress: null,

    service: new LeadsService(),

    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: "",
    message: "",

    errors: {},
    loading: false,
    isSubmitted: false,
  }),

  computed: {
    logo() {
      return this.organizationLogo ? this.organizationLogo : Logo
    }
  },

  watch: {
    firstName(val) {
      if (val !== "" && val.length < 3) {
        this.errors.firstName = "First Name must be at least 3 characters long";
      } else {
        this.errors.firstName = "";
      }
    },

    lastName(val) {
      if (val !== "" && val.length < 3) {
        this.errors.lastName = "Last Name must be at least 3 characters long";
      } else {
        this.errors.lastName = "";
      }
    },

    email(val) {
      if (val !== "" && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/.test(val) === false) {
        this.errors.email = "Email is not valid";
      } else {
        this.errors.email = "";
      }
    },

    phone(val) {
      if (val !== "" && val.length < 6) {
        this.errors.phone = "Phone must be at least 6 characters long";
      } else {
        this.errors.phone = "";
      }
    },

    date(val) {
      if (val !== "" && moment().isSameOrAfter(val)) {
        this.errors.date = "Date must be in the future";
      } else if(/(^\d{4})-(0?[1-9]|1[0-2])-(0?[0-9]|[12]\d|30|31)$/.test(val) === false) {
        this.errors.date = "Date is not valid";
      } else {
        this.errors.date = "";
      }
    },

    message(val) {
      if (val !== "" && val.length < 3) {
        this.errors.message = "message must be at least 3 characters long";
      } else {
        this.errors.message = "";
      }
    },
  },

  async mounted() {
    await this.loadData();
  },

  methods: {

    validate() {
      this.errors = {};

      if (!this.firstName) {
        this.errors.firstName = "First Name is required";
      }

      if (!this.lastName) {
        this.errors.lastName = "Last Name is required";
      }

      if (!this.email) {
        this.errors.email = "Email is required";
      }

      if (!this.phone) {
        this.errors.phone = "Phone is required";
      }

      if (!this.date) {
        this.errors.date = "Date is required";
      }

      if (!this.message) {
        this.errors.message = "Message is required";
      }

      return Object.keys(this.errors).length === 0;
    },

    async submit() {
      if (this.enabled) {

        if (this.errors.firstName || this.errors.lastName || this.errors.email || this.errors.phone || this.errors.date || this.errors.message) {
          return;
        }

        if (!this.validate()) return;

        try {
          this.loading = true
          this.isSubmitted = false

          const data = {
            email: this.email,
            name: this.firstName + (this.lastName ? ' ' + this.lastName : ''),
            phone: this.phone,
            note: this.message,
            date: this.date,
            person: this.personId
          }

          await this.service.create(data)
          this.loading = false
          this.isSubmitted = true
        } catch (e) {
          this.loading = false
          this.errors.submit = e?.response?.data?.message || e?.data?.message || e?.error || 'Some error occurred'
        }

      }
    },


    async loadData() {
      if (!this.enabled) {
        this.personId = this.$store.state.user._id;
      }

      this.loadingMessage = 'Loading...';
      this.loading = true
      this.error = false
      let slug;
      if (this.$route.path.split('/').length === 2) {
        slug = this.$route.path.split('/').slice(1);
      }
      const data = slug? (await this.$axios.get('/persons/slug/' + slug )).data : (await this.$axios.get('/persons/' + this.personId)).data;
      const template = data?.selected_template;

      this.title = template?.form_title || templateDefaultTitle;
      this.subtitle = template?.form_subtitle || templateDefaultSubtitle;
      this.backgroundImage = template?.background_image || templateDefaultBackgroundImage;

      this.organizationName = data.organizationName || templateDefaultOrganizationName;
      this.organizationPhone = data.phone || templateDefaultOrganizationPhone;
      this.organizationEmail = data.organizationEmail ? data?.organizationEmail : data?.username || templateDefaultOrganizationEmail;
      this.organizationLogo = data.organizationLogo;
      this.organizationAddress = data.organizationAddress || templateDefaultOrganizationAddress;

      this.loading = false
    },
  }
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.card {
  width: 80%;
  max-width: 1100px;
  height: 90vh;
  max-height: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
  overflow: hidden;
  display: flex;
}

.left {
  width: 30%;
  background-color: #FFD41D;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 50px;
  align-items: center;
}

.left__img {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
}

.left__paragraph {
  font-size: 16px;
  font-weight: 400;
  /*text-align: justify;*/
}

.left__paragraph:not(:last-child) {
  margin-bottom: 100px;
}

.company {
  margin-top: 4rem;
  width: 100%;
}

.company__heading {
  font-size: 18px;
  font-weight: 500;
}

.company__subheading {
  font-size: 16px;
  font-weight: 400;
  word-break: break-word;
}

.company__address {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.company__phone {
  font-size: 14px;
  font-weight: 400;
}

.right {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
  overflow-y: auto;
}

.right__form {
  width: 90%;
  text-align: left;
}

.right__heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.form__group {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.form__group:not(:last-child) {
  margin-bottom: 20px;
}

.error-text {
  color: red;
  font-size: 0.8rem;
  margin-top: 3px;
}

.form__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form__label {
  font-size: 12px;
  color: gray;
  font-weight: 300;
  margin-bottom: 5px;
}

.form__textarea {
  height: 100px;
  background-color: #F2F4F4;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  resize: none;
}

.form__textarea:focus, .form__input:focus {
  outline: 1px solid teal;
  border: 1px solid teal;
}

.form__input {
  background-color: #F2F4F4;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 400;
}

.form__footer {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
}

.form__submit {
  background-color: #EE0149;
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .4);
  border-bottom: 5px solid rgba(0, 0, 0, .2);
  transition: all .2s;
}

.form__submit:active {
  transform: scale(.9);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
}

@media screen and (max-width: 786px) {

  .card {
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
  }

  .left {
    width: 100%;
    padding: 40px 40px 20px;
  }

  .left__img {
    text-align: center;
    margin-bottom: 20px;
  }

  .left__paragraph {
    margin-bottom: 20px !important;
    text-align: center;
  }

  .right {
    width: 100%;
    height: 70%;
    padding: 20px;
    overflow-y: unset;
  }

  .form__group {
    flex-direction: column;
  }

  .form__group:not(:last-child) {
    margin-bottom: 10px;
  }

  .form__item {
    width: 100%;
  }

  .form__textarea {
    height: 100px;
  }

  .form__footer {
    justify-content: center;
    margin-bottom: 20px;
  }

}


</style>