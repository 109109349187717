import axios from 'axios';

export class OpenaiService {

    // async generate() {
    // async generate(postFormat) {
    //     try {
    //
    //         const apiUrl = process.env.VUE_APP_CHATGPT_URL;
    //         const apiKey = process.env.VUE_APP_CHATGPT_API_KEY;
    //
    //         const prompt = `Could you write a ${postFormat.tone.toLowerCase()}, ${postFormat.writingStyle.toLowerCase()} post of ${postFormat.postLength.toLowerCase()} length for ${postFormat.targetAudience.toLowerCase()} about ${postFormat.topic}?`;
    //
    //         const response = await axios.post(apiUrl, {
    //             model: "text-davinci-003",
    //             prompt,
    //             // max_tokens: 4096,
    //             max_tokens: 4000,
    //             temperature: 0.7,
    //             n: 1
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${apiKey}`
    //             }
    //         });
    //
    //         return response.data.choices[0].text.trim();
    //     } catch (error) {
    //         throw { "title": "Error generating post", "description": "An error occurred while generating the post." };
    //     }
    // }

    async findingResponseFromChatGpt(prompt) {
        try {

            const apiUrl = process.env.VUE_APP_CHATGPT_URL;
            const apiKey = process.env.VUE_APP_CHATGPT_API_KEY;

            // const prompt = `Could you write a ${postFormat.tone.toLowerCase()}, ${postFormat.writingStyle.toLowerCase()} post of ${postFormat.postLength.toLowerCase()} length for ${postFormat.targetAudience.toLowerCase()} about ${postFormat.topic}?`;

            const response = await axios.post(apiUrl, {
                model: "gpt-3.5-turbo",
                messages:[{
                    role: "user",
                    content: prompt,
                }]
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                }
            });

            console.log('chatGpt: ', response.data.choices[0].message.content);

            // return response.data.choices[0].text.trim();
            return response.data.choices[0].message.content;
        } catch (error) {
            throw { "title": "Error generating post", "description": "An error occurred while generating the post." };
        }
    }
}
