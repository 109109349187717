<template>
  <div>
    <div class="pa-3" style="background: rgb(244, 241, 239)">
      <div
          @click="$emit('openPostForm', null)"
          class="mb-2 rounded px-4 d-flex justify-space-between align-center pointer"
          style="width:auto;border: 1px solid #2177b0; min-height: 40px; background: #2177b0; color: white"
      >
        <p class="ma-0 font-weight-medium pointer">New Post
          <v-icon class="ml-2" color="white">mdi-pencil</v-icon>
        </p>
      </div>
      <v-menu
          v-model="socialMediaPicker"
          :close-on-content-click="false"
          :nudge-bottom="15"
          transition="scale-transition"
          offset-y
          min-width="calc(100% - 20px)"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
              @click:append="socialMediaPicker = true"
              class="mb-2 rounded px-4 d-flex justify-space-between align-center pointer"
              style="width:auto;min-width: 10%;border: 1px solid #2177b0; min-height: 40px; background: #2177b0; color: white"
              v-bind="attrs"
              v-on="on"
          >
            <v-avatar v-if="selectedSocialMediaProfiles.length > 0" size="26" class="mr-3" color="#001E30">
              <p class="ma-0">{{ selectedSocialMediaProfiles.length }}</p>
            </v-avatar>
            <p class="ma-0 font-weight-medium pointer">Social accounts</p>
            <v-icon color="white">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-card class="elevation-0" rounded="0">
          <div class="pa-2">
            <v-text-field
                v-model="socialMediaSearch"
                label="Search by account name"
                outlined dense clearable prepend-inner-icon="mdi-magnify"
                hide-details></v-text-field>
          </div>
          <div v-if="socialMediaProfiles && socialMediaProfiles.length > 0" style="max-height: 450px; overflow-y: scroll">
            <v-list>
              <v-list-item-group multiple>
                <v-list-item
                    v-for="(socialMediaProfile,i) in localSocialProfiles.filter((o) => o.type !== SocialMediaType.facebook)"
                    :key="i" @click="updateSocialMediaFilter(socialMediaProfile)">
                  <template v-slot:default>
                    <v-list-item-avatar size="32" class="mr-3">
                      <v-avatar size="32">
                        <img v-if="socialMediaProfile.avatar" :src="socialMediaProfile.avatar"/>
                        <img v-else src="https://s.pinimg.com/images/user/default_60.png"/>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="d-flex">
                      <v-list-item-title>
                        <v-icon small :color="getSocialMediaTypeColor(socialMediaProfile.type)">
                          {{ getSocialMediaTypeIcon(socialMediaProfile.type) }}
                        </v-icon>
                        {{ socialMediaProfile.username }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary"
                              v-if="selectedSocialMediaProfiles.find((o) => o === socialMediaProfile._id)">
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                    </v-list-item-action>

                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div v-else>
            <v-list-item dense>
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    No Social Profiles connected yet.
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </div>
          <v-list-item dense @click="openSocialMediaConnector">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title style="color: #2177b0" class="font-weight-medium">
                  <v-icon color="#2177b0" small>mdi-plus-circle</v-icon>
                  Add social accounts
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <div class="d-flex pa-2 justify-end flex-column">
            <v-btn class="flex-grow-1" elevation="0" color="grey lighten-2" @click="socialMediaPicker = false">Close
            </v-btn>
          </div>
        </v-card>
      </v-menu>
      <v-menu
          v-model="statusPicker"
          :close-on-content-click="false"
          :nudge-bottom="15"
          transition="scale-transition"
          offset-y
          min-width="calc(100% - 20px)"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
              @click:append="statusPicker = true"
              class="mb-2 rounded px-4 d-flex justify-space-between align-center pointer"
              style="width:auto;min-width: 10%;border: 1px solid #2177b0; min-height: 40px; background: #2177b0; color: white"
              v-bind="attrs"
              v-on="on"
          >
            <v-avatar v-if="selectedStatuses.length > 0" size="26" class="mr-3" color="#001E30">
              <p class="ma-0">{{ selectedStatuses.length }}</p>
            </v-avatar>
            <p class="ma-0 font-weight-medium pointer">Post Status</p>
            <v-icon color="white">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-card class="elevation-0" rounded="0">
          <v-list>
            <v-list-item-group
                multiple
            >
              <v-list-item v-for="(status,i) in statuses" :key="i" @click="updateStatusFilter(status)">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ status.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="d-flex pa-2 justify-end">
            <v-btn class="flex-grow-1" elevation="0" color="grey lighten-2" @click="statusPicker = false">Close
            </v-btn>
          </div>
        </v-card>
      </v-menu>
      <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-bottom="15"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
              @click:append="datePicker = true"
              class="mb-2 rounded px-4 d-flex justify-space-between align-center pointer"
              style="width:auto;min-width: 10%;border: 1px solid #2177b0; min-height: 40px; background: #2177b0; color: white"
              v-bind="attrs"
              v-on="on"
          >
            <p class="ma-0 font-weight-medium pointer">{{ formatDatePicker }}</p>
            <v-icon color="white">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-date-picker
            v-model="date"
        ></v-date-picker>
      </v-menu>
    </div>
    <div style="background: rgb(244, 241, 239); min-height: calc(100vh - 100px)">
      <div class="pa-4" v-if="posts && posts.length > 0">
        <div class="mb-4" v-for="(post,i) in posts" :key="i">
          <PostCalendarCard :post="post" :social-media-profiles="socialMediaProfiles"
                            @togglePost="togglePost(post)"/>
        </div>
      </div>
      <div v-else class="text-center mx-auto"
           :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 50%' : 'max-width: 70%'">
        <v-icon large color="primary" class="mb-3">mdi-image-filter-none</v-icon>
        <h3>You don't have any posts yet!</h3>
        <h4 v-if="socialMediaProfiles && socialMediaProfiles.length > 0" class="text-subtitle-1">Create some interesting
          posts using the above button</h4>
        <h4 v-else class="text-subtitle-1">Connect your social accounts to postredi using the connect button <span
            v-if="$vuetify.breakpoint.mdAndUp">on top right corner.</span>
          <span v-else>from the drawer.</span>
        </h4>
      </div>
    </div>
    <v-dialog v-model="detailPostDialog" width="90%">
      <v-card>
        <PostDetail :detail-post="detailPost" :is-post-open="detailPostDialog" @togglePost="closeDetail"
                    @reloadData="reload"
                    @openPostEditForm="(e) => $emit('openPostEditForm', e)"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PostCalendarCard from "@/components/posts/PostCalendarCard";
import PostDetail from "@/components/posts/PostDetail";
import {PostStatus, SocialMediaType} from "@/utils/enum";
import {getSocialMediaTypeColor, getSocialMediaTypeIcon} from '@/utils/local'
import moment from "moment-timezone";

export default {
  name: "PostsMobileView",
  components: {PostDetail, PostCalendarCard},
  props: {
    socialMediaProfiles: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      detailPostDialog: false,
      detailPost: null,
      date: null,
      datePicker: false,
      statusPicker: false,
      socialMediaPicker: false,
      statuses: [
        {
          text: 'Pending',
          value: PostStatus.pending
        },
        {
          text: 'Drafts',
          value: PostStatus.draft
        },
        {
          text: 'Scheduled',
          value: PostStatus.scheduled
        },
        {
          text: 'Published',
          value: PostStatus.posted
        },
        {
          text: 'Failed',
          value: PostStatus.failed
        },
        {
          text: 'Disconnected',
          value: PostStatus.disconnected
        },
      ],
      selectedStatuses: [],
      selectedSocialMediaProfiles: [],
      socialMediaSearch: null,
      PostStatus,
      SocialMediaType
    }
  },
  computed: {
    formatDatePicker() {
      let val = 'Select Date'
      if (this.date) {
        val = moment(this.date).format('MMM DD, YYYY')
      }
      return val.toString()
    },
    posts() {
      if (this.selectedSocialMediaProfiles.length > 0 && this.selectedStatuses.length > 0 && this.date) {
        // a b c
        return this.allPosts.filter((o) => this.selectedStatuses.includes(o.status) && this.selectedSocialMediaProfiles.includes(o.socialProfile._id) && (moment(o.scheduled_date.fullDate).diff(this.date, 'hour') < 24 && moment(o.scheduled_date.fullDate).diff(this.date, 'hour') > 0))
      } else if (this.selectedSocialMediaProfiles.length > 0 && this.selectedStatuses.length > 0) {
        // a b
        return this.allPosts.filter((o) => this.selectedStatuses.includes(o.status) && this.selectedSocialMediaProfiles.includes(o.socialProfile._id))
      } else if (this.selectedSocialMediaProfiles.length > 0 && this.date) {
        // a c
        return this.allPosts.filter((o) => (moment(o.scheduled_date.fullDate).diff(this.date, 'hour') < 24 && moment(o.scheduled_date.fullDate).diff(this.date, 'hour') > 0) && this.selectedSocialMediaProfiles.includes(o.socialProfile._id))
      } else if (this.selectedStatuses.length > 0 && this.date) {
        // b c
        return this.allPosts.filter((o) => this.selectedStatuses.includes(o.status) && (moment(o.scheduled_date.fullDate).diff(this.date, 'hour') < 24 && moment(o.scheduled_date.fullDate).diff(this.date, 'hour') > 0))
      } else if (this.selectedSocialMediaProfiles.length > 0) {
        // a
        return this.allPosts.filter((o) => this.selectedSocialMediaProfiles.includes(o.socialProfile._id))
      } else if (this.selectedStatuses.length > 0) {
        // b
        return this.allPosts.filter((o) => this.selectedStatuses.includes(o.status))
      } else if (this.date) {
        // c
        return this.allPosts.filter((o) => {
          return (moment(o.scheduled_date.fullDate).diff(this.date, 'hour') < 24 && moment(o.scheduled_date.fullDate).diff(this.date, 'hour') > 0)
        })
      } else {
        return this.allPosts
      }
    },
    allPosts() {
      return this.$store.state.posts
    },
    localSocialProfiles() {
      if (this.socialMediaSearch) {
        return this.socialMediaProfiles.filter((o) => o.username.toLowerCase().includes(this.socialMediaSearch.toLowerCase())).sort(function (a, b) {
          return a.username.localeCompare(b.username) && a.type.localeCompare(b.type);
        })
      } else {
        return this.socialMediaProfiles
      }
    }
  },
  methods: {
    getSocialMediaTypeColor,
    getSocialMediaTypeIcon,
    async reload() {
      this.reloading = true
      this.detailPost = null
      this.isPostOpen = false
      await this.$store.dispatch('getSocialPlatforms')
      await this.$store.dispatch('getPosts')
      this.reloading = false
    },
    togglePost(post, flag = true) {
      this.detailPostDialog = flag
      const delayInMilliseconds = 1000; //1 second
      setTimeout(function () {
      }, delayInMilliseconds);
      this.detailPost = post
    },
    closeDetail(event) {
      this.togglePost(event.post, event.flag)
    },
    openSocialMediaConnector() {
      this.$store.commit('setOpenSocialConnector', true)
      this.statusPicker = false
      this.socialMediaPicker = false
    },
    updateStatusFilter(status) {
      const element = this.selectedStatuses.find((o) => o === status.value)
      if (element) {
        const index = this.selectedStatuses.indexOf(element)
        this.selectedStatuses.splice(index, 1)
      } else {
        this.selectedStatuses.push(status.value)
      }
    },
    updateSocialMediaFilter(socialMediaProfile) {
      const element = this.selectedSocialMediaProfiles.find((o) => o === socialMediaProfile._id)
      if (element) {
        const index = this.selectedSocialMediaProfiles.indexOf(element)
        this.selectedSocialMediaProfiles.splice(index, 1)
      } else {
        this.selectedSocialMediaProfiles.push(socialMediaProfile._id)
      }
    },
    applyFilter() {
      this.statusPicker = false
      this.socialMediaPicker = false
    },
  }
}
</script>

<style scoped>
.post-card {
  max-width: 100% !important;
  margin: 0;
}

.post-card-main-content-div {
  max-width: 100% !important;
}

.post-card-main-content:hover {
  outline: none !important;
  transform: none !important;
  box-shadow: none !important;
}
</style>