<template>
  <v-menu v-model="profileMenu" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
          color="primary"
          elevation="5"
          size="36"
          v-bind="attrs"
          v-on="on"
      >
        <div class="profile-view__letter">{{ resolveLetter() }}</div>
      </v-avatar>
    </template>

    <v-card class="profile-view__popup" style="max-height: 600px; overflow-y: auto">
      <div class="profile-view__image my-2">
        <div class="profile-view__letter" style="width: 100px; height: 100px; border-radius: 50%; display: flex; justify-content: center; align-items: center"
        >{{ resolveLetter() }}
        </div>
      </div>

      <p id="display-name">{{ this.user.name || "Name" }}</p>
      <p id="username">{{ this.user.username || "Email" }}</p>

      <v-divider class="my-2"/>

      <v-list-item
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          to="/"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-calendar'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Publisher</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          to="/leads"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-group'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Leads</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          to="/social-platforms"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-bullhorn'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Social Platforms</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-dialog v-model="timezoneDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
              active-class="route--active"
              class="route"
              color="#da57a7"
              dense
              exact
              to=""
              v-bind="attrs"
              @click="timezoneDialog = true"
              v-on="on"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-clock'"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Set Timezone</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card>
          <v-col class="d-flex align-center justify-space-between">
            <v-card-title class="text-h5"> Time Zone</v-card-title>

            <v-btn color="primary" text @click="closeTzDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>

          <v-divider></v-divider>

          <v-col class="d-flex justify-center align-center">
            <v-card-text>Your Timezone is:</v-card-text>
            <v-card-text>{{ this.getTimezone() }}</v-card-text>
          </v-col>

          <v-col>
            <v-select
                v-model="timezone"
                :items="timezones"
                item-text="name"
                item-value="_id"
                label="Timezones"
                outlined
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                        v-model="searchtz"
                        outlined
                        placeholder="Search Timezone"
                        @input="searchTimezone"
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="setTimezone"> Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list-item
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          to="/account"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-credit-card'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          v-if="user.promoter"
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          to="/promoter"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-tie-voice'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Promoter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          v-else
          active-class="route--active"
          class="route"
          color="#da57a7"
          dense
          exact
          @click="$emit('openPromoter', true)"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-tie-voice'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Become a Promoter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"/>

      <v-btn
          class="mb-2"
          color="primary"
          elevation="0"
          width="100%"
          @click="openEditForm"
      >
        <v-icon class="mr-2" small>mdi-account</v-icon>
        Edit Profile
      </v-btn>
      <v-btn
          class="mb-2"
          color="primary"
          elevation="0"
          outlined
          width="100%"
          @click="openSecurityForm"
      >
        <v-icon class="mr-2" small>mdi-lock</v-icon>
        Change Password
      </v-btn>

      <v-btn class="mb-2" elevation="0" outlined width="100%" @click="logout">
        <v-icon class="mr-2" small>mdi-logout</v-icon>
        Sign Out
      </v-btn>
    </v-card>
    <ProfileForm v-model="profileForm" :user="user"/>
    <SecurityForm v-model="securityForm"/>
  </v-menu>
</template>

<script>
import ProfileForm from "@/components/auth/ProfileEdit";
import SecurityForm from "@/components/auth/SecurityEdit";

import {currentTimezone, getTimezones} from "@/utils/local";

export default {
  name: "ProfilePopup",
  components: {SecurityForm, ProfileForm},
  data() {
    return {
      profileForm: false,
      profileMenu: false,
      securityForm: false,
      reportIssueForm: false,
      timezone: "",
      timezoneDialog: false,
      searchtz: "",
      timezones: getTimezones(),
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    getTimezones,
    currentTimezone,

    closeTzDialog() {
      this.timezoneDialog = false;
      this.timezone = "";
    },

    searchTimezone() {
      if (this.searchtz != "") {
        this.timezones = this.timezones.filter((timezone) => {
          return this.searchtz
              .toLowerCase()
              .split(" ")
              .every((v) => timezone.toLowerCase().includes(v));
        });
      } else {
        this.timezones = "";
        this.timezones = getTimezones();
      }
    },

    setTimezone() {
      this.$store.commit("setTimezone", this.timezone);
      localStorage.setItem("Timezone", this.timezone);
      this.timezoneDialog = false;
      location.reload();
    },

    getTimezone() {
      if (localStorage.getItem("Timezone")) {
        return localStorage.getItem("Timezone");
      } else {
        localStorage.setItem("Timezone", currentTimezone());
        return localStorage.getItem("Timezone");
      }
    },

    resolveLetter() {
      if (this.user && this.user.name) {
        return this.user.name[0];
      } else {
        return "_";
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/sign-in").catch(() => {
      });
    },
    openEditForm() {
      this.profileMenu = false;
      this.profileForm = true;
    },
    openSecurityForm() {
      this.profileMenu = false;
      this.securityForm = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.profile-view
  display: flex
  align-items: center
  background: #f5c187
  justify-content: center

  &__letter
    font-size: 22px
    color: rgba(white, 1)
    font-family: google-sans, sans-serif

  &__image
    width: 80px
    height: 80px
    background: #2177b0
    margin: 0 auto
    border-radius: 40px !important
    display: flex
    justify-content: center
    align-items: center

  &__popup
    width: 290px
    padding: 15px
    display: flex
    text-align: center
    flex-direction: column

    &__actions
      margin-top: 10px
      display: flex

#display-name
  margin: 0
  font-size: 16px
  font-weight: bold

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active i
    color: inherit

  &--active
    color: #2177b0
</style>
