<template>
  <div id="background">
    <v-card class="sign-in" elevation="10">
      <img src="../../assets/logo-large.png" alt="Postredi" class="sign-in__logo"/>
      <div v-if="show === 0">
        <p class="sign-in__message">
          Don't have an account? <a href="/sign-in?signup=true">Create an account</a>
        </p>
        <v-form ref="form">
          <v-text-field
              v-model="resetData.password"
              :rules="[required('Do not leave this field empty') , minChar(), oneAlphabet(), oneNumber(), maxChar]"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              label="Your New Password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              @click:append="showPassword = !showPassword"
              @keypress.enter="reset"
          />
          <v-text-field
              v-model="resetData.confirmPassword"
              :rules="[required('Do not leave this field empty'), (v) => (v && v === resetData.password) || 'Password could not be confirmed']"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              label="Confirm New Password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              @click:append="showPassword = !showPassword"
              @keypress.enter="reset"
          />

          <v-btn
              color="primary"
              dark
              elevation="0"
              width="100%"
              height="45px"
              @click="reset"
          >
            Reset
          </v-btn>
          <p class="sign-in__message-below">
            Already with us? <a href="/sign-in">Sign In</a>
          </p>
        </v-form>
      </div>
      <div v-else>
        <p class="sign-in__message">
          Password Reset. Goto Sign in to access your account with new password.
        </p>
        <v-btn
            color="primary"
            dark
            elevation="0"
            width="100%"
            height="45px"
            @click="() => { $router.push('/sign-in').catch(() => {}); }"
        >
          Sign in
        </v-btn>
      </div>

    </v-card>

    <loading-dialog v-model="loading" message="Resetting your password, Please wait..."/>
    <error-dialog v-model="error" :error="errorVal"/>
  </div>

</template>

<script>

import LoadingDialog from '@/components/LoadingDialog'
import ErrorDialog from '@/components/ErrorDialog'
import {required, minChar, maxChar, oneAlphabet, oneNumber} from '@/utils/validators'

export default {
  name: 'Id',
  components: {ErrorDialog, LoadingDialog},
  data() {
    return {
      id: this.$route.params.id,
      error: false,
      errorVal: {},
      loading: false,
      resetData: {
        password: '',
        confirmPassword: ''
      },
      show: 0,
      showPassword: false
    }
  },
  methods: {
    required, minChar, maxChar, oneAlphabet, oneNumber,
    async reset() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.resetData.password !== this.resetData.confirmPassword) {
          this.loading = false
          this.error = true
          this.errorVal = {
            title: 'Could not confirm password',
            description: 'Both password fields should be same.'
          }
          return
        }
        try {
          const data = {
            hash: this.id,
            password: this.resetData.password
          }
          await this.$axios.patch('persons/reset-password', data)
          this.loading = false
          this.$toast.success('Password changed successfully')
          this.show = 1
          this.$refs.form.reset()
        } catch (e) {
          console.log(e)
          this.loading = false
          this.error = true
          this.errorVal = {
            title: 'Error',
            description: e?.response?.data?.message || e?.data?.message || 'Some error occured'
          }
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  text-align: center
  background: white
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 80%
    margin-bottom: 15px

  &__message
    text-align: left
    font-size: 13px
    margin-bottom: 40px

  &__message-below
    font-size: 13px
    margin-top: 20px


#background
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  //background: #7c1e1e
  background: #F4F5FA

.text-divider
  margin: 2em 0
  line-height: 0
  text-align: center

.text-divider span
  background-color: #ffffff
  padding: 1em

.text-divider:before
  content: " "
  display: block
  border-top: 1px solid #e3e3e3
  border-bottom: 1px solid #f7f7f7

//.logo-box {
//  padding: 10px;
//  margin: -120px auto 30px auto;
//}
//
//.heading {
//  font-size: 24px;
//  font-weight: normal;
//  margin-bottom: 20px;
//  font-family: google-sans, sans-serif;
//}
</style>

<style>
html {
  overflow-y: auto;
}
</style>