<template>
  <div>
    <v-card @click.self.prevent="confirmDialog" :style="!isProfessionalOrFull ? 'opacity: 1' : ''" elevation="1"
            rounded="0"
            :max-width="$vuetify.breakpoint.mdAndUp ? '80%' : '100%'" class="mx-auto text-center pa-4"
            style="position: relative">
      <div
          style="position: absolute; top: 5px; right: 5px; background: #800000;border-top-right-radius: 8px; border-bottom-left-radius: 8px; width: 35px; height: 35px"
          class="pa-2 d-flex justify-center align-center" @click="gotoAccount">
        <div v-if="!isProfessionalOrFull">
          <v-tooltip
              top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="white" small v-bind="attrs"
                      v-on="on">mdi-star
              </v-icon>
            </template>
            <span>Go Premium</span>
          </v-tooltip>
        </div>
        <v-tooltip v-else top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <p class="ma-0 white--text" v-bind="attrs"
               v-on="on">{{ totalPlatforms || 0 }}</p>
          </template>
          <span v-if="totalPlatforms > 0">Total {{ totalPlatforms }} {{ totalPlatforms > 1 ? 'accounts' : 'account' }} connected</span>
          <span v-else>No accounts connected.</span>
        </v-tooltip>
      </div>

      <v-icon @click.self.prevent="confirmDialog" x-large class="mb-2" :color="color">mdi-instagram</v-icon>
      <h4 @click.self.prevent="confirmDialog" class="text-center" v-if="$vuetify.breakpoint.mdAndUp">Instagram</h4>
    </v-card>
    <v-dialog v-model="setup" width="30%" persistent>
      <v-card class="pa-4">
        <div class="ma-4">
          <p class="ma-0 text-h4 mb-3">Social account setup still in progress</p>
          <p class="ma-0 mb-3"> There are still a few steps remaining to connect your social account to Postredi.</p>
          <div class="d-flex justify-end">
            <v-btn elevation="0" @click="closeWindow" class="mr-3" tile>Cancel</v-btn>
            <v-btn elevation="0" color="primary" @click="focusOnWindow" tile>Resume Setup</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmSetup" :width="$vuetify.breakpoint.mdAndUp ? '35%' : '85%'" persistent>
      <v-card class="pa-4">
        <div class="ma-4">
          <p class="ma-0 text-md-h4 text-h6 mb-3">Log in to Facebook to add Instagram</p>
          <p class="ma-0">Instagram Business profiles have to be connected to Facebook Pages.</p>
          <a class="mb-3 font-weight-bold"
             href="https://postredi.com/connect-with-postredi-link-instagram-with-facebook-page/"
             target="_blank">How to set up Instagram Business & connect to Facebook Page.</a>
          <v-timeline
              dense
              clipped
              align-top
          >
            <v-timeline-item fill-dot color="white">
              <template v-slot:icon>
                <v-icon color="#1878f3" large>mdi-facebook</v-icon>
              </template>
              <p class="text-md-h6 text-body-2 pa-0 mb-1">
                1- Log in with Facebook
              </p>
              <v-card-text class="white text--primary pa-0">
                <p>We'll find the Instagram Business profiles that are connected to your Facebook Pages.</p>
              </v-card-text>
            </v-timeline-item>
            <v-timeline-item fill-dot color="white">
              <template v-slot:icon>
                <v-icon color="#d93176" large>mdi-instagram</v-icon>
              </template>
              <p class="text-md-h6 text-body-2 text-wrap pa-0">
                2- Select the Instagram Business profiles to add to Postredi
              </p>
            </v-timeline-item>
          </v-timeline>
          <div class="d-flex justify-end">
            <v-btn elevation="0" @click="confirmSetup = false" class="mr-3" tile>Cancel</v-btn>
            <v-btn elevation="0" color="primary" @click="connectInstagram" tile>Log in to Facebook</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {FBAPPID, INSTAGRAMSCOPE, instaRedirectURL} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";

export default {
  name: "InstagramConnector",
  props: {
    color: String
  },
  data() {
    return {
      connectionWindow: null,
      setup: false,
      confirmSetup: false
    }
  },
  watch: {
    connectionWindow(newVal) {
      console.log(newVal, 'Connection Window')
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isProfessionalOrFull() {
      return this.user.subscription
          &&
          (
              this.$store.state.plan &&
              (
                  this.$store.state.plan.name === 'Professional' ||
                  this.$store.state.plan.name === 'Done for You - Full Service'
              )
          )
    },
    totalPlatforms() {
      return this.$store.state.socialPlatforms.filter((o) => o.type === SocialMediaType.instagram).length
    }
  },

  methods: {
    gotoAccount() {
      this.$store.commit('setOpenSocialConnector', false)
      this.$router.push('/account').catch(() => {
      });
    },
    confirmDialog() {
      if (this.isProfessionalOrFull)
        this.confirmSetup = true
      else
        this.$toast.warning('Kindly upgrade your account to add Instagram Business profile.')
    },
    connectInstagram() {
      this.loading = true;
      try {
        this.setup = true
        const scope = encodeURIComponent(INSTAGRAMSCOPE);
        const state = this.user._id;
        const url = "https://www.facebook.com/v12.0/dialog/oauth?display=popup&client_id=" +
            FBAPPID +
            "&redirect_uri=" + instaRedirectURL +
            "&scope=" + scope +
            "&state=" + state;
        const y = window.top.outerHeight / 2 + window.top.screenY - (670 / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (562 / 2);
        this.connectionWindow = window.open(url, '_blank', `width=562, height=670, top=${y}, left=${x}`);
        const timer = setInterval(async () => {
          console.log(timer)
          if (this.connectionWindow.closed) {
            clearInterval(timer)
            const length = this.$store.state.socialPlatforms.length
            await this.$store.dispatch('getSocialPlatforms')
            if (length >= this.$store.state.socialPlatforms.length) {
              this.$toast.error('You must sign in to facebook and allow Postredi to integrate with instagram.')
            } else {
              this.$toast.success('Instagram was integrated with Postredi successfully.')
            }
            this.setup = false
            this.confirmSetup = false
          }
        }, 1000)
      } catch (e) {
        this.loading = false;
      }
    },
    focusOnWindow() {
      this.connectionWindow.focus()
    },
    closeWindow() {
      this.setup = false
      this.connectionWindow.close()
    }
  }
}
</script>

<style scoped>

</style>