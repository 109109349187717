<template>
  <div>
    <div class="payment">
      <p class="section-header">Current Card</p>
      <div class="card-number">
        <template v-if="preLoading">
          <v-skeleton-loader width="62" loading type="text"/>
          <v-skeleton-loader style="margin: 0 13px" width="62" loading type="text"/>
          <v-skeleton-loader width="62" loading type="text"/>
          <v-skeleton-loader style="margin-left: 13px" width="62" loading type="text"/>
        </template>

        <template v-else>
          <span>
            <v-icon size="15" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
          </span>
          <span style="margin: 0 10px">
            <v-icon size="15" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
          </span>
          <span>
            <v-icon size="15" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
            <v-icon size="15" style="margin-left: -5px" color="#000000DD">mdi-asterisk</v-icon>
          </span>
          <span style="margin-left: 10px">
          <v-icon
              v-for="(item, i) in preCard.number"
              :key="i"
              size="30"
              :style="{ marginLeft: (i === 0 ? -5: -14  ) + 'px'}"
              color="#000000DD"
          >
            mdi-numeric-{{ item }}
          </v-icon>
          </span>
        </template>
      </div>
    </div>

    <div class="locality">
      <div>
        <p class="section-header">Billing Country</p>
        <v-skeleton-loader v-if="preLoading" type="text"/>
        <p v-else class="locality-info">{{ preCard.country }}</p>
      </div>
      <div>
        <p class="section-header">Card Expiry (MM/YY)</p>
        <v-skeleton-loader v-if="preLoading" type="text"/>
        <p v-else class="locality-info">{{ preCard.expMonth }}/{{ preCard.expYear }}</p>
      </div>
    </div>

    <button v-if="!preLoading" class="blue--text" @click="openDialog">Update Card</button>

    <v-dialog ref="paymentDialog" v-model="dialog" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '90%'" eager
              persistent>
      <v-card class="pa-5">
        <div class="main-payment-form">
          <div class="card-payment">
            <h1>Card Details</h1>
            <p>Update Payment Method</p>
            <v-form ref="form">
              <div id="cardWrapper" class="ma-4"></div>
              <p class="red--text">{{ stripeValidationError }}</p>
              <p class="red--text">{{ error }}</p>
            </v-form>
          </div>
        </div>
        <div>
          <p style="font-size: 0.75rem" class="mx-4">We will not be storing or sharing your card details.</p>
          <v-checkbox v-model="check" label="" :rules="[required()]">
            <template #label>
              <div>
                I have read the
                <a
                    target="_blank"
                    href="https://postredi.com/terms-privacy"
                    @click.stop
                > terms and conditions
                </a>
              </div>
            </template>
          </v-checkbox>
          <div class="d-flex justify-end">
            <v-btn :disabled="loader" color="primary" class="mr-2" outlined elevation="0" @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn :disabled="!check || loader" color="primary" elevation="0" @click="checkout">
              {{ loader ? "Loading..." : "Submit" }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <LoadingDialog v-model="stripeLoading" message="Loading..."/>
    <ErrorDialog v-model="errorDialog" :error="errorVal" />
  </div>
</template>

<script>
import {required} from "@/utils/validators";
import LoadingDialog from "@/components/LoadingDialog";
import {getUserDB, webAppUrl} from "@/utils/local";
import ErrorDialog from "@/components/ErrorDialog";

export default {
  name: "UpdatePayment",
  components: {ErrorDialog, LoadingDialog},
  data: () => ({
    preLoading: true,
    preCard: null,
    loading: false,
    dialog: false,
    card: null,
    check: false,
    loader: false,
    address: {},
    updateAddress: {},
    stripeLoading: false,
    elements: null,
    stripeValidationError: null,
    error: null,
    errorDialog: false,
    errorVal: {}
  }),
  mounted() {
    this.getCardInfo();
  },
  methods: {
    required,
    getUserDB,
    closeDialog() {
      this.card.unmount()
      this.card = null
      this.elements = null
      this.dialog = false
    },
    async openDialog() {
      this.stripeLoading = true;
      this.stripeValidationError = ''
      const setupIntent = (await this.$axios.get("/stripe/setup-intent")).data;
      console.log('setupIntent');
      console.log(setupIntent);
      if (!this.card) {
        const card = document.createElement("div");
        card.id = "card";
        try {
          const elements = this.$stripe.elements({clientSecret: setupIntent.client_secret});
          this.elements = elements;
          this.card = elements.create("payment", {
            terms: {
              card: "auto"
            }
          });
          this.card.mount(card);
          this.card.on("change", () => this.setValidationError);
          this.card.on("ready", () => {
            this.stripeLoading = false;
            this.dialog = true;
          });
        } catch (e) {
          console.log(e);
        }
        const cardWrapper = document.getElementById("cardWrapper");
        if (cardWrapper) {
          cardWrapper.appendChild(card);
        }
      }
    },
    setValidationError(event) {
      console.log('validation stripe')
      console.log(this.card);
      console.log(event);
      this.stripeValidationError = event.error ? event.error.message : "";
      this.loading = false;
      this.loader = false;
    },
    async checkout() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.loader = true;
          const elements = this.elements;
          const res = await this.$stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: webAppUrl
            },
            redirect: "if_required"
          });
          console.log(res);
          if (res.error) {
            this.stripeValidationError = res.error.message;
            this.loading = false;
            this.loader = false;
            return;
          }
          const customerData = {
            name: (this.$store.state.user.name || ''),
            email: this.$store.state.user.username,
            payment_method: res.setupIntent.payment_method,
            old_payment_method: this.$store.state.user.customer.default_payment_method
          };
          await this.$axios.post("stripe/update-customer", customerData);
          const user = await this.getUserDB()
          this.$store.commit('setUser', user)
          await this.getCardInfo();
          this.dialog = false;
          this.$router.go(this.$router.currentRoute)
        } catch (e) {
          console.log(e)
          console.log(e);
          console.log(e.response);
          this.closeDialog()
          this.errorDialog = true
          this.errorVal = {
            title: 'Issue updating card.',
            description: e?.data?.message || e?.response?.data || 'Some error occured. Try again later.'
          }
        }
        this.loading = false;
        this.loader = false;
      }
    },
    async getCardInfo() {
      try {
        const id = this.$store.state.user.customer.default_payment_method;
        const paymentMethod = (await this.$axios.get("/stripe/payment-method/" + id)).data;
        this.preCard = {
          brand: paymentMethod.card.brand,
          number: paymentMethod.card.last4,
          country: paymentMethod.billing_details.address.country,
          expYear: paymentMethod.card.exp_year,
          expMonth: paymentMethod.card.exp_month
        };
      } catch (e) {
        console.log(e);
      }
      this.preLoading = false;
    }
  }
};
</script>

<style scoped lang="sass">
.card-number
  display: flex

.section-header
  color: rgba(0, 0, 0, .6)

.locality-info
  font-size: 20px

.locality
  display: flex
  margin-top: 20px

  & > div
    margin-right: 50px

.CVC-base
  background-color: red
</style>