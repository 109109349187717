<template>
  <v-dialog :value="flag" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" persistent
            @input="$emit('value', false)">
    <v-card class="pa-4" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar class="pointer" @click="$emit('value', false)">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <v-form ref="postCreate">
        <div>
          <h3 class="text-md-h3 text-h5 mb-4 primary--text font-weight-medium">Edit Profile</h3>
        </div>
        <v-form ref="profileForm">
          <ul v-if="updated" class="mb-3">
            <li class="success--text">Your profile was updated.</li>
          </ul>
          <ul class="mb-3">
            <li v-for="(error,i) in errors" :key="i" class="error--text">{{ error }}</li>
          </ul>
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>First Name</label>
              </div>
              <v-text-field v-model="userData.first_name" :disabled="loading" :rules="[required()]" outlined
                            placeholder="First Name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mb-3">
                <label>Last Name</label>
              </div>
              <v-text-field v-model="userData.last_name" :disabled="loading" outlined
                            placeholder="Last Name"></v-text-field>
            </v-col>
          </v-row>


          <div class="mb-3">
            <label>Email</label>
          </div>
          <v-text-field v-model="userData.username" :disabled="loading" :rules="[required(), email()]" hint="Email Address cannot be changed."
                        outlined persistent-hint placeholder="Enter your email"
                        readonly
                        type="email"></v-text-field>

          <div class="mb-3">
            <label>Phone</label>
          </div>

          <VuePhoneNumberInput
              v-model="phoneNumber"
              :default-country-code="countryCode"
              :error="phoneError && showPhoneError"
              color="#2177b0"
              error-color="#FF0000"
              required
              size="lg"
              valid-color="#2177b0"
              @update="onPhoneUpdate"
          />
          <small v-if="phoneError && showPhoneError" class="error--text px-2"> Please provide valid phone
            number.</small>

          <div class="mb-3 mt-2">
            <label>Organization Logo</label>
          </div>
          <div class="d-flex justify-center align-end">
            <image-upload
                :hasError="showImageError"
                :image_obj="displayImage ? displayImage : null"
                class="mx-auto span-2"
                @openCropper="openCropper"
                @uploadedImage="getUploadedImage"
                @removeImage="removeImage"
            />
          </div>

          <div class="mb-3">
            <label>Organization Name</label>
          </div>
          <v-text-field
              v-model="userData.organizationName"
              :disabled="loading"
              :rules="[required()]"
              outlined
              persistent-hint
              placeholder="Enter your organization name"
          ></v-text-field>

          <div class="mb-3">
            <label>Organization Address</label>
          </div>
          <v-text-field
              v-model="userData.organizationAddress"
              :disabled="loading"
              outlined
              persistent-hint
              placeholder="Enter your organization address"
          ></v-text-field>

          <div class="mb-3">
            <label>Organization Email</label>
          </div>
          <v-text-field
              v-model="userData.organizationEmail"
              :disabled="loading"
              :rules="[required(), email()]"
              outlined
              persistent-hint
              placeholder="Enter your organization email"
          ></v-text-field>

          <v-btn :disabled="loading" class="mb-6" color="primary" elevation="0" width="100%" x-large
                 @click="saveProfile">
            <span>{{ loading ? 'Submitting' : 'Submit' }}</span>
            <v-progress-circular v-if="loading" class="ml-3" indeterminate size="18" width="2"/>
          </v-btn>

          <ImageCropper
              prfilePicCropper
              v-model="showCropperModal"
              :media="media"
              @onClose="closeCropperModal"
              @onSave="saveCroppedMedia"
          />

        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>


import {email, required} from "@/utils/validators";
import ImageUpload from "@/components/ImageUpload";
import {storage} from "@/plugins/firebase";
import VuePhoneNumberInput from 'vue-phone-number-input';
import parsePhoneNumber from 'libphonenumber-js'
import ImageCropper from "../ImageCropper.vue";


export default {
  name: "ProfileForm",

  components: {ImageCropper, ImageUpload, VuePhoneNumberInput},

  model: {
    prop: 'flag',
    event: 'value'
  },

  methods: {
    required,
    email,
    removeImage() {
      this.removeClicked = true;
      this.isFirstTime = true;
      this.displayImage = null;
      this.croppedImage = null;
      this.unCroppedImage = null;
    },

    async getBlobFromUrl(url) {
      if(!url) return null
      let blob = await fetch(url).then(r => r.blob());
      blob.lastModifiedDate = new Date();
      blob.name = "img_"+new Date().valueOf();
      blob.file = new File([blob], "img_"+new Date().valueOf(), {lastModified: blob.lastModifiedDate, type: blob.type});
      return blob;
    },

    getUploadedImage(newImage) {
      if (!this.removeClicked && this.isFirstTime){
        if (newImage.file){
          this.unCroppedImage = newImage.file;
          // Only Open Cropper Modal if image is changed
          if (this.modalLoadingComplete){
            this.openCropper();
          }
        } else {
          this.unCroppedImage = newImage;
        }
        this.croppedImage = this.unCroppedImage;

        if (this.isFirstTime && !this.displayImage ){
          this.openCropper();
        }

        this.isFirstTime = false;
      }

      this.modalLoadingComplete = true;

      this.removeClicked = false;
    },

    openCropper() {
      if(this.unCroppedImage && this.unCroppedImage.type.includes('image')) {
        const fileData = {
          file: this.unCroppedImage,
          url: URL.createObjectURL(this.unCroppedImage),
          uploaded: 0,
          isUploaded: true,
          name: this.unCroppedImage.name,
          type: this.unCroppedImage.type,
          size: this.unCroppedImage.size,
        };
        fileData.uploaded = 50;
        fileData.index = 0;
        this.media = fileData;
        this.showCropperModal = true;
      }
    },

    saveCroppedMedia(newCroppedImage) {
      let file = newCroppedImage.file;
      const fileData = {
        file: file,
        url: URL.createObjectURL(file),
        uploaded: 0,
        isUploaded: true,
        name: file.name,
        type: file.type,
        size: file.size,
      };
      fileData.uploaded = 50;
      this.croppedImage = file;
      this.displayImage = this.croppedImage;
      this.closeCropperModal();
    },

    closeCropperModal() {
      this.media = null;
      this.showCropperModal = false;
    },

    onPhoneUpdate(event) {
      if (event && event.isValid) {
        this.userData.phone = event.formatInternational
        this.phoneNumber = event.formatNational
        this.phoneError = false
        this.showPhoneError = false
      } else if (event.phoneNumber && !event.isValid) {
        this.userData.phone = null
        this.phoneError = true
        this.showPhoneError = true
      }
    },

    async saveProfile() {
      if (!this.userData.phone) {
        this.showPhoneError = true
        this.phoneError = true
        this.errors.push('Phone could not be used. Please use valid one')
        return
      }
      if (this.$refs.profileForm.validate()) {
        // Logo Required
        // if (!this.displayImage && !this.unCroppedImage) {
        //   this.showImageError = true;
        //   return false
        // }

        this.errors = []
        try {
          this.loading = true
          let downloadUrl;

          if (this.croppedImage) {
            const fileName = this.$store.state.user._id;
            // Upload File
            let reference = storage.ref("customization/lead/organization-logos/" + fileName);
            await reference.put(this.croppedImage);
            // Get download url from firebase storage and add to newMedia object
            downloadUrl = await storage.ref("customization/lead/organization-logos/").child(fileName).getDownloadURL();
            this.displayImage = await this.getBlobFromUrl(downloadUrl);
          } else if (!this.displayImage) {
            this.displayImage = null;
          }

          const data = {
            name: this.userData.first_name + (this.userData.last_name ? ' ' + this.userData.last_name : ''),
            phone: this.userData.phone,
            organizationName: this.userData.organizationName,
            organizationLogo: downloadUrl || null,
            organizationAddress: this.userData.organizationAddress,
            organizationEmail: this.userData.organizationEmail,
          }

          await this.$axios.patch('/persons/' + this.user._id, data)
          const user = await this.$axios.get('/persons/' + this.user._id)
          localStorage.setItem('auth_user', JSON.stringify(user.data))
          this.$store.commit('setUser', user.data)
          this.loading = false
          this.updated = true
        } catch (e) {
          this.loading = false
          this.errors.push(e?.response?.data?.message || 'Some error occurred')
        }
      }
    }
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      loading: false,
      updated: false,
      errors: [],

      showImageError: false,

      modalLoadingComplete: false,

      displayImage: null,
      unCroppedImage: null,
      croppedImage: null,
      isFirstTime: true,
      removeClicked: false,


      phoneNumber: null,
      phoneError: false,
      showPhoneError: false,
      countryCode: 'US',
      showCropperModal: false,
      media: {},
    }
  },

  computed: {
    userData() {
      return {
        first_name: this.user.name.split(' ').shift(),
        last_name: this.user.name.split(' ').length > 1 ? this.user.name.split(' ').pop() : '',
        username: this.user.username,
        phone: this.user.phone,
        organizationName: this.user.organizationName,
        organizationAddress: this.user.organizationAddress,
        organizationEmail: this.user.organizationEmail,
      }
    },
  },

  async mounted() {
    this.displayImage = await this.getBlobFromUrl(this.user.organizationLogo);

    const number = parsePhoneNumber(this.user.phone)

    if (number) {
      this.countryCode = number.country
      this.phoneNumber = number.nationalNumber
    }
  }
}
</script>

<style scoped></style>