<template>
  <div class="py-3">
    <v-container class="d-flex justify-center">
      <div :style="$vuetify.breakpoint.mdAndUp ? 'width: 70%' : 'width: 100%'">
        <div v-if="promoterDetail">
          <!--Promoter Login-->
          <v-card class="pa-6 mb-4 mx-2" v-if="promoterDetail && promoterDetail.profile">
            <h3 class="mb-2">Promoter Login</h3>
            <p class="mb-1"><span class="font-weight-bold">Name:</span>
              {{ promoterDetail.profile.first_name + ' ' + promoterDetail.profile.last_name }}</p>
            <p class="mb-1"><span class="font-weight-bold">Email:</span> {{ promoterDetail.email }}</p>
            <p class="mb-1"><span class="font-weight-bold">Temporary Password:</span> {{ promoterDetail.temp_password }}
            </p>
            <p class="mb-1">
              You can use these credential to login to you promoter dashboard in order to redeem your
              rewards <a :href="firstPromoterDashboard" target="_blank">here</a>.
            </p>
          </v-card>
          <!--stats-->
          <div class="d-flex justify-space-between pa-2 flex-wrap flex-sm-nowrap mb-4"
               v-if="promoterDetail && promoterDetail.promotions && promoterDetail.promotions.length > 0">
            <PromoterStatCard class="mb-4 mb-sm-0" title="CLICKS" :value="promoterDetail.promotions[0].visitors_count"/>
            <PromoterStatCard class="mb-4 mb-sm-0" title="REFERRALS" :value="promoterDetail.promotions[0].leads_count"/>
            <PromoterStatCard class="mb-4 mb-sm-0" title="CUSTOMERS"
                              :value="promoterDetail.promotions[0].customers_count"/>
            <PromoterStatCard class="mb-4 mb-sm-0" title="UNPAID EARNINGS"
                              :value="promoterDetail.current_balance ? promoterDetail.current_balance.cash / 100 : 0"
                              is-currency/>
          </div>
          <!--rewards-->
          <div class="pa-2" v-if="promoterDetail && promoterDetail.promotions && promoterDetail.promotions.length > 0">
            <v-card class="pa-4 mx-2">
              <div v-if="promoterDetail.promotions[0].current_offer" class="d-flex justify-space-between pa-2 text-center">
                <div class="d-none d-sm-flex justify-center align-center" style="width: 30%">
                  <v-icon large>mdi-gift</v-icon>
                </div>
                <div  class="flex-grow-1">
                  <h3>Special Offer for your friends and followers</h3>
                  <p>They get {{ promoterDetail.promotions[0].current_offer }}
                    when they subscribe with the promo code below.
                  </p>
                </div>
              </div>
              <v-divider v-if="promoterDetail.promotions[0].current_offer && promoterDetail.promotions[0].current_referral_reward" />
              <div v-if="promoterDetail.promotions[0].current_referral_reward" class="d-flex justify-center pa-2 text-center">
                <div class="d-none d-sm-flex justify-center align-center" style="width: 30%">
                  <v-icon x-large>mdi-cash</v-icon>
                </div>
                <div class="flex-grow-1">
                  <h3>Your reward for referring new customers</h3>
                  <p>You get {{ promoterDetail.promotions[0].current_referral_reward.name }} for each referred customer
                  </p>
                </div>
              </div>
            </v-card>
          </div>
          <!--promotion-->
          <div class="pa-2" v-if="promoterDetail && promoterDetail.promotions && promoterDetail.promotions.length > 0">
            <v-card class="pa-4 mx-2 d-flex flex-column align-center">
              <h3>Share on Social Media</h3>
              <div class="d-flex justify-center my-2">
                <v-card @click="openSocial(0)" color="#4267B2" rounded width="50px" height="50px"
                        class="d-flex justify-center align-center mx-2">
                  <v-icon dark>mdi-facebook</v-icon>
                </v-card>
                <v-card @click="openSocial(1)" color="#1DA1F2" rounded width="50px" height="50px"
                        class="d-flex justify-center align-center mx-2">
                  <v-icon dark>mdi-twitter</v-icon>
                </v-card>
              </div>
              <div v-if="promoterDetail.promotions[0].referral_link" class="my-2">
                <p class="text-h6">Share this referral link to your friends and followers</p>
                <div class="d-flex align-center">
                  <div class="grey lighten-2 rounded pa-2 flex-grow-1 text-left mr-2">
                    <p class="ma-0">{{ promoterDetail.promotions[0].referral_link }}</p>
                  </div>
                  <v-btn color="primary"
                         @click="copyLink(promoterDetail.promotions[0].referral_link, 'Referral Link Copied')">Copy
                  </v-btn>
                </div>
              </div>
              <div v-if="promoterDetail.promotions[0].current_offer" class="my-2">
                <p class="text-h6">Your special offer Promo Code</p>
                <div class="d-flex align-center">
                  <div class="grey lighten-2 rounded pa-2 flex-grow-1 text-left mr-2">
                    <p class="ma-0">{{ promoterDetail.promotions[0].current_offer.default_promo_code }}</p>
                  </div>
                  <v-btn color="primary"
                         @click="copyLink(promoterDetail.promotions[0].current_offer.default_promo_code, 'Promo Code Copied')">
                    Copy
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div v-else-if="!loading">
          <v-card class="pa-6 mb-4 mx-2">
            <h3 class="mb-2">Promoter</h3>
            <p class="mb-1">We couldn't find any promoter account related to your account.</p>
            <p class="mb-1">You can <a href="https://postredi.com/#contact" target="_blank">contact us</a> for further
              queries.</p>
          </v-card>
        </div>
      </div>
    </v-container>
    <LoadingDialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog";
import PromoterStatCard from "@/components/PromoterStatCard";
import {firstPromoterDashboard} from "@/utils/local";

export default {
  name: "promoter",
  components: {PromoterStatCard, LoadingDialog},
  data() {
    return {
      loading: false,
      promoterDetail: null,
      firstPromoterDashboard
    }
  },
  mounted() {
    this.getPromoter()
  },
  methods: {
    async getPromoter() {
      try {
        this.loading = true;
        const user = this.$store.state.user
        if (user.promoter) {
          console.log('user: ', user)
          this.promoterDetail = (await this.$axios.get("/promoter")).data;
          console.log('promoterDetail: ', this.promoterDetail)
        } else {
          await this.$router.push('/')
          this.$toast.error('You need to become a promoter to access promoter page.')
        }
        this.loading = false;
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
      console.log('loading: ',this.loading)
    },
    openSocial(id) {
      if (this.promoterDetail && this.promoterDetail.promotions && this.promoterDetail.promotions.length > 0) {
        let link = ''
        const referrer = encodeURIComponent(this.promoterDetail.promotions[0].referral_link)
        if (id === 0) {
          link = 'https://www.facebook.com/sharer/sharer.php?u=' + referrer + '&display=popup&quote='
        } else {
          link = 'https://twitter.com/intent/tweet?url=' + referrer
        }
        window.open(link, '_target')
      }
    },
    copyLink(link, text) {
      navigator.clipboard.writeText(encodeURI(link))
      this.$toast.success(text)
    }
  }
}
</script>

<style>
.statCard {
  height: 100px;
  width: 100%;
}
</style>