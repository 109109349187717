<template>
  <v-layout fill-height>
    <v-main>
      <router-view/>
    </v-main>
  </v-layout>
</template>

<script>
export default {
  name: 'EmptyLayout',
  data() {
    return {}
  },

  methods: {}
}
</script>

<style></style>
