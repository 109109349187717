import Vue from "vue";
import Vuex from "vuex";
import { PostsService } from "@/services/posts-service";
import { SocialPlatformService } from "@/services/social_platform-service";
import { getUserDB } from "@/utils/local";
import axios from "axios";

Vue.use(Vuex);

const postService = new PostsService();
const socialPlatformsService = new SocialPlatformService();

const store = new Vuex.Store({
  state() {
    return {
      user: null,
      token: null,
      posts: [],
      socialPlatforms: [],
      showWelcomePage: false,
      openSocialConnector: false,
      openGenerateIdeaModal: false,
      invoicesDetails: {},
      plan: null,
      timezone: localStorage.getItem("Timezone"),
    };
  },
  mutations: {
    setUser(state, user) {state.user = user},
    setTimezone(state, timezone) {state.timezone = timezone},
    setInvoicesDetails(state, details) {state.invoicesDetails = details},
    setToken(state, token) {state.token = token},
    setPosts(state, posts) {state.posts = posts},
    setSocialPlatforms(state, socialPlatforms) {state.socialPlatforms = socialPlatforms},
    setOpenSocialConnector(state, val) {state.openSocialConnector = val},
    setOpenGenerateIdeaModal(state, val) {state.openGenerateIdeaModal = val},
    setShowWelcomePage(state, val) {state.showWelcomePage = val},
    setPlan(state, plan) {state.plan = plan},
  },
  actions: {
    async getPosts(context) {
      const posts = await postService.fetchByUser(context.state.user._id);
      context.commit("setPosts", posts);
    },
    async getSocialPlatforms(context) {
      const posts = await socialPlatformsService.fetchByUser(context.state.user._id);
      context.commit("setSocialPlatforms", posts);
    },
    async getUser(context) {
      const user = getUserDB();
      context.commit("setUser", user);
    },
    async getPlan(context, { productID }) {
      const plan = (await axios.get("stripe/product/" + productID)).data;
      context.commit("setPlan", plan);
    },
  },
});

export default store;
