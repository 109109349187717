<template>
  <v-dialog id="ideas-modal" :value="flag" @input="closeDialog" :width="$vuetify.breakpoint.xl ? '60%' : '90%'">
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <h3>Post Idea Generator Recommends These Ideas</h3>
            <v-avatar id="closeIcon" @click="closeDialog" class="pointer">
              <v-icon>mdi-close</v-icon>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="12">
          <div :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-space-between align-center' : 'd-flex flex-column'"
               style="gap: 15px">
            <v-text-field
                    id="searchPosts"
                v-model="filter.text"
                label="Search Posts"
                class="flex-grow-1"
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                hide-details
                @change="filter.page = -1"
                @keyup="filter.page = -1"
                @keypress.enter="loadMore"
            >
            </v-text-field>

            <v-autocomplete
                    id="postCategories"
                v-model="filter.categories"
                :items="allCategoriesOptions"
                :loading="categoriesLoading"
                @change="filterSubCategories"
                item-text="name"
                item-value="_id"
                hide-details
                dense
                class="flex-grow-1"
                label="Choose Category"
                multiple
                name="Category"
                required
                outlined
                small-chips
            ></v-autocomplete>

            <v-autocomplete
                    id="postSubCategories"
                :items="allSubCategoriesOptions"
                v-model="filter.subcategories"
                :loading="subCategoriesLoading"
                @change="filterTags"
                item-text="name"
                item-value="_id"
                hide-details
                dense
                class="flex-grow-1"
                label="Choose Sub Category"
                multiple
                name="Category"
                required
                outlined
                small-chips
            ></v-autocomplete>

            <v-autocomplete
                    id="postTags"
                :items="allTagsOptions"
                v-model="filter.tags"
                item-text="name"
                item-value="_id"
                hide-details
                dense
                class="flex-grow-1"
                label="Choose Tags"
                multiple
                name="Category"
                required
                outlined
                small-chips
            ></v-autocomplete>
            <!--            <v-btn-->
            <!--                v-if="!(filter.text || filter.type !== 'Both' || (filter.categories && filter.categories.length > 0))"-->
            <!--                :disabled="!(filter)"-->
            <!--                elevation="0" color="primary" @click="fetchFiltered">Filter-->
            <!--            </v-btn>-->
            <v-btn
                    id="apply"
                v-if="(filter.text || (filter.categories && filter.categories.length > 0)|| (filter.subcategories && filter.subcategories.length > 0))"
                outlined elevation="0" color="primary" @click="loadMore">Apply
            </v-btn>

            <v-btn
                    id="clear"
                v-if="(filter.text || (filter.categories && filter.categories.length > 0)|| (filter.subcategories && filter.subcategories.length > 0))"
                outlined elevation="0" color="primary" @click="clearFilter">Clear
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" v-if="commonErrors.length > 0">
          <v-list>
            <v-list-item v-for="(error, i) in commonErrors" :key="i">
              <v-list-item-avatar color="error lighten-4">
                <v-icon color="error">mdi-alert</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ error.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon small v-if="error.type" class="mr-2" :color="error.color">
                    {{ error.icon }}
                  </v-icon>
                  {{ error.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" style="max-height: 500px; overflow-y: auto">
          <v-data-table :headers="headers" :items="localTemplates" :items-per-page="5" fixed-header
            mobile-breakpoint="1000" :loading="loadingTemplate" :height="$vuetify.breakpoint.mdAndUp ? '400' : '300'"
            dense :search="search" hide-default-footer @click:row="selectTemplate">
            <template #item.id="{ item }">
              {{ localTemplates.indexOf(item) + 1 }}
            </template>

            <template #item.description="{ item }">
              <span v-if="item.description.length > 120 && !item.expanded" class="ma-1">
                {{ item.description.substring(0, 120) }}... <span @click.stop.prevent="readMore(item)"
                  class="blue--text pointer">Read More</span>
              </span>
              <span v-else class="ma-1">{{ item.description }}</span>
            </template>

            <template #item.categories="{ item }">
              <div v-if="item.categories.length > 0">
                <v-chip small v-for="(category, key) in item.categories" :key="key" class="ma-1">{{
                  category.name
                }}
                </v-chip>
              </div>
              <div v-else>
                N/A
              </div>
            </template>

            <template #item.subcategories="{ item }">
              <div v-if="item.subcategories.length > 0">
                <v-chip small v-for="(subcategory, key) in item.subcategories" :key="key" class="ma-1">
                  {{ subcategory.name }}
                </v-chip>
              </div>
              <div v-else>
                N/A
              </div>
            </template>

            <template #item.tags="{ item }">
              <div v-if="item.tags.length > 0">
                <v-chip small v-for="(tag, key) in item.tags" :key="key" class="ma-1">{{ tag.name }}</v-chip>
              </div>
              <div v-else>
                N/A
              </div>
            </template>

            <template #item.hashtags="{ item }">
              <div class="pa-2">
                <v-chip small v-for="(hashtag, j) in item.hashtags" :key="j" class="ma-1">
                  #{{ hashtag }}
                </v-chip>
              </div>
            </template>
            <template #item.actions="{item}">
              <v-btn id="preview" v-if="item.media && item.media.length > 0" small elevation="0" class="ma-1"
                     outlined
                     color="primary" @click.stop.prevent="openMedia(item)">Preview
              </v-btn>
              <v-btn id="select" class="ma-1" small elevation="0" color="primary"
                     @click.stop.prevent="$emit('selectTemplate', item)">
                Select
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-end" style="gap: 15px">
            <v-btn id="generateMore" @click="loadMore" color="primary" elevation="0" outlined>
              Generate More
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <ShowMediaDialog v-model="mediaDialog" :post="post" />
  </v-dialog>
</template>

<script>

import ShowMediaDialog from "@/components/posts/ShowMediaDialog";
import { TemplatesService } from "@/services/templates-service";
import { CategoriesService } from "@/services/categories-service";
import { SubCategoriesService } from "@/services/sub-categories-service";
import { TagsService } from "@/services/tags-service";

export default {
  name: "TemplateSelection",
  components: { ShowMediaDialog },
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    templates: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      mediaDialog: false,
      templateService: new TemplatesService(),
      localTemplates: [],
      commonErrors: [],
      post: null,
      search: null,
      loadingTemplate: false,
      page: 0,
      isFilter: false,
      categories: [],
      categoryService: new CategoriesService(),
      subCategoryService: new SubCategoriesService(),
      tagsService: new TagsService(),
      headers: [
        {
          text: '#',
          value: 'id',
          width: '15px',
          sortable: false
        },
        {
          text: 'Description', value: 'description', sortable: false, width: '300px'
        },
        {
          text: 'Category', value: 'categories', sortable: false
        },
        {
          text: 'Subcategory', value: 'subcategories', sortable: false
        },
        {
          text: 'Tags', value: 'tags', sortable: false
        },
        {
          text: 'hashtags', value: 'hashtags', sortable: false
        },
        {
          text: 'Actions', value: 'actions', sortable: false
        },
      ],
      filter: {
        categories: [],
        subcategories: [],
        tags: [],
        page: -1,
        text: null,
      },

      // all categories from database
      allCategories: [],
      allCategoriesOptions: [],
      categoriesLoading: false,

      // all categories from database
      allSubCategories: [],
      allSubCategoriesOptions: [],
      subCategoriesLoading: false,

      // all categories from database
      allTags: [],
      allTagsOptions: [],
      tagsLoading: false,
    }
  },

  watch: {
    templates(newVal) {
      this.page = 0
      this.filter.page = -1
      this.localTemplates = newVal.map((item) => {
        item.expanded = false
        return item
      })
      this.loadCategories()
    },
    async flag(newVal) {
      if (newVal) {
        // reset all options
        this.clearFilter();

        //calling all categories
        await this.loadCategories();
        this.allCategoriesOptions = this.allCategories;

        // calling all sub-categories
        await this.loadSubCategories();
      }
    }
  },

  async mounted() {
    //calling all categories
    await this.loadCategories();
    this.allCategoriesOptions = this.allCategories;

    // calling all sub-categories
    await this.loadSubCategories();
  },

  methods: {
    closeDialog() {
      this.filter = {
        categories: [],
        subcategories: [],
        tags: [],
        page: -1,
        text: '',
      }
      this.filter = { ...this.filter }
      this.isFilter = false
      // resetting options
      this.allSubCategoriesOptions = [];
      this.allTagsOptions = [];
      this.$store.commit('setOpenGenerateIdeaModal', false)
      this.$emit('value', false)
    },
    clearFilter() {
      this.commonErrors = []
      this.filter = {
        categories: [],
        subcategories: [],
        tags: [],
        page: -1,
      }
      this.filter = { ...this.filter }
      this.isFilter = false
      // resetting options
      this.allSubCategoriesOptions = [];
      this.allTagsOptions = [];
      this.loadMore();
    },

    async loadCategories() {
      this.categoriesLoading = true;
      this.allCategories = await this.categoryService.fetchAllCategories()
      this.categoriesLoading = false;
    },

    async loadSubCategories() {
      this.subCategoriesLoading = true;
      this.allSubCategories = await this.subCategoryService.fetchAllSubCategories();
      this.subCategoriesLoading = false;
    },

    filterSubCategories() {
      this.subCategoriesLoading = true;
      this.allSubCategoriesOptions = this.allSubCategories.filter((subCat) => {
        return subCat.categories.filter((cat) => {
          return this.filter.categories.includes(cat._id)
        }).length > 0
      })

      if (this.filter.subcategories?.length > 0) {
        this.filter.subcategories = this.filter.subcategories?.filter((subCategory) => {
          return this.allSubCategoriesOptions.filter((subCat) => {
            return subCat._id === subCategory
          }).length > 0
        })
      }

      this.filter.page = -1
      this.subCategoriesLoading = false

      this.filterTags();
    },

    async filterTags() {
      this.tagsLoading = true;
      this.allTags = await this.tagsService.fetchAllTags(this.filter)
      console.log(this.allTags)
      this.filter.page = -1
      this.allTagsOptions = this.allTags
      this.tagsLoading = false;
    },

    openMedia(template) {
      this.post = template
      this.mediaDialog = true
    },

    selectTemplate(item) {
      this.$emit('selectTemplate', item)
    },

    readMore(item) {
      console.log(item)
      item.expanded = true
      this.localTemplates = [...this.localTemplates]
    },

    checkDisabled() {
      if (this.filter && (this.filter.text || (this.filter.categories && this.filter.categories.length > 0))) {
        return this.filter.page <= 0
      } else {
        return this.page === 0
      }
    },

    loadMore() {
      this.commonErrors = []
      this.isFilter = true
      this.fetchFiltered()
    },
    async fetchFiltered() {
      try {
        this.commonErrors = []
        this.loadingTemplate = true
        this.localTemplates = await this.templateService.fetchAllFiltered(this.filter)
        this.loadingTemplate = false
      } catch (e) {
        this.commonErrors = []
        const error = {
          title: 'Oops! Could not open post ideas.',
          subtitle: e?.response?.data?.message || e?.data?.message || 'Some error occurred'
        }
        this.commonErrors.push(error)
        this.loadingTemplate = false
      }
    },
  }
}
</script>
<style>
.v-select__selections {
  padding: 10px 0 !important;
}

.v-chip {
  margin: 2px !important;
}
</style>