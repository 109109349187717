<template>
  <div class="pa-6">
    <div v-if="!conflictedPlatform">
      <h2 class="mb-3">Which Instagram profiles do you want to add?</h2>
      <p class="text-body-2">Choose the Instagram Business profiles that you want to use in Postredi.</p>
      <div v-if="!loading && errors.length <= 0">
        <div>
          <v-text-field @input="searchData" @change="searchData" v-model="search" outlined dense
                        label="Search by name or keyword"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Search"/>
        </div>
        <div class="mb-3">
          <div class="my-2" v-if="pages.length > 0">
            <div v-for="(page,i) in pages" :key="i">
              <v-list-item dense>
                <v-list-item-avatar size="20" color="primary">
                  <v-icon x-small dark>mdi-flag</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-action-text class="font-weight-bold">{{
                      page.name.toUpperCase()
                    }}
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="page.instagram_business_account">
                <v-list-item-avatar>
                  <img :src="page.instagram_business_account.profile_picture_url"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{
                      page.instagram_business_account.username
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                      page.instagram_business_account.name
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="connectInstagram(page,i,SocialMediaType.instagram)" color="primary" rounded
                         elevation="0"
                         :disabled="pageLoading[i] || page.connected">
                    <span>{{ pageLoading[i] ? 'Connecting' : page.connected ? 'Connected' : 'Connect' }}</span>
                    <v-progress-circular indeterminate width="2" size="18" v-if="pageLoading[i]" class="ml-2"/>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-else class="py-0" dense>
                <v-list-item-avatar color="error lighten-3" size="30">
                  <v-icon small color="error">mdi-alert</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>There is no Instagram Business profile linked to this Facebook Page
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i < pages.length - 1" class="my-3"/>
            </div>
          </div>
          <div v-else>
            <p>No Pages Found</p>
          </div>
        </div>

        <v-divider class="my-3"/>

        <div class="d-flex justify-end mt-3">
          <v-btn @click="completeInstagramConnection" color="primary" elevation="0">Done</v-btn>
        </div>
      </div>
      <div v-else-if="loading" class="d-flex justify-center align-center">
        <v-progress-circular indeterminate/>
      </div>
      <div v-else-if="errors && errors.length > 0" class="d-flex justify-center align-center flex-column">
        <ul>
          <li class="error--text" v-for="(error,i) in new Set(errors)" :key="i">{{ error }}</li>
        </ul>
        <div class="d-flex justify-end mt-3">
          <v-btn @click="completeInstagramConnection" color="primary" elevation="0">Done</v-btn>
        </div>

      </div>
    </div>
    <div v-else>
      <h3 class="mb-3">Ownership Conflict</h3>
      <p>This social network is currently owned by another user.</p>
      <p>Do you wish to take ownership of this social network?</p>

      <div class="d-flex justify-end" style="gap:15px">
        <v-btn :disabled="ownershipLoading" elevation="0" large @click="conflictedPlatform = null">Cancel</v-btn>
        <v-btn :disabled="ownershipLoading" elevation="0" color="primary" large @click="takeOwnership">
          <span>{{ ownershipLoading ? 'Taking Ownership' : 'Take Ownership' }}</span>
          <v-progress-circular indeterminate width="2" size="18" v-if="ownershipLoading" class="ml-2"/>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {instaRedirectURL} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Instagram",
  data() {
    return {
      socialPlatformService: new SocialPlatformService(),
      search: null,
      pages: [],
      data: null,
      loading: false,
      pageLoading: [],
      SocialMediaType,
      loggedUser: null,
      loggedUserPlatforms: [],
      conflictedPlatform: null,
      ownershipLoading: false,
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    async resolveData() {
      try {
        this.loading = true
        this.errors = []
        this.loggedUser = this.$store.state.user
        this.loggedUserPlatforms = await this.socialPlatformService.fetchByUser(this.loggedUser._id)
        await this.resolveCode()
        this.pages = this.data.pages
        this.pages.forEach((page) => {
          if (page !== null) {
            const connectedPage = this.loggedUserPlatforms.find((o) => page.instagram_business_account && o.externalId === page.instagram_business_account.id)
            if (connectedPage)
              page.connected = true
          }
        })
        this.pages = this.pages.sort(function (x) {
          return x.instagram_business_account ? -1 : 1;
        });
        this.pages = [...this.pages]
        this.pageLoading = this.pages.map(() => false)
        console.log(this.pages, 'Pages')
        console.log(this.pageLoading, 'Pages')
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.errors.push(e?.response?.data || e?.response?.data?.message || 'Error while fetching instagram accounts')
      }
    },
    searchData() {
      if (this.search === '' || this.search === null) {
        this.pages = this.data.pages
        this.pageLoading = this.data.pages.map(() => false)
      } else {
        this.pages = this.data.pages.filter((o) =>
            o.name.toLowerCase().includes(this.search.toLowerCase()) ||
            (
                o.instagram_business_account &&
                (
                    o.instagram_business_account.name.toLowerCase().includes(this.search.toLowerCase())
                    ||
                    o.instagram_business_account.username.toLowerCase().includes(this.search.toLowerCase())
                )
            )
        )
        this.pageLoading = this.data.pages.filter((o) =>
            o.name.toLowerCase().includes(this.search.toLowerCase()) ||
            (
                o.instagram_business_account &&
                (
                    o.instagram_business_account.name.toLowerCase().includes(this.search.toLowerCase())
                    ||
                    o.instagram_business_account.username.toLowerCase().includes(this.search.toLowerCase())
                )
            )
        ).map(() => false)
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.error || data.error_code) {
          console.log('Error in Code')
          this.error = true;
          this.errors.push(data.error_description || data.error_message || 'Error while fetching instagram accounts')
          this.loading = false;
          return;
        }
        if (data.code && data.state === this.user._id) {
          const response = await this.$axios.post("/social-platforms/connect/meta", {
            code: data.code,
            redirect_url: instaRedirectURL,
            type: SocialMediaType.instagram
          });
          console.log(response)
          console.log(response.data)
          if (response) {
            this.data = response.data
          }
        } else {
          await this.$router.push("/").catch(() => {});
        }
      } catch (e) {
        console.log('Error in Code API')
        console.log(e)
        console.log(e.response)
        this.errors.push(e?.response?.data || e?.response?.data?.message || 'Error while fetching instagram accounts')
      }
      this.loading = false;
    },
    async connectInstagram(data, index, type) {
      console.log(data)
      const postData = {
        avatar: data.instagram_business_account.profile_picture_url,
        type: type,
        username: data.instagram_business_account.username,
        isReAuthRequired: false,
        externalId: data.instagram_business_account.id,
        extendedInfo: null,
        user: this.loggedUser._id,
      }

      console.log(postData)
      if (type === SocialMediaType.instagram) {
        this.pageLoading[index] = true
        postData.access_token = this.data.user_access_token
        this.pageLoading = [...this.pageLoading]
      }

      try {
        await this.socialPlatformService.create(postData)
        await this.$store.dispatch('getSocialPlatforms')
        data.connected = true
      } catch (e) {
        console.log('error')
        console.log(e)
        console.log(e.data.statusCode)
        if (e.data.statusCode === 409) {
          this.conflictedPlatform = postData
          this.conflictedPlatform._id = e.data._id
        }
        console.log(e)
      }
      if (type === SocialMediaType.instagram) {
        this.pageLoading[index] = false
        this.pages = [...this.pages]
        this.pageLoading = [...this.pageLoading]
      }
    },
    async takeOwnership() {
      try {
        this.ownershipLoading = true
        await this.socialPlatformService.update(this.conflictedPlatform)
        let platform
        if (this.conflictedPlatform.type === SocialMediaType.instagram) {
          platform = this.pages.find((o) => o.id === this.conflictedPlatform.externalId)
          if (platform) {
            platform.connected = true
            this.pages = [...this.pages]
          }
        }
        this.conflictedPlatform = null
        this.ownershipLoading = false
        await this.$store.dispatch('getSocialPlatforms')
      } catch (e) {
        this.ownershipLoading = false
        console.log(e)
      }
    },
    completeInstagramConnection() {
      window.close()
    }
  }
}
</script>

<style scoped>

</style>