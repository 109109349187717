<template>
  <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '700px' : '90%'"
            persistent>
    <v-card class="pa-8" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar @click="$emit('value', false)" class="pointer">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <div>
        <h3 class="text-h4 mb-4">Reset Password</h3>
        <p>Enter your email associated with your account and we'll send an email with instructions to reset your
          password.</p>
      </div>
      <v-list-item v-if="sent">
        <v-list-item-avatar rounded color="green lighten-4">
          <v-icon color="green darken-2">mdi-email</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Check Inbox</v-list-item-title>
          <v-list-item-action-text>We have sent password recovery instructions to your email.
          </v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
      <v-form ref="resetPasswordForm">
        <div class="mb-3">
          <label>Email</label>
        </div>
        <v-text-field :disabled="loading" v-model="username" :rules="[required(), email()]" outlined
                      placeholder="Enter your email"
                      type="email"></v-text-field>

        <v-btn @click="sendInstructions" :disabled="loading" color="primary" elevation="0" width="100%" x-large
               class="mb-6">
          <span>{{ loading ? 'Sending Instructions' : 'Send Instructions' }}</span>
          <v-progress-circular size="18" indeterminate v-if="loading" width="2" class="ml-3"/>
        </v-btn>
      </v-form>
    </v-card>
    <ErrorDialog v-model="error" :error="errorVal" />
  </v-dialog>
</template>

<script>


import {required, email} from "@/utils/validators";
import ErrorDialog from "@/components/ErrorDialog";


export default {
  name: "ForgotPasswordForm",
  components: {ErrorDialog},
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      username: '',
      sent: false,
      error: false,
      errorVal: {}
    }
  },
  methods: {
    required,
    email,
    async sendInstructions() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.loading = true
        try {
          if (this.$store.state.user) return;
          const data = {
            email: this.username
          };
          await this.$axios.post("persons/forgot-password", data);
          this.$toast.success("Email Sent Successfully");
          this.$refs.resetPasswordForm.reset();
        } catch (e) {
          console.log(e);
          this.error = true;
          this.errorVal = {
            title: "Error",
            description: e?.response?.data?.message || e?.data?.message || "Some error occured"
          };
          this.loading = false;
        }
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped></style>
<!--        setTimeout(() => {-->
<!--          this.loading = false-->
<!--          this.sent = true-->
<!--          this.$refs.resetPasswordForm.reset()-->
<!--        }, 3000)-->
