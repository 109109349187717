import axios from 'axios';

export class SocialPlatformService {
    async fetchAll() {
        return (await axios.get('/social-platforms')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/social-platforms/' + id)).data;
    }

    async fetchByUser(id) {
        return (await axios.get('/social-platforms/person/' + id)).data;
    }

    async create(socialPlatform) {
        return (await axios.post('/social-platforms', socialPlatform)).data;
    }

    async delete(socialPlatform) {
        return (await axios.delete('/social-platforms/' + socialPlatform._id)).data;
    }

    async update(socialPlatform) {
        return (await axios.patch('/social-platforms/' + socialPlatform._id, socialPlatform)).data;
    }
}