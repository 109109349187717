<template>
    <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
              persistent>
        <v-card class="pa-4">
            <v-form ref="postCreate">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex justify-space-between align-center">
                            <h3>Share a post</h3>
                            <v-avatar @click="closeForm" class="pointer">
                                <v-icon>mdi-close</v-icon>
                            </v-avatar>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" :style="$vuetify.breakpoint.mdAndUp
                ? 'max-height: calc(100vh - 300px); overflow-y: auto'
                : ''
                " ref="postCreateForm">
                                <v-list>
                                    <v-list-item v-for="(error, i) in commonErrors" :key="i">
                                        <v-list-item-avatar color="error lighten-4">
                                            <v-icon color="error">mdi-alert</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ error.title }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-icon small v-if="error.type" class="mr-2" :color="error.color">
                                                    {{ error.icon }}
                                                </v-icon>
                                                {{ error.subtitle }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <div class="d-flex justify-space-between align-center mb-3">
                                    <p class="ma-0">Select social accounts</p>
                                    <v-btn
                                            id="selectAll"
                                            v-if="
                      selectedSocialMediaProfiles.length <
                      socialMediaProfiles.length
                    "
                                            @click="selectAllSocialMedia"
                                            color="primary"
                                            elevation="0"
                                            text
                                            :disabled="drafting || posting"
                                    >Select All
                                    </v-btn>
                                </div>
                                <div class="mb-3">
                                    <v-autocomplete
                                            id="publishTo"
                                            v-model="selectedSocialMediaProfiles"
                                            label="Publish to"
                                            multiple
                                            outlined
                                            class="span-2"
                                            item-text="username"
                                            item-value="_id"
                                            chips
                                            dense
                                            return-object
                                            hide-details
                                            :menu-props="{
                      closeOnContentClick:
                        socialMediaProfiles && socialMediaProfiles.length <= 0,
                    }" :disabled="drafting || posting" :rules="[required()]" :items="socialMediaProfiles"
                                            @change="updateContent">
                                        <template v-slot:selection="data">
                                            <v-chip small>
                                                <v-icon small :color="getSocialMediaTypeColor(data.item.type)"
                                                        class="mr-2">
                                                    {{ getSocialMediaTypeIcon(data.item.type) }}
                                                </v-icon>
                                                {{ data.item.username }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item dense @click="$store.commit('setOpenSocialConnector', true)">
                                                <template v-slot:default>
                                                    <v-list-item-content>
                                                        <v-list-item-title style="color: #2177b0"
                                                                           class="font-weight-medium">
                                                            <v-icon color="#2177b0" small>mdi-plus-circle
                                                            </v-icon>
                                                            Add social accounts
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-action>
                                                    <v-checkbox :input-value="data.attrs.inputValue"/>
                                                </v-list-item-action>
                                                <v-list-item-avatar size="32" class="mr-3">
                                                    <v-avatar size="32">
                                                        <img alt="avatar" v-if="data.item.avatar"
                                                             :src="data.item.avatar"/>
                                                        <img v-else
                                                             src="https://s.pinimg.com/images/user/default_60.png"
                                                             alt="avatar"/>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="d-flex">
                                                    <v-list-item-title>
                                                        <v-icon small :color="getSocialMediaTypeColor(data.item.type)">
                                                            {{ getSocialMediaTypeIcon(data.item.type) }}
                                                        </v-icon>
                                                        {{ data.item.username }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                    <v-list>
                                        <v-list-item v-for="(error, i) in platformErrors" :key="i">
                                            <v-list-item-avatar color="error lighten-4">
                                                <v-icon color="error">mdi-alert</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ error.title }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-icon small v-if="error.type" class="mr-2"
                                                            :color="getSocialMediaTypeColor(error.type)">
                                                        {{ getSocialMediaTypeIcon(error.type) }}
                                                    </v-icon>
                                                    {{ error.subtitle }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                                <div :class="$vuetify.breakpoint.mdAndUp
                  ? 'd-flex justify-space-between align-center'
                  : 'd-flex flex-column'
                  " style="gap: 5px">
                                    <p class="ma-0">What do you want to share?</p>
                                    <div>
                                        <v-btn :disabled="drafting || posting" elevation="0"
                                               @click="aiTemplateDialog = true" small outlined
                                               :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : 'mr-2'">
                                            AI post generator
                                        </v-btn>

                                        <v-btn
                                                id="generatePostIdeas"
                                                :disabled="drafting || posting"
                                                elevation="0"
                                                @click="openTemplateForm"
                                                small
                                                outlined
                                                :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : 'mr-2'"
                                        >
                                            {{
                                            loadingTemplate
                                                ? "Generating post ideas..."
                                                : "Generate post ideas"
                                            }}
                                        </v-btn>
                                        <v-btn
                                                id="addLeadUrl"
                                                :disabled="drafting || posting"
                                                elevation="0"
                                                @click="addLeadURL"
                                                small
                                                outlined
                                        >
                                            {{ "Lead URL" }}
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <v-tabs v-model="tab" show-arrows>
                                        <v-tab v-for="(tabItem, i) in tabs" :key="i">
                                            <v-icon v-if="tabItem.icon" :class="tabs.length <= 1 ? 'mr-3' : ''">
                                                {{ tabItem.icon }}
                                            </v-icon>
                                            <span v-if="i === 0 || tabs.length <= 1">{{
                                                tabItem.title
                                                }}</span>
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="tab">
                                        <v-tab-item v-for="(tabItem, i) in tabs" :key="i">
                                            <v-textarea
                                                    id="content"
                                                    :disabled="drafting || posting"
                                                    v-model="tabItem.description"
                                                    type="html"
                                                    :rounded="false"
                                                    rows="8"
                                                    filled
                                                    dense
                                                    placeholder="Enter your caption and links"
                                                    :counter="tabItem.counter"
                                                    @change="updateContentText(tabItem)"
                                            ></v-textarea>
                                        </v-tab-item>
                                    </v-tabs-items>
                                    <v-list>
                                        <v-list-item v-for="(error, i) in descriptionErrors" :key="i">
                                            <v-list-item-avatar color="error lighten-4">
                                                <v-icon color="error">mdi-alert</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ error.title }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-icon small v-if="error.type" class="mr-2"
                                                            :color="getSocialMediaTypeColor(error.type)">
                                                        {{ getSocialMediaTypeIcon(error.type) }}
                                                    </v-icon>
                                                    {{ error.subtitle }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                                <div class="mb-3">
                                    <h3>Media</h3>
                                    <div v-if="media.length > 0">
                                        <div class="file-display my-5 pa-2"
                                             :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                                             v-for="(mediaItem, i) in media" :key="i">
                                            <v-progress-linear v-model="mediaItem.uploaded" height="25"
                                                               v-if="!mediaItem.isUploaded">
                                                <strong v-if="mediaItem.uploaded < 100" class="white--text">{{
                                                    Math.ceil(mediaItem.uploaded)
                                                    }}%</strong>
                                                <strong v-else class="white--text">Processing</strong>
                                            </v-progress-linear>
                                            <v-row v-else class="">
                                                <v-col cols="2" class="d-flex justify-center align-center">
                                                    <div style="width: 100px; height: 100px">
                                                        <img alt="Media" :src="mediaItem.url" v-if="mediaItem &&
                              !mediaItem.type.includes('video') &&
                              mediaItem.url
                              " width="100%" height="100%"/>
                                                        <img alt="thumbnail" :src="mediaItem.thumbnail.url" v-else
                                                             width="100%" height="100%"/>
                                                    </div>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="d-flex justify-space-between align-center">
                                                        <div>
                                                            <p class="ma-0">{{ mediaItem.name }}</p>
                                                        </div>
                                                        <div>
                                                            <!--                              <v-icon-->
                                                            <!--                                  @click="editMedia(mediaItem, i)"-->
                                                            <!--                                  color="primary"-->
                                                            <!--                              >-->
                                                            <!--                                mdi-pencil-->
                                                            <!--                              </v-icon>-->
                                                            <v-icon @click="removeMedia(mediaItem)" color="error">
                                                                mdi-delete
                                                            </v-icon>
                                                        </div>
                                                    </div>
                                                    <p class="ma-0">Source: Upload</p>
                                                    <p class="ma-0">Type: {{ mediaItem.type }}</p>
                                                    <p class="ma-0">
                                                        Size: {{ humanFileSize(mediaItem.size, true, 0) }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>

                                    <div class="mediaPrickerWrapper py-8 d-flex justify-center align-center my-5 flex-column"
                                         style="position: relative" @click="pickFile" @drop="dropHandler($event)"
                                         @dragenter.prevent
                                         @dragover.prevent>
                                        <v-avatar color="grey lighten-3 mb-2">
                                            <v-icon>mdi-upload</v-icon>
                                        </v-avatar>
                                        <p class="ma-0 text-center">
                                            Drag files here<br/>
                                            <span class="font-weight-bold primary--text">Or select files to upload
                      </span>
                                        </p>
                                        <v-file-input id="mediaPicker" v-model="selectedMedia" :value="selectedMedia"
                                                      @change="pickHandler"
                                                      multiple class="d-none" accept="image/*, video/*"/>
                                    </div>

                                    <v-list>
                                        <v-list-item v-for="(error, i) in mediaErrors" :key="i">
                                            <v-list-item-avatar color="error lighten-4">
                                                <v-icon color="error">mdi-alert</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ error.title }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <v-icon small v-if="error.type" class="mr-2"
                                                            :color="getSocialMediaTypeColor(error.type)">
                                                        {{ getSocialMediaTypeIcon(error.type) }}
                                                    </v-icon>
                                                    {{ error.subtitle }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <div
                                :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'd-flex justify-end'
                  : 'd-flex justify-end flex-column'
              "
                        >
                            <v-menu
                                    id="scheduleForLater"
                                    v-model="schedulePicker"
                                    :close-on-content-click="false"
                                    :nudge-top="350"
                                    :nudge-left="80"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :disabled="drafting || posting"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div @click.self="openScheduleMenu" :class="$vuetify.breakpoint.mdAndUp
                    ? 'mr-4 px-4 d-flex justify-space-between align-center pointer'
                    : 'mb-2 px-4 d-flex justify-space-between align-center pointer'
                    " :style="dateError
    ? 'width:auto;min-width: 10%;border: 1px solid #ff3333; min-height: 40px; background: #ff3333; color: white'
    : 'width:auto;min-width: 10%;border: 1px solid #2177b0; min-height: 40px; background: #2177b0; color: white'
    ">
                    <span v-bind="attrs" v-on="on" v-if="!scheduleDate" @click="openScheduleMenu"
                          style="margin: 0 auto">
                      <p class="ma-0 font-weight-medium pointer text-center">
                        Schedule for later
                      </p>
                    </span>
                                        <span v-else class="d-flex" style="margin: 0 auto">
                      <p v-bind="attrs" v-on="on" class="ma-0 font-weight-medium pointer mr-3">
                        {{
                          scheduleDate +
                          " at " +
                          scheduleHour +
                          ":" +
                          scheduleMin +
                          "" +
                          scheduleTimezone
                          }}
                      </p>
                      <v-icon color="white" small @click="removeSchedule">mdi-close</v-icon>
                    </span>
                                    </div>
                                </template>
                                <v-card class="elevation-0" rounded="0" width="300">
                                    <div class="pa-2">
                                        <h3 class="mb-3">Schedule Post</h3>
                                        <v-text-field type="date" v-model="scheduleDate" label="Post Date" outlined
                                                      dense/>

                                        <h4>Manually set time</h4>
                                        <p class="caption">{{ $store.state.timezone }}</p>

                                        <div class="d-flex" style="gap: 10px">
                                            <v-select v-model="scheduleHour" outlined dense :items="hours"/>
                                            <v-select v-model="scheduleMin" outlined dense :items="minutes"/>
                                            <v-select v-model="scheduleTimezone" outlined dense :items="timezones"/>
                                        </div>
                                    </div>
                                    <div class="d-flex pa-2">
                                        <v-btn
                                                id="done"
                                                class="flex-grow-1"
                                                elevation="0"
                                                color="grey lighten-2"
                                                @click="schedulePicker = false"
                                        >Done
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-menu>
                            <v-btn
                                    id="postNow"
                                    :class="$vuetify.breakpoint.mdAndUp ? 'mr-4' : 'mb-2'"
                                    :disabled="drafting || posting"
                                    v-if="!scheduleDate"
                                    elevation="0"
                                    :color="
                  platformErrors.length > 0 ||
                  commonErrors.length > 0 ||
                  mediaErrors.length > 0 ||
                  descriptionErrors.length > 0
                  ? 'error'
                  : 'primary'
                  " large tile @click="postNow(PostStatus.pending)">
                                {{ posting ? "Posting" : "Post Now" }}
                                <span v-if="posting">
                  <v-progress-circular class="ml-2" indeterminate size="20" width="2"/>
                </span>
                            </v-btn>
                            <v-btn
                                    id="scheduleNow"
                                    v-else
                                    :disabled="drafting || posting"
                                    :class="$vuetify.breakpoint.mdAndUp ? 'mr-4' : 'mb-2'"
                                    elevation="0"
                                    :color="
                  platformErrors.length > 0 ||
                  commonErrors.length > 0 ||
                  mediaErrors.length > 0 ||
                  descriptionErrors.length > 0
                  ? 'error'
                  : 'primary'
                  " large tile @click="schedule(PostStatus.pending)">
                                {{ posting ? "Scheduling" : "Schedule" }}
                                <span v-if="posting">
                  <v-progress-circular class="ml-2" indeterminate size="20" width="2"/>
                </span>
                            </v-btn>
                            <v-btn
                                    id="saveInDraft"
                                    :disabled="drafting || posting"
                                    elevation="0"
                                    :color="
                  platformErrors.length > 0 ||
                  commonErrors.length > 0 ||
                  mediaErrors.length > 0 ||
                  descriptionErrors.length > 0
                    ? 'error'
                    : 'primary'
                "
                                    large
                                    tile
                                    outlined
                                    @click="
                  scheduleDate
                    ? schedule(PostStatus.draft)
                    : postNow(PostStatus.draft)
                "
                            >
                                {{ drafting ? "Saving" : "Save Draft" }}
                                <span v-if="drafting">
                  <v-progress-circular class="ml-2" indeterminate size="20" width="2"/>
                </span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>

        <MediaSelector v-model="mediaSelector" :media="media" @selectMedia="selectMedia"/>
        <CheckMultipleSelector v-model="checkMultipleSelector" :media="media" @selectMedia="selectFromMultipleMedia"/>
        <TemplateSelection v-model="templateDialog" :templates="templates" @selectTemplate="selectTemplate"/>
        <AiPostGenerateModal v-model="aiTemplateDialog" ref="AiPostGenerateFormRef" @selectTemplate="selectTemplate"/>
        <LoadingDialog v-model="checkIdeaModal" message="Loading..."/>
        <!--    <ImageCropper-->
        <!--        v-model="showCropperModal"-->
        <!--        :media="selectedEditMedia"-->
        <!--        @onClose="closeCropperModal"-->
        <!--        @onSave="saveCroppedMedia"-->
        <!--    />-->
    </v-dialog>
</template>

<script>
import {
    currentTimezone,
    formatDate,
    getSocialMediaTypeColor,
    getSocialMediaTypeCount,
    getSocialMediaTypeIcon,
    getSocialMediaTypeTitle,
    humanFileSize,
    webAppUrl,
} from "@/utils/local";
import {required} from "@/utils/validators";
import {PostStatus, SocialMediaType} from "@/utils/enum";
import MediaSelector from "@/components/posts/MediaSelector";
import moment from "moment-timezone";
import TemplateSelection from "@/components/posts/TemplateSelection";
import AiPostGenerateModal from "@/components/posts/AiPostGenerateModal";
import {PostsService} from "@/services/posts-service";
import CheckMultipleSelector from "@/components/posts/CheckMultipleSelector";
import {TemplatesService} from "@/services/templates-service";
import {storage} from "@/plugins/firebase";
import LoadingDialog from "@/components/LoadingDialog";
// import ImageCropper from "../ImageCropper.vue";

export default {
    name: "PostForm",
    components: {
        // ImageCropper,
        CheckMultipleSelector,
        AiPostGenerateModal,
        TemplateSelection,
        MediaSelector,
        LoadingDialog,
    },
    model: {
        prop: "flag",
        event: "value",
    },
    props: {
        flag: {
            type: Boolean,
            default: false,
        },
        socialMediaProfiles: {
            type: Array,
            default: () => [],
        },
        selectedSchedule: {
            type: Object,
            default: () => {
            },
        },
        oldPost: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            posting: false,
            drafting: false,
            aiTemplateDialog: false,
            postService: new PostsService(),
            templateService: new TemplatesService(),
            SocialMediaType,
            display: false,
            description: null,
            selectedSocialMediaProfiles: [],
            tab: null,
            tabs: [
                {
                    title: "Content",
                    icon: null,
                    counter: 0,
                    type: "all",
                    description: null,
                },
            ],
            media: [],
            selectedMedia: [],
            mediaTypes: [],
            mediaSelector: false,
            checkMultipleSelector: false,
            uploadedFiles: 0,
            schedulePicker: false,
            scheduleDate: null,
            scheduleDateMoment: null,
            scheduleTimezone: null,
            scheduleMin: null,
            scheduleHour: null,
            hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            minutes: [
                "00",
                "05",
                "10",
                "15",
                "20",
                "25",
                "30",
                "35",
                "40",
                "45",
                "50",
                "55",
            ],
            timezones: ["AM", "PM"],
            templateDialog: false,
            templates: null,
            oldInitialValue: null,
            platformErrors: [],
            descriptionErrors: [],
            mediaErrors: [],
            commonErrors: [],
            PostStatus,
            dateError: false,
            loadingTemplate: false,
            showCropperModal: false,
            // for image cropper
            selectedEditMedia: null,
        };
    },
    computed: {
        // use for watching state in watch
        checkIdeaModal() {
            return this.$store.state.openGenerateIdeaModal;
        },
        user() {
            return this.$store.state.user;
        },
        isProfessional() {
            return (
                this.user.subscription &&
                this.$store.state.plan &&
                this.$store.state.plan.name === "Professional"
            );
        },
        getTimezone() {
            const timezone = this.$store.state.timezone;
            return timezone.split(" ")[1];
        },
        getAiResponse() {
            return this.$store.state.aiResponse;
        }
    },
    watch: {
        checkIdeaModal(val) {
            if (val) {
                this.openTemplateForm();
            }
        },
        async uploadedFiles(newVal) {
            if (newVal >= this.selectedMedia.length && !this.checkMediaType()) {
                this.media = [...this.media];
                this.mediaSelector = true;
            }
            if (
                newVal >= this.selectedMedia.length &&
                this.checkMultipleVideoOrGif() &&
                !this.mediaSelector
            ) {
                this.media = [...this.media];
                this.checkMultipleSelector = true;
            }
            this.mediaErrors = [];
            if (
                this.media.length > 10 &&
                this.selectedSocialMediaProfiles.find(
                    (o) => o.type === SocialMediaType.instagram
                )
            ) {
                const error = {
                    title: "Oops! To many files attached.",
                    subtitle: "Upto 10 files are allowed.",
                    type: SocialMediaType.instagram,
                };

                this.mediaErrors.push(error);
                this.$refs.postCreateForm.scrollTop =
                    this.$refs.postCreateForm.scrollHeight;
            }

            if (
                this.media.length > 9 &&
                this.selectedSocialMediaProfiles.find(
                    (o) => o.type === SocialMediaType.linkedIn
                )
            ) {
                const error = {
                    title: "Oops! To many files attached.",
                    subtitle: "Upto 9 files are allowed.",
                    type: SocialMediaType.linkedIn,
                };

                this.mediaErrors.push(error);
                this.$refs.postCreateForm.scrollTop =
                    this.$refs.postCreateForm.scrollHeight;
            }

            if (
                this.media.length > 4 &&
                this.selectedSocialMediaProfiles.find(
                    (o) => o.type === SocialMediaType.twitter
                )
            ) {
                const error = {
                    title: "Oops! To many files attached.",
                    subtitle: "Upto 4 files are allowed.",
                    type: SocialMediaType.twitter,
                };

                this.mediaErrors.push(error);
                this.$refs.postCreateForm.scrollTop =
                    this.$refs.postCreateForm.scrollHeight;
            }

            if (
                this.media.find((o) => o.type.includes("gif")) &&
                this.selectedSocialMediaProfiles.find(
                    (o) => o.type === SocialMediaType.linkedIn
                )
            ) {
                const error = {
                    title: "Oops! Gif is not allowed.",
                    subtitle: "Linkedin does not allow gif to be published.",
                    type: SocialMediaType.linkedIn,
                };

                this.mediaErrors.push(error);
                this.$refs.postCreateForm.scrollTop =
                    this.$refs.postCreateForm.scrollHeight;
            }

            if (
                this.selectedSocialMediaProfiles.find(
                    (o) => o.type === SocialMediaType.instagram
                ) &&
                (await this.checkAspectRatio())
            ) {
                console.log("show error aspect ratio");
                const error = {
                    title: "Oops! Invalid media.",
                    subtitle:
                        "Some files have invalid dimensions. Aspect ratio should be between 4:5 and 191:100",
                    type: SocialMediaType.instagram,
                };

                this.mediaErrors.push(error);
                this.$refs.postCreateForm.scrollTop =
                    this.$refs.postCreateForm.scrollHeight;
            }
        },
        selectedSchedule(newVal) {
            if (newVal) {
                this.scheduleDate = moment(newVal.scheduleDate).format("YYYY-MM-DD");
                this.scheduleDateMoment = moment(newVal.scheduleDate).tz(
                    this.getTimezone
                );
                this.scheduleHour = newVal.scheduleHour;
                this.scheduleMin = this.getSelectedMinutes(newVal.scheduleMin);
                this.scheduleTimezone = newVal.scheduleTimezone;
            }
            // else {
            //   this.scheduleDate = null;
            //   this.scheduleHour = null;
            //   this.scheduleMin = null;
            //   this.scheduleTimezone = null;
            // }
        },
    },
    mounted() {
        if (this.getAiResponse) {
            this.selectTemplate(this.getAiResponse);
        }
    },
    methods: {
        required,
        getSocialMediaTypeColor,
        getSocialMediaTypeIcon,
        getSocialMediaTypeCount,
        currentTimezone,
        formatDate,
        getSocialMediaTypeTitle,
        // for image cropper
        // editMedia(media,_index) {
        //   this.selectedEditMedia = media;
        //   this.selectedEditMedia.index = _index
        //   console.log('selectedEditMedia', this.selectedEditMedia);
        //   this.showCropperModal = true;
        // },
        // closeCropperModal() {
        //   this.selectedEditMedia = null;
        //   this.showCropperModal = false;
        // },
        // saveCroppedMedia(updatedMedia) {
        //   console.log('updatedMedia', updatedMedia);
        //   let file = updatedMedia.file;
        //   const fileData = {
        //     file: file,
        //     url: URL.createObjectURL(file),
        //     uploaded: 0,
        //     isUploaded: true,
        //     name: file.name,
        //     type: file.type,
        //     size: file.size,
        //   };
        //   fileData.uploaded = 50;
        //
        //   this.media[updatedMedia.index] = fileData;
        //   console.log('media', this.media);
        //   // this.handleMedia(this.media);
        //   console.log('done')
        //   this.closeCropperModal();
        // },
        changeTimeZone(date, timeZone) {
            if (typeof date === "string") {
                return new Date(
                    new Date(date).toLocaleString("en-US", {
                        timeZone,
                    })
                );
            }

            return new Date(
                date.toLocaleString("en-US", {
                    timeZone,
                })
            );
        },
        selectAllSocialMedia() {
            this.selectedSocialMediaProfiles = [...this.socialMediaProfiles];
            this.updateContent();
            this.$forceUpdate();
        },
        updateContent() {
            let oldTabs = this.tabs;
            this.tabs = [];
            const uniquePlatforms = [
                ...new Map(
                    this.selectedSocialMediaProfiles.map((item) => [
                        this.getSocialMediaTypeIcon(item.type),
                        item,
                    ])
                ).values(),
            ];
            if (uniquePlatforms.length > 0) {
                if (uniquePlatforms.length > 1) {
                    let oldTab = oldTabs.find((o) => o.type === "all");
                    if (!oldTab) {
                        oldTab = oldTabs[0];
                    }
                    this.tabs.push({
                        title: "Initial Content",
                        icon: null,
                        counter: 0,
                        type: "all",
                        description: oldTab ? oldTab.description || null : null,
                    });
                    this.oldInitialValue = oldTab ? oldTab.description || null : null;
                }

                const tabs = uniquePlatforms.map((o) => {
                    let oldTab = oldTabs.find(
                        (j) => j.type === getSocialMediaTypeTitle(o.type)
                    );
                    if (!oldTab) {
                        oldTab = oldTabs.find((j) => j.type === "all");
                        if (!oldTab) {
                            oldTab = oldTabs[0];
                        }
                    }
                    return {
                        title: "Content",
                        icon: this.getSocialMediaTypeIcon(o.type),
                        iconColor: this.getSocialMediaTypeColor(o.type),
                        counter: this.getSocialMediaTypeCount(o.type),
                        type: getSocialMediaTypeTitle(o.type),
                        description: oldTab ? oldTab.description || null : null,
                    };
                });
                this.tabs = [...this.tabs, ...tabs];
            } else {
                const oldTab = oldTabs.find((o) => o.type === "all");

                this.tabs.push({
                    title: "Content",
                    icon: null,
                    counter: 0,
                    type: "all",
                    description: oldTab ? oldTab.description || null : null,
                });
                this.oldInitialValue = oldTab ? oldTab.description || null : null;
            }

            if (this.checkMultipleVideoOrGif()) {
                this.media = [...this.media];
                this.checkMultipleSelector = true;
            }
        },
        updateContentText(item) {
            if (item.type === "all") {
                for (let i = 0; i < this.tabs.length; i++) {
                    if (
                        this.tabs[i].type !== "all"
                        // && this.tabs[i].description === this.oldInitialValue
                    ) {
                        this.tabs[i].description = item.description;
                    }
                }
                this.oldInitialValue = item.description;
            }
        },
        humanFileSize,
        async checkAspectRatio() {
            // eslint-disable-next-line no-async-promise-executor
            return await new Promise(async (resolve) => {
                let count = 0;
                for (let i = 0; i < this.media.length; i++) {
                    if (this.media[i].type.includes("image")) {
                        const image = new Image();
                        image.src = this.media[i].url;
                        count += await new Promise((resolve) => {
                            image.onload = () => {
                                const ratio = image.width / image.height;
                                console.log("image => ", ratio);
                                if (ratio < 0.8 || ratio > 1.78) {
                                    console.log("invalid ratio");
                                    resolve(1);
                                } else {
                                    resolve(0);
                                }
                            };
                        });
                    } else {
                        let video = document.createElement("video");
                        video.src = this.media[i].url;
                        count += await new Promise((resolve) => {
                            video.onloadedmetadata = () => {
                                const ratio = video.videoWidth / video.videoHeight;
                                console.log("video => ", ratio);
                                if (ratio < 0.8 || ratio > 1.78) {
                                    console.log("invalid ratio");
                                    resolve(1);
                                } else {
                                    resolve(0);
                                }
                            };
                        });
                    }
                }
                console.log(count, "after checking");
                resolve(count > 0);
            });
        },
        pickFile() {
            if (!this.posting && !this.drafting) {
                document.querySelector("#mediaPicker").click();
            }
        },
        pickHandler() {
            this.uploadedFiles = 0;
            this.handleMedia(this.selectedMedia);
            this.media = [...this.media];
        },
        async dropHandler(ev) {
            if (!this.posting && !this.drafting) {
                ev.preventDefault();
                this.uploadedFiles = 0;
                await this.handleMedia(ev.dataTransfer.files);
                this.media = [...this.media];
            }
        },
        async handleMedia(files) {
            // const allowedTypes = ['png', 'jpg', 'jpeg', 'gif','mp4'];
            for (let i = 0; i < files.length; i++) {
                if (
                    (files[i].type.includes("image") && files[i].size < 5242880) ||
                    (files[i].type.includes("video") && files[i].size < 209715200)
                ) {
                    try {
                        const fileData = {
                            file: files[i],
                            url: URL.createObjectURL(files[i]),
                            uploaded: 0,
                            isUploaded: false,
                            name: files[i].name,
                            type: files[i].type,
                            size: files[i].size,
                        };
                        fileData.uploaded = 50;
                        if (fileData.type.includes("video")) {
                            const thumbnail = {
                                file: null,
                                url: null,
                            };
                            thumbnail.file = await this.generateVideoThumbnail(files[i]);
                            thumbnail["url"] = URL.createObjectURL(thumbnail.file);
                            fileData.thumbnail = thumbnail;
                        }
                        fileData.uploaded = 100;
                        fileData.isUploaded = true;
                        this.media.push(fileData);
                        this.mediaTypes = this.media.map((o) => o.type);
                        this.mediaTypes = [...this.mediaTypes];
                        this.uploadedFiles = this.media.length;
                    } catch (e) {
                        this.$toast.error(
                            "Some error occurred. " + files[i].name + " cannot be selected."
                        );
                    }
                    // const index = this.media.indexOf(fileData)
                    // this.uploadFile(index)
                } else {
                    this.$toast.error(
                        files[i].type.includes("image")
                            ? files[i].name + " is too large. Max size allowed is 5 Mb"
                            : files[i].name + " is too large. Max size allowed is 200 Mb"
                    );
                }
            }
        },
        async uploadThumbnail(file) {
            let formData = new FormData();

            formData.append("file", file);
            return (
                await this.$axios.post("/posts/file", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
            ).data.file;
        },
        async uploadFile(index) {
            try {
                let type;
                if (this.media[index].type.includes("image")) {
                    type = "images";
                } else {
                    type = "videos";
                }
                const fileName =
                    this.media[index].name.split(".").shift() +
                    "~" +
                    new Date().getTime() +
                    "." +
                    this.media[index].name.split(".").pop();
                let reference = storage.ref(type + "/" + fileName);
                let task = reference.put(this.media[index].file);
                let thumbnail = null;
                await task
                    .then(async () => {
                        if (type === "videos") {
                            let thumbRef = storage.ref(type + "/thumbnails/" + fileName);
                            let thumbTask = thumbRef.put(this.media[index].thumbnail.file);
                            await thumbTask
                                .then(async () => {
                                    window.console.log("thumbnail posted");
                                    thumbnail = {
                                        url: await storage
                                            .ref(type + "/thumbnails/")
                                            .child(fileName)
                                            .getDownloadURL(),
                                        key: null,
                                    };
                                })
                                .catch((e) =>
                                    window.console.log("uploading image error => ", e)
                                );
                        }
                    })
                    .catch((e) => window.console.log("uploading image error => ", e));
                // this.media[index].isUploaded = false
                // let formData = new FormData();
                //
                // formData.append('file', this.media[index].file);
                // try {
                //   let thumbnail
                //   if (this.media[index].file.type.includes('video')) {
                //     thumbnail = await this.uploadThumbnail(this.media[index].thumbnail.file)
                //   }
                //   const response = await this.$axios.post('/posts/file',
                //       formData,
                //       {
                //         headers: {
                //           'Content-Type': 'multipart/form-data'
                //         },
                //         // onUploadProgress: function (progressEvent) {
                //         //   this.media[index].uploaded = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                //         // }.bind(this)
                //       }
                //   )
                this.media[index] = {...this.media[index]};
                this.media[index].url = await storage
                    .ref(type)
                    .child(fileName)
                    .getDownloadURL();
                this.media[index].key = null;
                this.media[index].isUploaded = true;
                this.media[index].thumbnail = thumbnail;
                this.$forceUpdate();
            } catch (e) {
                this.$toast.error("Could not upload media. Try again later");
                this.media.splice(index, 1);
            }
        },
        async generateVideoThumbnail(file) {
            const binaryData = [];
            binaryData.push(file);
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const video = document.createElement("video");
            video.setAttribute("src", URL.createObjectURL(new Blob(binaryData)));
            video.load();
            let thumbnail = await new Promise((resolve, reject) => {
                video.onloadedmetadata = async () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    video.currentTime = video.duration / 2;
                    await video.play();
                    context.drawImage(video, 0, 0);
                    video.pause();
                    const blob = await new Promise((resolve) => {
                        return canvas.toBlob(function (blob) {
                            resolve(blob);
                        });
                    });

                    resolve(blob);
                };
                video.onerror = () => {
                    if (video.error) reject("error");
                };
            });

            return thumbnail;
        },
        checkMediaType() {
            const types = [];
            for (let i = 0; i < this.mediaTypes.length; i++) {
                if (
                    this.mediaTypes[i].includes("image") &&
                    !this.mediaTypes[i].includes("gif")
                )
                    types.push("image");
                else if (this.mediaTypes[i].includes("gif")) types.push("gif");
                else if (this.mediaTypes[i].includes("video")) types.push("video");
            }
            const set = new Set(types);
            return set.size <= 1;
        },
        selectMedia(data) {
            if (this.media.length > 0 && data.items.length > 0) {
                const media = data.items;
                this.media = [...media];
                this.mediaTypes = this.media.map((o) => o.type);
                this.mediaTypes = [...this.mediaTypes];
            }
            this.mediaSelector = false;

            if (this.checkMultipleVideoOrGif()) {
                this.media = [...this.media];
                this.checkMultipleSelector = true;
            }
            this.uploadedFiles = this.media.length;
        },
        checkMultipleVideoOrGif() {
            if (
                this.media.length > 0 &&
                this.selectedSocialMediaProfiles.find(
                    (o) =>
                        o.type.toLowerCase().includes("facebook") ||
                        o.type.toLowerCase().includes("linkedin") ||
                        o.type.toLowerCase().includes("twitter")
                ) &&
                (this.media[0].type.includes("video") ||
                    this.media[0].type.includes("gif"))
            ) {
                if (this.media.length > 1) {
                    return true;
                }
            }

            return false;
        },
        selectFromMultipleMedia(data) {
            if (data) {
                this.media = [{...data}];
            }
            this.checkMultipleSelector = false;
        },
        removeMedia(media) {
            if (!this.posting && !this.drafting) {
                if (media.file) {
                    const index = this.selectedMedia.indexOf(media.file);
                    this.selectedMedia.splice(index, 1);
                }
                this.mediaTypes = this.media.map((o) => o.type);
                this.mediaTypes = [...this.mediaTypes];

                const indexMedia = this.media.indexOf(media);
                this.media.splice(indexMedia, 1);
                this.uploadedFiles = this.media.length;
            }
        },
        openScheduleMenu() {
            if (!this.posting && !this.drafting) {
                this.scheduleHour = moment().tz(this.getTimezone).hour();
                this.scheduleHour = this.scheduleHour % 12;
                this.scheduleHour = this.scheduleHour ? this.scheduleHour : 12;
                this.scheduleMin = this.getSelectedMinutes(
                    moment().tz(this.getTimezone).minute()
                );

                if (this.scheduleMin > 55) {
                    this.scheduleMin = "00";
                    console.log(this.scheduleHour);
                    this.scheduleHour++;
                }
                this.scheduleDate = moment().tz(this.getTimezone).format("YYYY-MM-DD");
                this.scheduleDateMoment = moment().tz(this.getTimezone);
                this.scheduleTimezone = moment().tz(this.getTimezone).format("A");
            }
        },
        removeSchedule() {
            if (!this.posting && !this.drafting) {
                this.scheduleDate = null;
                this.scheduleHour = null;
                this.scheduleMin = null;
                this.scheduleTimezone = null;
                this.schedulePicker = false;
            }
        },
        getSelectedMinutes(n, m = 5) {
            let q = parseInt(n / m);

            let n2 = n * m > 0 ? m * (q + 1) : m * (q - 1);
            if (n2 < 0) n2 += 10;
            return n2 >= 60 ? "00" : (n2 + 5).toString();
        },
        addLeadURL() {
            const link =
                "Let's talk real estate. Go " +
                (webAppUrl + '/' + this.$store.state.user.organizationSlug).replaceAll(
                    "https://",
                    ""
                );
            this.tabs.forEach((tab) => {
                if (
                    tab.type.toLowerCase() === SocialMediaType.instagram.toLowerCase()
                ) {
                    tab.description =
                        (tab.description || "") +
                        (tab.description ? "\n" : "") +
                        "Let's talk real estate. Click on my BIO";
                } else {
                    tab.description =
                        (tab.description || "") + (tab.description ? "\n" : "") + link;
                }
            });
            this.oldInitialValue = this.tabs[0].description || "";
        },
        async openTemplateForm() {
            this.$store.commit("setOpenGenerateIdeaModal", true);
            try {
                this.commonErrors = [];
                this.loadingTemplate = true;
                const filter = {
                    categories: [],
                    subcategories: [],
                    tags: [],
                    page: -1,
                    text: null,
                };
                this.templates = await this.templateService.fetchAllFiltered(filter);
                this.templateDialog = true;
                this.loadingTemplate = false;
            } catch (e) {
                this.commonErrors = [];
                const error = {
                    title: "Oops! Could not open post ideas.",
                    subtitle:
                        e?.response?.data?.message ||
                        e?.data?.message ||
                        "Some error occurred",
                };
                this.loadingTemplate = false;
                this.commonErrors.push(error);
            }
        },

        selectTemplate(template) {
            if (template) {
                for (let i = 0; i < this.tabs.length; i++) {
                    if (template.hashtags && template.hashtags.length > 0) {
                        this.tabs[i].description =
                            template.description + "\n\n#" + template.hashtags.join("  #");
                    } else {
                        this.tabs[i].description = template.description;
                        this.aiTemplateDialog = false
                    }
                }
                this.media = template.media || [];
                this.media.forEach((item) => {
                    item.hasUploaded = true;
                });
            }
            if (template.hashtags && template.hashtags.length > 0) {
                this.oldInitialValue =
                    template.description + "\n\n#" + template.hashtags.join("  #");
            } else {
                this.oldInitialValue = template.description;
            }
            if (template.categories) {
                this.addLeadURL();
            }
            this.$store.commit("setOpenGenerateIdeaModal", false);
            this.templateDialog = false;
        },
        async preCheckData() {
            this.platformErrors = [];
            this.descriptionErrors = [];
            this.mediaErrors = [];
            if (this.selectedSocialMediaProfiles.length <= 0) {
                const error = {
                    title: "Oops! You forgot to select a social account",
                    subtitle: "Please choose one or more social accounts to publish to",
                };

                this.platformErrors.push(error);
                this.$refs.postCreateForm.scrollTop = 0;

                return false;
            } else {
                let count = 0;
                for (let i = 0; i < this.tabs.length; i++) {
                    if (
                        !this.tabs[i].description &&
                        this.tabs[i].type !== "all" &&
                        this.tabs[i].type !== "Instagram"
                    ) {
                        const error = {
                            title: "Oops! You haven't added any caption",
                            subtitle: this.tabs[i].type + " requires text to be included",
                            type: this.tabs[i].type.toUpperCase(),
                        };

                        this.descriptionErrors.push(error);

                        count++;
                    }
                    if (
                        this.tabs[i].description &&
                        this.tabs[i].description.includes("app.postredi.com") &&
                        this.tabs[i].type === "Instagram"
                    ) {
                        const error = {
                            title: "Oops! You have added link in caption.",
                            subtitle: this.tabs[i].type + " doesn't allow links in caption.",
                            type: this.tabs[i].type.toUpperCase(),
                        };

                        this.descriptionErrors.push(error);

                        count++;
                    }

                    if (
                        this.tabs[i].description &&
                        this.tabs[i].description.length > this.tabs[i].counter &&
                        this.tabs[i].type !== "all"
                    ) {
                        const error = {
                            title: "Oops! Description too long",
                            subtitle:
                                this.tabs[i].type +
                                " allows only " +
                                this.tabs[i].counter +
                                " characters.",
                            type: this.tabs[i].type.toUpperCase(),
                        };

                        this.descriptionErrors.push(error);
                        count++;
                    }
                }

                if (
                    this.selectedSocialMediaProfiles.find(
                        (o) =>
                            o.type === SocialMediaType.instagram ||
                            o.type === SocialMediaType.instagramBusiness
                    ) &&
                    this.media.length <= 0
                ) {
                    const error = {
                        title: "Oops! You haven't added any media",
                        subtitle: "Instagram requires media to be included",
                        type: SocialMediaType.instagram,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (
                    this.media.find((o) => o.type.includes("gif")) &&
                    this.selectedSocialMediaProfiles.find(
                        (o) => o.type === SocialMediaType.linkedIn
                    )
                ) {
                    const error = {
                        title: "Oops! Gif is not allowed.",
                        subtitle: "Linkedin does not allow gif to be published.",
                        type: SocialMediaType.linkedIn,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (
                    this.media.length > 10 &&
                    this.selectedSocialMediaProfiles.find(
                        (o) => o.type === SocialMediaType.instagram
                    )
                ) {
                    const error = {
                        title: "Oops! To many files attached.",
                        subtitle: "Upto 10 files are allowed.",
                        type: SocialMediaType.instagram,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (
                    this.media.length > 9 &&
                    this.selectedSocialMediaProfiles.find(
                        (o) => o.type === SocialMediaType.linkedIn
                    )
                ) {
                    const error = {
                        title: "Oops! To many files attached.",
                        subtitle: "Upto 9 files are allowed.",
                        type: SocialMediaType.linkedIn,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (
                    this.media.length > 4 &&
                    this.selectedSocialMediaProfiles.find(
                        (o) => o.type === SocialMediaType.twitter
                    )
                ) {
                    const error = {
                        title: "Oops! To many files attached.",
                        subtitle: "Upto 4 files are allowed.",
                        type: SocialMediaType.twitter,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (
                    this.selectedSocialMediaProfiles.find(
                        (o) => o.type === SocialMediaType.instagram
                    ) &&
                    (await this.checkAspectRatio())
                ) {
                    const error = {
                        title: "Oops! Invalid media.",
                        subtitle:
                            "Some files have invalid dimensions. Aspect ratio should be between 4:5 and 191:100",
                        type: SocialMediaType.instagram,
                    };

                    this.mediaErrors.push(error);
                    this.$refs.postCreateForm.scrollTop =
                        this.$refs.postCreateForm.scrollHeight;

                    count++;
                }

                if (count > 0) return false;
            }

            return true;
        },
        preCheckSchedule() {
            // const date = moment(this.scheduleDate).tz(this.getTimezone);
            const date = this.scheduleDateMoment;
            let hour;
            if (this.scheduleTimezone === "AM" && this.scheduleHour === 12) {
                hour = 24;
            } else if (this.scheduleTimezone === "PM" && this.scheduleHour === 12) {
                hour = 12;
            } else {
                hour =
                    this.scheduleTimezone === "AM"
                        ? this.scheduleHour
                        : this.scheduleHour + 12;
            }
            date.set("hour", hour);
            date.set("minute", this.scheduleMin);
            date.set("date", moment(this.scheduleDate).date());
            date.set("month", moment(this.scheduleDate).month());
            date.set("year", moment(this.scheduleDate).year());

            if (hour === 24) date.subtract(1, "day");
            this.commonErrors = [];
            this.dateError = false;
            const current = moment().tz(this.getTimezone);
            current.add(5, "minutes");

            if (moment(date).tz(this.getTimezone).isBefore(current)) {
                const error = {
                    title: "Oops! You have selected wrong date",
                    subtitle:
                        "Scheduled date must be at least 5 minutes in future. Kindly select future date.",
                    color: "red",
                    icon: "mdi-calendar",
                };
                this.dateError = true;
                this.commonErrors.push(error);

                this.$refs.postCreateForm.scrollTop = 0;
                return false;
            }

            return true;
        },
        async schedule(status = PostStatus.pending) {
            const user = this.$store.state.user;

            if (
                (status === PostStatus.pending ? !this.posting : !this.drafting) &&
                (await this.preCheckData()) &&
                this.preCheckSchedule() &&
                user
            ) {
                if (status === PostStatus.pending) this.posting = true;
                else this.drafting = true;
                console.log("scheduling");
                const socialProfiles = this.getProfilesDataForPosting();

                const date = this.scheduleDateMoment;
                // const date = moment(this.scheduleDate).tz(this.getTimezone);

                let hour =
                    this.scheduleTimezone === "AM"
                        ? this.scheduleHour
                        : this.scheduleHour + 12;

                if (this.scheduleTimezone === "AM") {
                    if (this.scheduleHour === 12) {
                        hour = 0;
                    } else {
                        hour = this.scheduleHour;
                    }
                } else {
                    if (this.scheduleHour === 12) {
                        hour = 12;
                    } else {
                        hour = this.scheduleHour + 12;
                    }
                }

                date.set("hour", hour);
                date.set("minute", this.scheduleMin);

                const scheduled_date = {
                    fullDate: date,
                    date: date,
                    hour: this.scheduleHour,
                    minute: this.scheduleMin,
                    timezone: this.scheduleTimezone,
                };
                if (this.media.length > 0) {
                    for (let i = 0; i < this.media.length; i++) {
                        if (this.media[i] && this.media[i].file) {
                            await this.uploadFile(i);
                        }
                    }
                }
                const postData = {
                    socialProfiles,
                    media: this.media,
                    scheduled_date: scheduled_date,
                    status: status,
                    user: user._id,
                };
                try {
                    await this.postService.schedulePost(postData);
                    if (status === PostStatus.pending)
                        this.$toast.success(
                            "Your post is now processing. Will be ready in few seconds."
                        );
                    else this.$toast.success("Draft post created.");
                    if (status === PostStatus.pending) this.posting = false;
                    else this.drafting = false;
                    this.selectedSocialMediaProfiles = [];
                    this.media = [];
                    this.tabs = [
                        {
                            title: "Content",
                            icon: null,
                            counter: 0,
                            type: "all",
                            description: null,
                        },
                    ];
                    await this.$store.dispatch("getPosts");
                    this.$emit("getWeek", true);
                    this.$emit("value", false);
                    this.scheduleDate = null;
                    this.$store.commit("setOpenGenerateIdeaModal", false);
                } catch (e) {
                    if (status === PostStatus.pending) this.posting = false;
                    else this.drafting = false;
                    this.commonErrors = [];
                    const error = {
                        title: "Oops! Your post could not be added.",
                        subtitle:
                            e?.response?.data?.message ||
                            e?.data?.message ||
                            "Some error occurred",
                    };
                    this.commonErrors.push(error);
                }
            }
        },
        async postNow(status = PostStatus.pending) {

            const user = this.$store.state.user;
            if ((status === PostStatus.pending ? !this.posting : !this.drafting) && (await this.preCheckData()) && user) {
                console.log("posting");
                if (status === PostStatus.pending) this.posting = true;
                else this.drafting = true;
                const socialProfiles = this.getProfilesDataForPosting();

                const date = moment().tz(this.getTimezone);

                const scheduled_date = {
                    fullDate: date,
                    date: date,
                    hour: moment().tz(this.getTimezone).hour(),
                    minute: moment().tz(this.getTimezone).minute(),
                    timezone: moment().tz(this.getTimezone).hour() >= 12 ? "PM" : "AM",
                };

                if (this.media.length > 0) {
                    for (let i = 0; i < this.media.length; i++) {
                        if (!this.media[i].hasUploaded) await this.uploadFile(i);
                    }
                }

                const postData = {
                    socialProfiles,
                    media: this.media,
                    scheduled_date: scheduled_date,
                    status: status,
                    user: user._id,
                };

                try {
                    // console.log(postData);
                    await this.postService.postNow(postData);
                    await this.$store.dispatch("getPosts");
                    this.$emit("getWeek", true);
                    this.$emit("value", false);
                    this.$store.commit("setOpenGenerateIdeaModal", false);

                    if (status === PostStatus.pending) this.posting = false;
                    else this.drafting = false;

                    // resetting

                    this.$refs.AiPostGenerateFormRef.closeDialog();
                    this.selectedSocialMediaProfiles = [];
                    this.media = [];
                    this.mediaErrors = [];
                    this.descriptionErrors = [];
                    this.commonErrors = [];
                    this.tabs = [
                        {
                            title: "Content",
                            icon: null,
                            counter: 0,
                            type: "all",
                            description: null,
                        },
                    ];

                    if (status === PostStatus.pending)
                        this.$toast.success("Your post is now processing. Will be ready in few seconds.");
                    else this.$toast.success("Draft post created.");
                } catch (e) {
                    if (status === PostStatus.pending) this.posting = false;
                    else this.drafting = false;
                    this.commonErrors = [];
                    const error = {
                        title: "Oops! Your post could not be added.",
                        subtitle:
                            e?.response?.data?.message ||
                            e?.data?.message ||
                            "Some error occurred",
                    };
                    this.commonErrors.push(error);
                }
            }
        },
        getProfilesDataForPosting() {
            const socialProfiles = [];
            for (let i = 0; i < this.selectedSocialMediaProfiles.length; i++) {
                const type = this.getSocialMediaTypeTitle(
                    this.selectedSocialMediaProfiles[i].type
                );
                const description = this.tabs.find((o) => o.type === type);
                const obj = {
                    id: this.selectedSocialMediaProfiles[i]._id,
                    type: this.selectedSocialMediaProfiles[i].type,
                    description: description.description,
                };
                socialProfiles.push(obj);
            }

            return socialProfiles;
        },
        closeForm() {
            if (
                confirm("Warning! You will lose your progress. Do you want to proceed?")
            ) {
                this.selectedSocialMediaProfiles = [];
                this.media = [];
                this.selectedMedia = [];
                this.mediaErrors = [];
                this.descriptionErrors = [];
                this.commonErrors = [];
                this.dateError = false;
                this.$refs.postCreate.reset();
                this.tabs = [
                    {
                        title: "Content",
                        icon: null,
                        counter: 0,
                        type: "all",
                        description: null,
                    },
                ];
                (this.scheduleDate = null),
                    (this.scheduleTimezone = null),
                    (this.scheduleMin = null),
                    (this.scheduleHour = null),
                    this.$emit("value", false);
                this.$store.commit("setOpenGenerateIdeaModal", false);
            }
        },
    },
};
</script>

<style scoped>
.mediaPrickerWrapper {
    border: 1px dashed #2177b0;
    border-radius: 8px;
}

.file-display {
    border: 1px solid #2177b0;
}

.contact-upload-dialog p,
.contact-upload-dialog a {
    font-size: 14px;
}

.custom-error {
    border: 1px solid red;
}
</style>
