<template>
  <v-dialog :value="flag" @input="$emit('value', false)"
            :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
            persistent>
    <v-card class="pa-4" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar @click="$emit('value', false)" class="pointer">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <div>
        <h3 class="text-md-h4 text-h5 mb-4 primary--text font-weight-medium">
          {{ accepted ? 'Welcome Onboard' : 'Become a Promoter' }}</h3>
      </div>
      <div v-if="!accepted">
        <div class="my-2" style="max-height: 300px; overflow: hidden; overflow-y: scroll">
          <ReferralCondition/>
        </div>
        <div class="d-flex justify-end">
          <v-btn
              elevation="0"
              class="mr-2"
              @click="$emit('value', false)"
          >
            Reject
          </v-btn>
          <v-btn
              elevation="0"
              color="primary"
              @click="$emit('becomePromoter', true)"
          >
            Accept
          </v-btn>
        </div>
      </div>
      <div v-else>
        <p>We are glad you will be promoting our services.</p>
        <p>Head on to the <a href="/promoter">promoter dashboard</a> to access your promotion links and see your
          rewards.</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import ReferralCondition from "@/components/ReferralCondition";

export default {
  name: "BecomePromoter",
  components: {ReferralCondition},
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    accepted: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>

</style>