<template>
  <div>
    <div :class="[hasError ? 'invalid' : 'valid']"
         :style="{cursor: disabled ? 'default' : 'pointer'}"
         class="drop mb-4"
         @drop="onDrop"
         @dragover.prevent
         @click.self="!disabled ? clickOnBox() : null"
    >
      <input id="fileInput" ref="file-input" accept="image/*" name="image" style="display: none;" type="file"
             @change="onChange">
      <div v-if="!(image && image.url)" class="mx-4 cursor-pointer" style="margin-top: 80px"
           @click="$refs['file-input'].click();">
        + Select/Drop Image
      </div>

      <div v-else class="d-flex flex-column align-center main-container" v-bind:class="{ 'image': true }">
        <img :src="image.url" alt="" class="img" @click="$emit('openCropper', true)"/>
        <span v-if="!disabled" class="icon" @click="removeFile">Remove Image</span>
      </div>
    </div>
    <span v-if="hasError" style="color:red">Image is required!</span>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    hasError: {
      type: Boolean,
      defaults: false,
    },
    image_obj: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Blob | File,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      image: null,
    }
  },

  watch: {
    image: {
      handler(newVal) {
        this.$emit('uploadedImage', newVal);
      }, deep: true,
    },

    image_obj(newVal) {
      if (newVal) {
        if(!newVal.url) {
          newVal.url = URL.createObjectURL(newVal);
        }
        this.image = newVal;
      }
    }
  },

  mounted() {
    if(this.image_obj) {
      if(!this.image_obj.url) {
        this.image_obj.url = URL.createObjectURL(this.image_obj);
      }
      this.image = this.image_obj;
    }
  },

  methods: {
    clickOnBox() {
      this.$refs['file-input'].click();
    },

    // for image storage control
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      let files = e.dataTransfer.files;
      this.createFile(files[0]);
    },

    onChange(e) {
      let files = e.target.files;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (file) {
        this.image = {
          file: file,
          url: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
          size: file.size
        };
      } else {
        console.log('File Selection canceled');
      }
    },

    removeFile() {
      this.image = '';
      this.$emit('removeImage', null)
      document.getElementById("fileInput").value = "";
    },
  }
}
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.main-container {
  position: relative;
}

.icon {
  color: #2278B0;
  font-size: 13px;
  margin-top: 3px;
  text-decoration: underline;
}

html, body {
  height: 100%;
  text-align: center;
}

.invalid {
  border: 2px dashed red;
}

.valid {
  border: 2px dashed #ccc;
}

.btn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.btn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
}

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: 200px;
  width: 200px;
  /*margin-left: -2px;*/
  /*margin-top: -2px;*/
  object-fit: contain;
}

.drop {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 2px;
  height: 200px;
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

</style>