<template>
  <v-dialog :value="flag" @input="$store.commit('setOpenSocialConnector', !flag)"
            :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'" persistent>
    <v-card class="py-4 px-8">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <h2>Add an account to Postredi</h2>
            <v-avatar @click="$store.commit('setOpenSocialConnector', false)" class="pointer">
              <v-icon>mdi-close</v-icon>
            </v-avatar>
          </div>
          <p class="font-weight-regular">Choose each social network you want to connect and then authorize Postredi to
            access your accounts.</p>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pa-0 mb-4">
              <FacebookConnector :color="getSocialMediaTypeColor(SocialMediaType.facebook)"/>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 mb-4">
              <LinkedinConnector :color="getSocialMediaTypeColor(SocialMediaType.linkedIn)"/>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 mb-4">
              <InstagramConnector :color="getSocialMediaTypeColor(SocialMediaType.instagram)"/>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 mb-4">
              <TwitterConnector :color="getSocialMediaTypeColor(SocialMediaType.twitter)"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import {getSocialMediaTypeColor} from "@/utils/local";
import {SocialMediaType} from '@/utils/enum'
import FacebookConnector from "@/components/social-platforms/facebookConnector";
import InstagramConnector from "@/components/social-platforms/instagramConnector";
import LinkedinConnector from "@/components/social-platforms/linkedinConnector";
import TwitterConnector from "@/components/social-platforms/twitterConnector";

export default {
  name: "SocialConnectionDialog",
  components: {TwitterConnector, LinkedinConnector, InstagramConnector, FacebookConnector},
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      SocialMediaType
    }
  },

  methods: {
    getSocialMediaTypeColor
  }
}
</script>