<template>
  <div>
    <h3>Terms and Conditions – Referral Program</h3>
    <p>
      These terms and conditions (the “Program Terms”) apply to postredi.com, Inc.’s Refer-A-Business program (the
      “Program”). By referring a business, or placing a new order as a result of a referral, or otherwise participating
      in this Program, you agree to be bound by these Program Terms.
    </p>
    <h3>1). GENERAL PARTICIPATION</h3>

    <p> A Referring Customer must be an existing postredi.com, Inc. (“POSTREDI”) business customer in good payment standing, in
      order to participate in the Program. Current POSTREDI business customers who initiate a referral are defined as
      “Referring Customers.”

      A Referred Customer must sign up for POSTREDI qualifying business services. Prospective POSTREDI customers who become
      a POSTREDI customer through a Referring Customer referral are defined as “Referred Customers.” To be considered a
      “new” business customer, the Referred Customer must not have been a POSTREDI customer for at least ninety (90) days
      prior to the referral.

      The Referred Customer must install/activate Qualifying Service within the first ninety (90) days of the date of
      referral, and have an active POSTREDI business account in good standing sixty (60) days after the service
      activation. To be considered a customer in good standing, a Referred Customer is required to use the services in
      accordance with POSTREDI’s service terms and policies and pay all POSTREDI invoices for services in full when due.

      If the Qualifying Service is canceled or downgraded sixty (60) days after service activation, the Reward (if any),
      will be denied. Qualifying Service is defined as follows [insert definition – i.e. business services only?
      Colocation included? Network? Etc]

      By participating in the Program, the Referring Customer and Referred Customer agree that POSTREDI may share their
      name, email address, service installation status and date, and other information with the Referring Customer
      and/or Referred Customer and others, including third-party providers, for the purpose of administering the
      Program.

      Eligibility Requirements are subject to change. POSTREDI reserves the right to refuse to honor any referral based on
      a failure to adhere to the Terms of this Program, including these eligibility requirements.</p>

    <h3> 2). QUALIFYING REFERRAL REWARDS</h3>
    <p>
      Under the Program, eligible Referring Customers and Referred Customers satisfying all Program and Reward
      participation and eligibility criteria shall each receive a Reward. The type and amount of the Reward shall be
      determined by POSTREDI and is subject to change at POSTREDI’s sole discretion.

      A Referring Customer may receive a $500 VISA Gift Card or $500 Account Credit after referring a business that
      signs up for POSTREDI services and becomes a Referred Customer. A Referred Customer may receive a $250 VISA Gift
      Card or a FREE Month of Service.

      No Rewards or prorated Rewards will be provided for the Referring Customer and the Referred Customer unless and
      until the Referred Customer installs and continues Qualifying Services in good standing for at last sixty (60)
      days.
    </p>
    <h3> 3). GENERAL PROGRAM DETAILS AND RESTRICTIONS</h3>
    <p>
      In order for a referral submission to be complete and to be considered as an eligible referral for a Reward, the
      Referring Customer must complete and submit the online referral form (link).

      Agent partners are not eligible to participate as a Referring Customer.

      If multiple Referring Customers refer the same Prospective Customer, and that Prospective Customer becomes a
      Referred Customer, the Reward will be issued to the Referring Customer who first submitted the eligible referral
      to the extent the referral was still in good standing and unexpired at the time the Prospective Customer became a
      Referred Customer, according to POSTREDI’s records. Only one Referral Reward will be issued per qualifying sale.

      To be eligible, a Referred Customer must be a business customer who has a Federal Tax ID number and must agree to
      a minimum 24-month service agreement at a minimum of $300 per month.

      Referring Customers and Referred Customers should allow approximately ninety (90) days from service activation for
      the Referred Customer to receive a Referral Reward.

      If Referral Rewards to a Referring Customer under this program and any others offered by POSTREDI exceed FIVE
      HUNDRED AND NINETY-NINE DOLLARS ($599.00) in a calendar year, Rewards may be taxable and any such taxes are the
      responsibility of the recipient, not POSTREDI.
    </p>
    <h3> 4). LOST, STOLEN, EXPIRED VISA GIFT CARD</h3>
    <p>
      POSTREDI is not responsible for unclaimed, late, lost or stolen cards. Unclaimed, late, lost or stolen Lost/stolen
      prepaid Visa cards may be replaced by calling tollfree 1-800-750-3556. Card Customer Service is available 24 hours
      a day, 7 days a week. Replacement cards are subject to a $10 fee, which will be deducted from the balance on the
      replacement card before issuance. [insert bank name – i.e. Visa, Inc.] is not a sponsor or co-sponsor of this
      Program. This card is issued by [insert bank name – i.e. The Bancorp Bank, Member FDIC], pursuant to a license
      from [insert – i.e. Visa U.S.A. Inc.].

      Rewards Card Expiration: Any Reward cards which have not been used within six (6) months of issuance will
      automatically expire. Upon expiration of the Reward card, any unused funds will automatically expire. Unused funds
      on an expired Reward card may be transferred to a “replacement” card by calling 1-800-750-3556. Replacement cards
      are subject to a $10 fee, which will be deducted from the balance on the replacement card before issuance. Reward
      cards may not be transferred, assigned, or redeemed for cash. Reward cards are subject to the terms and conditions
      accompanying the Reward card.
    </p>
    <h3> 5). ADDITIONAL TERMS</h3>
    <p>
      POSTREDI MAY SUSPEND, TERMINATE OR MODIFY, WITHOUT LIABILITY, ALL OR ANY PART OF THE REFER-A-BUSINESS PROGRAM
      (INCLUDING THE TERMS, TYPE, AND AMOUNT OF REWARDS AND ELIGIBILITY CRITERIA UNDER THE PROGRAM), IN ITS SOLE
      DISCRETION, WITH OR WITHOUT NOTICE. POSTREDI reserves the right to suspend or terminate any customer’s participation
      in the Refer-A-Business program for fraud, abuse, misuse, harassment, or other inappropriate or suspicious
      activity that POSTREDI determines, in its sole discretion, compromises the fairness or integrity of the Program in
      any way. POSTREDI may change these Program Terms at any time, and by your participation in the Program, you agree to
      abide by the revised version of the Program Terms. If changes are made, the updated version of the Terms will be
      posted on the POSTREDI website.

      All referrals under the Program must only be made via the online referral form [insert link]. Only referrals which
      follow the Program ordering procedures and satisfy all Rewards criteria shall be eligible for a Reward.

      Rewards may not be combined with other referral rewards or similar programs. The Program is void in areas where
      prohibited.
    </p>
  </div>
</template>

<script>
export default {
  name: "ReferralCondition"
}
</script>

<style scoped>

</style>