<template>
  <v-card height="100px" width="100%" class="mx-2 d-flex justify-center align-center">
    <div class="text-center">
      <p class="ma-0 font-weight-bold mb-2">{{ title }}</p>
      <p class="ma-0 text-h4 primary--text"><span v-if="isCurrency" class="text-h5 mr-1">$</span>{{ value }}</p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PromoterStatCard",
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    value: {
      type: Number,
      default: 0
    },
    isCurrency: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>