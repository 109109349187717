<template>
  <div v-if="detailPost" style="width: 100%;">
    <v-toolbar elevation="0" :color="detailPost.status !== PostStatus.failed ? 'blue lighten-4' : 'red lighten-4'"
               dense>
      <v-avatar color="white" size="32">
        <v-icon size="20" :color="getPostStatusColor(detailPost.status)">{{ getPostStatusIcon(detailPost.status) }}
        </v-icon>
      </v-avatar>
      <v-card-title>{{ getPostStatus(detailPost.status) }}</v-card-title>
      <v-spacer/>
      <v-avatar class="pointer" color="white" size="32" @click="$emit('togglePost', {post: null, flag: false})">
        <v-icon size="20">mdi-close</v-icon>
      </v-avatar>
    </v-toolbar>
    <div v-if="detailPost.status !== PostStatus.failed" class="blue lighten-1 px-4 py-2">
      <p class="ma-0">{{ detailPost.status === 'PENDING' ? 'Created' : getPostStatus(detailPost.status) }} by
        {{ detailPost.user.name }}</p>
    </div>
    <div v-else>
      <div class="red lighten-2 px-4 py-2">
        <p class="ma-0">Post failed due to {{ detailPost.failed_reason.title || 'unexpected issues.' }}</p>
      </div>
      <div v-if="detailPost.failed_reason.subtitle" class="red lighten-3 px-4 py-2">
        <p class="ma-0">{{ detailPost.failed_reason.subtitle }}</p>
      </div>
    </div>
    <div class="px-4 py-2 d-flex justify-end post-drawer"
         :style=" isPostOpen ? 'width: 100%' : 'width: 0%' ">
      <v-btn
          v-if="(detailPost.status === PostStatus.pending ? getCreatedDiff(detailPost.updatedAt) > 5 : true) && detailPost.status !== PostStatus.posted"
          elevation="0"
          color="primary" class="mr-2" @click="$emit('openPostEditForm', detailPost)">
        Edit
      </v-btn>
      <v-btn :disabled="loading"
             v-if="detailPost.status === PostStatus.pending ? getCreatedDiff(detailPost.updatedAt) > 5 : true"
             elevation="0"
             color="primary" class="mr-2" @click="deletePost">
        {{ loading ? 'Deleting...' : 'Delete' }}
      </v-btn>
      <!--      <v-btn v-if="detailPost.status !== PostStatus.pending" elevation="0" color="primary" class="mr-2">-->
      <!--        Duplicate-->
      <!--      </v-btn>-->
      <v-btn
          v-if="detailPost.status === PostStatus.scheduled"
          elevation="0" color="primary" @click="markAsDraft">
        {{ loading ? 'marking as draft...' : 'Mark as draft' }}
      </v-btn>
    </div>
    <div class="px-4 py-2">
<!--      <LinkedinView v-if="detailPost.socialProfile.type === SocialMediaType.linkedIn" :post="detailPost"/>-->
<!--      <InstagramView v-else-if="detailPost.socialProfile.type === SocialMediaType.instagram" :post="detailPost" />-->
<!--      <FacebookView v-else :post="detailPost"/>-->
      <FacebookView :post="detailPost"/>
    </div>
    <div class="px-4 py-2">
      <p class="ma-0 mb-4 text-h6">Details</p>

      <h4>Scheduled time</h4>
      <p> {{ formatDate(detailPost.scheduled_date.fullDate, 'dddd, MMMM D [at] hh:mma') }}</p>
    </div>
  </div>
</template>

<script>

import {
  formatDate,
  getPostStatusIcon,
  getPostStatusColor,
  getPostStatus,
} from "@/utils/local";
import {SocialMediaType, PostStatus} from "@/utils/enum";
import FacebookView from "@/components/posts/postDetails/facebook-view";
import {PostsService} from "@/services/posts-service";
import moment from "moment/moment";
// import LinkedinView from "@/components/posts/postDetails/linkedin-view";
// import InstagramView from "@/components/posts/postDetails/instagram-view";

export default {
  name: "PostDetail",
  components: {FacebookView},

  props: {
    detailPost: {
      type: Object,
      default: () => {
      }
    },
    isPostOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SocialMediaType,
      PostStatus,
      loading: false,
      service: new PostsService()
    }
  },
  methods: {
    formatDate,
    getPostStatusIcon,
    getPostStatusColor,
    getPostStatus,
    getCreatedDiff(givenDate) {
      const duration = moment.duration(moment().diff(givenDate));
      return duration.asMinutes();
    },
    async deletePost() {
      console.log(this.detailPost)
      if (confirm('Warning! \n Are you sure you want to delete this post?')) {
        try {
          this.loading = true
          await this.service.delete(this.detailPost)
          this.$emit('reloadData', true)
          this.$emit('togglePost', {post: null, flag: false})
          this.$toast.success('Post deleted successfully')
          this.loading = false
          console.log('yes')
        } catch (e) {
          console.log(e)
          this.loading = false
          this.$toast.error(e?.response?.data?.message || e?.data?.message || 'Could not delete post. Try again later.')
        }
      }
    },
    async markAsDraft() {
      console.log(this.detailPost)
      if (confirm('Warning! \n Are you sure you want to mark this post as draft?')) {
        try {
          this.loading = true
          const data = {
            _id: this.detailPost._id,
            status: PostStatus.draft
          }
          await this.service.update(data)
          this.$emit('reloadData', true)
          this.$emit('togglePost', {post: null, flag: false})
          this.$toast.success('Post marked as draft successfully')
          this.loading = false
          console.log('yes')
        } catch (e) {
          console.log(e)
          this.loading = false
          this.$toast.error(e?.response?.data?.message || e?.data?.message || 'Could not mark post as draft. Try again later.')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>