<template>
  <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
            persistent>
    <v-card class="pa-4" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar @click="$emit('value', false)" class="pointer">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <v-form ref="postCreate">
        <div>
          <h3 class="text-md-h3 text-h5 mb-4 primary--text font-weight-medium">Update Security</h3>
        </div>
        <v-form ref="securityForm">
          <ul class="mb-3" v-if="updated">
            <li class="success--text">Your password was updated.</li>
          </ul>
          <ul class="mb-3">
            <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
          </ul>
          <div class="mb-3">
            <label>Old Password</label>
          </div>
          <v-text-field :disabled="loading" v-model="oldPassword" :rules="[required()]" outlined
                        placeholder="Old Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword">
          </v-text-field>

          <div class="mb-3">
            <label>Password</label>
          </div>
          <v-text-field :disabled="loading" v-model="password"
                        :rules="[required(), minChar(), oneAlphabet(), oneNumber(), maxChar]" outlined
                        placeholder="Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword">
          </v-text-field>
          <div class="mb-3">
            <label>Confirm Password</label>
          </div>
          <v-text-field :disabled="loading" v-model="confirmPassword"
                        :rules="[required(), (v) => (v && v === password) || 'Password could not be confirmed']"
                        outlined
                        placeholder="Confirm Password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword">
          </v-text-field>

          <v-btn @click="updatePassword" :disabled="loading" color="primary" elevation="0" width="100%" x-large
                 class="mb-6">
            <span>{{ loading ? 'Submitting' : 'Submit' }}</span>
            <v-progress-circular size="18" indeterminate v-if="loading" width="2" class="ml-3"/>
          </v-btn>


        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>


import {required, minChar, maxChar, oneAlphabet, oneNumber} from "@/utils/validators";


export default {
  name: "SecurityForm",
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      updated: false,
      errors: [],
      oldPassword: '',
      password: '',
      confirmPassword: '',
      showPassword: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    flag(newVal) {
      if(newVal) {
        this.updated = false;
      }
    }
  },
  methods: {
    required,
    minChar,
    oneAlphabet,
    oneNumber,
    maxChar,
    async updatePassword() {
      if (this.$refs.securityForm.validate()) {
        this.errors = []
        if (this.password !== this.confirmPassword) {
          this.errors.push('Password could not be confirmed.')
          return
        }
        try {
          this.loading = true
          const data = {
            newPassword: this.password,
            oldPassword: this.oldPassword
          }
          await this.$axios.patch('/persons/update-password/' + this.user._id, data)
          this.$toast.success('Your password was updated.')
          this.$emit('value', false)
          this.loading = false
          this.updated = true
          this.$refs.securityForm.reset()
        } catch (e) {
          this.loading = false
          console.log(e)
          this.errors.push(e?.response?.data?.message || e?.data?.message || 'Some error occured')
        }
      }
    }
  }
}
</script>

<style scoped></style>