<template>
  <div>
    <v-card @click="connectLinkedin" elevation="1" rounded="0" :max-width="$vuetify.breakpoint.mdAndUp ? '80%' : '100%'" class="mx-auto text-center pa-4">
      <div
          style="position: absolute; top: 5px; right: 5px; background: #800000;border-top-right-radius: 8px; border-bottom-left-radius: 8px; width: 35px; height: 35px"
          class="pa-2 d-flex justify-center align-center">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <p class="ma-0 white--text" v-bind="attrs"
               v-on="on">{{ totalPlatforms }}</p>
          </template>
          <span v-if="totalPlatforms > 0">Total {{ totalPlatforms }} {{ totalPlatforms > 1 ? 'accounts' : 'account' }} connected</span>
          <span v-else>No accounts connected.</span>
        </v-tooltip>
      </div>
      <v-icon x-large class="mb-2" :color="color">mdi-linkedin</v-icon>
      <h4 class="text-center" v-if="$vuetify.breakpoint.mdAndUp">Linkedin</h4>
    </v-card>
    <v-dialog v-model="setup" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'" persistent>
      <v-card class="pa-4">
        <div class="ma-4">
          <p class="ma-0 text-h4 mb-3">Social account setup still in progress</p>
          <p class="ma-0 mb-3"> There are still a few steps remaining to connect your social account to Postredi.</p>
          <div class="d-flex justify-end">
            <v-btn elevation="0" @click="closeWindow" class="mr-3" tile>Cancel</v-btn>
            <v-btn elevation="0" color="primary" @click="focusOnWindow" tile>Resume Setup</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {LINKEDINCLIENT, linkedinRedirectURL, LINKEDINSCOPE} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";

export default {
  name: "LinkedinConnector",
  props: {
    color: String
  },
  data() {
    return {
      connectionWindow: null,
      setup: false,
    }
  },
  watch: {
    connectionWindow(newVal) {
      console.log(newVal, 'Connection Window')
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    totalPlatforms() {
      return this.$store.state.socialPlatforms.filter((o) => o.type === SocialMediaType.linkedIn).length
    }
  },
  methods: {
    connectLinkedin() {
      this.loading = true;
      try {
        this.setup = true
        const scope = encodeURIComponent(LINKEDINSCOPE);
        const state = this.user._id;
        const url = "https://www.linkedin.com/oauth/v2/authorization?client_id=" +
            LINKEDINCLIENT +
            "&redirect_uri=" + linkedinRedirectURL +
            "&scope=" + scope +
            "&state=" + state +
            "&response_type=code";
        const y = window.top.outerHeight / 2 + window.top.screenY - (670 / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (562 / 2);
        this.connectionWindow = window.open(url, '_blank', `width=562, height=670, top=${y}, left=${x}`);
        const timer = setInterval(async () => {
          console.log(timer)
          if (this.connectionWindow.closed) {
            clearInterval(timer)
            const length = this.$store.state.socialPlatforms.length
            await this.$store.dispatch('getSocialPlatforms')
            if (length >= this.$store.state.socialPlatforms.length) {
              this.$toast.error('You must sign in to linkedin and allow Postredi to integrate.')
            } else {
              this.$toast.success('Linkedin was integrated with Postredi successfully.')
            }
            this.setup = false
          }
        }, 1000)
      } catch (e) {
        this.loading = false;
      }
    },
    focusOnWindow() {
      this.connectionWindow.focus()
    },
    closeWindow() {
      this.setup = false
      this.connectionWindow.close()
    }
  }
}
</script>

<style scoped>

</style>