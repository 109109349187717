<template>
  <div class="pa-6">
    <p v-if="errors.length <= 0">Reconnecting...</p>
    <p v-if="errors.length <= 0">Don't close this window.</p>
    <ul>
      <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
    </ul>
  </div>
</template>

<script>

import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Twitter",
  data() {
    return {
      socialPlatformService: new SocialPlatformService(),
      data: null,
      loading: false,
      SocialMediaType,
      loggedUser: null,
      loggedUserPlatforms: [],
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  methods: {
    async resolveData() {
      try {
        this.loggedUser = this.$store.state.user
        const res = await this.resolveCode()
        if (res)
          this.completeTwitterConnection()
      } catch (e) {
        this.completeTwitterConnection()
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.oauth_token && data.oauth_verifier) {
          await this.$axios.post("/social-platforms/connect/lt", {
            oauth_token: data.oauth_token,
            oauth_verifier: data.oauth_verifier,
            type: SocialMediaType.twitter,
            user: this.loggedUser._id,
            update: true
          });
          return true
        }
      } catch (e) {
        if (e.data.statusCode === 406)
          this.errors.push(e.data.message || 'Some error occured')
        
        return false
      }
    },
    completeTwitterConnection() {
      window.close()
    }
  }
}
</script>

<style scoped>

</style>