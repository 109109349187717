<template>
  <div
      :class="`post-card ${post.status === PostStatus.posted || post.status === PostStatus.failed ? 'posted' :''}`"
      @click="$emit('togglePost', true)" v-if="post && post.socialProfile">
    <div class="post-card-main">
      <div class="post-card-main-content">
        <div class="post-card-main-content-div">
          <div class="post-card-main-content-div-start">
            <div class="post-card-main-content-div-start-account">
              <div class="post-card-main-content-div-start-account-icon">
                <v-icon small class="ma-0" :color="getSocialMediaTypeColor(post.socialProfile.type)">
                  {{ getSocialMediaTypeIcon(post.socialProfile.type) }}
                </v-icon>
              </div>
              <span v-if="post.socialProfile" class="post-card-main-content-div-start-account-name">{{
                  post.socialProfile.username
                }}</span>
            </div>
            <div class="post-card-main-content-div-summary">
              <div class="post-card-main-content-div-summary-text-image"><b>{{
                  formatDate(post.scheduled_date.date, 'hh:mm A')
                }}</b> · {{ post.description }}
              </div>
              <div v-if="!post.media" class="post-card-main-content-div-summary-text-spacer"></div>
              <div v-if="!post.media" class="post-card-main-content-div-summary-text-spacer-gap"></div>
            </div>
            <div v-if="post.media" class="post-card-main-content-div-images-main">
              <div class="post-card-main-content-div-images">
                <div class="post-card-main-content-div-images-grid">
                  <div v-for="(media,i) in post.media.slice(0,3)" :key="i"
                       :class="`post-card-main-content-div-image ${post.media.length >= 3 && i === 0 ? 'row-span' : '' } ${post.media.length === 1 && i === 0 ? 'col-span' : '' }`">
                    <div class="post-card-main-content-div-image-source"
                         :style="`background: url('${media.type.includes('image') ? media.url : media.thumbnail.url}')`"></div>
                    <div v-if="post.media.length > 3 && i === 2" class="post-image-overlay"></div>
                    <span v-if="post.media.length > 3 && i === 2"
                          class="post-image-overlay-text">+{{ post.media.length - 2 }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="post-card-main-content-div-start-meta">
              <div class="post-card-main-content-div-start-meta-div">
                <v-icon small :color="getPostStatusColor(post.status)">{{
                    getPostStatusIcon(post.status)
                  }}
                </v-icon>
                <v-icon v-if="post.media && post.media.length > 0" small color="#504C4D">mdi-image</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDate,
  getPostStatusColor, getPostStatusIcon,
  getSocialMediaTypeColor,
  getSocialMediaTypeIcon,
} from "@/utils/local";

import {PostStatus} from '@/utils/enum';


export default {
  name: "PostCalendarCard",
  props: {
    post: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      PostStatus
    }
  },
  methods: {
    getSocialMediaTypeIcon,
    getSocialMediaTypeColor,
    getPostStatusColor,
    getPostStatusIcon,
    formatDate
  }
}
</script>

<style scoped>

</style>
