<template>
  <div>
    <v-card @click.self.prevent="connectTwitter" :style="!isProfessionalOrFull ? 'opacity: 1' : ''" elevation="1"
            rounded="0"
            :max-width="$vuetify.breakpoint.mdAndUp ? '80%' : '100%'" class="mx-auto text-center pa-4">
      <div
          style="position: absolute; top: 5px; right: 5px; background: #800000;border-top-right-radius: 8px; border-bottom-left-radius: 8px; width: 35px; height: 35px"
          class="pa-2 d-flex justify-center align-center" @click="gotoAccount">
        <div v-if="!isProfessionalOrFull">
          <v-tooltip
              top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="white" small v-bind="attrs"
                      v-on="on">mdi-star
              </v-icon>
            </template>
            <span>Go Premium</span>
          </v-tooltip>
        </div>
        <v-tooltip v-else top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <p class="ma-0 white--text" v-bind="attrs"
               v-on="on">{{ totalPlatforms || 0 }}</p>
          </template>
          <span v-if="totalPlatforms > 0">Total {{ totalPlatforms }} {{ totalPlatforms > 1 ? 'accounts' : 'account' }} connected</span>
          <span v-else>No accounts connected.</span>
        </v-tooltip>
      </div>

      <v-icon @click.self.prevent="connectTwitter" x-large class="mb-2" :color="color">mdi-twitter</v-icon>
      <h4 @click.self.prevent="connectTwitter" class="text-center" v-if="$vuetify.breakpoint.mdAndUp">Twitter</h4>
    </v-card>
    <v-dialog v-model="setup" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'" persistent>
      <v-card class="pa-4">
        <div class="ma-4">
          <p class="ma-0 text-h4 mb-3">Social account setup still in progress</p>
          <p class="ma-0 mb-3"> There are still a few steps remaining to connect your social account to Postredi.</p>
          <div class="d-flex justify-end">
            <v-btn elevation="0" @click="closeWindow" class="mr-3" tile>Cancel</v-btn>
            <v-btn elevation="0" color="primary" @click="focusOnWindow" tile>Resume Setup</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {twitterRedirectURL} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";

export default {
  name: "TwitterConnector",
  props: {
    color: String
  },
  data() {
    return {
      connectionWindow: null,
      setup: false,
    }
  },
  watch: {
    connectionWindow(newVal) {
      console.log(newVal, 'Connection Window')
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isProfessionalOrFull() {
      return !!(this.user.subscription
          &&
          (
              this.$store.state.plan &&
              (
                  this.$store.state.plan.name === 'Professional' ||
                  this.$store.state.plan.name === 'Done for You - Full Service'
              )
          ))
    },
    totalPlatforms() {
      return this.$store.state.socialPlatforms.filter((o) => o.type === SocialMediaType.twitter).length
    }
  },
  methods: {
    gotoAccount() {
      this.$store.commit('setOpenSocialConnector', false)
      this.$router.push('/account').catch(() => {
      });
    },
    async connectTwitter() {
      if (this.isProfessionalOrFull) {
        this.loading = true;
        try {
          this.setup = true
          const token = await this.$axios.get("/social-platforms/twitter-request-token?callback_url=" + twitterRedirectURL);
          const url = "https://api.twitter.com/oauth/authorize?oauth_token=" + token.data.access_token;
          const y = window.top.outerHeight / 2 + window.top.screenY - (670 / 2);
          const x = window.top.outerWidth / 2 + window.top.screenX - (562 / 2);
          this.connectionWindow = window.open(url, '_blank', `width=562, height=670, top=${y}, left=${x}`);

          const timer = setInterval(async () => {
            console.log(timer)
            if (this.connectionWindow && this.connectionWindow.closed) {
              clearInterval(timer)
              const length = this.$store.state.socialPlatforms.length
              await this.$store.dispatch('getSocialPlatforms')
              if (length >= this.$store.state.socialPlatforms.length) {
                this.$toast.error('You must sign in to twitter and allow Postredi to integrate.')
              } else {
                this.$toast.success('Twitter was integrated with Postredi successfully.')
              }
              this.setup = false
            }

            if (!this.connectionWindow) {
              clearInterval(timer)
              this.$toast.error('You must sign in to twitter and allow Postredi to integrate.')
              this.setup = false
            }
          }, 1000)
        } catch (e) {
          this.loading = false;
          this.setup = false
          this.$toast.error('Twitter could not be connected. Try again later.')
        }
      } else {
        this.$toast.warning('Kindly upgrade your account to add Twitter profile.')
      }
    },
    focusOnWindow() {
      this.connectionWindow.focus()
    },
    closeWindow() {
      this.setup = false
      this.connectionWindow.close()
    }
  }
}
</script>

<style scoped>

</style>