<template>
  <section :class="[isSubmitted ? 'flex-wrapper' : 'wrapper']"
           :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
    <section v-if="!isSubmitted" class="content">
      <div class="head">
        <h1 class="head__heading">{{ title }}</h1>
        <h2 class="head__subheading"> {{ subtitle }} </h2>
      </div>

      <div class="information">
        <div class="information__item">
          <svg class="information__item-icon" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
          </svg>
          <p class="information__item-text"> {{ organizationEmail }} </p>
        </div>

        <div v-if="organizationAddress" class="information__item">
          <svg class="information__item-icon" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd"
                  d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                  fill-rule="evenodd"></path>
          </svg>
          <p class="information__item-text">{{ organizationAddress }}</p>
        </div>


        <div class="information__item">
          <svg class="information__item-icon" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
          </svg>
          <p class="information__item-text"> {{ organizationPhone }} </p>
        </div>
      </div>

      <div class="card">
        <form action="#" class="form">
          <h1 class="form__heading">Contact Form</h1>

          <div class="form__content">
            <div class="form__left">
              <div class="form__item">
                <label class="form__label" for="first_name">First Name</label>
                <input id="first_name" v-model="firstName" :disabled="loading" class="form__input"
                       placeholder="Enter First Name" type="text">
                <span v-if="errors.firstName" class="error-text" v-html="errors.firstName"/>
              </div>

              <div class="form__item">
                <label class="form__label" for="last_name">Last Name</label>
                <input id="last_name" v-model="lastName" :disabled="loading" class="form__input"
                       placeholder="Enter Last Name" type="text">
                <span v-if="errors.lastName" class="error-text" v-html="errors.lastName"/>
              </div>

              <div class="form__item">
                <label class="form__label" for="email">Email</label>
                <input id="email" v-model="email" :disabled="loading" class="form__input" placeholder="Enter Email"
                       type="text">
                <span v-if="errors.email" class="error-text" v-html="errors.email"/>
              </div>

              <div class="form__item">
                <label class="form__label" for="phone">Phone</label>
                <input id="Phone" v-model="phone" :disabled="loading" class="form__input" placeholder="Enter Phone"
                       type="number">
                <span v-if="errors.phone" class="error-text" v-html="errors.phone"/>
              </div>

              <div class="form__item">
                <label class="form__label" for="meeting_date">Meeting Date</label>
                <input id="meeting_date" v-model="date" :disabled="loading" class="form__input" placeholder="Enter Date"
                       type="date">
                <span v-if="errors.date" class="error-text" v-html="errors.date"/>
              </div>
            </div>

            <div class="form__right">
              <div class="form__item">
                <label class="form__label" for="message">Message</label>
                <textarea id="message" v-model="message" :disabled="loading" class="form__textarea">
                </textarea>
                <span v-if="errors.message" class="error-text" v-html="errors.message"/>
              </div>
            </div>
          </div>

          <div class="form__footer">
            <button class="form__submit" type="button" @click="submit">
              Send Message
            </button>
          </div>

        </form>
      </div>
    </section>

    <div v-else class="pa-16 d-flex flex-column align-center rounded"
         style="background-color: white;max-width: 700px; max-height: 500px;">
      <h1 class="mb-6 text-sm-h3 text-center">Thank You!</h1>
      <p class="text-center">Thanks for getting in touch with us. We have received your message and will contact you
        shortly.</p>
      <img alt="thank-you" height="30%" src="@/assets/thankyou.svg" width="40%"/>
    </div>

  </section>
</template>
<script>
import {LeadsService} from "@/services/leads-service";
import moment from "moment/moment";
import {
  templateDefaultBackgroundImage,
  templateDefaultOrganizationAddress,
  templateDefaultOrganizationEmail,
  templateDefaultOrganizationName,
  templateDefaultOrganizationPhone,
  templateDefaultSubtitle,
  templateDefaultTitle
} from "@/utils/local";

export default {
  name: "lead5",

  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    personId: {
      type: String,
      default: null
    },
  },

  data: () => ({
    title: "",
    subtitle: "",
    backgroundImage: null,
    themeColor: "",
    templateId: null,

    organizationName: null,
    organizationLogo: null,
    organizationPhone: null,
    organizationEmail: null,
    organizationAddress: null,

    service: new LeadsService(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: "",
    message: "",

    errors: {},
    loading: false,
    isSubmitted: false,
  }),

  watch: {
    firstName(val) {
      if (val !== "" && val.length < 3) {
        this.errors.firstName = "First Name must be at least 3 characters long";
      } else {
        this.errors.firstName = "";
      }
    },

    lastName(val) {
      if (val !== "" && val.length < 3) {
        this.errors.lastName = "Last Name must be at least 3 characters long";
      } else {
        this.errors.lastName = "";
      }
    },

    email(val) {
      if (val !== "" && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/.test(val) === false) {
        this.errors.email = "Email is not valid";
      } else {
        this.errors.email = "";
      }
    },

    phone(val) {
      if (val !== "" && val.length < 6) {
        this.errors.phone = "Phone must be at least 6 characters long";
      } else {
        this.errors.phone = "";
      }
    },

    date(val) {
      if (val !== "" && moment().isSameOrAfter(val)) {
        this.errors.date = "Date must be in the future";
      } else if(/(^\d{4})-(0?[1-9]|1[0-2])-(0?[0-9]|[12]\d|30|31)$/.test(val) === false) {
        this.errors.date = "Date is not valid";
      } else {
        this.errors.date = "";
      }
    },

    message(val) {
      if (val !== "" && val.length < 3) {
        this.errors.message = "message must be at least 3 characters long";
      } else {
        this.errors.message = "";
      }
    },
  },


  async mounted() {
    await this.loadData();
  },

  methods: {

    validate() {
      this.errors = {};

      if (!this.firstName) {
        this.errors.firstName = "First Name is required";
      }

      if (!this.lastName) {
        this.errors.lastName = "Last Name is required";
      }

      if (!this.email) {
        this.errors.email = "Email is required";
      }

      if (!this.phone) {
        this.errors.phone = "Phone is required";
      }

      if (!this.date) {
        this.errors.date = "Date is required";
      }

      if (!this.message) {
        this.errors.message = "Message is required";
      }

      return Object.keys(this.errors).length === 0;
    },

    async submit() {
      if (this.enabled) {

        if (this.errors.firstName || this.errors.lastName || this.errors.email || this.errors.phone || this.errors.date || this.errors.message) {
          return;
        }

        if (!this.validate()) return;

        try {
          this.loading = true
          this.isSubmitted = false

          const data = {
            email: this.email,
            name: this.firstName + (this.lastName ? ' ' + this.lastName : ''),
            phone: this.phone,
            note: this.message,
            date: this.date,
            person: this.personId
          }
          await this.service.create(data)
          this.loading = false
          this.isSubmitted = true
        } catch (e) {
          this.loading = false
          this.errors.submit = e?.response?.data?.message || e?.data?.message || e?.error || 'Some error occurred'
        }

      }
    },


    async loadData() {
      if (!this.enabled) {
        this.personId = this.$store.state.user._id;
      }

      this.loadingMessage = 'Loading...';
      this.loading = true
      this.error = false
      let slug;
      if (this.$route.path.split('/').length === 2) {
        slug = this.$route.path.split('/').slice(1);
      }
      const data = slug? (await this.$axios.get('/persons/slug/' + slug )).data : (await this.$axios.get('/persons/' + this.personId)).data;
      const template = data?.selected_template;

      this.title = template?.form_title || templateDefaultTitle;
      this.subtitle = template?.form_subtitle || templateDefaultSubtitle;
      this.backgroundImage = template?.background_image || templateDefaultBackgroundImage;

      this.organizationName = data.organizationName || templateDefaultOrganizationName;
      this.organizationPhone = data.phone || templateDefaultOrganizationPhone;
      this.organizationEmail = data.organizationEmail ? data?.organizationEmail : data?.username || templateDefaultOrganizationEmail;
      this.organizationLogo = data.organizationLogo;
      this.organizationAddress = data.organizationAddress || templateDefaultOrganizationAddress;

      this.loading = false
    },
  }
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-size: cover;
  background-position: center;
}

.flex-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.head {
  text-align: center;
}

.head__heading {
  font-size: 26px;
  font-weight: 700;
  color: white;
}

.head__subheading {
  width: 80%;
  margin: auto auto;
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.information {
  width: 100%;
  max-width: 800px;
  display: flex;
  padding: 10px 0;
  margin: 10px 0;
  justify-content: space-evenly;
}

.information__item {
  min-width: 20%;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.information__item-icon {
  height: 20px;
  width: 20px;
  margin-bottom: 10px;
  color: white;
}

.information__item-text {
  text-align: center;
  font-size: 12px;
  color: white;
}

.information__item:hover {
  cursor: pointer;
  background-color: coral;
}

.card {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.form {
  width: 90%;
  text-align: left;
}

.form__heading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.form__content {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 30px;
}

.form__left {
  width: 50%;
  padding-right: 20px;
}

.form__right {
  width: 50%;
}

.form__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form__label {
  font-size: 12px;
  color: gray;
  font-weight: 300;
  margin-bottom: 5px;
}

.form__textarea {
  height: 245px;
  background-color: #F2F4F4;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  resize: none;
}

.form__input {
  background-color: #F2F4F4;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
}

.error-text {
  color: red;
  font-size: 0.8rem;
  margin-top: 3px;
}

.form__textarea:focus, .form__input:focus {
  outline: 1px solid teal;
  border: 1px solid teal;
}

.form__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.form__submit {
  margin: 10px 0;
  background-color: coral;
  color: white;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 10px;
  transition: all .2s;
}

.form__submit:active {
  transform: scale(.9);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
}

@media screen and (max-width: 786px) {
  .head {
    margin-top: 10px;
  }

  .card {
    border-radius: 0;
  }

  .content {
    padding: 0;
  }

  .head__subheading {
    font-size: 16px;
  }

  .information {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .information__item {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  .information__item-text {
    margin-bottom: 0;
  }

  .form__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .form__left {
    width: 100%;
  }

  .form__right {
    width: 100%;
  }

}


</style>