<template>
  <lead1 v-if="template_id === 'lead1'" :personId="userId"></lead1>
  <lead2 v-else-if="template_id === 'lead2'" :personId="userId"></lead2>
  <lead3 v-else-if="template_id === 'lead3'" :personId="userId"></lead3>
  <lead4 v-else-if="template_id === 'lead4'" :personId="userId"></lead4>
  <lead5 v-else-if="template_id === 'lead5'" :personId="userId"></lead5>
</template>

<script>

import {LeadsService} from "@/services/leads-service";
import Lead1 from "@/components/lead-pages-templates/lead1";
import Lead2 from "@/components/lead-pages-templates/lead2";
import Lead3 from "@/components/lead-pages-templates/lead3";
import Lead4 from "@/components/lead-pages-templates/lead4";
import Lead5 from "@/components/lead-pages-templates/lead5";

export default {
  name: "landing",

  components: {Lead1, Lead2, Lead3, Lead4, Lead5,},

  data: () => ({
    template_id: null,
    service: new LeadsService(),
    userId: null,
  }),
  async mounted() {
    await this.getPerson()
  },

  methods: {
    async getPerson() {
      try {
        if (!this.$route.params.id) {
          window.location.href = 'https://postredi.com'
        }
        let res = (await this.$axios.get('/persons/slug/' + this.$route.params.id)).data
        this.userId = res._id;

        if (!this.userId){
          window.location.href = 'https://postredi.com'
          return
        }

        this.template_id = res.selected_template?.template_id

        // Default Template
        if (!this.template_id) {
          this.template_id = 'lead1'
        }
      } catch (e) {
        window.location.href = 'https://postredi.com'
      }
    },
  }
}
</script>

<style scoped>

</style>