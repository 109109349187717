import store from '../plugins/vuex'
import moment from "moment-timezone";
import {PostStatus, SocialMediaType} from "@/utils/enum";


/*--------------------------------------------
   ENV Configs
-------------------------------------------- */

console.log('node_env: ', process.env.NODE_ENV)

// if node_env is development, local url will be used
export const baseUrl = process.env.VUE_APP_BASE_URL;
export const webAppUrl = process.env.NODE_ENV !== 'development' ? "https://app" + baseUrl : process.env.VUE_APP_FULL_LOCAL_URL;
export const apiURL = process.env.NODE_ENV !== 'development' ? "https://api" + baseUrl : process.env.VUE_APP_FULL_LOCAL_URL;

// just for check
// console.log('baseUrl: ', baseUrl)
// console.log('webAppUrl: ', webAppUrl)
// console.log('apiURL: ', apiURL)

export const fbRedirectURL = webAppUrl + "/social/facebook";
export const instaRedirectURL = webAppUrl + "/social/instagram";
export const twitterRedirectURL = webAppUrl + "/social/twitter";
export const linkedinRedirectURL = webAppUrl + "/social/linkedin";
export const fbRedirectURLReconnect = webAppUrl + "/social/facebook/reconnect";
export const instaRedirectURLReconnect = webAppUrl + "/social/instagram/reconnect";
export const twitterRedirectURLReconnect = webAppUrl + "/social/twitter/reconnect";
export const linkedinRedirectURLReconnect = webAppUrl + "/social/linkedin/reconnect";

// #Facebook Live
export const FBAPPID = "766139154367310";
export const FBSCOPE = "email,pages_show_list,business_management,publish_to_groups,pages_read_engagement,pages_read_user_content,pages_manage_posts,publish_video";
// export const FBSCOPE = 'email,pages_show_list,pages_manage_posts'

// #Instagram
export const INSTAGRAMSCOPE = "pages_show_list,instagram_basic,instagram_content_publish";

// #Twitter
// Not needed on my side

// #Linked In
export const LINKEDINCLIENT = "86b9ym4i3zo0il";
export const LINKEDINSCOPE = "r_liteprofile,w_member_social";

// Stripe
export const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
export const STRIPE_SECRET_KEY = process.env.VUE_APP_STRIPE_PK;
export const STRIPE_ACCOUNT_ID = process.env.VUE_APP_STRIPE_ACCOUNT_ID;

/*--------------------------------------------
   Lead Templates
-------------------------------------------- */

export const templateDefaultTitle = "Say Hi";
export const templateDefaultSubtitle =
    "What kind of problems are you tackling right now? Have any questions you’d like to ask?";
export const templateDefaultBackgroundImage =
    "https://images.unsplash.com/photo-1530305408560-82d13781b33a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80";
export const templateDefaultOrganizationName = "Postredi";
export const templateDefaultOrganizationEmail = "help@gmail.com";
export const templateDefaultOrganizationAddress =
    "2571 Lafeuille Ave, Ste 1 Cincinnati, Ohio 45211";
export const templateDefaultOrganizationPhone = "+1 201 555 0128";
export const templates = [
    {
        id: "lead1",
        image: require("../assets/leads/lead1.png"),
    },
    {
        id: "lead2",
        image: require("../assets/leads/lead2.png"),
    },
    {
        id: "lead3",
        image: require("../assets/leads/lead3.png"),
    },
    {
        id: "lead4",
        image: require("../assets/leads/lead4.png"),
    },
    {
        id: "lead5",
        image: require("../assets/leads/lead5.png"),
    },
];


/*--------------------------------------------
   Functions
-------------------------------------------- */

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function getUserDB() {
    const token = localStorage.getItem("auth_token");
    if (token) {
        const user = (await this.$axios.get("/auth/profile")).data;
        localStorage.setItem("auth_user", JSON.stringify(user));
        return user;
    }
}

export function formatDate(date, format = "MM/DD/YYYY") {
    return moment(date).tz(getTimezone()).format(format);
}

function getTimezone() {
    const timezone = store.state.timezone;
    return timezone.split(" ")[1];
}

export function getSocialMediaTypeIcon(type) {
    if (
        type === SocialMediaType.facebook ||
        type === SocialMediaType.facebookPage ||
        type === SocialMediaType.facebookGroup
    ) {
        return "mdi-facebook";
    } else if (type === SocialMediaType.twitter) {
        return "mdi-twitter";
    } else if (
        type === SocialMediaType.instagram ||
        type === SocialMediaType.instagramBusiness
    ) {
        return "mdi-instagram";
    } else if (type === SocialMediaType.linkedIn) {
        return "mdi-linkedin";
    } else if (type === SocialMediaType.youtubeChannel) {
        return "mdi-youtube";
    } else if (type === SocialMediaType.pinterest) {
        return "mdi-pinterest";
    }
}

export function getSocialMediaTypeTitle(type) {
    if (
        type === SocialMediaType.facebook ||
        type === SocialMediaType.facebookPage ||
        type === SocialMediaType.facebookGroup
    ) {
        return "Facebook";
    } else if (type === SocialMediaType.twitter) {
        return "Twitter";
    } else if (
        type === SocialMediaType.instagram ||
        type === SocialMediaType.instagramBusiness
    ) {
        return "Instagram";
    } else if (type === SocialMediaType.linkedIn) {
        return "LinkedIn";
    } else if (type === SocialMediaType.youtubeChannel) {
        return "Youtube";
    } else if (type === SocialMediaType.pinterest) {
        return "Pinterest";
    }
}

export function getSocialMediaTypeIndividualTitle(type) {
    if (type === SocialMediaType.facebook) {
        return "Facebook";
    } else if (type === SocialMediaType.facebookPage) {
        return "Facebook Page";
    } else if (type === SocialMediaType.facebookGroup) {
        return "Facebook Group";
    } else if (type === SocialMediaType.twitter) {
        return "Twitter";
    } else if (type === SocialMediaType.instagram) {
        return "Instagram";
    } else if (type === SocialMediaType.instagramBusiness) {
        return "Instagram Business";
    } else if (type === SocialMediaType.linkedIn) {
        return "LinkedIn";
    } else if (type === SocialMediaType.youtubeChannel) {
        return "Youtube";
    } else if (type === SocialMediaType.pinterest) {
        return "Pinterest";
    }
}

export function getSocialMediaTypeImage(type) {
    if (
        type === SocialMediaType.facebook ||
        type === SocialMediaType.facebookPage ||
        type === SocialMediaType.facebookGroup
    ) {
        return "facebook.png";
    } else if (type === SocialMediaType.twitter) {
        return "twitter.png";
    } else if (
        type === SocialMediaType.instagram ||
        type === SocialMediaType.instagramBusiness
    ) {
        return "instagram.png";
    } else if (type === SocialMediaType.linkedIn) {
        return "linkedIn.png";
    } else if (type === SocialMediaType.youtubeChannel) {
        return "youtube.png";
    } else if (type === SocialMediaType.pinterest) {
        return "pinterest.png";
    }
}

export function getSocialMediaTypeColor(type) {
    if (
        type === SocialMediaType.facebook ||
        type === SocialMediaType.facebookPage ||
        type === SocialMediaType.facebookGroup
    ) {
        return "#1878f3";
    } else if (type === SocialMediaType.twitter) {
        return "#1ca1f2";
    } else if (
        type === SocialMediaType.instagram ||
        type === SocialMediaType.instagramBusiness
    ) {
        return "#d93176";
    } else if (type === SocialMediaType.linkedIn) {
        return "#0b66c3";
    } else if (type === SocialMediaType.youtubeChannel) {
        return "#fe0000";
    } else if (type === SocialMediaType.pinterest) {
        return "#e30828";
    }
}

export function getSocialMediaTypeCount(type) {
    if (
        type === SocialMediaType.facebook ||
        type === SocialMediaType.facebookPage ||
        type === SocialMediaType.facebookGroup
    ) {
        return 2000;
    } else if (type === SocialMediaType.twitter) {
        return 280;
    } else if (
        type === SocialMediaType.instagram ||
        type === SocialMediaType.instagramBusiness
    ) {
        return 2200;
    } else if (type === SocialMediaType.linkedIn) {
        return 3000;
    } else if (type === SocialMediaType.youtubeChannel) {
        return 1000;
    } else if (type === SocialMediaType.pinterest) {
        return 1000;
    }
}

export function getPostStatusIcon(status) {
    if (status === PostStatus.scheduled) {
        return "mdi-calendar";
    } else if (status === PostStatus.draft) {
        return "mdi-file-document";
    } else if (status === PostStatus.failed) {
        return "mdi-close-circle";
    } else if (status === PostStatus.posted) {
        return "mdi-check-circle";
    } else if (status === PostStatus.pending) {
        return "mdi-clock";
    }
}

export function getPostStatus(status) {
    if (status === PostStatus.scheduled) {
        return "Scheduled";
    } else if (status === PostStatus.draft) {
        return "Drafted";
    } else if (status === PostStatus.failed) {
        return "Failed";
    } else if (status === PostStatus.posted) {
        return "Published";
    } else if (status === PostStatus.pending) {
        return "Processing";
    }
}

export function getPostStatusColor(status) {
    if (status === PostStatus.scheduled) {
        return "#543D80";
    } else if (status === PostStatus.draft || status === PostStatus.pending) {
        return "#504c4d";
    } else if (status === PostStatus.failed) {
        return "red";
    } else if (status === PostStatus.posted) {
        return "green";
    }
}

export function formatTimeIndex(i) {
    if (i > 0 && i < 12) return i + " AM";
    else if (i === 0) {
        return 12 + " AM";
    } else if (i === 12) {
        return 12 + " PM";
    } else {
        return i - 12 + " PM";
    }
}

export function getDay(day) {
    switch (day) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
}

export function compareDates(first, second) {
    return moment(first).tz(getTimezone()).isSame(moment(second).tz(getTimezone()), 'day')
}

export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
        );

    return bytes.toFixed(dp) + " " + units[u];
}

export function currentTimezone() {
    const timezone = moment.tz.guess();
    return (
        moment.tz(timezone).format("[(GMT]Z[)]").replace(":00", "") + " " + timezone
    );
}

export function getTimezones() {
    const timezone = moment.tz.names();
    const adjustedTimezones = timezone.map((el) => {
        return moment.tz(el).format("[(GMT]Z[)]").replace(":00", "") + " " + el;
    });
    return adjustedTimezones;
}

export const firstPromoterDashboard = 'https://postredi.firstpromoter.com/login'