<template>
  <v-app dark>
    <div class="error-main">
      <div class="error-bg"></div>
      <v-card class="pa-8 error-card">
        <div>
          <h1>Oh snap!</h1>
          <h2 class="mb-3 primary--text">There was an unexpected error.</h2>
          <p>Use button below to return to home.</p>
        </div>
        <v-btn elevation="0" color="primary" to="/">
          Return to home
        </v-btn>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "error"
}
</script>

<style scoped>
.error-main {
  position: relative;
  width: 100%;
  height: 100vh;
}
.error-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.error-bg {
  background-color: #ffffff;
  opacity: 0.1;
  background-image: linear-gradient(135deg, #2177b0 25%, transparent 25%), linear-gradient(225deg, #2177b0 25%, transparent 25%), linear-gradient(45deg, #2177b0 25%, transparent 25%), linear-gradient(315deg, #2177b0 25%, #ffffff 25%);
  background-position: 40px 0, 40px 0, 0 0, 0 0;
  background-size: 80px 80px;
  background-repeat: repeat;
}
.error-bg > * {
  opacity: 1 !important;
}
.error-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 700px;
}
@media screen and (min-width: 550px) {
  .error-card {
    width: 50%;
    height: 250px;
  }
}
</style>