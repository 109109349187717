<template>
  <v-dialog :value="value"
            :width="$vuetify.breakpoint.xl ? '60%' : '90%'"
            persistent
            @input="closeDialog"
  >
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <h3>Media Items</h3>
            <v-avatar class="pointer" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="12" style="max-height: 500px; overflow-y: hidden">
          <!--Image Cropper-->
          <!--image cropper for profile pic-->
          <cropper
              v-if="prfilePicCropper"
              :src="media ? media.url : ''"
              stencil-component="circle-stencil"
              style="height: 350px; margin: 0 auto; display: block"
              @change="change"
          />
          <cropper
              v-else
              :src="media ? media.url : ''"
              stencil-component="circle-stencil"
              :stencil-props="{
                aspectRatio: 16/9
              }"
              style="height: 350px; margin: 0 auto; display: block"
              @change="change"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-end" style="gap: 15px">
            <v-btn color="primary" elevation="0" outlined
                   @click="savesChanges"
            >
              Save Changes
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "ImageCropper",
  components: {
    Cropper
  },
  model: {
    value: 'value',
    event: 'value'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    media: {
      type: Object,
      default: null,
    },
    prfilePicCropper: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mediaIndex: null,
      updatedMedia: null,
    };
  },
  watch: {
    media(newVal) {
      if (newVal) {
        this.mediaIndex = newVal.index;
      }
    }
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    change({canvas}) {
      let file = this.dataURLtoFile(canvas.toDataURL(), this.media.name);
      this.updatedMedia = {
        file,
        index: this.mediaIndex
      };
    },
    closeDialog() {
      this.$emit('onClose', false);
    },
    savesChanges() {
      this.$emit('onSave', this.updatedMedia);
    }
  },

}
</script>

<!--<style scoped>-->
<!--::v-deep [data-v="0807d9e2"]{-->
<!--  z-index: 500 !important;-->
<!--}-->
<!--</style>-->