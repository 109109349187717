<template>
    <div>
        <CalendarViewWeek
                v-if="$vuetify.breakpoint.mdAndUp"
                :social-media-profiles="socialMediaProfiles"
                :get-week-check="getWeek"
                @openPostForm="openPostForm"
                @openPostEditForm="openPostEditForm"
                :close-post="closePost"
        />
        <PostsMobileView
                v-else
                :social-media-profiles="socialMediaProfiles"
                @openPostForm="openPostForm"
                @openPostEditForm="openPostEditForm"
                :close-post="closePost"
        />
        <PostForm
                v-model="postForm"
                :social-media-profiles="socialMediaProfiles"
                :selected-schedule="selectedSchedule"
                @getWeek="getWeek = !getWeek"
        />
        <PostEditForm
                v-model="postEditForm"
                :old-post="oldPost"
                :social-media-profiles="socialMediaProfiles"
                :selected-schedule="selectedSchedule"
                @getWeek="getWeek = !getWeek"
                @closePost="closePost = true"
        />
    </div>
</template>

<script>
import PostForm from "@/components/posts/PostForm";
import CalendarViewWeek from "@/components/CalendarViewWeek";
import {compareDates} from "@/utils/local";
import moment from "moment-timezone";
import {SocialPlatformService} from "@/services/social_platform-service";
import PostsMobileView from "@/components/posts/PostsMobileView";
import PostEditForm from "@/components/posts/PostEditForm";
import {currentTimezone} from "@/utils/local";

export default {
    name: "Publisher",
    components: {PostEditForm, PostsMobileView, PostForm, CalendarViewWeek},
    data() {
        return {
            drawer: false,
            postForm: false,
            selectedSchedule: {},
            oldPost: null,
            socialPlatformService: new SocialPlatformService(),
            getWeek: false,
            postEditForm: false,
            closePost: false,
        };
    },
    beforeMount() {
        if (!localStorage.getItem("Timezone")) {
            this.$store.commit("setTimezone", this.currentTimezone());
            localStorage.setItem("Timezone", currentTimezone());
        }

        this.$store.commit("setShowWelcomePage", false);
    },
    mounted() {
      if(this.getAiResponse) {
          this.openPostForm();
      }
    },
    computed: {
        // use for watching state in watch
        openPostFormModal() {
            return this.$store.state.openGenerateIdeaModal;
        },
        socialMediaProfiles() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            let profiles = this.$store.state.socialPlatforms.sort(function (a, b) {
                return (
                    a.username.localeCompare(b.username) && a.type.localeCompare(b.type)
                );
            });

            return profiles.filter((p) => !p.isReAuthRequired);
        },
        getAiResponse() {
            return this.$store.state.aiResponse;
        }
    },
    watch: {
        openPostFormModal(val) {
            if (val) {
                this.openPostForm();
            }
        },
        postEditForm(newVal) {
            if (!newVal) this.oldPost = null;
        },
    },
    methods: {
        compareDates,
        currentTimezone,

        openPostForm(event) {
            if (event) {
                this.selectedSchedule = {};
                this.selectedSchedule.scheduleDate = event.day.fullDate;
                if (event.time.index > 0 && event.time.index <= 12)
                    this.selectedSchedule.scheduleHour = event.time.index;
                else if (event.time.index === 0)
                    this.selectedSchedule.scheduleHour = 12;
                else this.selectedSchedule.scheduleHour = event.time.index - 12;
                if (
                    this.isActive(this.selectedSchedule.scheduleDate) &&
                    this.currentDivider(event.day, event.time.index)
                ) {
                    this.selectedSchedule.scheduleMin = new Date().getMinutes();
                } else {
                    this.selectedSchedule.scheduleMin = 0;
                }
                this.selectedSchedule.scheduleTimezone =
                    event.time.timeText.split(" ")[1];
            } else {
                this.selectedSchedule = null;
            }
            this.postForm = true;
            // this.postEditForm = true
        },
        openPostEditForm(event) {
            if (event) {
                this.oldPost = event;
                this.selectedSchedule = {};
                this.selectedSchedule.scheduleDate = event.scheduled_date.fullDate;
                if (event.scheduled_date.hour > 0 && event.scheduled_date.hour <= 12)
                    this.selectedSchedule.scheduleHour = event.scheduled_date.hour;
                else if (event.scheduled_date.hour === 0)
                    this.selectedSchedule.scheduleHour = 12;
                else
                    this.selectedSchedule.scheduleHour = event.scheduled_date.hour - 12;
                this.selectedSchedule.scheduleMin = event.scheduled_date.minute;
                this.selectedSchedule.scheduleTimezone = event.scheduled_date.timezone;
                this.postEditForm = true;
                this.closePost = false;
            }
        },
        currentDivider(day, i) {
            const currentDate = moment();
            const currentHour = currentDate.hour();
            const date = moment(day.fullDate);
            if (date.isSame(currentDate, "day")) {
                if (i === currentHour) return true;
            }

            return false;
        },
        isActive(date) {
            return this.compareDates(date, new Date());
        },
    },
};
</script>

<style scoped></style>
