<template>
  <div style="width: 100%">
    <div class="profile-view__popup">
      <div class="profile-view__image mb-2">
        <div class="profile-view__letter">{{ resolveLetter() }}</div>
      </div>

      <p id="display-name">{{ this.user.name || "Name" }}</p>
      <p id="username">{{ this.user.username || "Email" }}</p>

      <v-divider class="my-2"/>

      <v-list-item
          to="/"
          dense
          exact
          class="route"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-calendar'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Publisher</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          to="/leads"
          dense
          exact
          class="route"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-group'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Leads</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          to="/social-platforms"
          dense
          exact
          class="route"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-bullhorn'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Social Platforms</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-dialog v-model="timezoneDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
              v-bind="attrs"
              v-on="on"
              @click="timezoneDialog = true"
              to=""
              dense
              exact
              class="route"
              color="#da57a7"
              active-class="route--active"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-clock'"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Set Timezone</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-card>
          <v-col class="d-flex align-center justify-space-between">
            <v-card-title class="text-h5"> Time Zone</v-card-title>

            <v-btn color="primary" text @click="closeTzDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>

          <v-divider></v-divider>

          <v-col class="d-flex justify-center align-center">
            <v-card-text>Your Timezone is:</v-card-text>
            <v-card-text>{{ this.getTimezone() }}</v-card-text>
          </v-col>

          <v-col>
            <v-select
                v-model="timezone"
                outlined
                :items="timezones"
                item-text="name"
                item-value="_id"
                label="Timezones"
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                        v-model="searchtz"
                        placeholder="Search Timezone"
                        @input="searchTimezone"
                        outlined
                    ></v-text-field>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-select>
          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="setTimezone"> Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list-item
          to="/account"
          dense
          exact
          class="route"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-credit-card'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          to="/promoter"
          dense
          exact
          class="route"
          v-if="user.promoter"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-tie-voice'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Promoter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          @click="$emit('openPromoter', true)"
          dense
          v-else
          exact
          class="route"
          color="#da57a7"
          active-class="route--active"
      >
        <v-list-item-icon>
          <v-icon v-text="'mdi-account-tie-voice'"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Become a Promoter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"/>
    </div>
  </div>
</template>

<script>
import {currentTimezone, getTimezones} from "@/utils/local";

export default {
  name: "ProfileDrawer",
  data() {
    return {
      timezone: "",
      timezoneDialog: false,
      searchtz: "",
      timezones: getTimezones(),
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    getTimezones,
    currentTimezone,

    closeTzDialog() {
      this.timezoneDialog = false;
      this.timezone = "";
    },

    searchTimezone() {
      if (this.searchtz != "") {
        this.timezones = this.timezones.filter((timezone) => {
          return this.searchtz
              .toLowerCase()
              .split(" ")
              .every((v) => timezone.toLowerCase().includes(v));
        });
      } else {
        this.timezones = getTimezones();
      }
    },

    setTimezone() {
      this.$store.commit("setTimezone", this.timezone);
      localStorage.setItem("Timezone", this.timezone);
      this.timezoneDialog = false;
      location.reload();
    },

    getTimezone() {
      if (localStorage.getItem("Timezone")) {
        return localStorage.getItem("Timezone");
      } else {
        localStorage.setItem("Timezone", currentTimezone());
        return localStorage.getItem("Timezone");
      }
    },

    resolveLetter() {
      if (this.user && this.user.name) {
        return this.user.name[0];
      } else {
        return "_";
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/sign-in").catch(() => {
      });
    },
    openEditForm() {
      this.profileMenu = false;
      this.profileForm = true;
      this.$emit("closeDrawer", true);
    },
    openSecurityForm() {
      this.profileMenu = false;
      this.securityForm = true;
      this.$emit("closeDrawer", true);
    },
  },
};
</script>

<style lang="sass" scoped>
.profile-view
  width: 100%
  display: flex
  align-items: center
  background: #f5c187
  justify-content: center

  &__letter
    font-size: 22px
    color: rgba(white, 1)
    font-family: google-sans, sans-serif

  &__image
    width: 80px
    height: 80px
    background: #2177b0
    margin: 0 auto
    border-radius: 40px !important
    display: flex
    justify-content: center
    align-items: center

  &__popup
    width: 100%
    display: flex
    text-align: center
    flex-direction: column

    &__actions
      margin-top: 10px
      display: flex

#display-name
  margin: 0
  font-size: 16px
  font-weight: bold

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active i
    color: inherit

  &--active
    color: #2177b0
</style>
