<template>
    <v-dialog @click:outside="$emit('value', false)" :value="flag" :width="$vuetify.breakpoint.xl ? '60%' : '90%'">

        <v-card class="px-12 py-8 rounded">

            <v-row>
                <v-col cols="12">
                    <div class="d-flex justify-space-between align-center">
                        <h3>{{ aiResponse || aiResponse !== '' ? 'Generated Post' : 'Categories of Post' }}</h3>
                        <v-avatar class="pointer" @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-avatar>
                    </div>
                </v-col>
            </v-row>

            <RadioListWithIcon v-if="!aiResponse"
                               :items="postCategoriesOptions"
                               :selected-item="selectedCategory"
                               :on-selection="onSelectCategory"
            />

            <!-- ChatGpt Response-->
            <div v-if="aiResponse">
                <v-card class="d-flex my-4 px-4 py-2 gap-20" color="#e5e7eb" elevation="0">
                    <div class="text-center">
                        <v-avatar
                                tile
                                color="blue"
                                class="avatar_css"
                        >
                            <div>{{ resolveLetter() }}</div>
                        </v-avatar>
                    </div>

                    <div style="width: 100%">
                        <div class="my-2">
                            <p style="font-size: 10px" class="pa-0 ma-0 text-secondary"> {{
                                getCurrentDateTime()
                                }}</p>
                            <h5>{{ userQuery }}</h5>
                        </div>
                    </div>
                </v-card>
                <v-card class="d-flex my-4 px-4 py-2 gap-20" color="#e5e7eb" elevation="0">
                    <div class="text-center" >
                        <v-avatar
                                tile
                                color="blue"
                                class="avatar_css"
                        >
                            <v-icon dark>
                                mdi-robot
                            </v-icon>
                        </v-avatar>
                    </div>

                    <div style="width: 100%">
                        <div class="my-2">
                            <p style="font-size: 10px" class="pa-0 ma-0 text-secondary">{{
                                getCurrentDateTime()
                                }}</p>
                            <h5>AI response for: {{ userQuery }}</h5>
                        </div>

                        <div class="overflow-y-auto" style="max-height: 200px;">
                                <span v-html="getFormattedResponse" style="font-size: 12px"
                                      class="text-secondary"></span>
                        </div>
                    </div>
                </v-card>

                <div class="d-flex justify-center align-center gap-10"
                     :class="!$vuetify.breakpoint.mdAndUp ? 'flex-column' : ''">
                    <v-btn color="white" class="ma-2" style="color: #2177B0;"
                           :style="!$vuetify.breakpoint.mdAndUp && 'width:100%'"
                           @click="regenerateResponse"
                    >
                        Regenerate response
                        <v-icon right>mdi-refresh</v-icon>
                    </v-btn>

                    <v-btn color="primary" class="ma-2 text--white"
                           :style="!$vuetify.breakpoint.mdAndUp && 'width:100%'"
                           @click="onSelectResponse"
                    >
                        Select response
                        <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                </div>
            </div>

            <v-row v-if="!aiResponse || aiResponse === ''" class="my-4 mx-0 ">
                <v-col cols="12" md="3" class="pa-2 d-flex justify-center align-center ">
                    <div class="d-flex"
                         :class="!$vuetify.breakpoint.mdAndUp ? 'justify-space-between align-center' : ''"
                         :style="{ width: !$vuetify.breakpoint.mdAndUp && 100+'%' }"
                    >
                        <h5 class="mr-2 ">Generate Hashtags</h5>
                        <v-switch v-model="hasHashtags" class=" pa-0 ma-0"
                                  color="primary"
                                  dense
                                  hide-details="auto"
                                  inset/>
                    </div>
                </v-col>

                <v-col cols="12" md="3" class="pa-2 d-flex justify-center align-center ">
                    <div class="d-flex"
                         :class="!$vuetify.breakpoint.mdAndUp ? 'justify-space-between align-center' : ''"
                         :style="{ width: !$vuetify.breakpoint.mdAndUp && 100+'%' }"
                    >
                        <h5 class="mr-2 ">Include Emojis</h5>
                        <v-switch v-model="hasEmojis" class="pa-0 ma-0"
                                  color="primary"
                                  dense
                                  hide-details="auto"
                                  inset/>
                    </div>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3"
                       class="d-flex justify-center align-center"></v-col>

                <v-col cols="12" md="3" class="d-flex justify-center align-center ">
                    <h5>Advanced Options</h5>
                    <v-btn @click="showOptions = !showOptions" class="ma-2" small>
                        <v-icon v-if="showOptions">mdi-close</v-icon>
                        <v-icon v-else>mdi-menu-down</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <!--Advanced Options-->
            <v-card v-if="!aiResponse || aiResponse === ''" class="px-2 rounded" color="#e5e7eb" elevation="0">
                <div v-if="showOptions" class="rounded py-6 gap-10"
                     :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-space-between align-center' : 'd-flex flex-column'">
                    <v-text-field label="Topic" class="flex-grow-1" outlined
                                  dense clearable hide-details
                                  v-model="topic" :disabled="aiResponse || aiResponse !== ''" />

                    <!-- {{ options }} -->
                    <v-autocomplete  hide-details dense class="flex-grow-1"
                                    label="Writing Tone" name="tone" outlined
                                    :items="writingTonesOptions"
                                    v-model="writingTone" :disabled="aiResponse || aiResponse !== ''" />

                    <v-autocomplete hide-details dense
                                    class="flex-grow-1" label="Writing Style" name="tone" outlined
                                    :items="writingStylesOptions"
                                    v-model="writingStyle" :disabled="aiResponse || aiResponse !== ''" />

                    <v-autocomplete hide-details dense class="flex-grow-1"
                                    label="Post Length" name="tone" outlined
                                    :items="postLengthOptions"
                                    v-model="postLength" :disabled="aiResponse || aiResponse !== ''" />

                    <v-autocomplete hide-details dense
                                    class="flex-grow-1" label="Target Audience" name="tone" outlined
                                    :items="targetAudienceOptions"
                                    v-model="targetAudience" :disabled="aiResponse || aiResponse !== ''" />
                </div>

                <div class="py-4">
                    <v-form ref="inputForm">
                        <v-textarea no-resize hide-details="auto" rows="2"
                                    outlined
                                    background-color="#e5e7eb"
                                    :rules="[required()]"
                                    placeholder="Type text here ..."
                                    :disabled="aiResponse || aiResponse !== ''"
                                    v-model="userQuery"
                        >
                            <template #append>
                                <v-icon @click="generateChatGptResponse" color="primary">mdi-send-outline</v-icon>
                            </template>
                        </v-textarea>
                    </v-form>
                </div>
            </v-card>
        </v-card>

        <LoadingDialog v-model="loading" message="Please Wait... !"/>
        <ErrorDialog v-model="error" :error="errorVal"/>
    </v-dialog>
</template>

<script>
import {required} from "@/utils/validators";
import ErrorDialog from "@/components/ErrorDialog";
import LoadingDialog from "@/components/LoadingDialog";

import RadioListWithIcon from "../../components/ai-generator/RadioListWithIcon.vue";
import {OpenaiService} from "../../services/openai-service";


export default {
    components: {RadioListWithIcon, LoadingDialog, ErrorDialog},
    name: "TemplateSelection",
    model: {
        prop: 'flag',
        event: 'value'
    },
    props: {
        flag: {
            type: Boolean,
            default: false
        },
        templates: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            error: false,
            errorVal: {},
            postFormat: {
                topic: null,
                tone: null,
                writingStyle: null,
                postLength: null,
                targetAudience: null
            },
            checkIdeaModal: false,
            generatedPost: {
                description: null,
                aiGenerated: true
            },

            // forNewDashboardDesignProps
            postCategoriesOptions: [
                {
                    id: 'listing_showcase',
                    title: 'Listing Showcase',
                    sub_title: 'Generate a post of exclusive listing showcase and discover a stunning selection of premier properties of [Specific Area/City].',
                },
                {
                    id: 'area_advantages',
                    title: 'Area advantages',
                    sub_title: 'Generate a post which discover the amazing advantages of choosing [Specific Area/City].',
                },
                {
                    id: 'tips_and_guides',
                    title: 'Tips & Guides',
                    sub_title: 'Generate a post which provide you tips and guides related to the topic.',
                },
                {
                    id: 'write_topic',
                    title: 'Write Topic',
                    sub_title: 'Generate a social media post which share insights on certain topic.',
                }
            ],
            writingTonesOptions: [
                "Friendly",
                "Formal",
                "Informative",
                "Casual",
                "Persuasive",
                "Excited",
                "Sarcastic"
            ],
            writingStylesOptions: [
                "Descriptive",
                "Narrative",
                "Expository",
                "Persuasive",
                "Storytelling",
            ],
            postLengthOptions: [
                "Short (1-2 sentences)",
                "Medium (a small paragraph)",
                "Long (few paragraphs)"
            ],
            targetAudienceOptions: [
                "General",
                "Public",
                "Professionals",
                "Teenagers",
                "Teenagers",
                "Adults",
                "Senior",
                "Citizens",
            ],

            selectedCategory: {},

            topic: '',
            writingTone: '',
            writingStyle: '',
            postLength: '',
            targetAudience: '',
            hasHashtags: false,
            hasEmojis: false,

            service: new OpenaiService(),
            showOptions: false,
            result: false,
            userQuery: '',
            aiResponse: '',

            loading: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        getFormattedResponse() {
            return this.aiResponse.replace(/\n/g, "<br>");
        }
    },
    mounted() {
        // this.resetAll();
        this.selectedCategory = this.postCategoriesOptions[0];
    },
    methods: {
        required,
        closeDialog() {
            this.resetAll();
            this.$emit('value', false)
        },
        onSelectResponse() {
            this.loading = true;

            setTimeout(() => this.loading = false, 3000);

            if (this.aiResponse && this.aiResponse !== '') {
                const obj = {};
                obj.description = this.aiResponse;
                this.$emit('selectTemplate', obj)
            }
        },
        async generateChatGptResponse() {
            if (this.$refs.inputForm.validate()) {
                this.loading = true;
                try {
                    const prompt = this.getRelativePromptMessage();
                    console.log('prompt: ', prompt)
                    if (prompt || prompt !== '') {
                        this.aiResponse = await this.service.findingResponseFromChatGpt(prompt);
                        console.log('AI Response: ', this.aiResponse);
                    }
                    this.loading = false;
                } catch (error) {
                    console.log("Error: ", error);
                    this.loading = false;
                    this.error = true;
                    this.errorVal = error
                }
            }
        },
        getRelativePromptMessage() {
            if (this.selectedCategory && this.selectedCategory.id) {
                switch (this.selectedCategory.id) {
                    case 'listing_showcase':
                        return `Act as social media writing expert. Write a social media post showcasing a stunning ${this.userQuery}.Highlight the unique features that make it stand out and appeal to potential buyers.${this.topic && 'Topic: ' + this.topic}. ${this.writingTone && 'Tone: ' + this.writingTone}. Try to add an engaging CTA that seems part of the post.Keep its ${this.postLength ? this.postLength : 'length 10-50'}  words. ${this.writingStyle && 'Writing Style: ' + this.writingStyle} ${this.targetAudience && ', Target Audience: ' + this.targetAudience} ${this.hasHashtags && ', including 3 relevant hashtags'} ${this.hasEmojis && ', including relevant emojis'}.`;

                    case 'area_advantages':
                        return `Act as social media writing expert. Write a social media post. Write a post highlighting the benefits of living in ${this.userQuery}. Discuss the amenities, local attractions, and community aspects that make it an attractive place to call home.${this.topic && 'Topic: ' + this.topic}. ${this.writingTone && ', Tone: ' + this.writingTone}. Try to add an engaging CTA that seems part of the post. ${this.postLength && 'Keep its ' + this.postLength + ' words.'} ${this.writingStyle && 'Writing Style: ' + this.writingStyle} ${this.targetAudience && ', Target Audience: ' + this.targetAudience} ${this.hasHashtags && ', including 3 relevant hashtags'} ${this.hasEmojis && ', including relevant emojis'}.`;

                    case 'tips_and_guides':
                        return `Act as social media writing expert. Write a social media post. Provide informative posts with tips, guides, and advice. Share insights on topics such as ${this.userQuery}.${this.topic && 'Topic: ' + this.topic}. ${this.writingTone && 'Tone: ' + this.writingTone}. Try to add an engaging CTA that seems part of the post. ${this.postLength && 'Keep its ' + this.postLength + ' words.'} ${this.writingStyle && 'Writing Style: ' + this.writingStyle} ${this.targetAudience && ', Target Audience: ' + this.targetAudience} ${this.hasHashtags && ', including 3 relevant hashtags'} ${this.hasEmojis && ', including relevant emojis'}.`;

                    case 'write_topic':
                        return `Act as social media writing expert. Write a social media post. Share insights on topics such as ${this.userQuery}.${this.topic && 'Topic: ' + this.topic}.  ${this.writingTone && 'Tone: ' + this.writingTone}. Try to add an engaging CTA that seems part of the post. ${this.postLength && 'Keep its ' + this.postLength + ' words.'} ${this.writingStyle && 'Writing Style: ' + this.writingStyle} ${this.targetAudience && ', Target Audience: ' + this.targetAudience} ${this.hasHashtags && ', including 3 relevant hashtags'}. ${this.hasEmojis && 'including relevant emojis'}.`;
                }
            } else
                return '';
        },
        onSelectCategory(category) {
            this.selectedCategory = category;
            console.log('selectedCategory: ', this.selectedCategory)
        },
        regenerateResponse() {
            this.aiResponse = '';
        },
        resetAll() {
            this.selectedCategory = this.postCategoriesOptions[0];
            if(this.$refs.inputForm) this.$refs.inputForm.reset();
            this.userQuery = '';
            this.aiResponse = '';
            this.topic = '';
            this.writingTone = '';
            this.writingStyle = '';
            this.postLength = '';
            this.argetAudience = '';
            this.hasHashtags = false;
            this.hasEmojis = false;
        },
        getCurrentDateTime() {
            const months = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September', 'October', 'November', 'December'
            ];

            const date = new Date();

            // Get the current time
            let hours = date.getHours();
            let minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';

            // Convert to 12-hour format
            hours %= 12;
            hours = hours || 12; // If hours is 0, set it to 12

            // Add leading zeros to minutes if necessary
            minutes = minutes < 10 ? '0' + minutes : minutes;

            // Get the current date
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            // Construct the formatted string
            return `${hours}:${minutes} ${ampm}, ${day} ${month} ${year}`;
        },
        resolveLetter() {
            if (this.user && this.user.name) {
                return this.user.name[0];
            } else {
                return "_";
            }
        },
    }
}
</script>

<style scoped>
.red-border {
    border: 1px solid red;
}

.gap-10 {
    gap: 10px
}

.gap-20 {
    gap: 20px
}

.avatar_css {
    color: white;
    border-radius: 25px;
    border: 1px solid transparent;;
}
</style>
