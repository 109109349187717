<template>
  <div
    v-if="show_overlay"
    :class="
      $vuetify.breakpoint.smAndDown ? 'tour-overlay-mobile' : 'tour-overlay'
    "
  >
    <v-card
      :max-width="$vuetify.breakpoint.smAndDown ? '300' : '400'"
      v-if="steps.length > 0 && steps[current_step]"
    >
      <div class="mx-4 pt-4" v-if="steps[current_step].image">
        <div
          v-if="this.tourImg == ''"
          style="
            width: 100%;
            height: 160px;
            background-color: #ddd;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
          "
        >
          <span class="loader"></span>
        </div>
        <img
          v-else
          :src="this.tourImg"
          :alt="steps[current_step].title"
          width="100%"
          height="100%"
          style="object-fit: contain !important; max-height: 450px"
        />
      </div>

      <v-card-title>
        {{ steps[current_step].title }}
      </v-card-title>

      <v-card-text>
        <span v-if="$vuetify.breakpoint.smAndDown">
          {{ steps[current_step].description.substr(0, 100) + "..." }}
        </span>
        <span v-else>
          {{ steps[current_step].description }}
        </span>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="current_step > 0"
          @click="goToPreviousStep"
          color="primary"
          text
        >
          Previous
        </v-btn>

        <v-btn
          v-if="current_step < steps.length - 1"
          @click="goToNextStep"
          color="primary"
          text
        >
          Next
        </v-btn>

        <v-btn
          v-if="current_step === steps.length - 1"
          @click="finishTour"
          color="primary"
          text
        >
          Finish
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "VTour",
  data() {
    return {
      current_step: "",
      show_overlay: false,
      tourImg: "",
    };
  },
  props: {
    tourKey: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    let tours = JSON.parse(localStorage.getItem("tours"));
    if (
      this.steps.length > 0 &&
      (!tours || !tours[this.tourKey] || tours[this.tourKey] === false)
    ) {
      this.show_overlay = true;
      this.current_step = 0;
      this.changeImg();
    }
  },
  methods: {
    changeImg() {
      this.tourImg = "";

      setTimeout(() => {
        this.tourImg = this.steps[this.current_step].image;
      }, 800);
    },
    goToNextStep() {
      if (this.current_step < this.steps.length - 1) {
        this.current_step++;
        this.changeImg();
      }
    },
    goToPreviousStep() {
      if (this.current_step > 0) {
        this.current_step--;
        this.changeImg();
      }
    },
    finishTour() {
      this.show_overlay = false;
      this.current_step = "";
      this.saveToLocalStorage(this.tourKey);
    },

    saveToLocalStorage(_key) {
      let tours = JSON.parse(localStorage.getItem("tours"));
      if (!tours) tours = {};
      tours[_key] = true;
      localStorage.setItem("tours", JSON.stringify(tours));
    },
  },
};
</script>

<style scoped>
.tour-overlay {
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
  align-items: end;
  padding: 40px;
}

.tour-overlay-mobile {
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #1a5f8d;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
