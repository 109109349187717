<template>
  <v-container style="height: 100vh" class="text-center d-flex justify-center align-center">
    <div class="pa-6 rounded-lg elevation-10" style="border: 3px solid #2177b0; background-color: white;max-width: 90%">
      <img src="../../assets/logo-large.png" alt="Postredi" class="logo" width="100%" height="100%"
           style="max-width: 100%"/>
      <div v-if="!$store.state.user.verified">
        <v-icon x-large color="primary" class="mb-3">mdi-email-check-outline</v-icon>
        <h4 v-if="submitted" class="green--text mb-3">Verification Email Sent! Check Inbox</h4>
        <h2 class="mb-4">Time to verify your email</h2>
        <h5 class="mb-6 text--secondary">We sent a verification email to {{ $store.state.user.username }}. <br>
          Once verified you will be able to use the app.
        </h5>
        <p class="text-subtitle-1 mb-2">Don't see it? Try checking your Spam folder<br>
          <span v-if="!timer" class="primary--text text-decoration-underline pointer"
                @click="resendEmail">or have it resent.</span>
          <span v-else class="primary--text">0:{{ timer }}</span>
        </p>
        <p class="primary--text my-2 text-decoration-underline pointer" @click="signOut">Sign Out</p>
        <p class="primary--text my-2 text-decoration-underline pointer" @click="openReportIssueModal()">Report an Issue</p>
      </div>
    </div>
    <ReportIssueForm v-model="reportIssueForm" />
    <LoadingDialog v-model="loading" message="Sending Verification Email..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </v-container>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";
import ReportIssueForm from "@/components/auth/ReportIssueForm";

export default {
  name: "EmailVerifyForm",
  components: {ErrorDialog, LoadingDialog, ReportIssueForm},
  data() {
    return {
      submitted: false,
      loading: false,
      reportIssueForm: false,
      error: false,
      errorVal: {},
      timer: null
    };
  },
  methods: {
    async resendEmail() {
      try {
        this.loading = true;
        const data = {
          name: this.$store.state.user.name,
          email: this.$store.state.user.username,
          id: this.$store.state.user._id
        };
        await this.$axios.post("persons/verification-email", data);
        this.startTimer()
        this.loading = false;
        this.submitted = true;
      } catch (e) {
        console.log(e)
        this.loading = false;
        this.errorVal = {
          title: "Error",
          description: e?.response?.data?.message || e?.data?.message || "Some error occured"
        };
        this.error = true;
      }
      this.loading = false;
    },
    startTimer() {
      this.timer = 59;
      const timerInterval = setInterval(() => {
        if (this.timer > 0)
          this.timer -= 1;
        else {
          this.timer = null
          clearInterval(timerInterval)
        }
      }, 1000);
    },
    openReportIssueModal() {
      this.reportIssueForm = true;
    },
    async signOut() {
      try {
        window.localStorage.clear();
        await this.$router.push("/sign-in").catch(() => {});
      } catch (err) {
        console.log(err)
      }
    },
  }
};
</script>
<style>
.logo {
  width: 400px;
}
</style>