import Vue from "vue";
import VueRouter from "vue-router";
import Publisher from "@/pages/publisher";
import BaseLayout from "@/components/BaseLayout";
import Facebook from "@/pages/social/facebook";
import EmptyLayout from "@/components/EmptyLayout";
import SignIn from "@/pages/auth/sign-in";

import UnSubscribe from "@/pages/unsubscribe";

import store from "@/plugins/vuex";
import axios from "axios";
import Instagram from "@/pages/social/instagram";
import Linkedin from "@/pages/social/linkedin";
import Twitter from "@/pages/social/twitter";
import Leads from "@/pages/leads";
import SocialPlatforms from "@/pages/social-platforms";
import FacebookReconnect from "@/pages/social/facebookReconnect";
import InstagramReconnect from "@/pages/social/instagramReconnect";
import TwitterReconnect from "@/pages/social/twitterReconnect";
import LinkedInReconnect from "@/pages/social/linkedInReconnect";
import Error from "@/pages/error";
import Notfound from "@/pages/notfound";
import GetStarted from "@/pages/get-started";
import Account from "@/pages/account";
import VerifyEmail from "@/pages/auth/verify-email";
import VerifyAccount from "@/pages/auth/verify-account";
import ResetPassword from "@/pages/auth/reset-password";
import PlanCanceled from "@/pages/plan-canceled";
import CustomizeLeadPage from "@/pages/customize/LeadPage";
import Landing from "@/pages/landing";
import Lead1 from "@/components/lead-pages-templates/lead1";
import Lead2 from "@/components/lead-pages-templates/lead2";
import Lead3 from "@/components/lead-pages-templates/lead3";
import Lead4 from "@/components/lead-pages-templates/lead4";
import Lead5 from "@/components/lead-pages-templates/lead5";
import Maintenance from "@/pages/maintenance";
import Welcome from "@/pages/welcome";
import Promoter from "@/pages/promoter";
import {currentTimezone} from "@/utils/local";

Vue.use(VueRouter);

const routes = [
    {path: "/unsubscribe", name: "UnSubscribe", component: UnSubscribe},
    {
        path: "/",
        component: BaseLayout,
        children: [
            {path: "/", name: "home", component: Publisher},
            {path: "/leads", name: "Leads", component: Leads},
            {path: "/social-platforms", name: "SocialPlatforms", component: SocialPlatforms},
            {path: "/get-started", name: "GetStarted", component: GetStarted},
            {path: "/plan-canceled", name: "PlanCanceled", component: PlanCanceled},
            {path: "/account", name: "Account", component: Account},
            {path: "/promoter", name: "Promoter", component: Promoter},
        ],
    },
    {
        path: "/social",
        name: "Social",
        component: EmptyLayout,
        children: [
            {path: "facebook", name: "Facebook", component: Facebook},
            {
                path: "facebook/reconnect",
                name: "FacebookReconnect",
                component: FacebookReconnect,
            },
            {path: "instagram", name: "Instagram", component: Instagram},
            {
                path: "instagram/reconnect",
                name: "InstagramReconnect",
                component: InstagramReconnect,
            },
            {path: "linkedin", name: "Linkedin", component: Linkedin},
            {
                path: "linkedin/reconnect",
                name: "LinkedinReconnect",
                component: LinkedInReconnect,
            },
            {path: "twitter", name: "Twitter", component: Twitter},
            {
                path: "twitter/reconnect",
                name: "TwitterReconnect",
                component: TwitterReconnect,
            },
        ],
    },
    {
        path: "/customize",
        component: BaseLayout,
        children: [
            {
                path: "lead-page",
                name: "customizeLeadPage",
                component: CustomizeLeadPage,
            },
        ],
    },
    {
        path: "/templates",
        component: EmptyLayout,
        children: [
            {
                path: "lead1",
                name: "LeadPage1",
                component: Lead1,
                props: {enabled: false},
            },
            {
                path: "lead2",
                name: "LeadPage2",
                component: Lead2,
                props: {enabled: false},
            },
            {
                path: "lead3",
                name: "LeadPage3",
                component: Lead3,
                props: {enabled: false},
            },
            {
                path: "lead4",
                name: "LeadPage4",
                component: Lead4,
                props: {enabled: false},
            },
            {
                path: "lead5",
                name: "LeadPage5",
                component: Lead5,
                props: {enabled: false},
            },
        ],
    },

    {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/maintenance",
        name: "MaintenanceMode",
        component: Maintenance
    },
    {
        path: "/sign-in",
        name: "SignIn",
        component: SignIn,
    },
    {
        path: "/verify-email",
        name: "VerifyEmail",
        component: VerifyEmail
    },
    {
        path: "/reset-password/:id",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/verify-account/:hash/:id",
        name: "VerifyAccount",
        component: VerifyAccount,
    },
    {
        path: "/error",
        name: "Error",
        component: Error,
    },
    {
        path: "/notfound",
        name: "NotFoundMain",
        component: Notfound
    },
    {
        path: "/:id",
        name: "Landing",
        component: Landing,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: Notfound
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, __, next) => {
    if (!localStorage.getItem("auth_token")) {
        if (
            to.path !== "/sign-in" &&
            to.name !== "Landing" &&
            !to.path.includes("/reset-password") &&
            !to.path.includes("/verify-account")
        ) {
            next("/sign-in");
        }
    } else {
        if (to.path !== "/error" && to.path !== "/notfound") {
            // console.log(to, "to");
            try {
                const user = await getUserDB();
                console.log(user, "user");
                store.commit("setUser", user);

                // Updating Store data
                if (to.path === "/" || to.path === "/social-platforms") {
                    await store.dispatch("getSocialPlatforms");
                }
                if (to.path === "/") {
                    await store.dispatch("getPosts");
                }
                if (store.state.user.subscription) {
                    await store.dispatch("getPlan", {
                        productID: store.state.user.subscription.plan.product,
                    });

                    if (!localStorage.getItem("Timezone")) {
                        store.commit("setTimezone", currentTimezone());
                        localStorage.setItem("Timezone", currentTimezone());
                    }
                }


                // Redirecting Middleware

                if (to.path === "/sign-in" || to.path.includes("reset-password")) {
                    // console.log("cannot go to sign-in");
                    next("/");
                }
                // && store.state.user.subscription.default_payment_method

                else if (to.path === "/get-started" && !store.state.user.isNewUser) {
                    // console.log("cannot go to get-started");
                    next("/");
                } else if (to.path === "/" && store.state.user.subscription && store.state.showWelcomePage) {
                    console.log("go to welcome");
                    next("/welcome");
                } else if (to.path === "/get-started" && store.state.user.subscription) {
                    console.log("go to home");
                    next("/welcome");
                } else if (to.path === "/verify-email" && store.state.user.verified) {
                    // console.log("cannot go to verify-email");
                    next("/");
                } else if (to.path !== "/verify-email" && !store.state.user.verified && !to.path.includes("/verify-account")) {
                    // console.log("should go to verify-email");
                    next("/verify-email");
                } else if (to.path !== "/plan-canceled" && isPlanCanceled()) {
                    // console.log("should go to plan canceled");
                    next("/plan-canceled");
                } else if (to.path === "/plan-canceled" && !isPlanCanceled()) {
                    // console.log("should go to home");
                    next("/");
                }

                // || !store.state.user.subscription.default_payment_method
                else if (
                    !store.state.user.subscription &&
                    store.state.user.isNewUser &&
                    to.path !== "/get-started" &&
                    to.path !== "/verify-email" &&
                    !to.path.includes("/verify-account")
                ) {
                    // console.log("should go to get-started");
                    next("/get-started");
                }
            } catch (e) {
                console.log(e);
                // console.log("router error");
                next("/error");
            }
        }
    }

    next();
});

async function getUserDB() {
    const token = localStorage.getItem("auth_token");
    if (token) {
        const user = (await axios.get("/auth/profile")).data;
        localStorage.setItem("auth_user", JSON.stringify(user));
        return user;
    }
}

// function hasPaymentMethod() {
//     return !!store.state.user?.customer?.invoice_settings?.default_payment_method;
// }

function isPlanCanceled() {
    const subscription = store.state.user?.subscription;
    return (
        subscription &&
        (subscription.status === "canceled" ||
            subscription.status === "incomplete_expired")
    );
}

export default router;
