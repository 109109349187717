import axios from "axios";

export class TemplatesService {
  async fetchAll(page = 0) {
    return (await axios.get("/post-template/paginated/" + page)).data;
  }
  async fetchAllFiltered(data) {
    return (await axios.post("/post-template/filter", data)).data;
  }
}
