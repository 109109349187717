<template>
  <v-layout fill-height>
    <v-main style="height: 100%">
      <v-row style="height: 100%" no-gutters>
        <v-col cols="8" class="primary lighten-3 d-none d-lg-block">
          <v-container class="px-16">
            <v-row style="max-height: 100%">
              <v-col cols="12" style="height: 180px">
                <img
                  src="../../assets/logo-large.png"
                  height="100%"
                  alt="Postredi"
                />
              </v-col>
              <v-col
                cols="12"
                class="d-flex flex-column"
                style="height: calc(100% - 200px)"
              >
                <h1 class="mb-6 text-h4 white--text">
                  ATTRACT PRIVATE CAPITAL <br />
                  <span
                    class="primary--text text--darken-4 text-h2 font-weight-bold"
                  >
                    THROUGH SOCIAL MEDIA</span
                  >
                </h1>
                <div style="height: 50vh">
                  <img
                    src="../../assets/sign-in-banner.svg"
                    width="100%"
                    height="100%"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" lg="4">
          <v-container class="pa-16">
            <h1 class="mb-6 text-sm-h3">
              Welcome to <br />
              <span class="primary--text text-sm-h1 font-weight-bold"
                >Postredi.</span
              >
            </h1>
            <v-form ref="signInForm">
              <ul class="mb-3">
                <li class="error--text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </li>
              </ul>
              <div class="mb-3">
                <label>Email</label>
              </div>
              <v-text-field
                :disabled="loading"
                v-model="username"
                :rules="[required(), email()]"
                outlined
                placeholder="Enter your email"
                type="email"
              ></v-text-field>
              <div class="mb-3">
                <label>Password</label>
              </div>
              <v-text-field
                @keyup.enter="signIn"
                :disabled="loading"
                v-model="password"
                :rules="[required()]"
                outlined
                placeholder="Password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>
              <div class="d-flex justify-end mb-6">
                <p
                  @click="forgotPassword = true"
                  class="pointer primary--text ma-0 text-decoration-none"
                >
                  Forgot Password?
                </p>
              </div>

              <v-btn
                @click="signIn"
                :disabled="loading"
                color="primary"
                elevation="0"
                width="100%"
                x-large
                class="mb-6"
              >
                <span>{{ loading ? "Signing In" : "Sign In" }}</span>
                <v-progress-circular
                  size="18"
                  indeterminate
                  v-if="loading"
                  width="2"
                  class="ml-3"
                />
              </v-btn>

              <p class="text-center">
                Don't have an account?
                <span @click="signup = true" class="pointer primary--text ma-0"
                  >Sign Up</span
                >
              </p>

              <p class="text-center">
                By clicking Sign in, I agree to
                <a
                  target="_blank"
                  href="https://postredi.com/terms-privacy/"
                  class="text-decoration-none"
                >
                  Postredi's Terms</a
                >
                including the payment terms, and Privacy Policy
              </p>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
      <ForgotPasswordForm v-model="forgotPassword" />
      <SignUpForm v-model="signup" :plan="plan" :promo="promo" :referred-by="referredBy" />
    </v-main>
  </v-layout>
</template>

<script>
import { required, email } from "@/utils/validators";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordDialog";
import SignUpForm from "@/components/auth/SignUpDialog";
import { currentTimezone } from "@/utils/local";

export default {
  name: "SignIn",
  components: { SignUpForm, ForgotPasswordForm },
  data: () => ({
    username: null,
    password: null,
    showPassword: false,
    loading: false,
    errors: [],
    forgotPassword: false,
    signup: false,
    promo: null,
    plan: null,
    referredBy: null
  }),
  mounted() {
    if (this.$route.query.signup) {
      this.signup = !!this.$route.query.signup;
    }
    if (this.$route.query.forgotPassword) {
      this.forgotPassword = !!this.$route.query.forgotPassword;
    }
    if (this.$route.query.promo) {
      this.promo = this.$route.query.promo;
    }
    if (this.$route.query.plan) {
      this.plan = this.$route.query.plan;
    }
    if (this.$route.query.tid) {
      this.referredBy = this.$route.query.tid
    }
  },
  methods: {
    required,
    email,
    currentTimezone,

    async signIn() {
      if (this.$refs.signInForm.validate()) {
        this.errors = [];
        try {
          this.loading = true;
          const data = {
            username: this.username.toLowerCase(),
            password: this.password,
          };
          const getToken = await this.$axios.post("/auth/sign-in", data);
          if (getToken) {
            const token = getToken.data;
            const user = (
              await this.$axios.get("/auth/profile", {
                headers: { authorization: "bearer " + token.access_token },
              })
            ).data;
            if (user.role === 0) {
              this.$store.commit("setToken", token.access_token);
              this.$store.commit("setUser", user);
              // console.log('loggedIn user: ', user);
              this.$store.commit("setTimezone", this.currentTimezone());
              console.log("timezone", this.currentTimezone());
              console.log(this.$store.state.timezone);
              localStorage.setItem("Timezone", currentTimezone());
              localStorage.setItem("auth_token", token.access_token);
              localStorage.setItem("auth_user", JSON.stringify(user));
              await this.$router.push("/").catch(() => {});
            } else {
              localStorage.clear();
              this.errors.push(
                "You need a customer account to access the content ahead."
              );
              this.loading = false;
            }
          } else {
            localStorage.clear();
            this.errors.push(
              "Sign in failed. Check your credentials or try again later."
            );
            this.loading = false;
          }
        } catch (e) {
          console.log(e, "Sign in error");
          console.log(e.error, "Sign in error");
          if (!e.toString().toLowerCase().includes("redirected")) {
            this.errors.push(
              e?.response?.data?.message ||
                e?.data?.message ||
                "Some error occured"
            );
          }
          this.loading = false;
        }
      }
    },
  },
};
</script>
