<template>
  <v-container>
    <data-table
        :loader="loadData"
        :headers="headers"
        :search="search"
        title="Leads"
        :allow-add="false"
        @done="$router.back()"
    >
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template #date="{ item }">
        {{ item.date && item.date !== '' ? formatDate(item.date, 'D MMM YYYY') : '' }}
      </template>
    </data-table>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DataTable from '../components/DataTable';
import {LeadsService} from "@/services/leads-service";


export default {
  components: {DataTable},

  data: () => ({
    items: [],
    loading: false,
    search: null,
    service: new LeadsService(),

    headers: [
      {
        text: 'Display Name',
        value: 'name',
        width: 150
      },
      {
        text: 'Email',
        value: 'email',
        width: 200
      },
      {
        text: 'Phone',
        value: 'phone',
      },
      {
        text: 'Note',
        value: 'note',
      },
      {
        text: 'Preferred Date',
        value: 'date',
        width: 180,
      },
      {
        width: 180,
        text: 'Created At',
        value: 'createdAt'
      }
    ]
  }),

  methods: {
    formatDate(date, format = 'D MMM YYYY - hh:mm a') {
      return dayjs(date).format(format);
    },
    loadData() {
      return this.service.fetchByUser(this.$store.state.user._id);
    }
  }
};
</script>

<style scoped></style>