<template>
  <v-dialog :value="flag" style="overflow: auto" @input="$emit('value', false)"
            :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
            persistent>
    <v-card class="px-4 pt-4 pb-0" style="position: relative">
      <div style="position:absolute; top: 0; right: 0">
        <v-avatar @click="resetAndCloseModal" class="pointer">
          <v-icon>mdi-close</v-icon>
        </v-avatar>
      </div>
      <v-form ref="postCreate">
        <div>
          <h3 class="text-md-h3 text-h5 mb-4 primary--text font-weight-medium">Report an Issue</h3>
        </div>
        <v-form ref="reportForm">
          <ul class="mb-3" v-if="updated">
            <li class="success--text">Report has been submitted.</li>
          </ul>
          <ul class="mb-3">
            <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
          </ul>
          <v-row>
            <v-col cols="12">
              <div class="mb-3">
                <label>Type</label>
              </div>
              <v-select
                  v-model="report.type"
                  :rules="[required('Please select an option')]"
                  :items="report_type_options"
                  :item-text="(item) => item.text"
                  :item-value="(item) =>  item.value"
                  hide-details="auto"
                  class="span-2"
                  label="Report Type"
                  outlined
                  placeholder="Please Select an option"
              />
            </v-col>
            <v-col cols="12">
              <div class="mb-3">
                <label>Feedback</label>
              </div>
              <v-textarea
                  :rules="[required()]"
                  v-model="report.comment"
                  hide-details="auto"
                  rows="2"
                  outlined
                  name="input-7-4"
                  placeholder="Feedback"
              ></v-textarea>
            </v-col>

            <v-col cols="12" v-if="report.type === 'bug'">
              <div class="mb-3">
                <label>Steps to reproduce</label>
              </div>
              <v-textarea
                  v-model="report.reproduction_steps"
                  name="input-7-4"
                  rows="2"
                  hide-details="auto"
                  outlined
                  placeholder="Steps to reproduce a bug..."
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <div class="mb-3">
                <h3>Media</h3>
                <div v-if="media.length > 0">
                  <div class="file-display my-5 pa-2" :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                       v-for="(mediaItem, i) in media" :key="i">
                    <v-progress-linear
                        v-model="mediaItem.uploaded"
                        height="25"
                        v-if="!mediaItem.isUploaded"
                    >
                      <strong v-if="mediaItem.uploaded < 100" class="white--text">{{
                          Math.ceil(mediaItem.uploaded)
                        }}%</strong>
                      <strong v-else class="white--text">Processing</strong>
                    </v-progress-linear>
                    <v-row v-else class="">
                      <v-col cols="2" class="d-flex justify-center align-center">
                        <div style="width: 100px; height: 100px">
                          <img :src="mediaItem.url"
                               v-if="mediaItem && !mediaItem.type.includes('video') && mediaItem.url" width="100%"
                               height="100%"/>
                          <img :src="mediaItem.thumbnail.url"
                               v-else width="100%"
                               height="100%"/>
                        </div>
                      </v-col>
                      <v-col cols="10">
                        <div class="d-flex justify-space-between align-center">
                          <p class="ma-0">{{ mediaItem.name }}</p>
                          <v-icon @click="removeMedia(mediaItem)" color="error">mdi-delete</v-icon>
                        </div>
                        <p class="ma-0">Source: Upload</p>
                        <p class="ma-0">Type: {{ mediaItem.type }}</p>
                        <p class="ma-0">Size: {{ humanFileSize(mediaItem.size, true, 0) }}</p>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div class="mediaPrickerWrapper py-8 d-flex justify-center align-center my-5 flex-column"
                     style="position: relative"
                     @click="pickFile"
                     @drop="dropHandler($event)"
                     @dragenter.prevent
                     @dragover.prevent
                >
                  <v-avatar color="grey lighten-3 mb-2">
                    <v-icon>mdi-upload</v-icon>
                  </v-avatar>
                  <p class="ma-0 text-center">Drag files here<br>
                    <span class="font-weight-bold primary--text">Or select files to upload </span></p>
                  <v-file-input
                      id="mediaPicker"
                      v-model="selectedMedia"
                      :value="selectedMedia"
                      @change="pickHandler"
                      multiple
                      class="d-none"
                      accept="image/*, video/*"
                  />
                </div>

                <v-list>
                  <v-list-item v-for="(error,i) in mediaErrors" :key="i">
                    <v-list-item-avatar color="error lighten-4">
                      <v-icon color="error">mdi-alert</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ error.title }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon small v-if="error.type" class="mr-2" :color="getSocialMediaTypeColor(error.type)">
                          {{ getSocialMediaTypeIcon(error.type) }}
                        </v-icon>
                        {{ error.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-row>

          <v-btn @click="saveForm" :disabled="loading" color="primary" elevation="0" width="100%" x-large
                 class="my-4">
            <span>{{ loading ? 'Submitting' : 'Submit' }}</span>
            <v-progress-circular size="18" indeterminate v-if="loading" width="2" class="ml-3"/>
          </v-btn>
        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, email} from "@/utils/validators";
import {getSocialMediaTypeColor, getSocialMediaTypeIcon, humanFileSize} from "@/utils/local";
import {storage} from "@/plugins/firebase";

export default {
  name: "ReportForm",
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      updated: false,
      errors: [],
      media: [],
      mediaTypes: [],
      mediaErrors: [],
      selectedMedia: [],
      report: {
        type: '',
        comment: '',
        reproduction_steps: '',
        email: ''
      },

      report_type_options: [
        {text: 'Bug', value: 'bug'},
        {text: 'Feedback', value: 'feedback'},
        {text: 'Feature Request/Improvement', value: 'feature'},
      ]
    }
  },
  watch: {
    flag(newVal) {
      if (newVal) {
        this.updated = false;
      }
    }
  },
  methods: {
    required,
    email,
    humanFileSize,
    getSocialMediaTypeColor,
    getSocialMediaTypeIcon,
    resetAndCloseModal() {
      this.$refs.reportForm.reset();
      this.$emit('value', false)
    },
    removeMedia(media) {
      if (!this.posting && !this.drafting) {
        if (media.file) {
          const index = this.selectedMedia.indexOf(media.file)
          this.selectedMedia.splice(index, 1)
        }
        this.mediaTypes = this.media.map((o) => o.type)
        this.mediaTypes = [...this.mediaTypes]

        const indexMedia = this.media.indexOf(media)
        this.media.splice(indexMedia, 1)
        this.uploadedFiles = this.media.length
      }
    },
    pickFile() {
      if (!this.posting && !this.drafting) {
        document.querySelector("#mediaPicker").click();
      }
    },
    pickHandler() {
      this.uploadedFiles = 0
      this.handleMedia(this.selectedMedia)
      this.media = [...this.media]
    },
    async dropHandler(ev) {
      if (!this.posting && !this.drafting) {
        ev.preventDefault();
        this.uploadedFiles = 0
        await this.handleMedia(ev.dataTransfer.files)
        this.media = [...this.media]
      }
    },
    async handleMedia(files) {
      // const allowedTypes = ['png', 'jpg', 'jpeg', 'gif','mp4'];
      for (let i = 0; i < files.length; i++) {
        if ((files[i].type.includes('image') && files[i].size < 5242880) || (files[i].type.includes('video') && files[i].size < 209715200)) {
          try {
            const fileData = {
              file: files[i],
              url: URL.createObjectURL(files[i]),
              uploaded: 0,
              isUploaded: false,
              name: files[i].name,
              type: files[i].type,
              size: files[i].size
            }
            fileData.uploaded = 50
            if (fileData.type.includes('video')) {
              const thumbnail = {
                file: null,
                url: null
              }
              thumbnail.file = await this.generateVideoThumbnail(files[i])
              thumbnail['url'] = URL.createObjectURL(thumbnail.file)
              fileData.thumbnail = thumbnail

            }
            fileData.uploaded = 100
            fileData.isUploaded = true
            this.media.push(fileData);
            this.mediaTypes = this.media.map((o) => o.type)
            this.mediaTypes = [...this.mediaTypes]
            this.uploadedFiles = this.media.length
          } catch (e) {
            this.$toast.error('Some error occurred. ' + files[i].name + ' cannot be selected.')
          }
          // const index = this.media.indexOf(fileData)
          // this.uploadFile(index)
        } else {
          this.$toast.error(files[i].type.includes('image') ? files[i].name + ' is too large. Max size allowed is 5 Mb' : files[i].name + ' is too large. Max size allowed is 200 Mb')
        }
      }
    },
    async uploadFile(index) {
      try {
        let type
        if (this.media[index].type.includes('image')) {
          type = 'images'
        } else {
          type = 'videos'
        }
        const fileName = this.media[index].name.split('.').shift() + '~' + new Date().getTime() + '.' + this.media[index].name.split('.').pop()
        let reference = storage.ref("reports" + '/' + fileName);
        let task = reference.put(this.media[index].file);
        let thumbnail = null
        await task.then(async () => {
          if (type === 'videos') {
            let thumbRef = storage.ref("reports" + '/thumbnails/' + fileName + ".jpg");
            let thumbTask = thumbRef.put(this.media[index].thumbnail.file);
            await thumbTask.then(async () => {
              window.console.log('thumbnail uploaded')
              thumbnail = {
                url: await storage.ref("reports" + '/thumbnails/').child(fileName).getDownloadURL(),
                key: null
              }
            }).catch((e) => window.console.log('uploading image error => ', e))
          }
        }).catch((e) => window.console.log('uploading image error => ', e));
        // this.media[index].isUploaded = false
        // let formData = new FormData();
        //
        // formData.append('file', this.media[index].file);
        // try {
        //   let thumbnail
        //   if (this.media[index].file.type.includes('video')) {
        //     thumbnail = await this.uploadThumbnail(this.media[index].thumbnail.file)
        //   }
        //   const response = await this.$axios.post('/posts/file',
        //       formData,
        //       {
        //         headers: {
        //           'Content-Type': 'multipart/form-data'
        //         },
        //         // onUploadProgress: function (progressEvent) {
        //         //   this.media[index].uploaded = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        //         // }.bind(this)
        //       }
        //   )
        this.media[index] = {...this.media[index]}
        this.media[index].url = await storage.ref("reports").child(fileName).getDownloadURL()
        this.media[index].key = null
        this.media[index].isUploaded = true
        this.media[index].thumbnail = thumbnail
        this.$forceUpdate();
      } catch (e) {
        this.$toast.error('Could not upload media. Try again later')
        this.media.splice(index, 1)
      }
    },
    async generateVideoThumbnail(file) {
      const binaryData = []
      binaryData.push(file)
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.createElement('video')
      video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)))
      video.load()
      let thumbnail = await new Promise((resolve, reject) => {
        video.onloadedmetadata = async () => {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          video.currentTime = video.duration / 2
          await video.play()
          context.drawImage(video, 0, 0)
          video.pause()
          const blob = await new Promise((resolve) => {
            return canvas.toBlob(function (blob) {
              resolve(blob)
            })
          })

          resolve(blob)

        }
        video.onerror = () => {
          if (video.error)
            reject('error')
        }
      })

      return thumbnail
    },
    async saveForm() {
      if (this.$refs.reportForm.validate()) {
        this.errors = []
        try {
          this.loading = true

          if (this.media.length > 0) {
            for (let i = 0; i < this.media.length; i++) {
              if (!this.media[i].hasUploaded)
                await this.uploadFile(i)
            }
          }

          this.media = this.media.map((file) => ({
            url: file.url,
            key: null,
          }))

          this.report.media = this.media
          this.report.email = this.$store.state.user.username;
          this.report.phone = this.$store.state.user.phone;
          console.log(this.report)

          await this.$axios.post('/feedback', this.report)
          this.$toast.success('Report has been submitted.')
          this.loading = false
          this.updated = true;
          this.media = []
          this.mediaErrors = []
          this.resetAndCloseModal()
        } catch (e) {
          this.loading = false
          this.errors.push(e?.response?.data?.message || 'Some error occurred')
        }
      }
    },
  }
}
</script>

<style scoped>
.mediaPrickerWrapper {
  border: 1px dashed #2177b0;
  border-radius: 8px;
}

.file-display {
  border: 1px solid #2177b0;
}

.contact-upload-dialog p, .contact-upload-dialog a {
  font-size: 14px;
}

.custom-error {
  border: 1px solid red
}
</style>