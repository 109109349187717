<template>
  <v-container>
    <h1>Social Platforms</h1>
    <p>These are the social accounts that only you can view and post to.</p>
    <p v-if="socialPlatforms.length <= 0">No social platforms connected yet. Use Blue button to connect to social
      platforms.</p>
    <v-row>
      <v-col v-for="(socialPlatform,i) in socialPlatforms" :key="i" cols="12" sm="12" md="6" lg="4" class="mb-4">
        <FacebookReconnector
            v-if="socialPlatform.type === SocialMediaType.facebookPage || socialPlatform.type === SocialMediaType.facebookGroup"
            :title="getSocialMediaTypeIndividualTitle(socialPlatform.type)"
            :social-platform="socialPlatform" :color="SocialMediaColor.facebook"
            :is-re-auth-required="isReAuthRequired"
            @deletePlatform="deleteSocialPlatform(socialPlatform)"/>
        <InstagramReconnector
            v-if="socialPlatform.type === SocialMediaType.instagram"
            :title="getSocialMediaTypeIndividualTitle(socialPlatform.type)"
            :social-platform="socialPlatform" :color="SocialMediaColor.instagram"
            :is-re-auth-required="isReAuthRequired"
            @deletePlatform="deleteSocialPlatform(socialPlatform)"/>
        <TwitterReconnector
            v-if="socialPlatform.type === SocialMediaType.twitter"
            :title="getSocialMediaTypeIndividualTitle(socialPlatform.type)"
            :social-platform="socialPlatform" :color="SocialMediaColor.twitter"
            :is-re-auth-required="isReAuthRequired"
            @deletePlatform="deleteSocialPlatform(socialPlatform)"/>
        <LinkedInReconnector
            v-if="socialPlatform.type === SocialMediaType.linkedIn"
            :title="getSocialMediaTypeIndividualTitle(socialPlatform.type)"
            :social-platform="socialPlatform" :color="SocialMediaColor.linkedIn"
            :is-re-auth-required="isReAuthRequired"
            @deletePlatform="deleteSocialPlatform(socialPlatform)"/>
      </v-col>
    </v-row>
    <LoadingDialog v-model="loading" message="Loading..."/>
  </v-container>
</template>

<script>

import {getSocialMediaTypeIndividualTitle, getSocialMediaTypeColor, getSocialMediaTypeIcon} from "@/utils/local";
import {SocialPlatformService} from "@/services/social_platform-service";
import LoadingDialog from "@/components/LoadingDialog";
import FacebookReconnector from "@/components/social-platforms/reconnect/facebookReconnector";
import {SocialMediaColor, SocialMediaType} from "@/utils/enum";
import InstagramReconnector from "@/components/social-platforms/reconnect/instagramReconnector";
import LinkedInReconnector from "@/components/social-platforms/reconnect/linkedInReconnector";
import TwitterReconnector from "@/components/social-platforms/reconnect/twitterReconnector";

export default {
  name: "SocialPlatforms",
  components: {TwitterReconnector, LinkedInReconnector, InstagramReconnector, FacebookReconnector, LoadingDialog},
  data() {
    return {
      service: new SocialPlatformService(),
      loading: false,
      SocialMediaColor,
      SocialMediaType
    }
  },
  computed: {
    socialPlatforms() {
      return this.$store.state.socialPlatforms
    },
    isReAuthRequired() {
      return !!this.socialPlatforms.find((o) => o.isReAuthRequired)
    }
  },
  methods: {
    getSocialMediaTypeIndividualTitle,
    getSocialMediaTypeColor,
    getSocialMediaTypeIcon,
    async deleteSocialPlatform(platform) {
      if (confirm('Warning! \n Are you sure you want to remove this social platform? \n All posts related to this platform will also be removed.')) {
        console.log('confirmed')
        this.loading = true
        try {
          await this.service.delete(platform)
          await this.$store.dispatch('getSocialPlatforms')
          await this.$store.dispatch('getPosts')
          this.loading = false
          this.$toast.success('Social platform was deleted successfully.')
        } catch (e) {
          this.loading = false
          console.log(e)
          console.log(e.data)
          console.log(e.response)
          this.$toast.error('Social platform could not be deleted. Try again later.')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>