<template>
  <div>
    <v-card :style="socialPlatform.isReAuthRequired ? 'opacity: 0.6; border: 1px solid red;' : ''" class="pa-4"
            v-if="socialPlatform">
      <v-list-item :two-line="isReAuthRequired">
        <v-list-item-avatar size="48" class="mr-3 elevation-1">
          <v-avatar size="48">
            <img v-if="socialPlatform.avatar" :src="socialPlatform.avatar"/>
            <img v-else src="https://s.pinimg.com/images/user/default_60.png"/>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ socialPlatform.username }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-icon small :color="color">
              mdi-facebook
            </v-icon>
            {{ title }}
          </v-list-item-subtitle>
          <v-list-item-action-text v-if="socialPlatform.isReAuthRequired">
            <v-icon small color="warning">
              mdi-alert
            </v-icon>
            Reconnection Required
          </v-list-item-action-text>
        </v-list-item-content>
        <v-list-item-action class="flex-row">
          <v-tooltip top color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="elevation-1 mr-2"
                  @click="connectFacebook">
                <v-icon small color="success">mdi-sync</v-icon>
              </v-btn>
            </template>
            <span>Reconnect</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" icon class="elevation-1" @click="deletePlatform">
                <v-icon small color="error">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Remove</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-card>
    <v-dialog v-model="setup" :width="$vuetify.breakpoint.mdAndUp ? '30%' : '90%'" persistent>
      <v-card class="pa-4">
        <div class="ma-4">
          <p class="ma-0 text-h4 mb-3">Social account setup still in progress</p>
          <p class="ma-0 mb-3"> There are still a few steps remaining to connect your social account to Postredi.</p>
          <div class="d-flex justify-end">
            <v-btn elevation="0" @click="closeWindow" class="mr-3" tile>Cancel</v-btn>
            <v-btn elevation="0" color="primary" @click="focusOnWindow" tile>Resume Setup</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {FBAPPID, fbRedirectURLReconnect, FBSCOPE} from "@/utils/local";

export default {
  name: "FacebookReconnector",
  props: {
    color: String,
    title: String,
    isReAuthRequired: Boolean,
    socialPlatform: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      connectionWindow: null,
      setup: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    connectionWindow(newVal) {
      console.log(newVal, 'Connection Window')
    }
  },
  methods: {
    connectFacebook() {
      this.loading = true;
      try {
        this.setup = true
        const scope = encodeURIComponent(FBSCOPE);
        const state = this.user._id + '~' + this.socialPlatform._id;
        const url = "https://www.facebook.com/v12.0/dialog/oauth?display=popup&client_id=" +
            FBAPPID +
            "&redirect_uri=" + fbRedirectURLReconnect +
            "&scope=" + scope +
            "&state=" + state;
        const y = window.top.outerHeight / 2 + window.top.screenY - (670 / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (562 / 2);
        this.connectionWindow = window.open(url, '_blank', `width=562, height=670, top=${y}, left=${x}`);
        const timer = setInterval(async () => {
          console.log(timer)
          if (this.connectionWindow.closed) {
            clearInterval(timer)
            await this.$store.dispatch('getSocialPlatforms')
            const platform = this.$store.state.socialPlatforms.find((o) => o._id === this.socialPlatform._id)
            if (platform.isReAuthRequired) {
              this.$toast.error('You must sign in to facebook and allow Postredi to integrate.')
            } else {
              this.$toast.success('Facebook was re-integrated with Postredi successfully.')
            }
            this.setup = false
          }
        }, 1000)
      } catch (e) {
        this.loading = false;
      }
    },
    focusOnWindow() {
      this.connectionWindow.focus()
    },
    closeWindow() {
      this.setup = false
      this.connectionWindow.close()
    },
    deletePlatform() {
      this.$emit('deletePlatform', true)
    }
  }
}
</script>

<style scoped>

</style>