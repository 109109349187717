<template>
  <div class="pa-6">
    <p v-if="errors.length <= 0">Reconnecting...</p>
    <p v-if="errors.length <= 0">Don't close this window.</p>
    <ul>
      <li class="error--text" v-for="(error,i) in errors" :key="i">{{ error }}</li>
    </ul>
  </div>
</template>

<script>

import {linkedinRedirectURLReconnect} from "@/utils/local";
import {SocialMediaType} from "@/utils/enum";
import {SocialPlatformService} from "@/services/social_platform-service";

export default {
  name: "Linkedin",
  data() {
    return {
      socialPlatformService: new SocialPlatformService(),
      data: null,
      loading: false,
      SocialMediaType,
      loggedUser: null,
      loggedUserPlatforms: [],
      errors: []
    }
  },
  mounted() {
    this.resolveData()
  },
  methods: {
    async resolveData() {
      try {
        this.loggedUser = this.$store.state.user
        const res = await this.resolveCode()
        if (res)
          this.completeTwitterConnection()
      } catch (e) {
        this.completeTwitterConnection()
      }
    },
    async resolveCode() {
      try {
        const data = this.$route.query;
        console.log(data)
        if (data.code) {
          await this.$axios.post("/social-platforms/connect/lt", {
            code: data.code,
            type: SocialMediaType.linkedIn,
            user: this.loggedUser._id,
            update: true,
            redirect_uri: encodeURIComponent(linkedinRedirectURLReconnect)
          });
          return true
        }
      } catch (e) {
        this.errors.push(e.error_description || e.data.message || 'Some error occured')
        return false
      }
    },
    completeTwitterConnection() {
      window.close()
    }
  }
}
</script>

<style scoped>

</style>