<template>
  <v-dialog :value="flag" @input="$emit('value', false)" :width="$vuetify.breakpoint.mdAndUp ? '50%' : '90%'">
    <v-card class="pa-2" v-if="post" style="height: 70vh;position: relative">
      <v-avatar class="elevation-1" size="32" style="position: absolute; top: 10px; right: 10px; z-index: 1"
                color="white">
        <v-icon color="black" @click="$emit('value', false)">mdi-close</v-icon>
      </v-avatar>
      <v-carousel :hide-delimiters="post.mediaType === 'Video'" height="100%"
                  :show-arrows-on-hover="post.media.length > 1" :show-arrows="post.media.length > 1">
        <v-carousel-item
            v-for="(item,i) in post.media"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
        >
          <img v-if="item.type.includes('image')" :src="item.url" width="100%" height="100%"/>
          <video v-else :src="item.url" :poster="item.thumbnail.url" width="100%" height="100%" controls/>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>



export default {
  name: "ShowMediaDialog",
  model: {
    prop: 'flag',
    event: 'value'
  },

  props: {
    flag: {
      type: Boolean,
      default: false
    },
    post: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  methods: {}
}
</script>