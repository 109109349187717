<template>
  <div :style="{ backgroundImage: 'url(' + backgroundImage + ')' }" class="wrapper">
    <div v-if="!isSubmitted" class="card">
      <div class="card__header">
        <div class="card_header_inner">
           <span class="header__title">
           {{ title }}
         </span>
          <span class="header__description">
           {{ subtitle }}
         </span>
        </div>
      </div>

      <form class="body">

        <div class="inputWithLabel">
          <label for="firstName">First Name : </label>
          <input id="firstName" v-model="firstName" :disabled="loading" placeholder="Enter First Name" type="text">
        </div>

        <span v-if="errors.firstName" class="error-text" v-html="errors.firstName"/>

        <div class="inputWithLabel">
          <label for="lastName">Last Name : </label>
          <input id="lastName" v-model="lastName" :disabled="loading" placeholder="Enter Last Name" type="text">
        </div>

        <span v-if="errors.lastName" class="error-text" v-html="errors.lastName"/>

        <div class="inputWithLabel">
          <label for="email">Email : </label>
          <input id="email" v-model="email" :disabled="loading" placeholder="Enter Email Address" type="email">
        </div>

        <span v-if="errors.email" class="error-text" v-html="errors.email"/>

        <div class="inputWithLabel">
          <label for="phone">Phone : </label>
          <input id="phone" v-model="phone" :disabled="loading" placeholder="Enter Phone Number" type="number">
        </div>

        <span v-if="errors.phone" class="error-text" v-html="errors.phone"/>

        <div class="inputWithLabel">
          <label for="date">Meeting Date : </label>
          <input id="date" v-model="date" :disabled="loading" type="date">
        </div>

        <span v-if="errors.date" class="error-text" v-html="errors.date"/>

        <div class="inputWithLabel textareaInput">
          <label class="textareaLabel" for="message">Message : </label>
          <textarea id="message" v-model="message" :disabled="loading" placeholder="Your Message" rows="3">
          </textarea>
        </div>

        <span v-if="errors.message" class="error-text" v-html="errors.message"/>


        <button id="submit" type="button" @click="submit">
          {{ loading ? 'Submitting' : 'Submit' }}
          <v-progress-circular v-if="loading" class="ml-3" indeterminate size="18" width="2"/>
          <span v-else>
            &nbsp;
            &nbsp;
            &nbsp;
            <span class="arr">
            &rarr;
          </span>
          </span>
        </button>
      </form>
    </div>

    <div v-else class="pa-16 d-flex flex-column align-center rounded"
         style="background-color: white;max-width: 700px; max-height: 500px;">
      <h1 class="mb-6 text-sm-h3 text-center">Thank You!</h1>
      <p class="text-center">Thanks for getting in touch with us. We have received your message and will contact you
        shortly.</p>
      <img alt="thank-you" height="30%" src="@/assets/thankyou.svg" width="40%"/>
    </div>

  </div>
</template>

<script>
import {LeadsService} from "@/services/leads-service";
import moment from "moment/moment";
import {templateDefaultBackgroundImage, templateDefaultSubtitle, templateDefaultTitle} from "@/utils/local";

export default {
  name: "lead1",

  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    personId: {
      type: String,
      default: null
    },
  },

  data: () => ({
    title: "",
    subtitle: "",
    backgroundImage: null,
    themeColor: "",
    templateId: null,

    service: new LeadsService(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: "",
    message: "",

    errors: {},
    loading: false,
    isSubmitted: false,
  }),

  watch: {
    firstName(val) {
      if(val !== "" && val.length < 3) {
        this.errors.firstName = "First Name must be at least 3 characters long";
      } else {
        this.errors.firstName = "";
      }
    },

    lastName(val) {
      if(val !== "" && val.length < 3) {
        this.errors.lastName = "Last Name must be at least 3 characters long";
      } else {
        this.errors.lastName = "";
      }
    },

    email(val) {
       if(val !== "" && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/.test(val) === false) {
        this.errors.email = "Email is not valid";
      } else {
        this.errors.email = "";
      }
    },

    phone(val) {
      if(val !== "" && val.length < 6) {
        this.errors.phone = "Phone must be at least 6 characters long";
      } else {
        this.errors.phone = "";
      }
    },

    date(val) {
      if (val !== "" && moment().isSameOrAfter(val)) {
        this.errors.date = "Date must be in the future";
      } else if(/(^\d{4})-(0?[1-9]|1[0-2])-(0?[0-9]|[12]\d|30|31)$/.test(val) === false) {
        this.errors.date = "Date is not valid";
      } else {
        this.errors.date = "";
      }
    },

    message(val) {
      if(val !== "" && val.length < 3) {
        this.errors.message = "message must be at least 3 characters long";
      } else {
        this.errors.message = "";
      }
    },

  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    validate() {
      this.errors = {};

      if (!this.firstName) {
        this.errors.firstName = "First Name is required";
      }

      if (!this.lastName) {
        this.errors.lastName = "Last Name is required";
      }

      if (!this.email) {
        this.errors.email = "Email is required";
      }

      if (!this.phone) {
        this.errors.phone = "Phone is required";
      }

      if (!this.date) {
        this.errors.date = "Date is required";
      }

      if (!this.message) {
        this.errors.message = "Message is required";
      }

      return Object.keys(this.errors).length === 0;
    },

    async submit() {
      if (this.enabled) {

        if(this.errors.firstName || this.errors.lastName || this.errors.email || this.errors.phone || this.errors.date || this.errors.message) {
          return;
        }

        if (!this.validate()) return;

        try {
          this.loading = true
          this.isSubmitted = false

          const data = {
            email: this.email,
            name: this.firstName + (this.lastName ? ' ' + this.lastName : ''),
            phone: this.phone,
            note: this.message,
            date: this.date,
            person: this.personId
          }
          await this.service.create(data)
          this.loading = false
          this.isSubmitted = true
        } catch (e) {
          this.loading = false
          this.errors.submit = e?.response?.data?.message || e?.data?.message || e?.error || 'Some error occurred'
        }

      }
    },


    async loadData() {
      if (!this.enabled) {
        this.personId = this.$store.state.user._id;
      }

      this.loadingMessage = 'Loading...';
      this.loading = true
      this.error = false
      let slug;
      if (this.$route.path.split('/').length === 2) {
        slug = this.$route.path.split('/').slice(1);
      }
      const data = slug? (await this.$axios.get('/persons/slug/' + slug )).data : (await this.$axios.get('/persons/' + this.personId)).data;
      const template = data?.selected_template;

      this.title = template?.form_title || templateDefaultTitle;
      this.subtitle = template?.form_subtitle || templateDefaultSubtitle;
      this.backgroundImage = template?.background_image || templateDefaultBackgroundImage;

      this.loading = false
    },
  }
}
</script>

<style scoped>

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.card {
  width: 90vw;
  max-width: 800px;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background: #fff;
  border-radius: 8px;
  /*box-shadow: 0 0 6px rgba(0,0,0,0.2);*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__header {
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1560762484-813fc97650a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80");
  background-size: cover;
}

.card_header_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  background-color: rgb(0, 0, 0, 0.6);
}

.header__title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.header__description {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 20px;
  width: 90%;
}

.inputWithLabel {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.error-text {
  color: red;
  font-size: 0.8rem;
  align-self: end;
}

.textareaInput {
  align-items: start;
}

.inputWithLabel label {
  display: block;
  text-align: end;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
  width: 25%;
  padding-top: 3px;
  padding-right: 20px;
}

.textareaLabel {
  padding-top: 10px !important;
}

.inputWithLabel input {
  width: 75%;
  height: 40px;
  padding: 0 10px;
  outline: none;
  border-bottom: 1px solid #ccc;
  font-size: 0.9rem;
  font-weight: 400;
  color: #333;
}

.inputWithLabel input:focus {
  border-bottom: 1px solid #000;
}

.inputWithLabel textarea {
  width: 75%;
  resize: none;
  color: #333;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  outline: none;
  border-bottom: 1px solid #ccc;
}

.inputWithLabel textarea:focus {
  border-bottom: 1px solid #000;
}

#submit {
  position: relative;
  height: 40px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 50px;
}

#submit:hover {
  background: #333;
}

.arr {
  position: absolute;
  top: 50%;
  transform: translateY(-53%) translateX(-5px);
  transition-duration: 500ms;
  font-size: 1.5rem;
  font-weight: 600;
}

#submit:hover .arr {
  transform: translateY(-53%) translateX(5px);
}


@media screen and (max-width: 650px) {
  .card {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  .card__header {
    height: 40vh;
  }

  .card_header_inner {
    padding: 40px 20px;
  }

  .header__title {
    font-size: 1.5rem;
  }

  .header__description {
    font-size: 0.8rem;
  }

  .body {
    padding: 10px;
  }

  .inputWithLabel {
    flex-direction: column;
    align-items: start;
  }

  .inputWithLabel label {
    width: 100%;
    text-align: start;
    margin-bottom: 5px;
  }

  .inputWithLabel input {
    width: 100%;
  }

  .inputWithLabel textarea {
    width: 100%;
  }

  .textareaInput {
    align-items: start;
  }

  .textareaLabel {
    padding-top: 10px !important;
  }
}


</style>

